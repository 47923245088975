/* eslint-disable @typescript-eslint/no-explicit-any */
import CameraFrontIcon from "@mui/icons-material/CameraFront";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PaidIcon from "@mui/icons-material/Paid";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import { ISolicitationProps } from "../../../types/Solicitation";
import { convertSOrN } from "../../../utils/utils";
import { CardPrimery } from "../../base/Cards";
import { ButtonRadioStyles } from "../../base/stylesGeneric";
import * as T from "../../base/Text";
import * as S from "../styles";

type ProofOfLifeProps = {
  solicitation: ISolicitationProps;
  getSolicitation: () => Promise<void>;
  isEdit: boolean;
};

export function ProofOfLife({
  solicitation,
  getSolicitation,
  isEdit,
}: ProofOfLifeProps) {
  const { handleSubmit, control, reset } = useForm<any>();

  const dispatch = useAppDispatch();

  const onSubmit = () => null;

  const update = async (name: string, value: boolean) => {
    try {
      const body = {
        [name]: convertSOrN(value),
      };
      await api.put(
        `/solicitation/update/${solicitation?.id_solicitacao}`,
        body
      );
      getSolicitation();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  useEffect(() => {
    if (solicitation?.id_solicitacao) {
      reset({ ...solicitation });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardPrimery container margin="1rem" padding="1.5rem 2rem">
        <S.BoxGrid>
          <Controller
            name="ind_prova_vida_doc"
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <S.BoxLabel checked={value} isEdit={isEdit}>
                  <T.H5 textAlign="center">
                    Prova de vida com Anexos de Documentos
                  </T.H5>
                  <DocumentScannerIcon fontSize="large" />
                  <T.Span textAlign="center">
                    Sera solicitado o usuário anexe um documento de
                    identificação frente e verso
                  </T.Span>
                  <S.BoxStyleRadios>
                    <ButtonRadioStyles checked={value} />
                  </S.BoxStyleRadios>
                  <div style={{ display: "none" }}>
                    <input
                      type="checkbox"
                      checked={value || false}
                      name="ind_prova_vida_doc"
                      disabled={!isEdit}
                      onChange={(e) => {
                        onChange(e);
                        update(name, e.target.checked);
                      }}
                    />
                  </div>
                </S.BoxLabel>
              );
            }}
          />

          <Controller
            name="ind_prova_vida_faceapi"
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <S.BoxLabel checked={value} isEdit={isEdit}>
                  <T.H5 textAlign="center">
                    Prova de Vida com Reconhecimento Facial
                  </T.H5>
                  <SensorOccupiedIcon fontSize="large" />
                  <T.Span textAlign="center">
                    O usuário deverá realizar um reconhecimento facial para
                    comprovar a sua identidade.
                  </T.Span>
                  <S.BoxStyleRadios>
                    <ButtonRadioStyles checked={value} />
                  </S.BoxStyleRadios>
                  <div style={{ display: "none" }}>
                    <input
                      type="checkbox"
                      checked={value || false}
                      disabled={!isEdit}
                      name="ind_prova_vida_faceapi"
                      onChange={(e) => {
                        onChange(e);
                        update(name, e.target.checked);
                      }}
                    />
                  </div>
                </S.BoxLabel>
              );
            }}
          />

          <Controller
            name="ind_prova_vida_pix"
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <S.BoxLabel checked={value} isEdit={isEdit}>
                  <T.H5 textAlign="center">
                    Prova de Vida com Validação por Pix
                  </T.H5>
                  <PaidIcon fontSize="large" />
                  <T.Span textAlign="center">
                    O usuário deverá realizar uma transferência pix de R$ 0,01
                    para comprovar a sua identidade apartir de dados bancários.
                  </T.Span>
                  <S.BoxStyleRadios>
                    <ButtonRadioStyles checked={value} />
                  </S.BoxStyleRadios>
                  <div style={{ display: "none" }}>
                    <input
                      type="checkbox"
                      checked={value || false}
                      disabled={!isEdit}
                      name="ind_prova_vida_pix"
                      onChange={(e) => {
                        onChange(e);
                        update(name, e.target.checked);
                      }}
                    />
                  </div>
                </S.BoxLabel>
              );
            }}
          />

          <Controller
            name="ind_prova_vida_selfie"
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <S.BoxLabel checked={value} isEdit={isEdit}>
                  <T.H5 textAlign="center">
                    Prova de vida com Foto + Selfie
                  </T.H5>
                  <CameraFrontIcon fontSize="large" />
                  <T.Span textAlign="center">
                    O usuário deverá fazer uma selfie com o documento de
                    identificação em mãos.
                  </T.Span>
                  <S.BoxStyleRadios>
                    <ButtonRadioStyles checked={value} />
                  </S.BoxStyleRadios>
                  <div style={{ display: "none" }}>
                    <input
                      type="checkbox"
                      checked={value || false}
                      disabled={!isEdit}
                      name="ind_prova_vida_selfie"
                      onChange={(e) => {
                        onChange(e);
                        update(name, e.target.checked);
                      }}
                    />
                  </div>
                </S.BoxLabel>
              );
            }}
          />

          <Controller
            name="ind_prova_vida_sms"
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <S.BoxLabel checked={value} isEdit={isEdit}>
                  <T.H5 textAlign="center">Prova de vida com Codigo SMS</T.H5>
                  <ForwardToInboxIcon fontSize="large" />
                  <T.Span textAlign="center">
                    O usuário irá receber um codigo de validação por SMS.
                  </T.Span>
                  <S.BoxStyleRadios>
                    <ButtonRadioStyles checked={value} />
                  </S.BoxStyleRadios>
                  <div style={{ display: "none" }}>
                    <input
                      type="checkbox"
                      checked={value || false}
                      disabled={!isEdit}
                      name="ind_prova_vida_sms"
                      onChange={(e) => {
                        onChange(e);
                        update(name, e.target.checked);
                      }}
                    />
                  </div>
                </S.BoxLabel>
              );
            }}
          />

          {/* <Controller
            name="ind_prova_vida_video_chamada"
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <S.BoxLabel checked={value} isEdit={isEdit}>
                  <T.H5 textAlign="center">Video chamada</T.H5>
                  <ForwardToInboxIcon fontSize="large" />
                  <T.Span textAlign="center">
                    Sera solicitado os tipos de dados mais comuns a um usuario,
                    nome, sobrenome, email...
                  </T.Span>
                  <S.BoxStyleRadios>
                    <ButtonRadioStyles checked={value} />
                  </S.BoxStyleRadios>
                  <div style={{ display: "none" }}>
                    <input
                      type="checkbox"
                      checked={value || false}
                      disabled={!isEdit}
                      name="ind_prova_vida_video_chamada"
                      onChange={(e) => {
                        onChange(e);
                        update(name, e.target.checked);
                      }}
                    />
                  </div>
                </S.BoxLabel>
              );
            }}
          /> */}
        </S.BoxGrid>
      </CardPrimery>
    </form>
  );
}
