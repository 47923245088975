import { Grid } from "@mui/material";
import styled, { css } from "styled-components";

export const CardPrimery = styled(Grid)`
  ${({ theme }) => css`
    padding: 0.5rem;
    border-radius: 0.5rem;
    /* margin-top: initial !important; */
    margin-left: 0 !important;
    border: 1px solid ${theme.border.light};
    max-width: 100%;
  `}
`;
