import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {
  Grid,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import Chip from "@mui/material/Chip";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ButtonIcon, ButtonSecondary } from "../../components/base/Buttons";
import { CardPrimery } from "../../components/base/Cards";
import * as TB from "../../components/base/Table";
import * as T from "../../components/base/Text";
import { BodyContainer } from "../../components/BodyContainer";
import { DialogConfirmDelete } from "../../components/Dialog/ConfirmDelete";
import { InputDate } from "../../components/Inputs/InputDate";
import { InputSelect } from "../../components/Inputs/InputSelect";
import { InputBase } from "../../components/Inputs/InputText";
import { useTableStructure } from "../../hooks/useTableStructure";
import { api } from "../../services/api";
import { setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IAgendamento } from "../../types/Agendamento";
import { cpfMask } from "../../utils/mask";
import {
  convertData,
  getClientUrl,
  removeMask,
  statusAgendamentoString,
} from "../../utils/utils";

type SearchTableProps = {
  id_config_agendamento: number;
  protocolo?: string;
  status?: number;
  cpf?: string;
  dataInicial?: string;
  dataFinal?: string;
  descr_solicitacao?: string;
  tipo_solicitacao_item?: number;
  ordem?: "ASC" | "DESC";
};

type ListaAgendamentosProps = Array<
  IAgendamento & {
    nome?: string;
    descr_solicitacao?: string;
    numero_cpf?: string;
  }
>;

export function AgendamentosPage() {
  const { handleSubmit, control, getValues, setValue } =
    useForm<SearchTableProps>();

  const navegate = useNavigate();

  const dispatch = useAppDispatch();

  const {
    Clients: { clientToUpdate },
    Utils: { device },
    TableStructure: { typeSolicitation },
  } = useAppSelector((state) => state);

  const [listaEnderecos, setListaEnderecos] = useState<
    Array<{
      id_config_agendamento: number;
      descricao: string;
      endereco_atend_presencial: string;
    }>
  >([]);
  const [onOpenMenu, setOnOpenMenu] = useState<null | HTMLElement>(null);
  const [itemSelectedMenu, setItemSelectedMenu] = useState<null | IAgendamento>(
    null
  );

  const [agendamentos, setAgendamentos] = useState<ListaAgendamentosProps>([]);
  const [isFilter, setIsFilter] = useState(false);
  const [isCancelAg, setIsCancelAg] = useState<IAgendamento>(
    {} as IAgendamento
  );

  useTableStructure(["status", "typeSolicitation"]);

  async function getAgendamentos({
    id_config_agendamento,
    cpf,
    status,
    dataInicial,
    dataFinal,
    protocolo,
    descr_solicitacao,
    tipo_solicitacao_item,
    ordem,
  }: SearchTableProps) {
    try {
      const queryParam = JSON.stringify({
        id_config_agendamento,
        protocoloAg: protocolo || "",
        cpf: removeMask(cpf || "") || "",
        statusAg: status || "",
        dataInicial: convertData(dataInicial) || "",
        dataFinal: convertData(dataFinal) || "",
        descr_solicitacao: descr_solicitacao || "",
        tipo_solicitacao_item: tipo_solicitacao_item || "",
        ordem: ordem || "ASC",
      });

      const { data } = await api.get(`/agendamento/list/${btoa(queryParam)}`);

      setAgendamentos(data);
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  }

  const onSubmit: SubmitHandler<SearchTableProps> = async (body) => {
    try {
      await getAgendamentos(body);
    } catch (err) {
      setAgendamentos([]);
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  };
  const getUpdateTables = () => onSubmit(getValues());

  const cancelarAgendamento = async (idAgendamento: number) => {
    try {
      const { data } = await api.delete(
        `/agendamento/cancelar/${idAgendamento}`
      );
      setIsCancelAg({} as IAgendamento);
      dispatch(setToastMessage({ type: "success", message: data.message }));
      getUpdateTables();
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  };

  const getEnderecos = async () => {
    const { data } = await api.get("/agendamento/config-enderecos");
    if (!data.length) return;
    setValue("id_config_agendamento", data[0].id_config_agendamento);
    setListaEnderecos(data);
  };

  useEffect(() => {
    getEnderecos();
    getUpdateTables();
  }, [clientToUpdate.sigla]);

  return (
    <BodyContainer menu="Agendamentos">
      <Grid
        container
        spacing={1}
        style={{
          margin: "1rem 0 1.5rem 0",
          borderLeft: "4px solid #ff6e07",
          paddingLeft: "1rem",
        }}
      >
        <Grid item xs={12}>
          <T.H2>AGENDAMENTOS</T.H2>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "0.6rem",
          }}
        >
          <T.Paragraph lineHeight="1.3rem">
            Veja todos seus agendamentos, crie novos e edite os existentes.
          </T.Paragraph>
        </Grid>
      </Grid>
      <CardPrimery container spacing={1} height="100%">
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ flex: "1" }}
        >
          <Grid container spacing={1} marginBottom="1">
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              style={{ height: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  paddingRight: "1rem",
                }}
              >
                <Tooltip title="Filtar agendamentos">
                  <IconButton onClick={() => setIsFilter((state) => !state)}>
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
            {isFilter && (
              <>
                <Grid item xs={12} md={3}>
                  <InputSelect
                    title="Local do agendamento"
                    control={control}
                    id="id_config_agendamento"
                    label="Local do agendamento"
                    name="id_config_agendamento"
                  >
                    <option> </option>
                    {listaEnderecos.length &&
                      listaEnderecos.map((end) => (
                        <option
                          key={end.id_config_agendamento}
                          value={end.id_config_agendamento}
                        >
                          {end.descricao}
                        </option>
                      ))}
                  </InputSelect>
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputBase
                    control={control}
                    id="descr_solicitacao"
                    label="Solicitação"
                    name="descr_solicitacao"
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputSelect
                    title="Status"
                    control={control}
                    id="status"
                    label="Status"
                    name="status"
                    isErrorMessage={false}
                  >
                    <option> </option>
                    {[1, 2, 3].map((status) => {
                      return (
                        <option key={status} value={status}>
                          {statusAgendamentoString(status).label}
                        </option>
                      );
                    })}
                  </InputSelect>
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputSelect
                    title="Tipo Solicitação"
                    control={control}
                    id="tipo_solicitacao_item"
                    label="Tipo Solicitação"
                    name="tipo_solicitacao_item"
                    isErrorMessage={false}
                  >
                    <option> </option>
                    {typeSolicitation.itemsTable?.length &&
                      typeSolicitation.itemsTable.map((type) => {
                        if (type.item_tabela === 0) return null;
                        return (
                          <option
                            key={type.item_tabela}
                            value={type.item_tabela}
                          >
                            {type.descr_tabela}
                          </option>
                        );
                      })}
                  </InputSelect>
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputBase
                    control={control}
                    id="cpf"
                    label="Nº CPF"
                    name="cpf"
                    type="cpf"
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputBase
                    control={control}
                    id="protocolo"
                    label="Protocolo"
                    name="protocolo"
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputDate
                    control={control}
                    id="dataInicial"
                    name="dataInicial"
                    label="Data inicial"
                    defaultValue={new Date().toISOString().split("T")[0]}
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputDate
                    control={control}
                    id="dataFinal"
                    name="dataFinal"
                    label="Data final"
                    defaultValue=""
                    isErrorMessage={false}
                  />
                </Grid>

                <Grid item xs={12} md={3}>
                  <InputSelect
                    title="Ordem"
                    control={control}
                    id="ordem"
                    label="Ordem"
                    name="ordem"
                    isErrorMessage={false}
                  >
                    <option value="ASC" defaultChecked>
                      Crescente
                    </option>
                    <option value="DESC">Decrescente</option>
                  </InputSelect>
                </Grid>
                <Grid item xs={12} md={2}>
                  <ButtonSecondary
                    variant="outlined"
                    type="submit"
                    style={{ width: "100%", gap: "0.5rem" }}
                  >
                    Busca
                    <SearchIcon />
                  </ButtonSecondary>
                </Grid>
              </>
            )}
          </Grid>
        </form>

        <TB.TableContainer sx={{ maxHeight: "65vh", minHeight: "65vh" }}>
          <TB.Table stickyHeader aria-label="sticky table">
            <TB.TableHead>
              <TB.TableRow>
                <TB.TableCell width="40%">SOLICITAÇÃO</TB.TableCell>
                {device === "desktop" && (
                  <TB.TableCell width="10rem" minWidth="10rem">
                    USUARIO
                  </TB.TableCell>
                )}
                <TB.TableCell align="center" minWidth="10rem">
                  CPF
                </TB.TableCell>
                <TB.TableCell align="center" minWidth="10rem">
                  DATA
                </TB.TableCell>
                <TB.TableCell align="center" minWidth="10rem">
                  HORA
                </TB.TableCell>
                <TB.TableCell align="center" minWidth="15rem">
                  STATUS
                </TB.TableCell>
                <TB.TableCell align="center" minWidth="15rem">
                  PROTOCOLO
                </TB.TableCell>
                <TB.TableCell align="center" minWidth="5rem">
                  AÇÕES
                </TB.TableCell>
              </TB.TableRow>
            </TB.TableHead>
            <TB.TableBody>
              {agendamentos.map((item) => (
                <TB.TableRow key={item.id_solicit_agendamento}>
                  <TB.TableCell>{item.descr_solicitacao}</TB.TableCell>
                  {device === "desktop" && (
                    <TB.TableCell>{item?.nome}</TB.TableCell>
                  )}
                  <TB.TableCell align="center" minWidth="10rem">
                    {item?.numero_cpf ? cpfMask(item.numero_cpf) : ""}
                  </TB.TableCell>
                  <TB.TableCell align="center" minWidth="10rem">
                    {convertData(item.dt_agendamento)}
                  </TB.TableCell>
                  <TB.TableCell align="center" minWidth="10rem">
                    {item.hr_agendamento}
                  </TB.TableCell>
                  <TB.TableCell align="center" minWidth="15rem">
                    <Chip
                      label={statusAgendamentoString(item.status).label}
                      color={statusAgendamentoString(item.status).color}
                    />
                  </TB.TableCell>
                  <TB.TableCell align="center" minWidth="15rem">
                    {item.protocolo_agendamento}
                  </TB.TableCell>
                  <TB.TableCell align="center" minWidth="5rem">
                    <ButtonIcon
                      variant="text"
                      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
                        setOnOpenMenu(e.currentTarget);
                        setItemSelectedMenu(item);
                      }}
                    >
                      <MoreVertIcon fontSize="small" />
                    </ButtonIcon>
                  </TB.TableCell>
                </TB.TableRow>
              ))}
            </TB.TableBody>
          </TB.Table>
        </TB.TableContainer>
      </CardPrimery>
      <Menu
        anchorEl={onOpenMenu}
        open={Boolean(onOpenMenu)}
        onClose={() => setOnOpenMenu(null)}
        style={{ boxShadow: "" }}
      >
        <MenuItem
          onClick={() =>
            navegate(
              `../${getClientUrl()}/solicitacao/${
                itemSelectedMenu?.id_sol_usuario
              }`
            )
          }
        >
          <ListItemIcon>
            <VisibilityOutlinedIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Visualizar solicitação</ListItemText>
        </MenuItem>

        {itemSelectedMenu?.status === 1 && (
          <MenuItem
            onClick={() => {
              setIsCancelAg(itemSelectedMenu);
              setOnOpenMenu(null);
            }}
          >
            <ListItemIcon>
              <ClearIcon fontSize="small" />
            </ListItemIcon>
            <ListItemText>Cancelar agendamento</ListItemText>
          </MenuItem>
        )}
      </Menu>
      <DialogConfirmDelete
        isOpen={!!isCancelAg.id_solicit_agendamento}
        onClose={() => setIsCancelAg({} as IAgendamento)}
        title="Cancelar agendamento"
        textContent="Tem certeza que deseja cancelar o agendamento? Essa ação não poderá ser desfeita."
        onConfirm={() => cancelarAgendamento(isCancelAg.id_solicit_agendamento)}
      />
    </BodyContainer>
  );
}
