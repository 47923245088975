import { Reducer } from "redux";

import { ClientsTypes, ClientsState } from "./types";

const INITIAL_STATE: ClientsState = {
  clientLogged: {
    id_cliente: "",
    nome_cliente: "",
    numero_cnpj: "",
    sigla: "",
    logomarca: "",
    ind_ativo: "S",
    ind_login_cpf: "N",
    ind_permitir_criar_conta: "N",
    ind_agendamento: "N",
    ind_default: "N",
    schema_base: "",
    dt_cadastro: "",
    hr_cadastro: "",
  },
  clientToUpdate: {
    id_cliente: "",
    nome_cliente: "",
    numero_cnpj: "",
    sigla: "",
    logomarca: "",
    ind_ativo: "S",
    ind_login_cpf: "N",
    ind_permitir_criar_conta: "N",
    ind_agendamento: "N",
    ind_default: "N",
    schema_base: "",
    dt_cadastro: "",
    hr_cadastro: "",
  },
  clients: [],
};

const reducer: Reducer<ClientsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ClientsTypes.SET_CLIENT_LOGGED:
      return { ...state, clientLogged: action.payload };

    case ClientsTypes.SET_CLIENT_TO_UPDATE:
      return { ...state, clientToUpdate: action.payload };

    case ClientsTypes.SET_CLIENTS_ARRAY:
      return { ...state, clients: action.payload };

    default:
      return state;
  }
};

export default reducer;
