import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import { Grid } from "@mui/material";

import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import DialogComponent from "../index";

interface IDialogConfirmDelete {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  textContent?: string | React.ReactNode;
  onConfirm?: () => void;
}

export function DialogConfirmDelete({
  isOpen,
  onClose,
  title,
  textContent,
  onConfirm,
}: IDialogConfirmDelete) {
  return (
    <DialogComponent
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      content={
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <T.Paragraph>
              {textContent ||
                "Tem certeza que deseja excluir? Essa ação não poderá ser desfeita."}
            </T.Paragraph>
          </Grid>
        </Grid>
      }
      footer={
        <>
          <B.ButtonSecondary onClick={onClose} variant="text">
            Cancelar
          </B.ButtonSecondary>
          <B.ButtonPrimary
            onClick={onConfirm}
            color="error"
            style={{ display: "flex", alignItems: "center" }}
          >
            Excluir
            <DeleteOutlineOutlinedIcon
              fontSize="small"
              style={{ marginLeft: "0.25rem" }}
            />
          </B.ButtonPrimary>
        </>
      }
    />
  );
}
