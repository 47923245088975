import styled, { css } from "styled-components";

type ContainerInputFileProps = {
  success?: boolean;
};

export const ContainerInputFile = styled.div<ContainerInputFileProps>`
  ${({ theme, success }) => css`
    width: 100%;
    height: 4.125rem;
    background-color: ${theme.background.active};
    display: flex;
    align-items: center;
    justify-content: space-between;

    border-style: dashed;
    border-color: ${theme.border.light};
    border-width: 3px;
    border-spacing: 0.5rem;
    border-radius: 0.5rem;
    ${success &&
    css`
      border-color: ${theme.border.light};
      border-style: solid;
    `}
  `}
`;

export const InputLabel = styled.label<ContainerInputFileProps>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  padding: 0.5rem 1rem;
`;
