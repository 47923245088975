/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable react/destructuring-assignment */

import React from "react";

import { ButtonPrimary } from "../base/Buttons";
import * as T from "../base/Text";

// Definir os tipos de estado e props (se necessário)
interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
}

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

export class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  ErrorBoundaryState
> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, error: null, errorInfo: null };
  }

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    // Atualizar o estado para que a próxima renderização mostre a UI alternativa.
    return { hasError: true, error, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // Você também pode registrar o erro em um serviço de relatório de erros
    console.log(error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render() {
    if (this.state.hasError) {
      // Interface amigável para o usuário
      return (
        <div
          style={{
            height: " 100vh",
            width: "100vw",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            padding: "20px",
          }}
        >
          <T.H1 textAlign="center">Opa! Algo deu errado.</T.H1>
          <T.Paragraph textAlign="center">
            Desculpe pelo inconveniente. Encontramos um problema inesperado.
          </T.Paragraph>
          <details
            style={{
              whiteSpace: "pre-wrap",
              margin: "20px",
              padding: "20px",
              background: "#f0f0f0",
              borderRadius: "5px",
              maxWidth: "80vw",
              overflow: "auto",
            }}
          >
            <summary>Clique para ver os detalhes do erro</summary>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo && this.state.errorInfo.componentStack}
          </details>
          <ButtonPrimary
            onClick={() => window.location.reload()}
            style={{
              padding: "10px",
              margin: "20px",
              fontSize: "1rem",
              cursor: "pointer",
            }}
          >
            Tentar Novamente
          </ButtonPrimary>
          <br />
          <T.Span>
            Se o problema persistir, tire um print e envie para o suporte.
          </T.Span>
        </div>
      );
    }

    return this.props.children;
  }
}
