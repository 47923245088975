import { AxiosError, AxiosRequestHeaders } from "axios";

import { api } from "./api";

export async function apiGet(url: string, headers?: AxiosRequestHeaders) {
  try {
    const data = await api.get(url, { headers });
    return data;
  } catch (error) {
    const axiosError = error as AxiosError;
    throw axiosError.response?.data;
  }
}
