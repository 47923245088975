import { Drawer } from "@mui/material";
import styled, { css } from "styled-components";

export const DrawerBottom = styled(Drawer)``;

export const DrawerContainer = styled.form`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 100%;
    height: 80vh;
    padding: 0 3rem;
    position: relative;
  `}
`;

export const DrawerHeader = styled.header`
  ${() => css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    padding-top: 1rem;

    width: 100%;
    height: 5rem;
  `}
`;

export const DrawerContent = styled.div`
  ${() => css`
    flex: 1;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 1rem;
    padding-top: 1rem;
    overflow-x: hidden;
    overflow-y: auto;
  `}
`;

export const DrawerFooter = styled.footer`
  ${({ theme }) => css`
    width: 100%;

    & > div {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      gap: 1rem;
      padding: 1rem 0;
      border-top: 1px solid ${theme.border.light};
    }
  `}
`;
