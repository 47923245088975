import styled, { css } from "styled-components";

export const Content = styled.form`
  ${({ theme }) => css`
    width: 200px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    padding: 1rem;
    background-color: ${theme.palette.primary};
    border-radius: 0.5rem;
  `}
`;
