import { Header } from "../Header";
import { Menu } from "../Menu";
import * as S from "./styles";

type BodyContainerProps = {
  menu?: string;
  bodyStyle?: React.CSSProperties;
  children: React.ReactNode;
};

export function BodyContainer({
  menu,
  bodyStyle,
  children,
}: BodyContainerProps) {
  return (
    <S.Container>
      {menu && <Menu active={menu} />}
      <S.Content>
        <Header />
        <S.Body style={bodyStyle}>{children}</S.Body>
      </S.Content>
    </S.Container>
  );
}
