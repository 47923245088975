import styled from "styled-components";

export const Conteiner = styled.div`
  width: 100%;
  min-height: 90vh;
  max-height: 90vh;
  display: flex;
  justify-content: space-between;
  gap: 2rem;

  @media (max-width: 768px) {
    max-height: initial;
  }
`;
