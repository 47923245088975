/* eslint-disable no-empty */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CreditScoreIcon from "@mui/icons-material/CreditScore";
import DeleteIcon from "@mui/icons-material/Delete";
import DirectionsCarIcon from "@mui/icons-material/DirectionsCar";
import EditIcon from "@mui/icons-material/Edit";
import ReportIcon from "@mui/icons-material/Report";
import {
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import { currencyMask } from "../../../utils/mask";
import { DECLARACAO_BENS, getIdURLUserSol } from "../../../utils/utils";
import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import { InputRadios } from "../../Inputs/InputRadios";
import { AddBensAndOuns } from "../../ModalsComponents/AddBensAndOuns";
import * as S from "../styles";

type IDeclaracaoBens = {
  isEditable: boolean;
  getUserSolicitation(): Promise<void>;
  isCompleted: boolean;
  isOpenCorrecion: boolean;
  isOnChangeSetListCorrecion: (etapa: string, campo: string) => boolean;
  onChangeSetListCorrecion: (
    etapa: string,
    campo: string,
    label: string
  ) => void;
  correctionValuesJsonData: any;
  statusCorrecion: number;
};

type IDeclaracaoBensProps = {
  id_sol_dados_decl_bens?: number;
  id_sol_usuario?: number;
  ind_declarante_irrf: "S" | "N";
  ind_declarante_outras_fontes: "S" | "N";
};

type ListBensOnus = {
  id_sol_dados_bens_onus: number;
  id_sol_dados_decl_bens: number;
  tipo_bem_onus_tab?: number;
  tipo_bem_onus_item?: number;
  quantidade?: string;
  descricao?: string;
  valor?: string;

  hr_inclusao?: string;
  dt_inclusao?: string;
};

export function DeclaracaoBens({
  isEditable,
  getUserSolicitation,
  isCompleted,
  isOpenCorrecion,
  isOnChangeSetListCorrecion,
  onChangeSetListCorrecion,
  correctionValuesJsonData,
  statusCorrecion,
}: IDeclaracaoBens) {
  const { handleSubmit, control, getValues, reset } = useForm<any>();

  const [declaracaoBens, setDeclaracaoBens] = useState<IDeclaracaoBensProps>(
    {} as IDeclaracaoBensProps
  );
  const [listBens, setListBens] = useState<ListBensOnus[]>([]);
  const [listOnus, setListOnus] = useState<ListBensOnus[]>([]);

  const [indBensOnus, setIndBensOnus] = useState("N");

  const dispatch = useAppDispatch();

  const [typeModal, setTypeModal] = useState<"bens" | "onus">("bens");
  const [openModalDeclarBens, setOpenModalDeclarBens] = useState<
    Object | boolean
  >(false);

  const getData = async () => {
    try {
      const { data } = await api.get(
        `/solicitation/bens-onus/${getIdURLUserSol()}`
      );

      setDeclaracaoBens(data);
      reset({
        ind_declarante_irrf: data.ind_declarante_irrf || "N",
        ind_declarante_outras_fontes: data.ind_declarante_outras_fontes || "N",
      });
      if (data.ind_declarante_outras_fontes === "S") {
        setListBens(data?.bens ? data.bens : []);
        setListOnus(data?.onus ? data.onus : []);
        setIndBensOnus("S");
      } else if (data.ind_declarante_outras_fontes === "N") {
        setListBens([]);
        setListOnus([]);
      }
    } catch (err: any) {}
  };

  const onSubmit = async (body: any) => {
    try {
      await api.post(
        `/solicitation/bens-onus/${getIdURLUserSol()}/${
          declaracaoBens.id_sol_dados_decl_bens || ""
        }`,
        { ...body, commited: "S" }
      );

      getData();
      await getUserSolicitation();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const saveData = async () => {
    try {
      const body = getValues();

      const { data } = await api.post(
        `/solicitation/bens-onus/${getIdURLUserSol()}/${
          declaracaoBens.id_sol_dados_decl_bens || ""
        }`,
        { ...body }
      );

      getData();
      if (data?.message)
        dispatch(setToastMessage({ type: "success", message: data.message }));
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const deleteItemListBensOnus = async (id: number) => {
    try {
      const { data } = await api.delete(
        `/solicitation/bens-onus/${getIdURLUserSol()}/${id}`
      );

      getData();
      if (data?.message)
        dispatch(setToastMessage({ type: "success", message: data.message }));
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  useEffect(() => {
    if (!declaracaoBens.id_sol_usuario) {
      getData();
    }
  }, []);

  return (
    <Grid container spacing={1} padding="1rem">
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        style={{ width: "100%", height: "100%" }}
      >
        <Grid container spacing={1} style={{ width: "100%" }}>
          <S.ContainerInput
            item
            xs={12}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              DECLARACAO_BENS,
              "ind_declarante_irrf"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                DECLARACAO_BENS,
                "ind_declarante_irrf",
                "Sou declarante do Imposto de Renda Retido na Fonte"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[
                  `${DECLARACAO_BENS}-ind_declarante_irrf`
                ]
              }
              message={
                correctionValuesJsonData[
                  `${DECLARACAO_BENS}-ind_declarante_irrf`
                ]?.message
              }
            />
            <InputRadios
              control={control}
              id="ind_declarante_irrf"
              title="Sou declarante do Imposto de Renda Retido na Fonte"
              name="ind_declarante_irrf"
              readOnly={!isEditable}
              required
              row
            >
              <FormControlLabel
                value="S"
                control={<Radio size="small" />}
                label="Sim"
              />
              <FormControlLabel
                value="N"
                control={<Radio size="small" />}
                label="Não"
              />
            </InputRadios>
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            display="flex"
            justifyContent="space-between"
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              DECLARACAO_BENS,
              "ind_declarante_outras_fontes"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                DECLARACAO_BENS,
                "ind_declarante_outras_fontes",
                "Bens e Onus Reais"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[
                  `${DECLARACAO_BENS}-ind_declarante_outras_fontes`
                ]
              }
              message={
                correctionValuesJsonData[
                  `${DECLARACAO_BENS}-ind_declarante_outras_fontes`
                ]?.message
              }
            />
            <InputRadios
              control={control}
              id="ind_declarante_outras_fontes"
              title="Bens e Onus Reais"
              name="ind_declarante_outras_fontes"
              readOnly={!isEditable}
              required
              row
            >
              <FormControlLabel
                value="S"
                control={
                  <Radio
                    size="small"
                    onChange={() => {
                      setIndBensOnus("S");
                    }}
                  />
                }
                label="Possuo os seguintes bens, ônus reais e obrigações"
              />
              <FormControlLabel
                value="N"
                control={
                  <Radio
                    size="small"
                    onChange={() => {
                      setIndBensOnus("N");
                    }}
                  />
                }
                label="Não possuo bens e renda."
              />
            </InputRadios>
          </S.ContainerInput>
          {indBensOnus === "S" && (
            <>
              <Divider style={{ width: "100%", marginTop: "0.8rem" }} />
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <div>
                  <T.H6>BENS E RENDA</T.H6>
                  <T.Paragraph>Ex: Casa, carro, etc.</T.Paragraph>
                </div>
                {isEditable && (
                  <B.ButtonSecondary
                    variant="outlined"
                    onClick={() => {
                      setTypeModal("bens");
                      setOpenModalDeclarBens(true);
                    }}
                    style={{ marginTop: "1rem" }}
                  >
                    ADICIONA BENS
                    <DirectionsCarIcon
                      fontSize="small"
                      style={{ marginLeft: "0.5rem" }}
                    />
                  </B.ButtonSecondary>
                )}
              </Grid>
              <TableContainer sx={{ margin: "1rem 0" }}>
                {listBens.length ? (
                  <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell
                          align="left"
                          style={{ minWidth: "8rem", width: "8rem" }}
                        >
                          QUANTIDADE
                        </TableCell>
                        <TableCell>DESCRIÇÃO</TableCell>
                        <TableCell
                          align="center"
                          style={{ minWidth: "10rem", width: "10rem" }}
                        >
                          VALOR
                        </TableCell>
                        {isEditable && (
                          <TableCell
                            align="center"
                            style={{ minWidth: "8rem", width: "8rem" }}
                          >
                            AÇÕES
                          </TableCell>
                        )}
                        {isOpenCorrecion && (
                          <TableCell
                            align="center"
                            style={{ minWidth: "2rem", width: "2rem" }}
                          >
                            CORREÇÃO
                          </TableCell>
                        )}
                        {[2, 3].includes(statusCorrecion) && (
                          <TableCell
                            align="center"
                            style={{ minWidth: "2rem", width: "2rem" }}
                          />
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listBens.map((bens) => (
                        <TableRow
                          hover
                          id={`id-${bens.id_sol_dados_bens_onus}`}
                          style={{ cursor: "pointer" }}
                          key={bens.id_sol_dados_bens_onus}
                          onClick={() => {
                            if (isOpenCorrecion) {
                              onChangeSetListCorrecion(
                                DECLARACAO_BENS,
                                String(bens.id_sol_dados_bens_onus),
                                String(bens.descricao)
                              );
                            }
                          }}
                        >
                          <TableCell align="center">
                            {bens.quantidade}
                          </TableCell>
                          <TableCell>{bens.descricao}</TableCell>
                          <TableCell align="center">
                            {currencyMask(bens.valor)}
                          </TableCell>
                          {isEditable && (
                            <TableCell align="center">
                              <IconButton
                                onClick={() => {
                                  setTypeModal("bens");
                                  setOpenModalDeclarBens(bens);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  deleteItemListBensOnus(
                                    bens.id_sol_dados_bens_onus
                                  )
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          )}
                          {isOpenCorrecion && (
                            <TableCell align="center">
                              <Checkbox
                                color="warning"
                                checked={isOnChangeSetListCorrecion(
                                  DECLARACAO_BENS,
                                  String(bens.id_sol_dados_bens_onus)
                                )}
                                onChange={() =>
                                  onChangeSetListCorrecion(
                                    DECLARACAO_BENS,
                                    String(bens.id_sol_dados_bens_onus),
                                    String(bens.descricao)
                                  )
                                }
                              />
                            </TableCell>
                          )}
                          {correctionValuesJsonData[
                            `${DECLARACAO_BENS}-${String(
                              bens.id_sol_dados_bens_onus
                            )}`
                          ] && !isOpenCorrecion ? (
                            <TableCell align="center">
                              {correctionValuesJsonData[
                                `${DECLARACAO_BENS}-${String(
                                  bens.id_sol_dados_bens_onus
                                )}`
                              ]?.message ? (
                                <Tooltip
                                  title={
                                    correctionValuesJsonData[
                                      `${DECLARACAO_BENS}-${String(
                                        bens.id_sol_dados_bens_onus
                                      )}`
                                    ]?.message
                                  }
                                  style={{ cursor: "help" }}
                                >
                                  <ReportIcon color="warning" />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title={false}
                                  disableFocusListener
                                  disableInteractive
                                  disableHoverListener
                                  style={{ cursor: "help" }}
                                >
                                  <ReportIcon color="warning" />
                                </Tooltip>
                              )}
                            </TableCell>
                          ) : (
                            <TableCell />
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : null}
              </TableContainer>
              <Grid
                item
                xs={12}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <S.IconInputCorrecion
                  isIcon={correctionValuesJsonData[`${DECLARACAO_BENS}-onus`]}
                  message={
                    correctionValuesJsonData[`${DECLARACAO_BENS}-onus`]?.message
                  }
                  style={{ top: "0" }}
                />
                <div>
                  <T.H6>DÍVIDAS E ÔNUS REAIS</T.H6>
                  <T.Paragraph>
                    Ex: Empréstimos , financiamentos, crédito consignado.
                    Informar o valor total da dívida(empréstimo)
                  </T.Paragraph>
                </div>
                {isEditable && (
                  <B.ButtonSecondary
                    variant="outlined"
                    onClick={() => {
                      setTypeModal("onus");
                      setOpenModalDeclarBens(true);
                    }}
                    style={{ marginTop: "1rem" }}
                  >
                    ADICIONA ÔNUS
                    <CreditScoreIcon
                      fontSize="small"
                      style={{ marginLeft: "0.5rem" }}
                    />
                  </B.ButtonSecondary>
                )}
              </Grid>
              <TableContainer sx={{ margin: "1rem 0" }}>
                {listOnus.length ? (
                  <Table stickyHeader aria-label="sticky table" size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell>DESCRIÇÃO</TableCell>
                        <TableCell
                          align="center"
                          style={{ minWidth: "10rem", width: "10rem" }}
                        >
                          VALOR
                        </TableCell>
                        {isEditable && (
                          <TableCell
                            align="center"
                            style={{ minWidth: "8rem", width: "8rem" }}
                          >
                            AÇÕES
                          </TableCell>
                        )}
                        {isOpenCorrecion && (
                          <TableCell
                            align="center"
                            style={{ minWidth: "2rem", width: "2rem" }}
                          >
                            CORREÇÃO
                          </TableCell>
                        )}
                        {[2, 3].includes(statusCorrecion) && (
                          <TableCell
                            align="center"
                            style={{ minWidth: "2rem", width: "2rem" }}
                          />
                        )}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {listOnus.map((onus) => (
                        <TableRow
                          hover
                          id={`id-${onus.id_sol_dados_bens_onus}`}
                          style={{ cursor: "pointer" }}
                          key={onus.id_sol_dados_bens_onus}
                          onClick={() => {
                            if (isOpenCorrecion) {
                              onChangeSetListCorrecion(
                                DECLARACAO_BENS,
                                String(`onus-${onus.id_sol_dados_bens_onus}`),
                                String(onus.descricao)
                              );
                            }
                          }}
                        >
                          <TableCell>{onus.descricao}</TableCell>
                          <TableCell align="center">
                            {currencyMask(onus.valor)}
                          </TableCell>
                          {isEditable && (
                            <TableCell align="center">
                              <IconButton
                                onClick={() => {
                                  setTypeModal("onus");
                                  setOpenModalDeclarBens(onus);
                                }}
                              >
                                <EditIcon />
                              </IconButton>
                              <IconButton
                                onClick={() =>
                                  deleteItemListBensOnus(
                                    onus.id_sol_dados_bens_onus
                                  )
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          )}
                          {isOpenCorrecion && (
                            <TableCell align="center">
                              <Checkbox
                                color="warning"
                                checked={isOnChangeSetListCorrecion(
                                  DECLARACAO_BENS,
                                  String(`onus-${onus.id_sol_dados_bens_onus}`)
                                )}
                                onChange={() =>
                                  onChangeSetListCorrecion(
                                    DECLARACAO_BENS,
                                    String(
                                      `onus-${onus.id_sol_dados_bens_onus}`
                                    ),
                                    String(onus.descricao)
                                  )
                                }
                              />
                            </TableCell>
                          )}
                          {correctionValuesJsonData[
                            `${DECLARACAO_BENS}-onus-${String(
                              onus.id_sol_dados_bens_onus
                            )}`
                          ] && !isOpenCorrecion ? (
                            <TableCell align="center">
                              {correctionValuesJsonData[
                                `${DECLARACAO_BENS}-onus-${String(
                                  onus.id_sol_dados_bens_onus
                                )}`
                              ]?.message ? (
                                <Tooltip
                                  title={
                                    correctionValuesJsonData[
                                      `${DECLARACAO_BENS}-onus-${String(
                                        onus.id_sol_dados_bens_onus
                                      )}`
                                    ]?.message
                                  }
                                  style={{ cursor: "help" }}
                                >
                                  <ReportIcon color="warning" />
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title={false}
                                  disableFocusListener
                                  disableInteractive
                                  disableHoverListener
                                  style={{ cursor: "help" }}
                                >
                                  <ReportIcon color="warning" />
                                </Tooltip>
                              )}
                            </TableCell>
                          ) : (
                            <TableCell />
                          )}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : null}
              </TableContainer>
            </>
          )}
        </Grid>
        {isEditable && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "1rem",
            }}
          >
            {!isCompleted && (
              <B.ButtonPrimary variant="outlined" onClick={() => saveData()}>
                Salvar
              </B.ButtonPrimary>
            )}
            <B.ButtonPrimary type="submit">
              {isCompleted ? "Atualizar" : "CONCLUIR ETAPA"}
            </B.ButtonPrimary>
          </div>
        )}
      </form>
      {isEditable && (
        <AddBensAndOuns
          isOpen={Boolean(openModalDeclarBens)}
          onClose={() => setOpenModalDeclarBens(false)}
          bensOnus={openModalDeclarBens || {}}
          updateListBensOnus={getData}
          isEditable={isEditable}
          idDeclBens={Number(declaracaoBens.id_sol_dados_decl_bens)}
          typeModal={typeModal}
        />
      )}
    </Grid>
  );
}
