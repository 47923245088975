/* eslint-disable @typescript-eslint/no-explicit-any */
import ArticleIcon from "@mui/icons-material/Article";
import { Divider } from "@mui/material";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import {
  convertSOrN,
  INDOBRIGATORIODOCUMENT,
  TIPODOCUMENTOITEM,
} from "../../../utils/utils";
import { CardPrimery } from "../../base/Cards";
import { SwitchStyle } from "../../base/stylesGeneric";
import * as T from "../../base/Text";
import * as S from "../styles";

type DocsSolicitadosProps = {
  documentsSolicitantion: any[];
  isEdit: boolean;
};

type documentsProps = {
  id_solicitacao: number;
  cod_tabela: string;
  ind_solicitado: boolean;
  ind_obrigatorio: boolean;
  item_tabela: string;
};

export function DocsSolicitados({
  documentsSolicitantion,
  isEdit,
}: DocsSolicitadosProps) {
  const { handleSubmit, control, getValues, setValue } = useForm<any>();

  const onSubmit: SubmitHandler<any> = async () => null;

  const dispatch = useAppDispatch();

  const idSolicitacao = Number(useParams().id);

  const deleteDocument = async ({
    cod_tabela,
    item_tabela,
  }: documentsProps) => {
    try {
      await api.delete(
        `/solicitation/documnets/${idSolicitacao}?cod_tabela=${
          cod_tabela || ""
        }&item_tabela=${item_tabela || ""}`
      );
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const updateDocument = async ({
    cod_tabela,
    item_tabela,
    ind_solicitado,
    ind_obrigatorio,
  }: documentsProps) => {
    try {
      await api.put(
        `/solicitation/documnets/${idSolicitacao}?cod_tabela=${
          cod_tabela || ""
        }&item_tabela=${item_tabela || ""}&ind_obrigatorio=${
          convertSOrN(ind_obrigatorio) || ""
        }&ind_solicitado=${convertSOrN(ind_solicitado) || ""}`
      );
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const toogleDocument = async (
    document: any,
    value: boolean,
    name: string
  ) => {
    if (name.includes(TIPODOCUMENTOITEM)) {
      const indObrigatorioProperty =
        `${INDOBRIGATORIODOCUMENT}${document.item_tabela}` as any;
      const indObrigatorioValue = getValues(indObrigatorioProperty);

      const data: documentsProps = {
        id_solicitacao: idSolicitacao,
        ind_solicitado: value,
        cod_tabela: document.cod_tabela,
        ind_obrigatorio:
          !value && getValues(indObrigatorioProperty)
            ? false
            : indObrigatorioValue,
        item_tabela: document.item_tabela,
      };

      if (value) updateDocument(data);
      else deleteDocument(data);

      setValue(
        indObrigatorioProperty,
        !value && getValues(indObrigatorioProperty)
          ? false
          : indObrigatorioValue
      );
    } else if (name.includes(INDOBRIGATORIODOCUMENT)) {
      const tipoDocumentProperty =
        `${TIPODOCUMENTOITEM}${document.item_tabela}` as any;

      const data: documentsProps = {
        id_solicitacao: idSolicitacao,
        ind_solicitado: !!value,
        cod_tabela: document.cod_tabela,
        ind_obrigatorio: value,
        item_tabela: document.item_tabela,
      };

      if (value) updateDocument(data);
      else deleteDocument(data);

      setValue(tipoDocumentProperty, !!value);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <CardPrimery container marginTop="1rem" padding="1.5rem 2rem">
        <S.BoxGrid>
          {documentsSolicitantion.length &&
            documentsSolicitantion.map((document) => (
              <Controller
                key={document.item_tabela}
                name={`${TIPODOCUMENTOITEM}${document.item_tabela}`}
                defaultValue={!!document.id}
                control={control}
                render={({ field: { onChange, value, name } }) => {
                  return (
                    <S.BoxDocumentsAnexos checked={value} isEdit={isEdit}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          gap: "0.5rem",
                        }}
                      >
                        <ArticleIcon />
                        <T.H5>{document.descr_tabela}</T.H5>
                      </div>
                      <S.BoxSwitchs>
                        <Divider />
                        <S.BoxLabelSwitch>
                          <T.Paragraph>Solicitar Documento</T.Paragraph>
                          <SwitchStyle checked={value} />
                          <div style={{ display: "none" }}>
                            <input
                              type="checkbox"
                              checked={value || false}
                              onChange={async (e) => {
                                onChange(e);
                                toogleDocument(
                                  document,
                                  e.target.checked,
                                  name
                                );
                              }}
                            />
                          </div>
                        </S.BoxLabelSwitch>
                        <Divider />
                        <Controller
                          name={`${INDOBRIGATORIODOCUMENT}${document.item_tabela}`}
                          control={control}
                          defaultValue={document.ind_obrigatorio === "S"}
                          render={({ field: { onChange, value, name } }) => {
                            return (
                              <S.BoxLabelSwitch>
                                <T.Paragraph>Obrigatorio</T.Paragraph>
                                <SwitchStyle checked={value} />
                                <div style={{ display: "none" }}>
                                  <input
                                    type="checkbox"
                                    checked={value || false}
                                    onChange={async (e) => {
                                      onChange(e);
                                      toogleDocument(
                                        document,
                                        e.target.checked,
                                        name
                                      );
                                    }}
                                  />
                                </div>
                              </S.BoxLabelSwitch>
                            );
                          }}
                        />
                        <Divider />
                      </S.BoxSwitchs>
                    </S.BoxDocumentsAnexos>
                  );
                }}
              />
            ))}
        </S.BoxGrid>
      </CardPrimery>
    </form>
  );
}
