import { toogleMenuAction } from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import * as S from "./styles";

type ButtonMenuProps = {
  active?: string;
};

export function ButtonMenu({ active }: ButtonMenuProps) {
  const dispatch = useAppDispatch();
  const {
    menu: { isOpen },
  } = useAppSelector(({ Utils }) => Utils);

  const toogleMenu = () =>
    dispatch(toogleMenuAction({ isOpen: !isOpen, active: active || "" }));

  return (
    <S.ButtonMenu active={isOpen} onClick={toogleMenu}>
      <span />
    </S.ButtonMenu>
  );
}
