/* eslint-disable @typescript-eslint/no-explicit-any */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import Modal from "../index";
import * as S from "./styles";

interface IModalWarningRevisaoSolicitacao {
  isOpen: boolean;
  onClose: () => void;
  getUserSolicitation: () => Promise<void>;
  idUserSolicitation: number;
}

export function WarningRevisaoSolicitacao({
  isOpen,
  onClose,
  idUserSolicitation,
  getUserSolicitation,
}: IModalWarningRevisaoSolicitacao) {
  const dispatch = useAppDispatch();

  const sendConcluirRevisao = async () => {
    try {
      const { data: response } = await api.post(
        `/solicitation/revision/${idUserSolicitation}`
      );

      dispatch(setToastMessage({ type: "success", message: response.message }));
      await getUserSolicitation();
      onClose();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <S.ModalBody>
        <S.BoxDescription>
          <CheckCircleIcon fontSize="large" color="success" />
          <T.H3 color="#2e7d32" margin="0 0 0 1rem">
            CONCLUIR REVISÃO DA SOLICITAÇÃO
          </T.H3>
        </S.BoxDescription>
        <S.BoxDescription>
          <WarningAmberIcon fontSize="medium" color="warning" />
          <T.Paragraph margin="0 0 0 0.5rem" color="#ed6c02">
            <b>
              Após a conclusão da revisão, voce não poderá mais alterar a
              solicitação.
            </b>
          </T.Paragraph>
        </S.BoxDescription>
        <S.Footer>
          <B.ButtonSecondary onClick={onClose}>Cancelar</B.ButtonSecondary>
          <B.ButtonPrimary onClick={sendConcluirRevisao}>
            Concluir revisão
          </B.ButtonPrimary>
        </S.Footer>
      </S.ModalBody>
    </Modal>
  );
}
