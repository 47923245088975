/* eslint-disable quote-props */
/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unstable-nested-components */
import Stack from "@mui/material/Stack";
import Step from "@mui/material/Step";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import StepLabel from "@mui/material/StepLabel";
import Stepper from "@mui/material/Stepper";
import { styled } from "@mui/material/styles";
import * as React from "react";

import { useAppSelector } from "../../store/hooks";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
    "@media (max-width: 767px)": {
      top: 14,
    },
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        // "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
        "linear-gradient( 136deg, rgb(97, 195, 240) 0%, rgb(55, 137, 175) 50%, rgb(0, 70, 103) 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        // "linear-gradient( 95deg,rgb(242,113,33) 0%,rgb(233,64,87) 50%,rgb(138,35,135) 100%)",
        "linear-gradient( 136deg, rgb(86, 176, 218) 0%, rgb(55, 137, 175) 50%, rgb(0, 70, 103) 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
    "@media (max-width: 767px)": {
      height: 2,
    },
  },
  [`&.${stepConnectorClasses.root}`]: {
    "@media (max-width: 767px)": {
      left: "calc(-50% + 0px)",
      right: "calc(40% + 0px)",
    },
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      // "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      "linear-gradient( 136deg, rgb(86, 176, 218) 0%, rgb(55, 137, 175) 50%, rgb(0, 70, 103) 100%)",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      // "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      "linear-gradient( 136deg, rgb(86, 176, 218) 0%, rgb(55, 137, 175) 50%, rgb(0, 70, 103) 100%)",
  }),

  "@media (max-width: 767px)": {
    minWidth: 30,
    maxHeight: 30,
    maxWidth: 30,
    minHeight: 30,
  },
}));

type CustomizedSteppersProps = {
  activeStep: number;
  steps: { title: string; icon: React.ReactNode }[];
  onClick?: (step: number) => void;
};

export function CustomizedSteppers({
  steps,
  activeStep,
  onClick,
}: CustomizedSteppersProps) {
  const { device } = useAppSelector(({ Utils }) => Utils);

  return (
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper
        alternativeLabel
        activeStep={activeStep}
        connector={<ColorlibConnector />}
      >
        {steps.map((step, index) => (
          <Step key={step.title}>
            <StepLabel
              style={{ ...(device === "mobile" && { maxWidth: "30px" }) }}
              StepIconComponent={({
                active,
                completed,
                className,
              }: StepIconProps) => (
                <ColorlibStepIconRoot
                  ownerState={{ completed, active }}
                  className={className}
                  onClick={() => {
                    if (onClick?.name) onClick(index);
                  }}
                >
                  {step.icon}
                </ColorlibStepIconRoot>
              )}
            >
              {device === "desktop" ? step.title : ""}
            </StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
