/**
 * Action types
 */
export enum UserTypes {
  SET_USER = "@user/SET_USER",
  GET_USER = "@user/GET_USER",
}

/**
 * valores isolados do objeto de parametros
 */
export type UserObjectTypes = {
  id_usuario?: number;
  codigo_interno: string;
  nome: string;
  iniciais_usuario: string;
  email: string;
  senha?: string;
  ind_ativo: "S" | "N";
  celular: string;
  numero_cpf: string;
  dt_nascimento: string | null;
  perfil_usuario: number;
  dt_cadastro: string;
  hr_cadastro: string;
  ind_conta_verificada: "S" | "N";
  ind_acesso_api: "S" | "N";
  token_api: string | null;
  url_callback: string | null;
  img_foto: string | null;
  cargo: string | null;
  empresa: string | null;
};

/**
 * valores do state completo desse redux
 */
export type UserState = {
  readonly user: UserObjectTypes;
};
