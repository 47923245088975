/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-explicit-any */
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import ArrowLeftIcon from "@mui/icons-material/ArrowLeft";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArticleIcon from "@mui/icons-material/Article";
import DriveFileMoveIcon from "@mui/icons-material/DriveFileMove";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SettingsIcon from "@mui/icons-material/Settings";
import { Grid } from "@mui/material";
import { AxiosError } from "axios";
import { useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ButtonPrimary, ButtonSecondary } from "../../components/base/Buttons";
import * as T from "../../components/base/Text";
import { BodyContainer } from "../../components/BodyContainer";
import { GeneralDefinition } from "../../components/CreateSolicitation/GeneralDefinition";
import { Identifications } from "../../components/CreateSolicitation/Identifications";
import { ProofOfLife } from "../../components/CreateSolicitation/ProofOfLife";
import { SolicitAnexos } from "../../components/CreateSolicitation/SolicitAnexos";
import { SolicitDocuments } from "../../components/CreateSolicitation/SolicitDocuments";
import { CustomizedSteppers } from "../../components/CustomizedSteppers";
import { useTableStructure } from "../../hooks/useTableStructure";
import { api } from "../../services/api";
import { setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  convertData,
  convertSOrN,
  convertValuesDocumentsAndAnexos,
  getClientUrl,
  validadeSOrNArrayAll,
} from "../../utils/utils";

export type CreateSolicitationProps = {
  id_solicitacao?: number;
  id_solicitacao_sup?: number;
  id_autor: number;
  tipo_solicitacao_tab?: number;
  tipo_solicitacao_item: number;
  status_tab?: number;
  status_item: number;
  descr_solicitacao: string;
  observacoes?: string;
  msg_fim_preench?: string;
  ind_ativo: "S" | "N";
  exigir_dados_compl: "S" | "N";
  exigir_dados_ident: "S" | "N";
  exigir_dados_depend: "S" | "N";
  exigir_dados_vinc_trab: "S" | "N";
  exigir_dados_beneficios: "S" | "N";
  exigir_dados_decl_bens: "S" | "N";
  exigir_dados_prova_vida: "S" | "N";
  ind_prova_vida_doc: "S" | "N";
  ind_prova_vida_selfie: "S" | "N";
  ind_prova_vida_faceapi: "S" | "N";
  ind_prova_vida_pix: "S" | "N";
  ind_prova_vida_sms: "S" | "N";
  ind_prova_vida_video_chamada: "S" | "N";
  dt_inicio_preench?: string;
  dt_fim_preench?: string;
  nome: string;
  descr_tabela: string;
  termo_preenchimento: FileList;
};

export type DataAnexosSolicitations = {
  id: string;
  descricao: string;
  ind_obrigatorio: "S" | "N";
};

const steps = [
  {
    title: "Definições Primarias",
    icon: <SettingsIcon />,
  },
  {
    title: "Identificações Gerais",
    icon: <ManageAccountsIcon />,
  },
  {
    title: "Prova de Vida",
    icon: <AddModeratorIcon />,
  },
  {
    title: "Solicitação de Documentos",
    icon: <ArticleIcon />,
  },
  {
    title: "Solicitação de Anexos",
    icon: <DriveFileMoveIcon />,
  },
];

export function CreateSolicitation() {
  const {
    handleSubmit,
    control,
    getValues,
    watch,
    setValue,
    trigger,
    setError,
    register,
  } = useForm<CreateSolicitationProps>();
  const [activeStep, setActiveStep] = useState(0);

  const [anexosSolicitantion, setAnexosSolicitantion] = useState<
    DataAnexosSolicitations[]
  >([
    {
      id: String(Math.random().toString(36).substr(2, 9)),
      descricao: "Registro de geral",
      ind_obrigatorio: "N",
    },
    {
      id: String(Math.random().toString(36).substr(2, 9)),
      descricao: "Carteira de trabalho",
      ind_obrigatorio: "N",
    },
    {
      id: String(Math.random().toString(36).substr(2, 9)),
      descricao: "Carteira de Nacional de Habilitação",
      ind_obrigatorio: "N",
    },
  ]);

  useTableStructure(["typeSolicitation", "documentsSolicitantion"]);

  const dispatch = useAppDispatch();
  const { typeSolicitation, documentsSolicitantion } = useAppSelector(
    ({ TableStructure }) => TableStructure
  );

  const navegate = useNavigate();

  const onSubmit: SubmitHandler<CreateSolicitationProps> = async (body) => {
    try {
      const { documentos } = convertValuesDocumentsAndAnexos(body);
      const exigirDadosProvaVida = validadeSOrNArrayAll([
        convertSOrN(body.ind_prova_vida_doc),
        convertSOrN(body.ind_prova_vida_selfie),
        convertSOrN(body.ind_prova_vida_faceapi),
        convertSOrN(body.ind_prova_vida_pix),
        convertSOrN(body.ind_prova_vida_sms),
        convertSOrN(body.ind_prova_vida_video_chamada),
      ]);
      const parametros = {
        descr_solicitacao: body.descr_solicitacao,
        tipo_solicitacao_item: body.tipo_solicitacao_item,
        observacoes: body.observacoes,
        msg_fim_preench: body.msg_fim_preench,
        ...(body.observacoes && { observacoes: body.observacoes }),
        ...(body.msg_fim_preench && { msg_fim_preench: body.msg_fim_preench }),
        ...(body.dt_inicio_preench && {
          dt_inicio_preench: convertData(body.dt_inicio_preench),
        }),
        ...(body.dt_fim_preench && {
          dt_fim_preench: convertData(body.dt_fim_preench),
        }),
        ind_ativo: convertSOrN(body.ind_ativo),
        exigir_dados_compl: convertSOrN(body.exigir_dados_compl),
        exigir_dados_ident: convertSOrN(body.exigir_dados_ident),
        exigir_dados_depend: convertSOrN(body.exigir_dados_depend),
        exigir_dados_vinc_trab: convertSOrN(body.exigir_dados_vinc_trab),
        exigir_dados_beneficios: convertSOrN(body.exigir_dados_beneficios),
        exigir_dados_decl_bens: convertSOrN(body.exigir_dados_decl_bens),
        exigir_dados_prova_vida: convertSOrN(exigirDadosProvaVida),
        ind_prova_vida_doc: convertSOrN(body.ind_prova_vida_doc),
        ind_prova_vida_selfie: convertSOrN(body.ind_prova_vida_selfie),
        ind_prova_vida_faceapi: convertSOrN(body.ind_prova_vida_faceapi),
        ind_prova_vida_pix: convertSOrN(body.ind_prova_vida_pix),
        ind_prova_vida_sms: convertSOrN(body.ind_prova_vida_sms),
        ind_prova_vida_video_chamada: convertSOrN(
          body.ind_prova_vida_video_chamada
        ),
        ...(body.id_solicitacao_sup && {
          id_solicitacao_sup: body.id_solicitacao_sup,
        }),
        anexos: anexosSolicitantion,
        documentos,
        termo_preenchimento: body.termo_preenchimento?.length
          ? body.termo_preenchimento.item(0)
          : null,
      };

      const formData = new FormData();
      const keys = Object.keys(parametros);
      keys.forEach((key) => {
        if (key === "anexos" || key === "documentos") {
          const value = parametros[key];
          value.forEach((item: any) => {
            formData.append(key, JSON.stringify(item));
          });
          // @ts-ignore
        } else if (parametros[key]) {
          // @ts-ignore
          formData.append(key, parametros[key]);
        }
      });

      const { data } = await api.post("/solicitation/create", formData);
      dispatch(setToastMessage({ type: "success", message: data.message }));
      navegate(`../${getClientUrl()}/admin/solicitacoes`, { replace: true });
    } catch (err: any) {
      if (err instanceof AxiosError) {
        const error = err?.response?.data;
        if (error?.errors && error?.errors.length) {
          error.errors.forEach((error: any) => {
            setError(error.property, {
              type: "manual",
              message: error.error,
            });
          });
        }
      } else {
        dispatch(
          setToastMessage({
            type: "error",
            message: err?.message || "Erro ao criar solicitação",
          })
        );
      }
    }
  };

  const nextStep = async () => {
    if (activeStep === 0) {
      if (await trigger()) setActiveStep(activeStep + 1);
      return;
    }
    if (activeStep === steps.length - 1) {
      onSubmit(getValues());
      return;
    }
    setActiveStep(activeStep + 1);
  };
  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  return (
    <BodyContainer menu="Cria Solicitação">
      <Grid
        container
        spacing={1}
        style={{
          margin: "1rem 0 1.5rem 0",
          borderLeft: "4px solid #ff6e07",
          paddingLeft: "1rem",
        }}
      >
        <Grid item xs={12}>
          <T.H2>CRIA SOLICITAÇÃO</T.H2>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "0.6rem",
          }}
        >
          <T.Paragraph lineHeight="1.3rem">
            Defina as informações da solicitação, e as etapas que o usuário terá
            que seguir durante o preenchimento.
          </T.Paragraph>
        </Grid>
      </Grid>

      <CustomizedSteppers steps={steps} activeStep={activeStep} />
      <form
        onSubmit={handleSubmit(onSubmit)}
        // noValidate
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: "1",
          padding: "3rem 0 2rem 0",
        }}
      >
        {activeStep === 0 && (
          <GeneralDefinition
            typeSolicitation={typeSolicitation}
            control={control}
            register={register}
            watch={watch}
          />
        )}
        {activeStep === 1 && (
          <Identifications
            control={control}
            getValues={getValues}
            watch={watch}
          />
        )}
        {activeStep === 2 && (
          <ProofOfLife control={control} getValues={getValues} watch={watch} />
        )}
        {activeStep === 3 && (
          <SolicitDocuments
            control={control}
            setValue={setValue}
            getValues={getValues}
            documentsSolicitantion={documentsSolicitantion}
          />
        )}
        {activeStep === 4 && (
          <SolicitAnexos
            anexosSolicitantion={anexosSolicitantion}
            setAnexosSolicitantion={setAnexosSolicitantion}
          />
        )}
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: activeStep === 0 ? "flex-end" : "space-between",
            margin: "1rem 0 0 0",
          }}
        >
          {activeStep > 0 && (
            <ButtonSecondary
              style={{ width: "clamp(200px, 250px, 100%)", gap: "0.3rem" }}
              onClick={prevStep}
            >
              <ArrowLeftIcon /> Voltar
            </ButtonSecondary>
          )}
          <ButtonPrimary
            style={{ width: "clamp(200px, 250px, 100%)", gap: "0.3rem" }}
            onClick={nextStep}
          >
            {activeStep === steps.length - 1
              ? "Criar Solicitação"
              : "CONCLUIR ETAPA"}
            <ArrowRightIcon />
          </ButtonPrimary>
        </Grid>
      </form>
    </BodyContainer>
  );
}
