/* eslint-disable @typescript-eslint/naming-convention */
import AddIcon from "@mui/icons-material/Add";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  alpha,
  Checkbox,
  FormControlLabel,
  IconButton,
  Toolbar,
  Tooltip,
} from "@mui/material";

import * as T from "../base/Text";

interface HeaderDataGridAddUserProps {
  numSelected: number;
  importCSV: () => void;
  addUserManual: () => void;
  handleDeleteLote: () => void;
  isCreateAccount: boolean;
  setIsCreateAccount: (value: boolean) => void;
}

export function HeaderDataGridAddUser({
  numSelected,
  importCSV,
  addUserManual,
  handleDeleteLote,
  isCreateAccount,
  setIsCreateAccount,
}: HeaderDataGridAddUserProps) {
  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <>
          <T.Paragraph>{numSelected} Selecionados</T.Paragraph>
          <Tooltip title="Deletar itens Selecionados">
            <IconButton onClick={handleDeleteLote}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        </>
      ) : (
        <>
          <T.Paragraph>Lista de usuários</T.Paragraph>
          {setIsCreateAccount && (
            <Tooltip title="Criar Conta para usuarios sem conta no sistema?">
              <FormControlLabel
                style={{ textOverflow: "ellipsis", whiteSpace: "nowrap" }}
                control={
                  <Checkbox
                    checked={isCreateAccount}
                    onChange={({ target }) =>
                      setIsCreateAccount(target.checked)
                    }
                  />
                }
                label="Criar Conta"
              />
            </Tooltip>
          )}
          {importCSV && (
            <Tooltip title="Importa CSV">
              <IconButton onClick={importCSV}>
                <ArchiveIcon />
              </IconButton>
            </Tooltip>
          )}
          {addUserManual && (
            <Tooltip title="Adiciona Manual">
              <IconButton onClick={addUserManual}>
                <AddIcon />
              </IconButton>
            </Tooltip>
          )}
        </>
      )}
    </Toolbar>
  );
}
