/**
 * Action types
 */
export enum TableStructureTypes {
  GET_TABLE_ITEMS = "@tablEstrcture/GET_TABLE_ITEMS",
  GET_TABLE_ITEM = "@tablEstrcture/GET_TABLE_ITEM",
  SET_TABLE_ITEMS = "@tablEstrcture/SET_TABLE_ITEMS",
  SET_ITEM = "@tablEstrcture/SET_ITEM",
  SET_ITEM_TYPE_SOLICITATION = "@tablEstrcture/SET_ITEM_TYPE_SOLICITATION",
}

/**
 * valores isolados do objeto de parametros
 */
export type ItensTableTypes = {
  cod_tabela: number;
  cod_tabela_sup: null | number;
  descr_tabela: string;
  id_tab_estrut: number;
  item_tabela: number;
  item_tabela_sup: null | number;
  sigla_tabela: string | null;
};

export type ItensVaueTypes = {
  cod_tabela: number;
  itemsTable: ItensTableTypes[];
};

/**
 * valores do state completo desse redux
 */
export type TableStructureState = {
  readonly status: ItensVaueTypes;
  readonly typeSolicitation: ItensVaueTypes;
  readonly documentsSolicitantion: ItensVaueTypes;
  readonly paises: ItensVaueTypes;
  readonly estados: ItensVaueTypes;
  readonly cidades: ItensVaueTypes;
  readonly estadoCivil: ItensVaueTypes;
  readonly etnia: ItensVaueTypes;
  readonly nacionalidade: ItensVaueTypes;
  readonly escolaridade: ItensVaueTypes;
  readonly deficiencia: ItensVaueTypes;
  readonly cid: ItensVaueTypes;
  readonly typeDependents: ItensVaueTypes;
  readonly typeDocVinculo: ItensVaueTypes;
  readonly statusUserSolicitation: ItensVaueTypes;
  readonly regimeTrabalho: ItensVaueTypes;
  readonly corOlhos: ItensVaueTypes;
  readonly corCabelo: ItensVaueTypes;
  readonly regimePrevidenciario: ItensVaueTypes;
  readonly listaBeneficios: ItensVaueTypes;
  readonly listaSecretarias: ItensVaueTypes;
  readonly listaSetores: ItensVaueTypes;
};
