import { action } from "typesafe-actions";

import {
  SolicitationTypes,
  IIdentificacaoGeral,
  IDadosComplementares,
} from "./types";

export const setIdentificationGenereicAction = (data: IIdentificacaoGeral) =>
  action(SolicitationTypes.SET_IDENTIFICATION_GENERIC, data);

export const setDadosComplementaresAction = (data: IDadosComplementares) =>
  action(SolicitationTypes.SET_DADOS_COMPLEMENTARES, data);

export const setStateDefaultSolicitationAction = () =>
  action(SolicitationTypes.SET_STATE_DEFAULT);
