import styled, { css } from "styled-components";

type ButtonRadioStylesProps = {
  checked: boolean | "S" | "N";
};

type ButtonSwitchStyleStylesProps = {
  checked: boolean | "S" | "N";
  disabled?: boolean;
};

type StatusProps = {
  status: number;
};

export const ButtonRadioStyles = styled.div<ButtonRadioStylesProps>`
  ${({ theme, checked }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 22px;
    max-width: 22px;
    min-height: 22px;
    max-height: 22px;
    border-radius: 50%;
    background-color: ${theme.background.active};
    transition: 150ms ease-in-out;
    border: 3px solid
      ${checked === "S" || checked === true
        ? theme.palette.primary.light
        : theme.text.withDark};

    &:before {
      content: "";
      min-width: 10px;
      max-width: 10px;
      min-height: 10px;
      max-height: 10px;
      border-radius: 50%;
      background-color: ${checked === "S" || checked === true
        ? theme.palette.primary.light
        : "transparent"};
    }
  `}
`;

export const SwitchStyle = styled.div<ButtonSwitchStyleStylesProps>`
  ${({ theme, checked }) => css`
    appearance: none;
    min-width: calc(1.3rem * 2.2);
    max-width: calc(1.3rem * 2.2);
    min-height: 1.3rem;
    max-height: 1.3rem;
    display: inline-block;
    border-radius: calc(1.3rem / 2);
    cursor: pointer;
    background-color: ${checked === "S" || checked === true
      ? theme.palette.primary.main
      : theme.background.paperDark};
    background-image: radial-gradient(
        circle calc(1.3rem / 2.1),
        #fff 100%,
        #0000 0
      ),
      radial-gradient(circle calc(1.3rem / 1.5), #0003 0%, #0000 100%);
    background-repeat: no-repeat;
    background-position: ${checked === "S" || checked === true
      ? "calc(1.3rem / 1.75) 0"
      : "calc(1.3rem / -1.75) 0"};
    transition: background 0.2s ease-out;
  `}
`;

const colorStatus = [
  "",
  "#fff07ee2",
  "#a1d5ffdb",
  "#8257e5b8",
  "#88ff7de3",
  "#fd8077d5",
];

export const BoxStatusSolicitation = styled.div<StatusProps>`
  ${({ status }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: min-content;
    height: max-content;
    border-radius: 0.5rem;
    white-space: nowrap;
    text-transform: uppercase;
    border: 1px solid ${colorStatus[status]};
    background-color: ${colorStatus[status]};
    fill-opacity: 0.8;
    padding: 0.5rem;

    @media screen {
      @media (max-width: 768px) {
        padding: 0.225rem;
        border-radius: 0.225rem;
        font-size: 0.75rem;
      }
    }
  `}
`;

export const BoxRevisadaSolicitation = styled.div`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: min-content;
    height: max-content;
    border-radius: 0.5rem;
    white-space: nowrap;
    text-transform: uppercase;
    border: 1px solid ${theme.palette.primary.main};
    background-color: ${theme.palette.primary.main};
    color: #fff;
    fill-opacity: 0.8;
    padding: 0.5rem;

    @media screen {
      @media (max-width: 768px) {
        padding: 0.225rem;
        border-radius: 0.225rem;
        font-size: 0.75rem;
      }
    }
  `}
`;
