/* eslint-disable react/jsx-props-no-spreading */
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { IconButton, Popover } from "@mui/material";
import { DatePicker, ptBR } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AxiosError } from "axios";
import { ptBR as ptBRLocale } from "date-fns/locale";
import { useEffect, useState } from "react";

import { api } from "../../../services/api";
import {
  setLoading,
  setToastMessage,
} from "../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ButtonPrimary } from "../../base/Buttons";
import * as T from "../../base/Text";
import * as S from "./styles";

type MenuPappoverprops = {
  open: boolean;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
  idAgendamento: number;
  onClick: (hoursChecked: HoursProps | undefined) => Promise<void>;
};

type dateProps = {
  value: Date | null;
  label: string;
};

type HoursProps = {
  id: string;
  hora: string;
  disponivel: boolean;
  data: string;
};

type HoursListProps = Array<{
  data: "2024-01-13";
  numDiaSemana: 5;
  horarios: Array<{
    id: string;
    hora: string;
    disponivel: boolean;
  }>;
}>;

const getIntervalWeek = (date: Date) => {
  const dayWeek = date.getDay(); // 0: domingo ... 6: sabado
  let dayWeekStart = 0;
  let dayWeekEnd = 0;

  switch (dayWeek) {
    case 0:
      dayWeekStart = 0;
      dayWeekEnd = 6;
      break;
    case 1:
      dayWeekStart = 1;
      dayWeekEnd = 5;
      break;
    case 2:
      dayWeekStart = 2;
      dayWeekEnd = 4;
      break;
    case 3:
      dayWeekStart = 3;
      dayWeekEnd = 3;
      break;
    case 4:
      dayWeekStart = 4;
      dayWeekEnd = 2;
      break;
    case 5:
      dayWeekStart = 5;
      dayWeekEnd = 1;
      break;
    case 6:
      dayWeekStart = 6;
      dayWeekEnd = 0;
      break;
    default:
      dayWeekStart = 0;
      dayWeekEnd = 6;
      break;
  }
  return { dayWeekStart, dayWeekEnd };
};

export function AgendarPreench({
  open,
  handleClose,
  anchorEl,
  idAgendamento,
  onClick,
}: MenuPappoverprops) {
  const [dateValue, setDateValue] = useState<Date | null>(new Date());
  const [dateStart, setDateStart] = useState<dateProps>({} as dateProps);
  const [dateEnd, setDateEnd] = useState<dateProps>({} as dateProps);

  const [hoursList, setHoursList] = useState<HoursListProps>([]);
  const [hoursChecked, setHoursChecked] = useState<HoursProps>();

  const dispatch = useAppDispatch();

  const { loading } = useAppSelector(({ Utils }) => Utils);

  const formatDate = (date: Date) => {
    return new Intl.DateTimeFormat("pt-BR", {
      day: "2-digit",
      month: "2-digit",
    }).format(date);
  };

  const intervaleWeek = (value: Date | null) => {
    const date = value || new Date();
    const { dayWeekStart, dayWeekEnd } = getIntervalWeek(date);

    const start = {
      value: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() - dayWeekStart
      ),
      label: formatDate(
        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() - dayWeekStart
        )
      ),
    };
    const end = {
      value: new Date(
        date.getFullYear(),
        date.getMonth(),
        date.getDate() + dayWeekEnd
      ),
      label: formatDate(
        new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate() + dayWeekEnd
        )
      ),
    };
    setDateStart(start);
    setDateEnd(end);
  };

  const handleChange = (value: Date | null, aux?: number) => {
    const currentDate = new Date();
    const newValue =
      value && value.getTime() > currentDate.getTime() ? value : currentDate;

    if (aux) {
      setDateValue(
        new Date(
          newValue.getFullYear(),
          newValue.getMonth(),
          newValue.getDate() + aux
        )
      );
      intervaleWeek(
        new Date(
          newValue.getFullYear(),
          newValue.getMonth(),
          newValue.getDate() + aux
        )
      );
    } else {
      setDateValue(newValue);
      intervaleWeek(newValue);
    }
  };

  useEffect(() => intervaleWeek(new Date()), []);

  const getHousDisponibles = async () => {
    try {
      const dt_inicial = dateStart.value?.toISOString().split("T")[0];
      const dt_final = dateEnd.value?.toISOString().split("T")[0];

      dispatch(setLoading(true));
      const { data } = await await api.get(
        `/agendamento/horarios-disponiveis?idConfigAg=${idAgendamento}&dt_inicial=${dt_inicial}&dt_final=${dt_final}`
      );
      setHoursList(data);
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    if (open) {
      getHousDisponibles();
    }

    return () => {
      setHoursList([]);
      setHoursChecked(undefined);
    };
  }, [open, dateValue]);

  return (
    <div>
      <Popover
        id="ver-horarios"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <S.Content>
          <S.ContentHeader>
            <div>
              <T.Paragraph size="20px">
                Semana {dateStart.label} - {dateEnd.label}
              </T.Paragraph>
              <LocalizationProvider
                dateAdapter={AdapterDateFns}
                adapterLocale={ptBRLocale}
                localeText={
                  ptBR.components.MuiLocalizationProvider.defaultProps
                    .localeText
                }
              >
                <DatePicker
                  inputFormat="dd/MM/yyyy"
                  openTo="day"
                  value={dateValue}
                  onChange={(value) => {
                    handleChange(value);
                  }}
                  renderInput={(params) => <S.InputDate {...params} />}
                />
              </LocalizationProvider>
            </div>
            <div>
              <IconButton onClick={() => handleChange(dateValue, -6)}>
                <ArrowBackIosIcon />
              </IconButton>
              <IconButton onClick={() => handleChange(dateValue, 6)}>
                <ArrowForwardIosIcon />
              </IconButton>
            </div>
          </S.ContentHeader>
          <S.ContentBody>
            <S.WeekHeaderContent>
              <S.WeekHeaderItem>
                <T.Span style={{ fontSize: "inherit" }}>DOM</T.Span>
              </S.WeekHeaderItem>
              <S.WeekHeaderItem>
                <T.Span style={{ fontSize: "inherit" }}>SEG</T.Span>
              </S.WeekHeaderItem>
              <S.WeekHeaderItem>
                <T.Span style={{ fontSize: "inherit" }}>TER</T.Span>
              </S.WeekHeaderItem>
              <S.WeekHeaderItem>
                <T.Span style={{ fontSize: "inherit" }}>QUA</T.Span>
              </S.WeekHeaderItem>
              <S.WeekHeaderItem>
                <T.Span style={{ fontSize: "inherit" }}>QUI</T.Span>
              </S.WeekHeaderItem>
              <S.WeekHeaderItem>
                <T.Span style={{ fontSize: "inherit" }}>SEX</T.Span>
              </S.WeekHeaderItem>
              <S.WeekHeaderItem>
                <T.Span style={{ fontSize: "inherit" }}>SAB</T.Span>
              </S.WeekHeaderItem>
            </S.WeekHeaderContent>
            <S.WeeContent>
              {hoursList.length && !loading ? (
                hoursList.map((item) => (
                  <S.WeekItens key={crypto.randomUUID()}>
                    {item.horarios.map((hour) => (
                      <S.WeekItem
                        key={hour.id}
                        disabled={!hour.disponivel}
                        checked={hoursChecked?.id === hour.id}
                        onClick={() => {
                          setHoursChecked({
                            ...hour,
                            data: item.data,
                          });
                        }}
                      >
                        <T.Span
                          style={{ fontSize: "inherit", color: "inherit" }}
                        >
                          {hour.hora}
                        </T.Span>
                      </S.WeekItem>
                    ))}
                  </S.WeekItens>
                ))
              ) : (
                <div>Loading...</div>
              )}
            </S.WeeContent>
          </S.ContentBody>
          <S.ContentFooter>
            <ButtonPrimary
              onClick={async () => {
                if (!hoursChecked) {
                  dispatch(
                    setToastMessage({
                      type: "error",
                      message: "Selecione um horário",
                    })
                  );
                  return;
                }

                await onClick(hoursChecked);
                handleClose();
              }}
            >
              Agendar
            </ButtonPrimary>
          </S.ContentFooter>
        </S.Content>
      </Popover>
    </div>
  );
}
