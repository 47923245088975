import styled from "styled-components";

export const FormContainer = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

export const FormContent = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;

  @media screen {
    @media (max-width: 768px) {
      padding: 1rem 0;
    }
  }
`;

export const FormItem = styled.div`
  width: 100%;
`;

export const FormBoxButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
