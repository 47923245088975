import { Tooltip } from "@mui/material";

import { Paragraph } from "../base/Text";
import * as S from "./styles";

type ProgressSolicitationProps = {
  progress: number;
  count?: number;
  countComplete?: number;
};

export function ProgressSolicitation({
  progress,
  count,
  countComplete,
}: ProgressSolicitationProps) {
  return (
    <S.BoxProgressBar>
      {count && (
        <S.ProgressBarHeader>
          <Paragraph width="100%">Seu progresso</Paragraph>
          <Paragraph width="min-content">
            {countComplete}/{count}
          </Paragraph>
        </S.ProgressBarHeader>
      )}
      <Tooltip followCursor arrow title={`${progress}%`}>
        <S.ProgressBody>
          <S.ProgressBar percent={progress} />
        </S.ProgressBody>
      </Tooltip>
    </S.BoxProgressBar>
  );
}
