/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControlLabel, Grid, Radio } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setIdentificationGenereicAction } from "../../../store/ducks/Solicitation/actions";
import { IIdentificacaoGeral } from "../../../store/ducks/Solicitation/types";
import { setItemAction } from "../../../store/ducks/TableStructure/actions";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  convertData,
  createObjectValNotNull,
  getIdURLUserSol,
  IDENTIFICACAO,
  removeMask,
} from "../../../utils/utils";
import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import { InputDate } from "../../Inputs/InputDate";
import { InputRadios } from "../../Inputs/InputRadios";
import { InputSelect } from "../../Inputs/InputSelect";
import { InputBase } from "../../Inputs/InputText";
import * as S from "../styles";

type IdentificacaoGeralProps = {
  isEditable: boolean;
  getUserSolicitation(): Promise<void>;
  isCompleted: boolean;
  isOpenCorrecion: boolean;
  isOnChangeSetListCorrecion: (etapa: string, campo: string) => boolean;
  onChangeSetListCorrecion: (
    etapa: string,
    campo: string,
    label: string
  ) => void;
  correctionValuesJsonData: any;
};

export function IdentificacaoGeral({
  isEditable,
  getUserSolicitation,
  isCompleted,
  isOpenCorrecion,
  isOnChangeSetListCorrecion,
  onChangeSetListCorrecion,
  correctionValuesJsonData,
}: IdentificacaoGeralProps) {
  const [indPreenchTerceiro, setIndPreenchTerceiro] = useState(false);
  const { handleSubmit, control, setError, getValues, reset, setValue } =
    useForm<IIdentificacaoGeral>();

  const dispatch = useAppDispatch();

  const { TableStructure, Solicitation } = useAppSelector((state) => state);
  const {
    paises,
    estados,
    cidades,
    estadoCivil,
    etnia,
    nacionalidade,
    escolaridade,
    deficiencia,
    cid,
    corCabelo,
    corOlhos,
  } = TableStructure;

  const { identificacaoGeral } = Solicitation;

  const getData = async (values?: any) => {
    const idSolUser = getIdURLUserSol();
    const data =
      values ||
      (await api.get(`/solicitation/identification/${idSolUser}`)).data;

    if (data) {
      const identification: IIdentificacaoGeral = {
        ...identificacaoGeral,
        ...data,
        dt_nascimento: data?.dt_nascimento,
        estado_civil: data?.estado_civil_item,
        etnia: data?.etnia_item,
        nacionalidade: data?.nacionalidade_item,
        escolaridade: data?.escolaridade_item,
        deficiencia: data?.deficiencia_item,
        cidade: data?.nasc_cidade_item,
        estado: data?.nasc_estado_item,
        pais: data?.nasc_pais_item,
        cid: data?.cid_item,
        cor_cabelo: data?.cor_cabelo_item,
        cor_olhos: data?.cor_olhos_item,
        tipo_vinc_financeiro: data?.tipo_vinc_fin_item,
        ind_preench_terceiro: data?.ind_preench_terceiro,
        nome_compl_preench_terceiro: data?.nome_compl_preench_terceiro,
        cpf_preench_terceiro: data?.cpf_preench_terceiro,
        rg_preench_terceiro: data?.rg_preench_terceiro,
        fone_preench_terceiro: data?.fone_preench_terceiro,
      };
      setIndPreenchTerceiro(!!data?.ind_preench_terceiro);
      reset(identification);
      dispatch(setIdentificationGenereicAction(identification));
    }
  };

  const onSubmit: SubmitHandler<IIdentificacaoGeral> = async (body) => {
    try {
      const dataIdentificacao = createObjectValNotNull(
        body
      ) as IIdentificacaoGeral;

      const { data } = await api.post(
        `/solicitation/identification/commited/${getIdURLUserSol()}/${
          identificacaoGeral.id_sol_dados_ident || ""
        }`,
        {
          ...dataIdentificacao,
          numero_cpf: removeMask(dataIdentificacao.numero_cpf),
          ...(!!dataIdentificacao?.dt_nascimento && {
            dt_nascimento: convertData(dataIdentificacao.dt_nascimento),
          }),
          ...(!!dataIdentificacao?.cpf_preench_terceiro && {
            cpf_preench_terceiro: removeMask(
              dataIdentificacao.cpf_preench_terceiro
            ),
          }),
        }
      );
      getData(data);
      await getUserSolicitation();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
      if (error?.errors && error?.errors.length) {
        error.errors.forEach((error: any) => {
          setError(error.property, {
            type: "manual",
            message: error.error,
          });
        });
      }
    }
  };

  const saveData = async () => {
    try {
      const body = createObjectValNotNull(getValues()) as IIdentificacaoGeral;
      if (!Object.keys(body).length) {
        dispatch(
          setToastMessage({
            type: "error",
            message: "Preencha os campos para salvar",
          })
        );
        return;
      }

      const { data } = await api.post(
        `/solicitation/identification/save/${getIdURLUserSol()}/${
          identificacaoGeral.id_sol_dados_ident || ""
        }`,
        {
          ...body,
          ...(!!body?.numero_cpf && {
            numero_cpf: removeMask(body.numero_cpf),
          }),
          ...(!!body?.dt_nascimento && {
            dt_nascimento: convertData(body.dt_nascimento),
          }),
          ...(!!body?.cpf_preench_terceiro && {
            cpf_preench_terceiro: removeMask(body.cpf_preench_terceiro),
          }),
        }
      );
      getData(data);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
      if (error?.errors && error?.errors.length) {
        error.errors.forEach((error: any) => {
          setError(error.property, {
            type: "manual",
            message: error.error,
          });
        });
      }
    }
  };

  const getItemTableStructure = async (
    codTabelaSup: number,
    itemTabelaSup: number,
    inputAtt: string
  ) => {
    const { data } = await api.get(
      `/system/structured-table?cod=&codSup=${codTabelaSup}&itemSup=${itemTabelaSup}`
    );
    dispatch(setItemAction({ data, key: inputAtt }));
  };

  useEffect(() => {
    if (!identificacaoGeral.id_sol_usuario) {
      getData();
    }
  }, []);

  return (
    <Grid container spacing={1} padding="1rem">
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        style={{ width: "100%", height: "100%" }}
      >
        <Grid container spacing={1} style={{ width: "100%" }}>
          <S.ContainerInput
            item
            xs={12}
            md={5}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              IDENTIFICACAO,
              "nome_completo"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                IDENTIFICACAO,
                "nome_completo",
                "Nome Completo"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[`${IDENTIFICACAO}-nome_completo`]
              }
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-nome_completo`]
                  ?.message
              }
            />
            <InputBase
              control={control}
              id="nome_completo"
              label="Nome Completo"
              name="nome_completo"
              type="nome_completo"
              readOnly={!isEditable}
              required
            />
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              IDENTIFICACAO,
              "nome_social"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                IDENTIFICACAO,
                "nome_social",
                "Nome Social"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-nome_social`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-nome_social`]
                  ?.message
              }
            />
            <InputBase
              control={control}
              id="nome_social"
              label="Nome Social"
              name="nome_social"
              readOnly={!isEditable}
            />
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={2}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              IDENTIFICACAO,
              "dt_nascimento"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                IDENTIFICACAO,
                "dt_nascimento",
                "Data Nascimento"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[`${IDENTIFICACAO}-dt_nascimento`]
              }
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-dt_nascimento`]
                  ?.message
              }
            />
            <InputDate
              control={control}
              id="dt_nascimento"
              label="Data Nascimento"
              name="dt_nascimento"
              readOnly={!isEditable}
              required
            />
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={2}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(IDENTIFICACAO, "numero_cpf")}
            onClick={() =>
              onChangeSetListCorrecion(
                IDENTIFICACAO,
                "numero_cpf",
                "Numero CPF"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-numero_cpf`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-numero_cpf`]?.message
              }
            />
            <InputBase
              control={control}
              id="numero_cpf"
              label="Numero CPF"
              name="numero_cpf"
              type="cpf"
              readOnly={!isEditable}
              required
            />
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(IDENTIFICACAO, "sexo")}
            onClick={() =>
              onChangeSetListCorrecion(IDENTIFICACAO, "sexo", "Sexo")
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-sexo`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-sexo`]?.message
              }
            />
            <InputRadios
              control={control}
              id="sexo"
              title="Sexo"
              name="sexo"
              readOnly={!isEditable}
              row
              required
            >
              <FormControlLabel
                value="M"
                control={<Radio size="small" />}
                label="Masculino"
              />
              <FormControlLabel
                value="F"
                control={<Radio size="small" />}
                label="Feminino"
              />
            </InputRadios>
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={6}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              IDENTIFICACAO,
              "escolaridade"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                IDENTIFICACAO,
                "escolaridade",
                "Escolaridade"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-escolaridade`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-escolaridade`]
                  ?.message
              }
            />
            <InputSelect
              control={control}
              title="Escolaridade"
              id="escolaridade"
              label="Escolaridade"
              name="escolaridade"
              readOnly={!isEditable}
              required
            >
              <option> </option>
              {escolaridade.itemsTable?.length &&
                escolaridade.itemsTable.map((escolaridade) => {
                  if (escolaridade.item_tabela === 0) return null;
                  return (
                    <option
                      key={escolaridade.item_tabela}
                      value={escolaridade.item_tabela}
                    >
                      {escolaridade.descr_tabela}
                    </option>
                  );
                })}
            </InputSelect>
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              IDENTIFICACAO,
              "estado_civil"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                IDENTIFICACAO,
                "estado_civil",
                "Estado civil"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-estado_civil`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-estado_civil`]
                  ?.message
              }
            />
            <InputSelect
              title="Estado civil"
              control={control}
              id="estado_civil"
              label="Estado civil"
              name="estado_civil"
              readOnly={!isEditable}
              required
            >
              <option> </option>
              {estadoCivil.itemsTable?.length &&
                estadoCivil.itemsTable.map((situacao) => {
                  if (situacao.item_tabela === 0) return null;
                  return (
                    <option key={Math.random()} value={situacao.item_tabela}>
                      {situacao.descr_tabela}
                    </option>
                  );
                })}
            </InputSelect>
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(IDENTIFICACAO, "nis")}
            onClick={() =>
              onChangeSetListCorrecion(IDENTIFICACAO, "nis", "NIS")
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-nis`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-nis`]?.message
              }
            />
            <InputBase
              control={control}
              id="nis"
              label="NIS"
              type="number"
              name="nis"
              readOnly={!isEditable}
            />
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(IDENTIFICACAO, "cor_olhos")}
            onClick={() =>
              onChangeSetListCorrecion(
                IDENTIFICACAO,
                "cor_olhos",
                "Cor dos olhos"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-cor_olhos`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-cor_olhos`]?.message
              }
            />
            <InputSelect
              title="Cor dos olhos"
              control={control}
              id="cor_olhos"
              label="Cor dos olhos"
              name="cor_olhos"
              readOnly={!isEditable}
            >
              <option> </option>
              {corOlhos.itemsTable?.length &&
                corOlhos.itemsTable.map((situacao) => {
                  if (situacao.item_tabela === 0) return null;
                  return (
                    <option key={Math.random()} value={situacao.item_tabela}>
                      {situacao.descr_tabela}
                    </option>
                  );
                })}
            </InputSelect>
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(IDENTIFICACAO, "cor_cabelo")}
            onClick={() =>
              onChangeSetListCorrecion(
                IDENTIFICACAO,
                "cor_cabelo",
                "Cor do cabelo"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-cor_cabelo`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-cor_cabelo`]?.message
              }
            />
            <InputSelect
              title="Cor do cabelo"
              control={control}
              id="cor_cabelo"
              label="Cor do cabelo"
              name="cor_cabelo"
              readOnly={!isEditable}
            >
              <option> </option>
              {corCabelo.itemsTable?.length &&
                corCabelo.itemsTable.map((situacao) => {
                  if (situacao.item_tabela === 0) return null;
                  return (
                    <option key={Math.random()} value={situacao.item_tabela}>
                      {situacao.descr_tabela}
                    </option>
                  );
                })}
            </InputSelect>
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(IDENTIFICACAO, "etnia")}
            onClick={() =>
              onChangeSetListCorrecion(IDENTIFICACAO, "etnia", "Etnia")
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-etnia`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-etnia`]?.message
              }
            />
            <InputSelect
              title="Etnia"
              control={control}
              id="etnia"
              label="Etnia"
              name="etnia"
              readOnly={!isEditable}
              required
            >
              <option> </option>
              {etnia.itemsTable?.length &&
                etnia.itemsTable.map((etnia) => {
                  if (etnia.item_tabela === 0) return null;
                  return (
                    <option key={Math.random()} value={etnia.item_tabela}>
                      {etnia.descr_tabela}
                    </option>
                  );
                })}
            </InputSelect>
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={4}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(IDENTIFICACAO, "nome_mae")}
            onClick={() =>
              onChangeSetListCorrecion(IDENTIFICACAO, "nome_mae", "Nome da mãe")
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-nome_mae`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-nome_mae`]?.message
              }
            />
            <InputBase
              control={control}
              id="nome_mae"
              label="Nome da mãe"
              name="nome_mae"
              readOnly={!isEditable}
            />
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={4}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(IDENTIFICACAO, "nome_pai")}
            onClick={() =>
              onChangeSetListCorrecion(IDENTIFICACAO, "nome_pai", "Nome do pai")
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-nome_pai`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-nome_pai`]?.message
              }
            />
            <InputBase
              control={control}
              id="nome_pai"
              label="Nome do pai"
              name="nome_pai"
              readOnly={!isEditable}
            />
          </S.ContainerInput>
          <Grid item xs={12} md={12}>
            <T.H6>Informações do local de nascimento</T.H6>
          </Grid>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(IDENTIFICACAO, "pais")}
            onClick={() =>
              onChangeSetListCorrecion(
                IDENTIFICACAO,
                "pais",
                "Pais de nascimento"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-pais`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-pais`]?.message
              }
            />
            <InputSelect
              title="Pais de nascimento"
              control={control}
              id="pais"
              label="Pais de nascimento"
              name="pais"
              readOnly={!isEditable}
              required
              onClick={(e: any) =>
                getItemTableStructure(
                  paises.cod_tabela,
                  e.target.value,
                  "estados"
                )
              }
            >
              <option> </option>
              {paises.itemsTable?.length &&
                paises.itemsTable.map((pais) => {
                  if (pais.item_tabela === 0) return null;
                  return (
                    <option key={Math.random()} value={pais.item_tabela}>
                      {pais.descr_tabela}
                    </option>
                  );
                })}
            </InputSelect>
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(IDENTIFICACAO, "estado")}
            onClick={() =>
              onChangeSetListCorrecion(
                IDENTIFICACAO,
                "estado",
                "Estado de nascimento"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-estado`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-estado`]?.message
              }
            />
            <InputSelect
              title="Estado de nascimento"
              control={control}
              id="estado"
              label="Estado de nascimento"
              name="estado"
              readOnly={!isEditable}
              required
              onClick={(e: any) =>
                getItemTableStructure(
                  estados.cod_tabela,
                  e.target.value,
                  "cidades"
                )
              }
            >
              <option> </option>
              {estados.itemsTable?.length &&
                estados.itemsTable.map((estado) => {
                  if (estado.item_tabela === 0) return null;
                  return (
                    <option key={Math.random()} value={estado.item_tabela}>
                      {estado.descr_tabela}
                    </option>
                  );
                })}
            </InputSelect>
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(IDENTIFICACAO, "cidade")}
            onClick={() =>
              onChangeSetListCorrecion(
                IDENTIFICACAO,
                "cidade",
                "Cidade de nascimento"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-cidade`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-cidade`]?.message
              }
            />
            <InputSelect
              title="Cidade de nascimento"
              control={control}
              id="cidade"
              label="Cidade de nascimento"
              name="cidade"
              readOnly={!isEditable}
              required
            >
              <option> </option>
              {cidades.itemsTable?.length &&
                cidades.itemsTable.map((cidade) => {
                  if (cidade.item_tabela === 0) return null;
                  return (
                    <option key={Math.random()} value={cidade.item_tabela}>
                      {cidade.descr_tabela}
                    </option>
                  );
                })}
            </InputSelect>
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              IDENTIFICACAO,
              "nacionalidade"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                IDENTIFICACAO,
                "nacionalidade",
                "Nacionalidade"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[`${IDENTIFICACAO}-nacionalidade`]
              }
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-nacionalidade`]
                  ?.message
              }
            />
            <InputSelect
              title="Nacionalidade"
              control={control}
              id="nacionalidade"
              label="Nacionalidade"
              name="nacionalidade"
              readOnly={!isEditable}
              required
            >
              <option> </option>
              {nacionalidade.itemsTable?.length &&
                nacionalidade.itemsTable.map((nacionalidade) => {
                  if (nacionalidade.item_tabela === 0) return null;
                  return (
                    <option
                      key={nacionalidade.item_tabela}
                      value={nacionalidade.item_tabela}
                    >
                      {nacionalidade.descr_tabela}
                    </option>
                  );
                })}
            </InputSelect>
          </S.ContainerInput>
          <Grid item xs={12} md={12}>
            <T.H6>
              Informações adicionais se o preenchimento esta sendo feito por
              terceito autorizado
            </T.H6>
          </Grid>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              IDENTIFICACAO,
              "ind_preench_terceiro"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                IDENTIFICACAO,
                "ind_preench_terceiro",
                "Quem esta preenchendo"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[
                  `${IDENTIFICACAO}-ind_preench_terceiro`
                ]
              }
              message={
                correctionValuesJsonData[
                  `${IDENTIFICACAO}-ind_preench_terceiro`
                ]?.message
              }
            />
            <InputSelect
              title="Quem esta preenchendo"
              control={control}
              id="ind_preench_terceiro"
              label="Quem esta preenchendo"
              name="ind_preench_terceiro"
              readOnly={!isEditable}
              onClick={(e: any) => {
                if (e.target.value) {
                  setIndPreenchTerceiro(true);
                  reset({
                    ...identificacaoGeral,
                    ...getValues(),
                    ind_preench_terceiro: e.target.value,
                  });
                } else {
                  setIndPreenchTerceiro(false);
                  setValue("ind_preench_terceiro", "");
                  setValue("nome_compl_preench_terceiro", "");
                  setValue("cpf_preench_terceiro", "");
                  setValue("rg_preench_terceiro", "");
                  setValue("fone_preench_terceiro", "");
                }
              }}
            >
              <option value=""> </option>
              <option value="PROCURADOR">Procuador</option>
              <option value="CURADOR">Curador</option>
              <option value="">Nenhuma das oções</option>
            </InputSelect>
          </S.ContainerInput>
          {indPreenchTerceiro && (
            <>
              <S.ContainerInput
                item
                xs={12}
                md={3}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  IDENTIFICACAO,
                  "nome_compl_preench_terceiro"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    IDENTIFICACAO,
                    "nome_compl_preench_terceiro",
                    "Nome Completo do procurador ou curador"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${IDENTIFICACAO}-nome_compl_preench_terceiro`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${IDENTIFICACAO}-nome_compl_preench_terceiro`
                    ]?.message
                  }
                />
                <InputBase
                  control={control}
                  id="nome_compl_preench_terceiro"
                  label="Nome Completo do procurador ou curador"
                  name="nome_compl_preench_terceiro"
                  readOnly={!isEditable}
                  required
                />
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={2}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  IDENTIFICACAO,
                  "cpf_preench_terceiro"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    IDENTIFICACAO,
                    "cpf_preench_terceiro",
                    "Numero do CPF"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${IDENTIFICACAO}-cpf_preench_terceiro`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${IDENTIFICACAO}-cpf_preench_terceiro`
                    ]?.message
                  }
                />
                <InputBase
                  control={control}
                  id="cpf_preench_terceiro"
                  label="Numero do CPF"
                  name="cpf_preench_terceiro"
                  type="cpf"
                  readOnly={!isEditable}
                  required
                />
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={2}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  IDENTIFICACAO,
                  "rg_preench_terceiro"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    IDENTIFICACAO,
                    "rg_preench_terceiro",
                    "Numero do RG"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${IDENTIFICACAO}-rg_preench_terceiro`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${IDENTIFICACAO}-rg_preench_terceiro`
                    ]?.message
                  }
                />
                <InputBase
                  control={control}
                  id="rg_preench_terceiro"
                  label="Numero do RG"
                  name="rg_preench_terceiro"
                  type=""
                  readOnly={!isEditable}
                  required
                />
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={2}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  IDENTIFICACAO,
                  "fone_preench_terceiro"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    IDENTIFICACAO,
                    "fone_preench_terceiro",
                    "Celular"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${IDENTIFICACAO}-fone_preench_terceiro`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${IDENTIFICACAO}-fone_preench_terceiro`
                    ]?.message
                  }
                />
                <InputBase
                  control={control}
                  id="fone_preench_terceiro"
                  label="Celular"
                  name="fone_preench_terceiro"
                  type="phone"
                  readOnly={!isEditable}
                  required
                />
              </S.ContainerInput>
            </>
          )}

          <Grid item xs={12} md={12}>
            <T.H6>Informações de deficiência</T.H6>
          </Grid>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              IDENTIFICACAO,
              "deficiencia"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                IDENTIFICACAO,
                "deficiencia",
                "Deficiência"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-deficiencia`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-deficiencia`]
                  ?.message
              }
            />
            <InputSelect
              control={control}
              title="Deficiência"
              id="deficiencia"
              label="Deficiência"
              name="deficiencia"
              readOnly={!isEditable}
            >
              <option> </option>
              {deficiencia.itemsTable?.length &&
                deficiencia.itemsTable.map((deficiencia) => {
                  if (deficiencia.item_tabela === 0) return null;
                  return (
                    <option
                      key={deficiencia.item_tabela}
                      value={deficiencia.item_tabela}
                    >
                      {deficiencia.descr_tabela}
                    </option>
                  );
                })}
            </InputSelect>
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={4}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(IDENTIFICACAO, "cid")}
            onClick={() =>
              onChangeSetListCorrecion(IDENTIFICACAO, "cid", "CID")
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${IDENTIFICACAO}-cid`]}
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-cid`]?.message
              }
            />
            <InputSelect
              control={control}
              title="CID"
              id="cid"
              label="CID"
              name="cid"
              readOnly={!isEditable}
            >
              <option> </option>
              {cid.itemsTable?.length &&
                cid.itemsTable.map((cid) => {
                  if (cid.item_tabela === 0) return null;
                  return (
                    <option key={cid.item_tabela} value={cid.item_tabela}>
                      {cid.descr_tabela}
                    </option>
                  );
                })}
            </InputSelect>
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={5}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              IDENTIFICACAO,
              "obs_deficiencia"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                IDENTIFICACAO,
                "obs_deficiencia",
                "Observação"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[`${IDENTIFICACAO}-obs_deficiencia`]
              }
              message={
                correctionValuesJsonData[`${IDENTIFICACAO}-obs_deficiencia`]
                  ?.message
              }
            />
            <InputBase
              control={control}
              id="obs_deficiencia"
              label="Observação"
              name="obs_deficiencia"
              readOnly={!isEditable}
            />
          </S.ContainerInput>
        </Grid>
        {isEditable && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "1rem",
            }}
          >
            {!isCompleted && (
              <B.ButtonPrimary variant="outlined" onClick={() => saveData()}>
                Salvar
              </B.ButtonPrimary>
            )}
            <B.ButtonPrimary type="submit">
              {isCompleted ? "Atualizar" : "CONCLUIR ETAPA"}
            </B.ButtonPrimary>
          </div>
        )}
      </form>
    </Grid>
  );
}
