/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ReportIcon from "@mui/icons-material/Report";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

import { api } from "../../../../services/api";
import { setToastMessage } from "../../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  base64ToBlob,
  convertData,
  formtTextCamera,
  getIdURLUserSol,
  PROVA_VIDA,
  PROVA_VIDA_SELFIE,
} from "../../../../utils/utils";
import * as T from "../../../base/Text";
import { MenuPappover } from "../../../MenuPappover";
import { WebcamCapture } from "../../../WebcamComponent";
import * as S from "./styles";

type DocSelfieProps = {
  isEditable: boolean;
  isOpenCorrecion: boolean;
  isOnChangeSetListCorrecion: (etapa: string, campo: string) => boolean;
  onChangeSetListCorrecion: (
    etapa: string,
    campo: string,
    label: string
  ) => void;
  correctionValuesJsonData: any;
};

type DocumentsProps = {
  conteudo_arquivo?: string;
  mime_type?: string;
  nome_arquivo?: string;
  data: string;
  hora: string;
};

type DeviceProps = {
  active: boolean;
  id: string;
  label: string;
  deviceId: string;
};

export function DocSelfie({
  isEditable,
  isOpenCorrecion,
  isOnChangeSetListCorrecion,
  correctionValuesJsonData,
  onChangeSetListCorrecion,
}: DocSelfieProps) {
  const [imgPreviewSelfie, setImgPreviewSelfie] = useState<DocumentsProps>(
    {} as DocumentsProps
  );

  const [isCamera, setIscamera] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [devices, setDevices] = useState<DeviceProps[]>([]);
  const [deviceId, setDeviceId] = useState("");
  const [tracks, setTracks] = useState<any>({});

  const dispatch = useAppDispatch();
  const { device } = useAppSelector(({ Utils }) => Utils);

  const getDataProvaVida = async () => {
    try {
      const { data } = await api.get(
        `/proof-of-life/doc-selfie/${getIdURLUserSol()}`
      );

      setImgPreviewSelfie(data.img_doc_selfie ? data.img_doc_selfie : {});
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const onCloseWebcam = () => {
    tracks.forEach((track: any) => track.stop());
    setDeviceId("");
    setIscamera(false);
  };

  const uploadImageCamera = async (imageBase64: string) => {
    try {
      if (imageBase64) {
        const blob = base64ToBlob(imageBase64);
        const file = new File([blob], "imageWebcam.jpeg", {
          type: "image/jpeg",
        });

        const formData = new FormData();
        formData.append("file", file);

        await api.post(
          `/proof-of-life/doc-selfie/${getIdURLUserSol()}`,
          formData
        );
        onCloseWebcam();
        setIscamera(false);
        getDataProvaVida();
      }
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const getPermissionCamera = async (deviceId: string) => {
    try {
      const response = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          deviceId: deviceId ? { exact: deviceId } : undefined,
          width: { ideal: 640 },
          height: { ideal: 480 },
        },
      });
      setTracks(response.getTracks());
      setDeviceId(deviceId);
      setIscamera(true);
    } catch (err: any) {
      if (err.name === "OverconstrainedError") {
        console.log(`Constraint not satisfied: ${err.constraint}`);
      }
      dispatch(
        setToastMessage({
          type: "error",
          message:
            "Não foi possivel acessar a camera do dispositivo, acesse as configurações do navegador e permita o acesso a camera!",
        })
      );
    }
  };

  const getAllDevicesVideo = async () => {
    try {
      const devicesVideo = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devicesVideo.filter(
        (device) => device.kind === "videoinput"
      );
      setDevices(
        videoDevices.map(({ deviceId, label }) => ({
          id: deviceId,
          deviceId,
          active: true,
          label: String(formtTextCamera(label)),
          onClick: () => {
            getPermissionCamera(deviceId);
            setAnchorEl(null);
          },
        }))
      );
    } catch (error: any) {
      if (error.name === "NotAllowedError") {
        dispatch(
          setToastMessage({
            type: "error",
            message: "Você precisa autorizar o acesso a câmera!",
          })
        );
      } else
        dispatch(
          setToastMessage({
            type: "error",
            message: "Não foi possível acessar os dispositivos de vídeo!",
          })
        );
    }
  };

  useEffect(() => {
    getAllDevicesVideo();
  }, []);

  const downloadFile = async (doc: DocumentsProps) => {
    try {
      if (!doc.mime_type) return;
      const a = document.createElement("a");
      a.href = `data:${doc.mime_type};base64,${doc.conteudo_arquivo}`;
      a.download = doc.nome_arquivo as string;
      a.click();

      dispatch(
        setToastMessage({
          type: "success",
          message: "Download concluido com sucesso!",
        })
      );
    } catch (err: any) {
      dispatch(
        setToastMessage({ type: "error", message: "Erro ao fazer download" })
      );
    }
  };

  useEffect(() => {
    if (!imgPreviewSelfie?.conteudo_arquivo) {
      getDataProvaVida();
    }
  }, []);

  // TEMPORARIO
  const { user } = useAppSelector(({ User }) => User);

  const uploadImageJpeg = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append("file", file);

      await api.post(
        `/proof-of-life/doc-selfie/${getIdURLUserSol()}`,
        formData
      );
      console.log("uploadImageJpeg");
      getDataProvaVida();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };
  // TEMPORARIO

  return (
    <S.Container>
      {device === "mobile" && (
        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          <T.H6 size="1.3rem">Selfie com documento</T.H6>
        </div>
      )}
      <S.BoxDocImages>
        <S.BoxDocIcon>
          <img src="/images/animationDocSelfie.gif" alt="icon" />
        </S.BoxDocIcon>

        <S.BoxBoxDocImageItem>
          <S.BoxBoxDocImageItemTitle>
            <T.H6>
              Tire um selfie com o lado frontal de seu documento de
              identificação
            </T.H6>
            <div
              style={{
                padding: "0 0.25rem",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              {isEditable &&
                !isCamera &&
                devices.length &&
                (devices.length === 1 ? (
                  <Tooltip title={formtTextCamera(devices[0].label) as string}>
                    <IconButton
                      onClick={() => getPermissionCamera(devices[0].deviceId)}
                    >
                      <CameraAltIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton
                    title="verse"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <CameraAltIcon fontSize="small" />
                  </IconButton>
                ))}
              {!isEditable && (
                <IconButton
                  title="Download"
                  onClick={() => downloadFile(imgPreviewSelfie)}
                >
                  <FileDownloadIcon fontSize="small" />
                </IconButton>
              )}

              {correctionValuesJsonData[
                `${PROVA_VIDA}-${PROVA_VIDA_SELFIE}`
              ] ? (
                correctionValuesJsonData[`${PROVA_VIDA}-${PROVA_VIDA_SELFIE}`]
                  ?.message ? (
                  <Tooltip
                    title={
                      correctionValuesJsonData[
                        `${PROVA_VIDA}-${PROVA_VIDA_SELFIE}`
                      ]?.message
                    }
                  >
                    <ReportIcon color="warning" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={false}
                    disableFocusListener
                    disableInteractive
                    disableHoverListener
                  >
                    <ReportIcon color="warning" />
                  </Tooltip>
                )
              ) : null}
            </div>
          </S.BoxBoxDocImageItemTitle>
          {/* TEMPORARIO */}
          {["04168497097"].includes(user.numero_cpf) && isEditable && (
            <div style={{ width: "100%" }}>
              <input
                placeholder="Coloque a foto da prova de vida aqui"
                type="file"
                accept="image/*"
                onChange={(e) => {
                  if (e.target.files) {
                    uploadImageJpeg(e.target.files[0]);
                  }
                }}
              />
            </div>
          )}
          {/* TEMPORARIO */}
          {imgPreviewSelfie?.data && (
            <Grid container>
              <Grid item xs={12} sm={12} md={12} lg={12}>
                <T.Paragraph size="0.775rem">
                  Data e hora recolhidos do observatorio nacional no momento da
                  capitura da selfie.
                </T.Paragraph>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                display="flex"
                alignItems="center"
                gap="0.5rem"
              >
                <T.Paragraph size="0.775rem" width="min-content">
                  Data:
                </T.Paragraph>
                <T.Paragraph size="0.775rem">
                  {!!imgPreviewSelfie?.data &&
                    convertData(imgPreviewSelfie?.data)}
                </T.Paragraph>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                display="flex"
                alignItems="center"
                gap="0.5rem"
              >
                <T.Paragraph size="0.775rem" width="min-content">
                  Hora:
                </T.Paragraph>
                <T.Paragraph size="0.775rem">
                  {imgPreviewSelfie?.hora}
                </T.Paragraph>
              </Grid>
            </Grid>
          )}
          {isCamera && (
            <S.BoxDocImageLabelContainer>
              <S.BoxDocImageLabel isCamera={isCamera}>
                <WebcamCapture
                  deviceId={deviceId}
                  uploadImageCamera={uploadImageCamera}
                  onClose={onCloseWebcam}
                />
              </S.BoxDocImageLabel>
            </S.BoxDocImageLabelContainer>
          )}
          {!isCamera && (
            <S.BoxDocImageLabelContainer
              isOpenCorrecion={isOpenCorrecion}
              isSelected={isOnChangeSetListCorrecion(
                PROVA_VIDA,
                PROVA_VIDA_SELFIE
              )}
              onClick={() =>
                onChangeSetListCorrecion(
                  PROVA_VIDA,
                  PROVA_VIDA_SELFIE,
                  "Selfie com documento"
                )
              }
            >
              <S.BoxDocImageLabel
                isCamera={isCamera}
                backgroundUrl={
                  imgPreviewSelfie &&
                  imgPreviewSelfie.conteudo_arquivo &&
                  `data:${imgPreviewSelfie.mime_type};base64,${imgPreviewSelfie.conteudo_arquivo}`
                }
              >
                {isEditable &&
                  !isCamera &&
                  !Object.keys(imgPreviewSelfie).length &&
                  devices.length &&
                  (devices.length === 1 ? (
                    <Tooltip
                      title={formtTextCamera(devices[0].label) as string}
                    >
                      <IconButton
                        onClick={() => getPermissionCamera(devices[0].deviceId)}
                      >
                        <CameraAltIcon fontSize="large" color="action" />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <IconButton
                      title="verse"
                      onClick={(e) => setAnchorEl(e.currentTarget)}
                    >
                      <CameraAltIcon fontSize="large" color="action" />
                    </IconButton>
                  ))}
              </S.BoxDocImageLabel>
            </S.BoxDocImageLabelContainer>
          )}
        </S.BoxBoxDocImageItem>
      </S.BoxDocImages>

      {devices.length > 1 && (
        <MenuPappover
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          handleClose={() => setAnchorEl(null)}
          options={devices as any}
        />
      )}
    </S.Container>
  );
}
