/**
 * Action types
 */
export enum SolicitationTypes {
  SET_IDENTIFICATION_GENERIC = "@solicitation/SET_IDENTIFICATION_GENERIC",
  SET_DADOS_COMPLEMENTARES = "@solicitation/SET_DADOS_COMPLEMENTARES",
  SET_STATE_DEFAULT = "@solicitation/SET_STATE_DEFAULT",
}

/**
 * valores isolados do objeto de parametros
 */
export interface IIdentificacaoGeral {
  id_sol_dados_ident?: number;
  id_sol_usuario?: number;
  nome_completo: string;
  nome_social: string;
  dt_nascimento: string;
  numero_cpf: string;
  nis: number | null;
  sexo: "M" | "F";
  nome_pai: string;
  nome_mae: string;
  estado_civil: number | null; // TAB: 4
  etnia: number | null; // TAB: 5
  nacionalidade: number | null; // TAB: 6
  cidade: number | null; // TAB: 3
  estado: number | null; // TAB: 2
  pais: number | null; // TAB: 1
  escolaridade: number | null; // TAB: 7
  deficiencia: number | null; // TAB: 8
  obs_deficiencia: string;
  cid: number | null; // TAB: 9
  cor_cabelo: number | null; // TAB: 20
  cor_olhos: number | null; // TAB: 21
  ind_preench_terceiro: string;
  nome_compl_preench_terceiro?: string;
  cpf_preench_terceiro?: string;
  rg_preench_terceiro?: string;
  fone_preench_terceiro?: string;
}

export interface IDadosComplementares {
  id_sol_dados_compl?: number | null;
  id_sol_usuario?: number | null;
  cep: string;
  endereco: string;
  numero: string;
  complemento: string | null;
  bairro: string | null;
  cidade: number | null;
  estado: number | null;
  pais: number | null;
  telefone_fixo: string | null;
  telefone_movel: string | null;
  email: string | null;
  dt_chegada_brasil: string;
  dt_naturalizacao: string;
  casado_br: "S" | "N";
  filho_br: "S" | "N";
  ind_residencia_propria: "S" | "N";
  ind_comprada_fgts: "S" | "N";
  ind_reside_exterior: "S" | "N";
  ind_trabalhador_estrang: "S" | "N";
}

/**
 * valores do state completo desse redux
 */
export type SolicitationState = {
  readonly identificacaoGeral: IIdentificacaoGeral;
  readonly dadosComplementares: IDadosComplementares;
};
