import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 640px;
  margin: 0 auto;
  height: min-content;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  @media screen {
    @media (max-width: 768px) {
      max-width: 100%;
      width: 320px; // tamnho no mobile
    }
  }
`;

export const BoxImageIcon = styled.div`
  width: 640px;
  height: 480px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media screen {
    @media (max-width: 768px) {
      width: 320px;
      height: 240px;
    }
  }
`;

export const DetailsFaceRecognition = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: max-content;
    border-radius: 0.25rem;
    border: 1px solid ${theme.palette.primary.light};
    background-color: ${theme.background.blueLight};
    fill-opacity: 0.8;
    padding: 0.8rem;
    margin-bottom: 1rem;
  `}
`;
