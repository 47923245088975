import { Reducer } from "redux";

import { ProofOfLifeTypes, ProofOfLifeState } from "./types";

const INITIAL_STATE: ProofOfLifeState = {
  faceRecognition: {
    info_faceapi_genero: "",
    info_faceapi_idade: "",
    linkVideo: "",
  },
};

const reducer: Reducer<ProofOfLifeState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ProofOfLifeTypes.SET_FACE_RECOGNITION:
      return { ...state, faceRecognition: action.payload };

    default:
      return state;
  }
};

export default reducer;
