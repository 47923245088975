/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseIcon from "@mui/icons-material/Close";
import HistoryIcon from "@mui/icons-material/History";
import QueryBuilderIcon from "@mui/icons-material/QueryBuilder";
import { useCallback, useEffect, useState } from "react";

import { api } from "../../services/api";
import { setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../store/hooks";
import { convertData, perfilStringFormat } from "../../utils/utils";
import { ButtonSecondary } from "../base/Buttons";
import * as T from "../base/Text";
import * as S from "./styles";

type DrawerHistoricoSolicitationProps = {
  openDrawer: boolean;
  onCloseDrawer: () => void;
  idSolicitation: number;
};

type HistoricoProps = {
  dt_operacao: string;
  id_sol_usuario: number;
  id_usuario_envio: number;
  id_solicit_hist: number;
  msg_despacho: string;
  numero_cpf: string;
  nome: string;
  status: string;
  status_registrado: number;
  perfil_usuario: number;
};

export function DrawerHistoricoSolicitation({
  openDrawer,
  onCloseDrawer,
  idSolicitation,
}: DrawerHistoricoSolicitationProps) {
  const [historico, setHistorico] = useState<HistoricoProps[]>([]);

  const dispatch = useAppDispatch();

  const getAllHistoric = useCallback(async () => {
    try {
      const { data } = await api.get(
        `/solicitation/historic/${idSolicitation}`
      );
      setHistorico(data);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  }, []);

  useEffect(() => {
    if (openDrawer) {
      getAllHistoric();
    }
  }, [openDrawer]);

  return (
    <S.DrawerBottom
      anchor="right"
      open={openDrawer}
      onClose={() => onCloseDrawer()}
    >
      <S.DrawerContainer>
        <S.DrawerHeader>
          <HistoryIcon fontSize="large" />
          <div>
            <T.H2>Historico de Interações</T.H2>
            <T.Paragraph color="#8c8c8c">
              Aqui você pode ver o histórico de interações da sua solicitação.
            </T.Paragraph>
          </div>
        </S.DrawerHeader>
        <S.DrawerContent>
          {historico.length
            ? historico.map((item: HistoricoProps) => (
                <S.BoxDetails key={item.id_solicit_hist}>
                  <T.Paragraph>
                    {perfilStringFormat(item.perfil_usuario)}:
                    <T.Span>{item.nome}</T.Span>
                  </T.Paragraph>
                  <T.Paragraph>
                    Status:<T.Span>{item.status}</T.Span>
                  </T.Paragraph>
                  <T.Paragraph>
                    Mensagem:
                    <T.Span>{item.msg_despacho}</T.Span>
                  </T.Paragraph>
                  <S.BoxDetailsTime>
                    <QueryBuilderIcon fontSize="small" />
                    <T.Span>{convertData(item.dt_operacao)}</T.Span>
                  </S.BoxDetailsTime>
                </S.BoxDetails>
              ))
            : "Não há histórico para essa solicitação."}
        </S.DrawerContent>
        <S.DrawerFooter>
          <ButtonSecondary onClick={() => onCloseDrawer()}>
            Fechar
            <CloseIcon fontSize="small" style={{ marginLeft: "0.5rem" }} />
          </ButtonSecondary>
        </S.DrawerFooter>
      </S.DrawerContainer>
    </S.DrawerBottom>
  );
}
