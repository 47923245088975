/* eslint-disable @typescript-eslint/no-explicit-any */
import { BottomNavigation, BottomNavigationAction } from "@mui/material";
import { CSSProperties, ReactNode } from "react";

type NavigationProps = {
  icon: ReactNode;
  name: string;
  onClick?: () => void;
};

type BottomNavigationProps = {
  actions: NavigationProps[];
  value: number;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  style: CSSProperties | undefined;
};

export function BottomNavigationComponent({
  actions,
  style,
  setValue,
  value,
}: BottomNavigationProps) {
  return (
    <BottomNavigation
      showLabels
      value={value}
      onChange={(_: any, newValue: number) => {
        setValue(newValue);
      }}
      style={style}
    >
      {actions.map((action: NavigationProps) => (
        <BottomNavigationAction
          key={action.name}
          label={action.name}
          icon={action.icon}
          onClick={action.onClick}
        />
      ))}
    </BottomNavigation>
  );
}
