import { Box, Grid } from "@mui/material";
import styled from "styled-components";

export const CardLogin = styled.div`
  padding: 2rem 2.5rem;
  width: 25rem;

  @media (max-width: 768px) {
    width: 95%;
    padding: 1rem 1.5rem;
  }
`;

export const CardHeader = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CardBody = styled(Grid)``;

export const Logo = styled.img`
  width: 80%;
  margin-bottom: 3rem;
`;
