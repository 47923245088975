/* eslint-disable react/jsx-props-no-spreading */
import BarChartIcon from "@mui/icons-material/BarChart";
import DataSaverOffIcon from "@mui/icons-material/DataSaverOff";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

import {
  getGraphicPizzaAction,
  getGraphicBarAction,
} from "../../../store/ducks/Graphics/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { CardPrimery } from "../../base/Cards";
import * as T from "../../base/Text";
import { GraphicBar } from "../../GraphicBar";
import { GraphicPizza } from "../../GraphicPizza";
import * as S from "../styles";

type DashbordSolicitationProps = {
  idItemGraphic: number;
};

export function DashbordSolicitation({
  idItemGraphic,
}: DashbordSolicitationProps) {
  const [toggleGraphic, setToggleGraphic] = useState(2);
  const dispatch = useAppDispatch();
  const { graphicBar, graphicPizza } = useAppSelector(
    ({ Graphics }) => Graphics
  );

  useEffect(() => {
    if (idItemGraphic) {
      dispatch(getGraphicPizzaAction(idItemGraphic));
      dispatch(getGraphicBarAction(idItemGraphic));
    }
  }, [idItemGraphic]);

  return (
    <CardPrimery container marginTop="1rem" padding="1.5rem 2rem" height="100%">
      {graphicPizza.length ? (
        <>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <T.H6 size="1.2rem">
              Visualize o andamento das solicitações de acordo com os status de
              preenchimento
            </T.H6>
            <div
              style={{
                width: "min-content",
                display: "flex",
                flexDirection: "column",
              }}
            >
              {graphicBar.length >= 4 && (
                <>
                  <IconButton onClick={() => setToggleGraphic(1)}>
                    <Tooltip title="Grafico de Barra">
                      <BarChartIcon />
                    </Tooltip>
                  </IconButton>
                  <IconButton onClick={() => setToggleGraphic(2)}>
                    <Tooltip title="Grafico de pizza">
                      <DataSaverOffIcon />
                    </Tooltip>
                  </IconButton>
                </>
              )}
            </div>
          </Grid>
          {(graphicBar.length >= 2 && graphicBar.length < 4) ||
          toggleGraphic === 1 ? (
            <Grid
              item
              xs={12}
              height={400}
              {...(graphicBar.length < 4 && { md: 6 })}
            >
              <S.BoxGraphic>{idItemGraphic && <GraphicBar />}</S.BoxGraphic>
            </Grid>
          ) : null}
          {graphicBar.length < 4 || toggleGraphic === 2 ? (
            <Grid
              item
              xs={12}
              height={400}
              {...(graphicBar.length >= 2 &&
                graphicBar.length < 4 && { md: 6 })}
            >
              <S.BoxGraphic>{idItemGraphic && <GraphicPizza />}</S.BoxGraphic>
            </Grid>
          ) : null}
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <T.H6 size="1.2rem">Não há dados para serem exibidos</T.H6>
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <img
              src="/images/graphics.svg"
              alt="graphics"
              style={{
                width: "100%",
                maxHeight: "400px",
              }}
            />
          </Grid>
        </>
      )}
    </CardPrimery>
  );
}
