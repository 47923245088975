/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate, useLocation } from "react-router-dom";

import * as B from "../../components/base/Buttons";
import { BoxLogin } from "../../components/boxLogin";
import { InputPassword } from "../../components/Inputs/InputPassword";
import { InputBase } from "../../components/Inputs/InputText";
import { RulesPassword } from "../../components/RulesPassword";
import { useLoginRedirect } from "../../hooks/useLoginRedirect";
import { api } from "../../services/api";
import { setLoading, setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getClientUrl, removeMask } from "../../utils/utils";
import * as S from "./styles";

type FormDataRegister = {
  name: string;
  email: string;
  cpf: string;
  password: string;
  passwordReapeat: string;
};

export function Register() {
  const [password, setPassword] = useState("");
  const [cpfUserBylogin, setCpfUserBylogin] = useState(""); // verifica se o usuário tem conta ativa
  const [hasValidatePassword, setHasValidatePassword] = useState(false);
  const { handleSubmit, control, setValue } = useForm<FormDataRegister>();

  const navegate = useNavigate();
  const location = useLocation();

  const dispatch = useAppDispatch();
  const [{ clientLogged }, { theme }] = useAppSelector(({ Clients, Utils }) => [
    Clients,
    Utils,
  ]);
  const isLogged = useLoginRedirect();

  useEffect(() => {
    if (clientLogged.ind_permitir_criar_conta !== "S") {
      navegate(`/${getClientUrl()}/login`);
    }
    isLogged();
    if (location.state) {
      setCpfUserBylogin(location.state as string);
      setValue("cpf", location.state as string);
    }
  }, []);

  const onSubmit: SubmitHandler<FormDataRegister> = async ({
    cpf,
    email,
    name,
    password,
    passwordReapeat,
  }) => {
    try {
      if (password !== passwordReapeat) {
        dispatch(
          setToastMessage({ type: "error", message: "Senhas diferentes" })
        );
        return;
      }
      if (!hasValidatePassword) {
        dispatch(
          setToastMessage({ type: "error", message: "Senha invalida!" })
        );
        return;
      }
      dispatch(setLoading(true));
      const body = {
        cpf: removeMask(cpf),
        confirmPassword: passwordReapeat,
        name,
        email,
        password,
      };
      const { data } = await api.post("/user/register", body);
      dispatch(setToastMessage({ type: "success", message: data.message }));
      navegate(`/${getClientUrl()}/login`);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <S.Container>
      <S.Content>
        <S.ContentLogin>
          <BoxLogin>
            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              style={{ width: "100%" }}
            >
              <Grid item xs={12}>
                <InputBase
                  control={control}
                  id="nomeCompleto"
                  label="Nome completo"
                  name="name"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <InputBase
                  control={control}
                  id="email"
                  label="Email"
                  name="email"
                  required
                  rules={{
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: "Email inválido",
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputBase
                  control={control}
                  id="CPF"
                  label="CPF"
                  name="cpf"
                  type="cpf"
                  defaultValue={cpfUserBylogin}
                  disabled={cpfUserBylogin !== ""}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <InputPassword
                  control={control}
                  id="password"
                  label="Senha"
                  name="password"
                  required
                  customChange={(e) => {
                    setPassword(e.target.value);
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <InputPassword
                  control={control}
                  id="passwordReapeat"
                  label="Repetir senha"
                  name="passwordReapeat"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <RulesPassword
                  password={password}
                  setHasValidatePassword={setHasValidatePassword}
                />
              </Grid>
              <Grid item xs={12}>
                <B.ButtonPrimary
                  type="submit"
                  style={{ width: "100%", marginTop: "1rem" }}
                >
                  Criar conta
                </B.ButtonPrimary>
              </Grid>
            </form>
            <Grid item xs={12} style={{ marginTop: "1rem" }}>
              <B.ButtonLink
                color="secondary"
                to={`/${getClientUrl()}/login`}
                style={{ width: "100%" }}
              >
                Login
              </B.ButtonLink>
            </Grid>
          </BoxLogin>
        </S.ContentLogin>
        <S.BoxImage
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_BASE_URL_API}decreto-previrg`,
              "_blank"
            );
          }}
        >
          <img src={`/images/bunner_${theme.mode}.png`} alt="" />
        </S.BoxImage>
      </S.Content>
    </S.Container>
  );
}
