/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { FormControlLabel, Grid, Radio } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setItemAction } from "../../../store/ducks/TableStructure/actions";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { convertData, getIdURLUserSol, removeMask } from "../../../utils/utils";
import { validarCNPJ, validarCPF } from "../../../utils/validators";
import * as B from "../../base/Buttons";
import { InputDate } from "../../Inputs/InputDate";
import { InputRadios } from "../../Inputs/InputRadios";
import { InputSelect } from "../../Inputs/InputSelect";
import { InputBase } from "../../Inputs/InputText";
import Modal from "../index";
import * as S from "./styles";

interface IModalAddVinculo {
  isOpen: boolean;
  onClose: () => void;
  returnIcon?: () => void;
  vinculo?: FormData;
  updateListVinc: () => Promise<void>;
  isEditable: boolean;
  idVincTrab: number;
}

type FormData = {
  id_sol_dados_vinculo?: number | null;
  id_sol_dados_vinc_trab?: number | null;
  tipo_vinculo?: number | null;
  data_inicio?: string | null;
  data_final?: string | null;
  cargo?: string | null;
  razao_social?: string | null;
  regime_vinc?: string | null;
  tipo_doc_vinc_item?: string | null;
  num_doc_vinculo?: string | null;
  obs_vinculo?: string | null;
  salario_bruto?: string | null;
  salario_liquido?: string | null;
  contribuicao_prev?: string | null;
  num_certif_tempo_contribuicao?: string | null;
  natureza_juridica?: string | null; // Publica ou Privada
  ind_vinc_municipal?: "S" | "N";
  matricula?: string | null; // se ind_vinc_municipal = S
  secretaria_item?: number | null; // cod_tabela: 24 - combo da previrg - se ind_vinc_municipal = S
  setor?: number | null; // combo da previrg - opcional - se ind_vinc_municipal = S
  ind_vinc_magisterio?: "S" | "N";
  regime_previdenciario_item?: string | null; // cod_tabela: 23
  municipio_regime_previdenciario?: string | null;
};

export function AddVinculo({
  isOpen,
  onClose,
  returnIcon,
  vinculo,
  idVincTrab,
  updateListVinc,
  isEditable,
}: IModalAddVinculo) {
  const { handleSubmit, control, reset, setError, setValue } =
    useForm<FormData>();

  const [typeDocument, setTypeDocument] = useState("");
  const [indVincMunicipal, setIndVincMunicipal] = useState("N");
  const [regimePrevi, setRegimePrevi] = useState(0);

  const dispatch = useAppDispatch();

  const {
    typeDocVinculo,
    regimeTrabalho,
    listaSecretarias,
    listaSetores,
    regimePrevidenciario,
  } = useAppSelector(({ TableStructure }) => TableStructure);

  const onSubmit: SubmitHandler<FormData> = async (body) => {
    try {
      const valueDoc = removeMask(body.num_doc_vinculo!);
      const isValidDoc =
        Number(body.tipo_doc_vinc_item) === 1
          ? validarCPF(valueDoc!)
          : validarCNPJ(valueDoc!);

      if (!isValidDoc) {
        setError("num_doc_vinculo", {
          type: "manual",
          message: "Documento inválido",
        });
        return;
      }

      const { data } = await api.post(
        `/solicitation/vinculo/save/${getIdURLUserSol()}/${idVincTrab || ""}`,
        {
          ...body,
          data_inicio: convertData(body.data_inicio!),
          data_final: convertData(body.data_final!),
        }
      );
      dispatch(setToastMessage({ type: "success", message: data.message }));
      await updateListVinc();
      onClose();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
      if (error?.errors && error?.errors.length) {
        error.errors.forEach((error: any) => {
          setError(error.property, {
            type: "manual",
            message: error.error,
          });
        });
      }
    }
  };

  useEffect(() => {
    if (vinculo?.id_sol_dados_vinc_trab) {
      reset(vinculo);
      setTypeDocument(
        Number(vinculo.tipo_doc_vinc_item) === 1
          ? "cpf"
          : Number(vinculo.tipo_doc_vinc_item) === 2
          ? "cnpj"
          : ""
      );
      setIndVincMunicipal(vinculo.ind_vinc_municipal!);
      setRegimePrevi(Number(vinculo.regime_previdenciario_item));
    } else {
      reset({
        id_sol_dados_vinc_trab: null,
        id_sol_dados_vinculo: null,
        tipo_vinculo: null,
        data_inicio: null,
        data_final: null,
        cargo: null,
        razao_social: null,
        regime_vinc: null,
        tipo_doc_vinc_item: null,
        num_doc_vinculo: null,
        obs_vinculo: null,
        salario_bruto: null,
        salario_liquido: null,
        contribuicao_prev: null,
        num_certif_tempo_contribuicao: null,
        natureza_juridica: null,
        ind_vinc_municipal: "N",
        matricula: null,
        secretaria_item: null,
        setor: null,
        ind_vinc_magisterio: "N",
        regime_previdenciario_item: null,
      });
    }
  }, [vinculo]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Vinculo Trabalhista"
      returnIcon={returnIcon}
    >
      <S.ModalBody>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ width: "100%" }}
        >
          <Grid
            container
            spacing={1}
            style={{ width: "100%", marginTop: "1rem" }}
          >
            <Grid item xs={12} md={regimePrevi === 2 ? 3 : 6}>
              <InputBase
                control={control}
                id="razao_social"
                label="Razão social"
                name="razao_social"
                readOnly={!isEditable}
                required
                helperText="Nome da empresa ou órgão público"
              />
            </Grid>

            <Grid item xs={12} md={3}>
              <InputSelect
                control={control}
                title="Regime previdenciário"
                id="regime_previdenciario_item"
                label="Regime previdenciário"
                name="regime_previdenciario_item"
                required
                helperText="Estatutario = RPPS / CLT = INSS"
                readOnly={!isEditable}
                onClick={({ target }: any) => {
                  const value = Number(target.value);
                  if (value === 1) {
                    setValue("municipio_regime_previdenciario", "");
                  }
                  setRegimePrevi(Number(target.value));
                }}
              >
                <option> </option>
                {regimePrevidenciario.itemsTable?.length &&
                  regimePrevidenciario.itemsTable.map((regime) => {
                    if (regime.item_tabela === 0) return null;
                    return (
                      <option key={Math.random()} value={regime.item_tabela}>
                        {regime.descr_tabela}
                      </option>
                    );
                  })}
              </InputSelect>
            </Grid>
            {regimePrevi === 2 && (
              <Grid item xs={12} md={3}>
                <InputBase
                  control={control}
                  id="municipio_regime_previdenciario"
                  label="Municipio/Estado do regime previdenciário"
                  name="municipio_regime_previdenciario"
                  helperText="Se concursado, especificar município/estado"
                  readOnly={!isEditable}
                  required
                />
              </Grid>
            )}
            <Grid item xs={12} md={3}>
              <InputSelect
                control={control}
                title="Regime de trabalho"
                id="tipo_regime_item"
                label="Regime de trabalho"
                name="tipo_regime_item"
                helperText="Modelo do vinculo"
                required
                readOnly={!isEditable}
              >
                <option> </option>
                {regimeTrabalho.itemsTable?.length &&
                  regimeTrabalho.itemsTable.map((regime) => {
                    if (regime.item_tabela === 0) return null;
                    return (
                      <option key={Math.random()} value={regime.item_tabela}>
                        {regime.descr_tabela}
                      </option>
                    );
                  })}
              </InputSelect>
            </Grid>
            <Grid item xs={12} md={3}>
              <InputRadios
                control={control}
                id="natureza_juridica"
                title="Natureza jurídica"
                name="natureza_juridica"
                required
                readOnly={!isEditable}
                row
              >
                <FormControlLabel
                  value="Publica"
                  control={<Radio size="small" />}
                  label="Pública"
                />
                <FormControlLabel
                  value="Privada"
                  control={<Radio size="small" />}
                  label="Privada"
                />
              </InputRadios>
            </Grid>
            <Grid item xs={12} md={3}>
              <InputRadios
                control={control}
                id="ind_vinc_magisterio"
                title="Vínculo magistério"
                name="ind_vinc_magisterio"
                required
                readOnly={!isEditable}
                row
              >
                <FormControlLabel
                  value="S"
                  control={<Radio size="small" />}
                  label="Sim"
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  label="Não"
                />
              </InputRadios>
            </Grid>
            <Grid item xs={12} md={3}>
              <InputRadios
                control={control}
                id="ind_vinc_municipal"
                title="Vínculo municipal"
                name="ind_vinc_municipal"
                required
                readOnly={!isEditable}
                row
              >
                <FormControlLabel
                  value="S"
                  control={<Radio size="small" />}
                  onChange={() => {
                    setValue("matricula", vinculo?.matricula);
                    setValue("secretaria_item", vinculo?.secretaria_item);
                    setValue("setor", vinculo?.setor);
                    setIndVincMunicipal("S");
                  }}
                  label="Sim"
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  onChange={() => {
                    setValue("matricula", null);
                    setValue("secretaria_item", null);
                    setValue("setor", null);
                    setIndVincMunicipal("N");
                  }}
                  label="Não"
                />
              </InputRadios>
            </Grid>

            {indVincMunicipal === "S" && (
              <>
                <Grid item xs={12} md={3}>
                  <InputBase
                    control={control}
                    id="matricula"
                    label="Matrícula"
                    name="matricula"
                    helperText="Informar matrícula atual"
                    readOnly={!isEditable}
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputSelect
                    control={control}
                    title="Secretaria"
                    id="secretaria_item"
                    label="Secretaria"
                    name="secretaria_item"
                    required
                    readOnly={!isEditable}
                    onClick={async (e: any) => {
                      const { data } = await api.get(
                        `/system/structured-table?cod=&codSup=26&itemSup=${e.target.value}`
                      );
                      dispatch(setItemAction({ data, key: "listaSetores" }));
                    }}
                  >
                    <option> </option>
                    {listaSecretarias.itemsTable?.length &&
                      listaSecretarias.itemsTable.map((regime) => {
                        if (regime.item_tabela === 0) return null;
                        return (
                          <option
                            key={Math.random()}
                            value={regime.item_tabela}
                          >
                            {regime.descr_tabela}
                          </option>
                        );
                      })}
                  </InputSelect>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputSelect
                    control={control}
                    title="Setor"
                    id="setor"
                    label="Setor"
                    name="setor"
                    readOnly={!isEditable}
                  >
                    <option> </option>
                    {listaSetores.itemsTable?.length &&
                      listaSetores.itemsTable.map((regime) => {
                        if (regime.item_tabela === 0) return null;
                        return (
                          <option
                            key={Math.random()}
                            value={regime.item_tabela}
                          >
                            {regime.descr_tabela}
                          </option>
                        );
                      })}
                  </InputSelect>
                </Grid>
              </>
            )}

            <Grid item xs={12} md={indVincMunicipal === "S" ? 5 : 3}>
              <InputBase
                control={control}
                id="cargo"
                label="Cargo"
                name="cargo"
                readOnly={!isEditable}
                required
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputDate
                control={control}
                id="data_inicio"
                label="Data inicio"
                name="data_inicio"
                helperText="Data inicial do vínculo"
                readOnly={!isEditable}
                required
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputDate
                control={control}
                id="data_final"
                label="Data final"
                name="data_final"
                helperText="Data de encerramento do vínculo (se houver)"
                readOnly={!isEditable}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputBase
                control={control}
                id="salario_bruto"
                label="Salário bruto"
                name="salario_bruto"
                helperText="Opcional"
                readOnly={!isEditable}
                type="currency"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputBase
                control={control}
                id="salario_liquido"
                label="Salário liquido"
                name="salario_liquido"
                helperText="Opcional"
                readOnly={!isEditable}
                type="currency"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputBase
                control={control}
                id="contribuicao_prev"
                label="Contribuição previdenciária"
                name="contribuicao_prev"
                helperText="Opcional"
                readOnly={!isEditable}
                type="currency"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputBase
                control={control}
                id="num_certif_tempo_contribuicao"
                label="Nº certificado tempo contribuição (CTC)"
                name="num_certif_tempo_contribuicao"
                helperText="Opcional"
                readOnly={!isEditable}
              />
            </Grid>

            <Grid item xs={12} md={2}>
              <InputSelect
                title="Tipo documento"
                control={control}
                id="tipo_doc_vinc_item"
                label="Tipo documento"
                name="tipo_doc_vinc_item"
                required
                readOnly={!isEditable}
                onClick={({ target }: any) => {
                  setTypeDocument(
                    Number(target.value) === 1
                      ? "cpf"
                      : Number(target.value) === 2
                      ? "cnpj"
                      : ""
                  );
                }}
              >
                <option> </option>
                {typeDocVinculo.itemsTable?.length &&
                  typeDocVinculo.itemsTable.map((doc) => {
                    if (doc.item_tabela === 0) return null;
                    return (
                      <option key={Math.random()} value={doc.item_tabela}>
                        {doc.sigla_tabela}
                      </option>
                    );
                  })}
              </InputSelect>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputBase
                control={control}
                id="num_doc_vinculo"
                label="Numero documento"
                name="num_doc_vinculo"
                helperText="Documento da empresa ou órgão público"
                readOnly={!isEditable}
                type={typeDocument}
                required
              />
            </Grid>
          </Grid>
          {isEditable && (
            <Grid
              item
              xs={12}
              marginTop="1rem"
              display="flex"
              justifyContent="center"
            >
              <B.ButtonPrimary
                type="submit"
                style={{
                  width: "clamp(150px, 300px, 100%)",
                  marginBottom: "0.5rem",
                }}
              >
                {vinculo?.id_sol_dados_vinc_trab ? "Salvar" : "Adicionar"}
              </B.ButtonPrimary>
            </Grid>
          )}
        </form>
      </S.ModalBody>
    </Modal>
  );
}
