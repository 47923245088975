/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-case-declarations */
import { Reducer } from "redux";

import { TableStructureTypes, TableStructureState } from "./types";

const INITIAL_STATE: TableStructureState = {
  paises: {
    cod_tabela: 1,
    itemsTable: [],
  },
  estados: {
    cod_tabela: 2,
    itemsTable: [],
  },
  cidades: {
    cod_tabela: 3,
    itemsTable: [],
  },
  estadoCivil: {
    cod_tabela: 4,
    itemsTable: [],
  },
  etnia: {
    cod_tabela: 5,
    itemsTable: [],
  },
  nacionalidade: {
    cod_tabela: 6,
    itemsTable: [],
  },
  escolaridade: {
    cod_tabela: 7,
    itemsTable: [],
  },
  deficiencia: {
    cod_tabela: 8,
    itemsTable: [],
  },
  cid: {
    cod_tabela: 9,
    itemsTable: [],
  },
  typeDependents: {
    cod_tabela: 10,
    itemsTable: [],
  },
  typeDocVinculo: {
    cod_tabela: 11,
    itemsTable: [],
  },
  status: {
    cod_tabela: 16,
    itemsTable: [],
  },
  typeSolicitation: {
    cod_tabela: 17,
    itemsTable: [],
  },
  documentsSolicitantion: {
    cod_tabela: 14,
    itemsTable: [],
  },
  statusUserSolicitation: {
    cod_tabela: 15,
    itemsTable: [],
  },
  regimeTrabalho: {
    cod_tabela: 19,
    itemsTable: [],
  },
  corCabelo: {
    cod_tabela: 20,
    itemsTable: [],
  },
  corOlhos: {
    cod_tabela: 21,
    itemsTable: [],
  },
  regimePrevidenciario: {
    cod_tabela: 23,
    itemsTable: [],
  },
  listaBeneficios: {
    cod_tabela: 25,
    itemsTable: [],
  },
  listaSecretarias: {
    cod_tabela: 26,
    itemsTable: [],
  },
  listaSetores: {
    cod_tabela: 27,
    itemsTable: [],
  },
};

const reducer: Reducer<TableStructureState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case TableStructureTypes.SET_TABLE_ITEMS:
      const keysState = Object.keys(state);
      const objectResponse: TableStructureState = {} as TableStructureState;
      keysState.forEach((key) => {
        // @ts-ignore
        const chaveState = state[key];
        if (action.payload[chaveState.cod_tabela]) {
          // @ts-ignore
          objectResponse[key] = {
            ...chaveState,
            itemsTable: action.payload[chaveState.cod_tabela],
          };
        }
        return chaveState;
      });
      return { ...state, ...objectResponse };

    case TableStructureTypes.SET_ITEM:
      const { data, key } = action.payload;
      // @ts-ignore
      if (state[key]) {
        // @ts-ignore
        return { ...state, [key]: { ...state[key], itemsTable: data } };
      }
      return { ...state };

    case TableStructureTypes.SET_ITEM_TYPE_SOLICITATION:
      return { ...state, typeSolicitation: action.payload };

    default:
      return state;
  }
};

export default reducer;
