/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/require-default-props */
import { FormControlLabel, Grid, Radio } from "@mui/material";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setItemAction } from "../../../store/ducks/TableStructure/actions";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { convertData, getIdURLUserSol, removeMask } from "../../../utils/utils";
import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import { FormDocumentsDependets } from "../../FormSolicitation/Dependents";
import { InputDate } from "../../Inputs/InputDate";
import { InputRadios } from "../../Inputs/InputRadios";
import { InputSelect } from "../../Inputs/InputSelect";
import { InputBase } from "../../Inputs/InputText";
import Modal from "../index";
import * as S from "./styles";

interface IModalAddNewStructuredTable {
  isOpen: boolean;
  onClose: () => void;
  returnIcon?: () => void;
  dependent?: FormDocumentsDependets;
  updateListDepedents: () => Promise<void>;
  isEditable: boolean;
}

export function DependentsSolicitation({
  isOpen,
  onClose,
  returnIcon,
  dependent,
  updateListDepedents,
  isEditable,
}: IModalAddNewStructuredTable) {
  const { handleSubmit, control, reset, setError } =
    useForm<FormDocumentsDependets>();

  const dispatch = useAppDispatch();

  const { typeDependents, nacionalidade, paises, cidades, estados } =
    useAppSelector(({ TableStructure }) => TableStructure);

  const onSubmit: SubmitHandler<FormDocumentsDependets> = async (body) => {
    try {
      const newBody = {
        ...(dependent?.id_sol_dados_depend && { ...dependent }),
        ...body,
        cpf_dependente: removeMask(body.cpf_dependente),
        dt_nascimento_depend: convertData(body.dt_nascimento_depend),
        nome_carto_registro: body.nome_carto_registro || undefined,
        num_carto_registro: body.num_carto_registro || undefined,
        num_folha_carto_registro: body.num_folha_carto_registro || undefined,
        num_livro_carto_registro: body.num_livro_carto_registro || undefined,
        ind_fam: "S",
      };

      const { data } = await api.post(
        `/solicitation/dependents/save/${getIdURLUserSol()}/${
          dependent?.id_sol_dados_depend || ""
        }`,
        newBody
      );
      dispatch(setToastMessage({ type: "success", message: data.message }));
      await updateListDepedents();
      onClose();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
      if (error?.errors && error?.errors.length) {
        error.errors.forEach((error: any) => {
          setError(error.property, {
            type: "manual",
            message: error.error,
          });
        });
      }
    }
  };

  const getItemTableStructure = async (
    codTabelaSup: number,
    itemTabelaSup: number,
    inputAtt: string
  ) => {
    const { data } = await api.get(
      `/system/structured-table?cod=&codSup=${codTabelaSup}&itemSup=${itemTabelaSup}`
    );
    dispatch(setItemAction({ data, key: inputAtt }));
  };

  useEffect(() => {
    if (dependent?.id_sol_dados_depend) {
      reset(dependent);
    } else {
      reset({
        rg_dependente: "",
        cpf_dependente: "",
        dt_nascimento_depend: "",
        nome_dependente: "",
        nacionalidade_item: undefined,
        pais_nasc_item: undefined,
        estado_nasc_item: undefined,
        cidade_nasc_item: undefined,
        tipo_dependente_item: undefined,
        nome_mae: "",
        nome_pai: "",
        nome_carto_registro: undefined,
        num_carto_registro: undefined,
        num_folha_carto_registro: undefined,
        num_livro_carto_registro: undefined,
        id_sol_dados_depend: undefined,
        id_sol_usuario: undefined,
        ind_estudante: undefined,
        ind_fam: "S",
        ind_irrf: undefined,
        ind_salario_familia: undefined,
        ind_pensao_morte: undefined,
        sexo_dependente: undefined,
        ind_invalidez: undefined,
        ind_necess_espec: undefined,
        dt_inclusao: "",
        hr_inclusao: "",
      });
    }
  }, [dependent]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Dependente"
      returnIcon={returnIcon}
    >
      <S.ModalBody>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ width: "100%" }}
        >
          <Grid
            container
            spacing={1}
            style={{ width: "100%", marginTop: "1rem" }}
          >
            <Grid item xs={12}>
              <T.H6>Informações de indentificação</T.H6>
            </Grid>
            <Grid item xs={12} md={3}>
              <InputBase
                control={control}
                id="nome_dependente"
                label="Nome completo"
                name="nome_dependente"
                readOnly={!isEditable}
                required
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputBase
                control={control}
                id="rg_dependente"
                label="Numero RG"
                name="rg_dependente"
                readOnly={!isEditable}
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputBase
                control={control}
                id="cpf_dependente"
                label="Numero CPF"
                name="cpf_dependente"
                required
                readOnly={!isEditable}
                type="cpf"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputDate
                control={control}
                id="dt_nascimento_depend"
                name="dt_nascimento_depend"
                label="Data Nascimento"
                readOnly={!isEditable}
                required
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputRadios
                control={control}
                id="sexo_dependente"
                title="Sexo"
                name="sexo_dependente"
                required
                readOnly={!isEditable}
                row
              >
                <FormControlLabel
                  value="M"
                  control={<Radio size="small" />}
                  label="Masculino"
                />
                <FormControlLabel
                  value="F"
                  control={<Radio size="small" />}
                  label="Feminino"
                />
              </InputRadios>
            </Grid>
            <Grid item xs={12}>
              <T.H6>Nacionalidade</T.H6>
            </Grid>
            <Grid item xs={12} md={2}>
              <InputSelect
                title="Nacionalidade"
                control={control}
                id="nacionalidade_item"
                label="Nacionalidade"
                name="nacionalidade_item"
                readOnly={!isEditable}
                required
              >
                <option> </option>
                {nacionalidade.itemsTable?.length &&
                  nacionalidade.itemsTable.map((nacionalidade) => {
                    if (nacionalidade.item_tabela === 0) return null;
                    return (
                      <option
                        key={nacionalidade.item_tabela}
                        value={nacionalidade.item_tabela}
                      >
                        {nacionalidade.descr_tabela}
                      </option>
                    );
                  })}
              </InputSelect>
            </Grid>
            <Grid item xs={12} md={2}>
              <InputSelect
                title="Pais de nascimento"
                control={control}
                id="pais_nasc_item"
                label="Pais de nascimento"
                name="pais_nasc_item"
                readOnly={!isEditable}
                required
                onClick={(e: any) =>
                  getItemTableStructure(
                    paises.cod_tabela,
                    e.target.value,
                    "estados"
                  )
                }
              >
                <option> </option>
                {paises.itemsTable?.length &&
                  paises.itemsTable.map((pais) => {
                    if (pais.item_tabela === 0) return null;
                    return (
                      <option key={Math.random()} value={pais.item_tabela}>
                        {pais.descr_tabela}
                      </option>
                    );
                  })}
              </InputSelect>
            </Grid>
            <Grid item xs={12} md={3}>
              <InputSelect
                title="Estado de nascimento"
                control={control}
                id="estado_nasc_item"
                label="Estado de nascimento"
                name="estado_nasc_item"
                readOnly={!isEditable}
                required
                onClick={(e: any) =>
                  getItemTableStructure(
                    estados.cod_tabela,
                    e.target.value,
                    "cidades"
                  )
                }
              >
                <option> </option>
                {estados.itemsTable?.length &&
                  estados.itemsTable.map((estado) => {
                    if (estado.item_tabela === 0) return null;
                    return (
                      <option key={Math.random()} value={estado.item_tabela}>
                        {estado.descr_tabela}
                      </option>
                    );
                  })}
              </InputSelect>
            </Grid>
            <Grid item xs={12} md={3}>
              <InputSelect
                title="Cidade de nascimento"
                control={control}
                id="cidade_nasc_item"
                label="Cidade de nascimento"
                name="cidade_nasc_item"
                readOnly={!isEditable}
                required
              >
                <option> </option>
                {cidades.itemsTable?.length &&
                  cidades.itemsTable.map((cidade) => {
                    if (cidade.item_tabela === 0) return null;
                    return (
                      <option key={Math.random()} value={cidade.item_tabela}>
                        {cidade.descr_tabela}
                      </option>
                    );
                  })}
              </InputSelect>
            </Grid>
            <Grid item xs={12}>
              <T.H6>Vínculo de parentesco</T.H6>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputSelect
                title="Parentesco"
                control={control}
                id="tipo_dependente_item"
                label="Parentesco"
                name="tipo_dependente_item"
                required
                readOnly={!isEditable}
              >
                <option> </option>
                {typeDependents.itemsTable?.length &&
                  typeDependents.itemsTable.map((dependent) => {
                    if (dependent.item_tabela === 0) return null;
                    return (
                      <option key={Math.random()} value={dependent.item_tabela}>
                        {dependent.descr_tabela}
                      </option>
                    );
                  })}
              </InputSelect>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputBase
                control={control}
                id="nome_mae"
                label="Nome da mãe"
                name="nome_mae"
                readOnly={!isEditable}
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputBase
                control={control}
                id="nome_pai"
                label="Nome da pai"
                name="nome_pai"
                readOnly={!isEditable}
                required
              />
            </Grid>
            <Grid item xs={12}>
              <T.H6>Certidão de nascimento</T.H6>
            </Grid>
            <Grid item xs={12} md={3}>
              <InputBase
                control={control}
                id="nome_carto_registro"
                label="Nome do cartório de registro"
                name="nome_carto_registro"
                readOnly={!isEditable}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputBase
                control={control}
                id="num_carto_registro"
                label="Nº do registro do cartório"
                name="num_carto_registro"
                readOnly={!isEditable}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputBase
                control={control}
                id="num_folha_carto_registro"
                label="Nº da folha do registro do cartório"
                name="num_folha_carto_registro"
                readOnly={!isEditable}
                type="number"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputBase
                control={control}
                id="num_livro_carto_registro"
                label="Nº do livro do registro do cartório"
                name="num_livro_carto_registro"
                readOnly={!isEditable}
                type="number"
              />
            </Grid>
            <Grid item xs={12}>
              <T.H6>Outras informações</T.H6>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputRadios
                control={control}
                id="ind_invalidez"
                title="Dependente é inválido"
                name="ind_invalidez"
                row
                required
                readOnly={!isEditable}
              >
                <FormControlLabel
                  value="S"
                  control={<Radio size="small" />}
                  label="Sim"
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  label="Não"
                />
              </InputRadios>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputRadios
                control={control}
                id="ind_necess_espec"
                title="Dependente possui necessidades especiais"
                name="ind_necess_espec"
                row
                required
                readOnly={!isEditable}
              >
                <FormControlLabel
                  value="S"
                  control={<Radio size="small" />}
                  label="Sim"
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  label="Não"
                />
              </InputRadios>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputRadios
                control={control}
                id="ind_irrf"
                title="Dependente para fins de IRRF"
                name="ind_irrf"
                row
                required
                readOnly={!isEditable}
              >
                <FormControlLabel
                  value="S"
                  control={<Radio size="small" />}
                  label="Sim"
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  label="Não"
                />
              </InputRadios>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputRadios
                control={control}
                id="ind_salario_familia"
                title="Dependente para fins de Salário Família (até 14 anos)"
                name="ind_salario_familia"
                row
                required
                readOnly={!isEditable}
              >
                <FormControlLabel
                  value="S"
                  control={<Radio size="small" />}
                  label="Sim"
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  label="Não"
                />
              </InputRadios>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputRadios
                control={control}
                id="ind_estudante"
                title="Dependente é estudante"
                name="ind_estudante"
                row
                required
                readOnly={!isEditable}
              >
                <FormControlLabel
                  value="S"
                  control={<Radio size="small" />}
                  label="Sim"
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  label="Não"
                />
              </InputRadios>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputRadios
                control={control}
                id="ind_pensao_morte"
                title="Dependente para fins previdenciários (pensão por morte)"
                name="ind_pensao_morte"
                row
                required
                readOnly={!isEditable}
              >
                <FormControlLabel
                  value="S"
                  control={<Radio size="small" />}
                  label="Sim"
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  label="Não"
                />
              </InputRadios>
            </Grid>
            {/* <Grid item xs={12} md={4}>
              <InputRadios
                control={control}
                id="ind_fam"
                title="Dependente para fins de fundo de assistência medica (FAM)"
                name="ind_fam"
                row
                required
                readOnly={!isEditable}
              >
                <FormControlLabel
                  value="S"
                  control={<Radio size="small" />}
                  label="Sim"
                />
                <FormControlLabel
                  value="N"
                  control={<Radio size="small" />}
                  label="Não"
                />
              </InputRadios>
            </Grid> */}
          </Grid>
          {isEditable && (
            <Grid
              item
              xs={12}
              marginTop="1rem"
              display="flex"
              justifyContent="center"
            >
              <B.ButtonPrimary
                type="submit"
                style={{
                  width: "clamp(150px, 300px, 100%)",
                  marginBottom: "0.5rem",
                }}
              >
                {dependent?.id_sol_dados_depend ? "Salvar" : "Adicionar"}
              </B.ButtonPrimary>
            </Grid>
          )}
        </form>
      </S.ModalBody>
    </Modal>
  );
}
