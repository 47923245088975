import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { ButtonSecondary } from "../../components/base/Buttons";
import { CardPrimery } from "../../components/base/Cards";
import * as T from "../../components/base/Text";
import { BodyContainer } from "../../components/BodyContainer";
import { InputDate } from "../../components/Inputs/InputDate";
import { InputSelect } from "../../components/Inputs/InputSelect";
import { InputBase } from "../../components/Inputs/InputText";
import { JsonView } from "../../components/JsonView";
import { api } from "../../services/api";
import { setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { cpfMask } from "../../utils/mask";
import { convertData, convertHora, debounce } from "../../utils/utils";

type SearchTableProps = {
  operacao?: string;
  tabela?: string;
  numero_cpf?: string;
  dt_inicio?: string;
  dt_final?: string;

  // ordenação
  ordem?: "ASC" | "DESC";
  limit?: number;
  offset?: number;
};

type ILogs = {
  id_log: number;
  nome_usuario: string;
  cpf: string;
  tabela: string;
  operacao: string;
  dados_registro: object;
  dt_inclusao: string;
  hr_inclusao: string;
};

const LIMIT = 50;

export function LogsPage() {
  const [logs, setLogs] = useState<ILogs[]>([]);
  const [paramsSearch, setParamsSearch] = useState<SearchTableProps>(
    {} as SearchTableProps
  );
  const [expanded, setExpanded] = useState<number | false>(false);
  const refListaLogs = useRef<HTMLDivElement>(null);

  const { handleSubmit, control } = useForm<SearchTableProps>();

  const dispatch = useAppDispatch();
  const { clientToUpdate } = useAppSelector(({ Clients }) => Clients);

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  async function getLogs(newSerach: SearchTableProps) {
    try {
      setParamsSearch(newSerach);

      const urlSearchParams = new URLSearchParams();
      urlSearchParams.append("operacao", newSerach.operacao || "");
      urlSearchParams.append("tabela", newSerach.tabela || "");
      urlSearchParams.append("numero_cpf", newSerach.numero_cpf || "");
      urlSearchParams.append(
        "dt_inicio",
        convertData(newSerach.dt_inicio) || ""
      );
      urlSearchParams.append("dt_final", convertData(newSerach.dt_final) || "");
      urlSearchParams.append("limit", String(newSerach.limit || LIMIT));
      urlSearchParams.append("offset", String(newSerach.offset || 0));
      const url = `/system/logs?${urlSearchParams.toString()}`;

      const { data } = await api.get(url);

      setLogs((prevState) =>
        newSerach.offset === 0 ? data : [...prevState, ...data]
      );
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  }

  const onSubmit: SubmitHandler<SearchTableProps> = async (body) => {
    try {
      const newSerach = {
        ...paramsSearch,
        ...body,
        offset: 0,
        indSearch: true,
      };
      getLogs(newSerach);
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  };

  const scrollListaLogs = debounce(async () => {
    const scrollTotal = Number(refListaLogs.current?.scrollTop);
    const heightWin =
      Number(refListaLogs.current?.scrollHeight) -
      Number(refListaLogs.current?.clientHeight);
    const scroll = (scrollTotal / heightWin) * 100;
    if (scroll >= Number(100) - Number(5)) {
      await getLogs({ ...paramsSearch, offset: logs.length });
    }
  }, 120);

  useEffect(() => {
    getLogs({ limit: LIMIT, offset: 0 });
  }, [clientToUpdate.sigla]);

  return (
    <BodyContainer
      menu="logs"
      bodyStyle={{
        maxHeight: "calc(100vh - 70px)",
      }}
    >
      <Grid
        container
        spacing={1}
        style={{
          margin: "1rem 0 1.5rem 0",
          borderLeft: "4px solid #ff6e07",
          paddingLeft: "1rem",
        }}
      >
        <Grid item xs={12}>
          <T.H2>Logs do sistema</T.H2>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "0.6rem",
          }}
        >
          <T.Paragraph lineHeight="1.3rem">
            Veja os logs de operações efetuadas no sistema.
          </T.Paragraph>
        </Grid>
      </Grid>

      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        style={{ marginBottom: "1rem" }}
      >
        <Grid container spacing={1} margin="0">
          <Grid item xs={12} md={2}>
            <InputSelect
              control={control}
              title="Operaçao"
              label="Operaçao"
              id="operacao"
              name="operacao"
              isErrorMessage={false}
            >
              <option> </option>
              {["INSERT", "UPDATE", "DELETE"].map((op) => (
                <option key={op} value={op}>
                  {op}
                </option>
              ))}
            </InputSelect>
          </Grid>
          <Grid item xs={12} md={2}>
            <InputBase
              control={control}
              id="tabela"
              label="Tabela"
              name="tabela"
              isErrorMessage={false}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InputBase
              control={control}
              id="numero_cpf"
              label="Número do CPF"
              name="numero_cpf"
              type="cpf"
              isErrorMessage={false}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InputDate
              control={control}
              id="dt_inicio"
              name="dt_inicio"
              label="Data de início"
              defaultValue=""
              isErrorMessage={false}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <InputDate
              control={control}
              id="dt_final"
              name="dt_final"
              label="Data final"
              defaultValue=""
              isErrorMessage={false}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <ButtonSecondary
              variant="outlined"
              type="submit"
              style={{ width: "100%", gap: "0.5rem" }}
            >
              Busca
              <SearchIcon />
            </ButtonSecondary>
          </Grid>
        </Grid>
      </form>

      <Grid container spacing={1} padding="0 60px 0 16px" margin="0">
        <Grid item md={2}>
          <T.Paragraph>Operação</T.Paragraph>
        </Grid>
        <Grid item md={3}>
          <T.Paragraph>Tabela</T.Paragraph>
        </Grid>
        <Grid item md={2}>
          <T.Paragraph>Usuário</T.Paragraph>
        </Grid>
        <Grid item md={2}>
          <T.Paragraph>CPF</T.Paragraph>
        </Grid>
        <Grid item md={3}>
          <T.Paragraph>Data de operação</T.Paragraph>
        </Grid>
      </Grid>
      <CardPrimery
        ref={refListaLogs}
        style={{
          flex: 1,
          maxHeight: "inherit",
          overflowY: "auto",
          padding: "0",
        }}
        onScroll={scrollListaLogs}
      >
        {logs.map((log) => (
          <Accordion
            key={log.id_log}
            expanded={expanded === Number(log.id_log)}
            onChange={handleChange(log.id_log)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid container spacing={1}>
                <Grid item md={2}>
                  <T.H5>{log.operacao}</T.H5>
                </Grid>
                <Grid item md={3}>
                  <T.H5>{log.tabela}</T.H5>
                </Grid>
                <Grid item md={2}>
                  <T.Paragraph>{log.nome_usuario}</T.Paragraph>
                </Grid>
                <Grid item md={2}>
                  <T.Paragraph>{cpfMask(log.cpf)}</T.Paragraph>
                </Grid>
                <Grid item md={3}>
                  <T.Span>{convertData(log.dt_inclusao)}</T.Span> ás{" "}
                  <T.Span>{convertHora(log.hr_inclusao)}</T.Span>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <JsonView src={log.dados_registro} />
            </AccordionDetails>
          </Accordion>
        ))}
      </CardPrimery>
    </BodyContainer>
  );
}
