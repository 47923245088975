import CheckIcon from "@mui/icons-material/Check";
import WarningIcon from "@mui/icons-material/Warning";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";

import * as T from "../base/Text";

function Icon({ type }: { type: boolean }) {
  if (type) {
    return <CheckIcon htmlColor="#0f0" fontSize="small" />;
  }
  return <WarningIcon htmlColor="#f00" fontSize="small" />;
}

export function RulesPassword({
  password,
  setHasValidatePassword,
}: {
  password: string;
  setHasValidatePassword: (value: boolean) => void;
}) {
  const [hasNumber, setHasNumber] = useState(false);
  const [hasUpperCase, setHasUpperCase] = useState(false);
  const [hasLowerCase, setHasLowerCase] = useState(false);
  const [hasSpecialCharacter, setHasSpecialCharacter] = useState(false);
  const [hasMinLength, setHasMinLength] = useState(false);

  useEffect(() => {
    if (password) {
      // validar as regras da senha
      const hasNumberRule = /\d/.test(password);
      const hasSpecialCharRule = /[!@#$%^&*()_+\-=/?]/.test(password);
      const hasUpperCaseRule = /[A-Z]/.test(password);
      const hasLowerCaseRule = /[a-z]/.test(password);
      const hasMinLengthRule = password.length >= 8;

      setHasNumber(hasNumberRule);
      setHasSpecialCharacter(hasSpecialCharRule);
      setHasUpperCase(hasUpperCaseRule);
      setHasLowerCase(hasLowerCaseRule);
      setHasMinLength(hasMinLengthRule);

      if (
        hasNumberRule &&
        hasSpecialCharRule &&
        hasUpperCaseRule &&
        hasLowerCaseRule &&
        hasMinLengthRule
      ) {
        setHasValidatePassword(true);
      } else {
        setHasValidatePassword(false);
      }
    } else {
      setHasNumber(false);
      setHasSpecialCharacter(false);
      setHasUpperCase(false);
      setHasLowerCase(false);
      setHasMinLength(false);
      setHasValidatePassword(false);
    }
  }, [password]);

  return (
    <Grid container>
      <Grid item xs={12}>
        <T.Paragraph>Sua senha deve seguir as segras abaixo:</T.Paragraph>
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center" gap="0.5rem">
        <Icon type={hasUpperCase} />
        <T.Span color={hasUpperCase ? "#0f0" : "#f00"}>Letra Maiúscula</T.Span>
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center" gap="0.5rem">
        <Icon type={hasLowerCase} />
        <T.Span color={hasLowerCase ? "#0f0" : "#f00"}>Letra Minúscula</T.Span>
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center" gap="0.5rem">
        <Icon type={hasNumber} />
        <T.Span color={hasNumber ? "#0f0" : "#f00"}>Número</T.Span>
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center" gap="0.5rem">
        <Icon type={hasSpecialCharacter} />
        <T.Span color={hasSpecialCharacter ? "#0f0" : "#f00"}>
          Simbolos: !@#$%&*()_+
        </T.Span>
      </Grid>
      <Grid item xs={12} display="flex" alignItems="center" gap="0.5rem">
        <Icon type={hasMinLength} />
        <T.Span color={hasMinLength ? "#0f0" : "#f00"}>
          Min. 8 caracteres
        </T.Span>
      </Grid>
    </Grid>
  );
}
