/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import AddIcon from "@mui/icons-material/Add";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../../services/api";
import { setToastMessage } from "../../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../../store/hooks";
import { ButtonPrimary } from "../../../base/Buttons";
import { CardPrimery } from "../../../base/Cards";
import * as T from "../../../base/Text";
import { InputBase } from "../../../Inputs/InputText";
import { AddNewStructuredTable } from "../../../ModalsComponents/AddNewStructuredTable";

type ItensRowProps = {
  cod_tabela: number;
  item_tabela: number;
  descr_tabela: string;
  sigla_tabela: string;
  cod_tabela_sup: number;
  item_tabela_sup: number;
};

type SearchTableProps = {
  cod_tabela: number;
  item_tabela: number;
  descr_tabela: string;
};

export function StructuredTable() {
  const formRef = useRef<HTMLFormElement>(null);
  const { handleSubmit, control, getValues } = useForm<SearchTableProps>();

  const dispatch = useAppDispatch();

  const [itemAllTable, setItemAllTable] = useState<ItensRowProps[]>([]);

  const [isOpenMOdalNewTable, setIsOpenMOdalNewTable] = useState<
    boolean | ItensRowProps
  >(false);

  const onClseModalNewTable = () => setIsOpenMOdalNewTable(false);

  const onSubmit: SubmitHandler<SearchTableProps> = async (body) => {
    try {
      const { cod_tabela, item_tabela, descr_tabela } = body;
      const { data } = await api.get(
        `/system/structured-table?cod=${cod_tabela || ""}&item=${
          item_tabela || ""
        }&descricao=${descr_tabela || ""}`
      );
      setItemAllTable(data);
    } catch (err: any) {
      setItemAllTable([]);
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };
  const getUpdateTables = () => onSubmit(getValues());

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        ref={formRef}
        style={{ flex: "1" }}
      >
        <CardPrimery container spacing={1} style={{ height: "100%" }}>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <T.H6>Busca</T.H6>
            <div
              onClick={() => setIsOpenMOdalNewTable(true)}
              style={{ padding: "0 1rem", cursor: "pointer" }}
            >
              <AddIcon />
            </div>
          </Grid>
          <Grid item xs={6} md={2}>
            <InputBase
              control={control}
              id="cod_tabela"
              label="Codigo"
              name="cod_tabela"
              type="number"
            />
          </Grid>
          <Grid item xs={6} md={2}>
            <InputBase
              control={control}
              id="item_tabela"
              label="Codigo Item"
              name="item_tabela"
              type="number"
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <InputBase
              control={control}
              id="descr_tabela"
              label="Descrição"
              name="descr_tabela"
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <ButtonPrimary
              variant="outlined"
              type="submit"
              style={{ width: "100%" }}
            >
              Busca
            </ButtonPrimary>
          </Grid>
          <Grid item xs={12}>
            <T.H6>Tabela</T.H6>
          </Grid>
          <TableContainer sx={{ maxHeight: "55vh", minHeight: "55vh" }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: "8rem", width: "8rem" }}>
                    CODIGO
                  </TableCell>
                  <TableCell style={{ minWidth: "8rem", width: "8rem" }}>
                    ITEM
                  </TableCell>
                  <TableCell style={{ width: "100%" }}>DESCRIÇÃO</TableCell>
                  <TableCell style={{ minWidth: "8rem" }}>SIGLA</TableCell>
                  <TableCell style={{ minWidth: "8rem" }}>TABELA SUP</TableCell>
                  <TableCell style={{ minWidth: "8rem" }}>ITEM SUP</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemAllTable.map((item, index) => (
                  <TableRow
                    hover
                    style={{ cursor: "pointer" }}
                    role="checkbox"
                    key={index}
                    onClick={() => setIsOpenMOdalNewTable(item)}
                  >
                    <TableCell>{item.cod_tabela}</TableCell>
                    <TableCell>{item.item_tabela}</TableCell>
                    <TableCell>{item.descr_tabela}</TableCell>
                    <TableCell>{item.sigla_tabela}</TableCell>
                    <TableCell>{item.cod_tabela_sup}</TableCell>
                    <TableCell>{item.item_tabela_sup}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardPrimery>
      </form>
      <AddNewStructuredTable
        isOpen={!!isOpenMOdalNewTable}
        onClose={onClseModalNewTable}
        item={isOpenMOdalNewTable}
        getUpdateTables={getUpdateTables}
      />
    </>
  );
}
