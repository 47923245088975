import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { isAuthenticated } from "../services/auth";
import { getClientUrl } from "../utils/utils";

export const useLoginRedirect = () => {
  const navegate = useNavigate();
  const isLogged = useCallback(() => {
    if (isAuthenticated()) {
      navegate(`/${getClientUrl()}/home`);
    }
  }, []);
  return isLogged;
};
