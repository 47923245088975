/* eslint-disable @typescript-eslint/no-explicit-any */
import ArticleIcon from "@mui/icons-material/Article";
import { Divider } from "@mui/material";
import { Controller, UseFormGetValues, UseFormSetValue } from "react-hook-form";

import { CreateSolicitationProps } from "../../../pages/CreateSolicitation";
import { ItensVaueTypes } from "../../../store/ducks/TableStructure/types";
import {
  INDOBRIGATORIODOCUMENT,
  TIPODOCUMENTOITEM,
} from "../../../utils/utils";
import { CardPrimery } from "../../base/Cards";
import { SwitchStyle } from "../../base/stylesGeneric";
import * as T from "../../base/Text";
import * as S from "../styles";

type SolicitDocumentsProps = {
  control: any;
  documentsSolicitantion: ItensVaueTypes;
  setValue: UseFormSetValue<CreateSolicitationProps>;
  getValues: UseFormGetValues<CreateSolicitationProps>;
};

export function SolicitDocuments({
  control,
  setValue,
  getValues,
  documentsSolicitantion,
}: SolicitDocumentsProps) {
  return (
    <CardPrimery
      container
      style={{
        padding: "1.5rem 2rem",
      }}
    >
      <S.BoxGrid>
        {documentsSolicitantion.itemsTable?.length &&
          documentsSolicitantion.itemsTable.map((document) => {
            if (document.item_tabela !== 0) {
              return (
                <Controller
                  key={document.item_tabela}
                  name={`${TIPODOCUMENTOITEM}${document.item_tabela}`}
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <S.BoxDocumentsAnexos checked={value}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "flex-start",
                            gap: "0.5rem",
                          }}
                        >
                          <ArticleIcon />
                          <T.H5>{document.descr_tabela}</T.H5>
                        </div>
                        <S.BoxSwitchs>
                          <Divider />
                          <S.BoxLabelSwitch>
                            <T.Paragraph>Solicitar Documento</T.Paragraph>
                            <SwitchStyle checked={value} />
                            <div style={{ display: "none" }}>
                              <input
                                type="checkbox"
                                checked={value || false}
                                onChange={(e) => {
                                  onChange(e);
                                  const val = e.target.checked;
                                  const indObrigatorioProperty =
                                    `${INDOBRIGATORIODOCUMENT}${document.item_tabela}` as any;

                                  if (!val && getValues(indObrigatorioProperty))
                                    setValue(indObrigatorioProperty, false);
                                }}
                              />
                            </div>
                          </S.BoxLabelSwitch>
                          <Divider />
                          <Controller
                            name={`${INDOBRIGATORIODOCUMENT}${document.item_tabela}`}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <S.BoxLabelSwitch>
                                  <T.Paragraph>Obrigatorio</T.Paragraph>
                                  <SwitchStyle checked={value} />
                                  <div style={{ display: "none" }}>
                                    <input
                                      type="checkbox"
                                      checked={value || false}
                                      onChange={(e) => {
                                        onChange(e);
                                        const val = e.target.checked;
                                        const tipoDocumentProperty =
                                          `${TIPODOCUMENTOITEM}${document.item_tabela}` as any;

                                        if (
                                          val &&
                                          !getValues(tipoDocumentProperty)
                                        )
                                          setValue(tipoDocumentProperty, true);
                                        else if (
                                          !val &&
                                          getValues(tipoDocumentProperty)
                                        )
                                          setValue(tipoDocumentProperty, false);
                                      }}
                                    />
                                  </div>
                                </S.BoxLabelSwitch>
                              );
                            }}
                          />
                          <Divider />
                        </S.BoxSwitchs>
                      </S.BoxDocumentsAnexos>
                    );
                  }}
                />
              );
            }
            return null;
          })}
      </S.BoxGrid>
    </CardPrimery>
  );
}
