import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import * as T from "../base/Text";

type DialogProps = {
  isOpen: boolean;
  onClose: () => void;
  title?: string;
  content?: string | React.ReactNode;
  footer?: React.ReactNode;
};

export default function DialogComponent({
  isOpen,
  onClose,
  title,
  content,
  footer,
}: DialogProps) {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {title && (
        <DialogTitle id="alert-dialog-title">
          <T.H5>{title}</T.H5>
        </DialogTitle>
      )}

      <DialogContent>{content}</DialogContent>
      {footer && <DialogActions>{footer}</DialogActions>}
    </Dialog>
  );
}
