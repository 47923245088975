import { Card } from "@mui/material";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const HeaderContent = styled.div`
  width: 100%;
  height: 430px;
  display: flex;
  justify-content: space-between;
  gap: 1.5rem;

  @media (max-width: 768px) {
    height: auto;
  }
`;

export const CardElevation = styled(Card)`
  ${({ theme }) => css`
    border-radius: 0.5rem !important;
    margin-left: 0 !important;
    border: 1px solid ${theme.border.light};
  `}
`;

export const BoxGraphics = styled(CardElevation)`
  flex: 3;
  height: 100%;
  max-width: 100%;
  padding: 1rem;
  display: flex;
  justify-content: space-between;

  @media (max-width: 768px) {
    display: none;
  }
`;

export const BoxListSolicitations = styled.ul`
  ${({ theme }) => css`
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    overflow-y: auto;

    scrollbar-width: 5px; /* for Firefox */
    scrollbar-color: transparent ${theme.border.light}; /* for Firefox */
    ::-webkit-scrollbar {
      width: 5px;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: ${theme.border.light};
      border-radius: 5px;
    }
  `}
`;

export const ListSolicitationsItem = styled.li<{ active: boolean }>`
  ${({ theme, active }) => css`
    width: 100%;
    height: max-content;

    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    cursor: pointer;

    border-radius: 0.25rem;
    border: 1px solid ${theme.border.light};
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

    :hover {
      background-color: ${theme.background.blueLight};
    }

    ${active &&
    css`
      background-color: ${theme.background.blueLight};
      pointer-events: none;
    `}
  `}
`;

export const BoxInteractions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 1rem 0;
`;

export const BoxInteractionsHeader = styled.div`
  width: 100%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const BoxInteractionsBody = styled(BoxInteractions)`
  margin-top: 0.775rem;
  gap: 1rem;

  @media (max-width: 768px) {
    img {
      max-width: 150px;
    }
  }
`;

export const BoxInteractionsItem = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 5rem;

    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    padding: 1rem;
    cursor: pointer;

    border-radius: 0.25rem;
    border: 1px solid ${theme.border.light};
    box-shadow: 0px 2px 1px -1px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 1px 3px 0px rgb(0 0 0 / 12%);

    :hover {
      background-color: ${theme.background.blueLight};
    }

    @media (max-width: 768px) {
      height: min-content;

      & > div:nth-child(2) {
        flex-direction: column;
      }
    }
  `}
`;
