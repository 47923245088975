/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReportIcon from "@mui/icons-material/Report";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Alert, Checkbox, Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IBeneficio } from "../../../types/FormUserSolicitacao/Beneficios";
import { cnpjMask } from "../../../utils/mask";
import {
  convertData,
  DEPENDENTES,
  getIdURLUserSol,
} from "../../../utils/utils";
import * as B from "../../base/Buttons";
import * as TB from "../../base/Table";
import * as T from "../../base/Text";
import { BeneficioSolicitation } from "../../ModalsComponents/BeneficioSolicitation";

type BeneficiosProps = {
  isEditable: boolean;
  getUserSolicitation(): Promise<void>;
  isCompleted: boolean;
  isOpenCorrecion: boolean;
  isOnChangeSetListCorrecion: (etapa: string, campo: string) => boolean;
  onChangeSetListCorrecion: (
    etapa: string,
    campo: string,
    label: string
  ) => void;
  correctionValuesJsonData: any;
  statusCorrecion: number;
};

export function Beneficios({
  isEditable,
  getUserSolicitation,
  isCompleted,
  isOpenCorrecion,
  isOnChangeSetListCorrecion,
  onChangeSetListCorrecion,
  correctionValuesJsonData,
  statusCorrecion,
}: BeneficiosProps) {
  const [formBeneficios, setFormBeneficios] = useState<IBeneficio[]>([]);

  const [openModalFormBeneficos, setOpenModalFormBeneficios] = useState<{
    open: boolean;
    beneficio?: IBeneficio;
  }>({ open: false });

  const dispatch = useAppDispatch();

  const {
    TableStructure: { listaBeneficios },
    Utils: { device },
  } = useAppSelector((state) => state);

  const getBeneficios = async () => {
    const idSolUser = getIdURLUserSol();
    const { data } = await api.get(`/solicitation/beneficios/${idSolUser}`);

    setFormBeneficios(data);
  };

  const deleteBeneficios = async (idBenefic: number) => {
    try {
      const { data } = await api.delete(
        `/solicitation/beneficios/${getIdURLUserSol()}/${idBenefic}`
      );

      dispatch(setToastMessage({ type: "success", message: data.message }));
      getBeneficios();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const nextEtapa = async () => {
    try {
      await api.post(`/solicitation/beneficios/commited/${getIdURLUserSol()}`);
      getUserSolicitation();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  useEffect(() => {
    if (formBeneficios.length === 0) {
      getBeneficios();
    }
  }, []);

  return (
    <Grid container spacing={1} padding="1rem">
      {isEditable && (
        <Alert severity="info" style={{ width: "100%" }}>
          Incluir todos os benefícios previdenciários que voce esta recebendo
          atualmente, como aposentadoria, pensão, auxílio-doença, entre outros.
          <br />
          Caso não possua benefícios, clique em &quot;Não possuo
          benefícios&quot;.
        </Alert>
      )}
      {formBeneficios.length ? (
        <TB.TableContainer sx={{ margin: "1rem" }}>
          <TB.Table stickyHeader aria-label="sticky table" size="small">
            <TB.TableHead>
              <TB.TableRow>
                <TB.TableCell
                  align="center"
                  style={
                    device === "desktop"
                      ? {
                          minWidth: "20%",
                          width: "20%",
                        }
                      : {
                          minWidth: "30%",
                          width: "30%",
                        }
                  }
                >
                  TIPO DE BENEFICO
                </TB.TableCell>

                {device === "desktop" && (
                  <TB.TableCell align="center">CONCESSÃO</TB.TableCell>
                )}

                {device === "desktop" && (
                  <TB.TableCell align="center" minWidth="11rem">
                    Nº CONCESSÃO
                  </TB.TableCell>
                )}

                <TB.TableCell minWidth="30%" width="30%" maxWidth="50%">
                  RAZÃO SOCIAL
                </TB.TableCell>
                {device === "desktop" && (
                  <TB.TableCell width="11rem" minWidth="11rem">
                    CNPJ
                  </TB.TableCell>
                )}

                <TB.TableCell align="center" minWidth="20%" width="20%">
                  AÇÕES
                </TB.TableCell>
                {isOpenCorrecion && (
                  <TB.TableCell
                    align="center"
                    style={{ minWidth: "2rem", width: "2rem" }}
                  >
                    CORREÇÃO
                  </TB.TableCell>
                )}
                {[2, 3].includes(statusCorrecion) && (
                  <TB.TableCell
                    align="center"
                    style={{ minWidth: "2rem", width: "2rem" }}
                  />
                )}
              </TB.TableRow>
            </TB.TableHead>
            <TB.TableBody>
              {formBeneficios.map((beneficio) => (
                <TB.TableRow
                  hover
                  id={`id-${beneficio.id_sol_dados_beneficio}`}
                  style={{ cursor: "pointer" }}
                  key={beneficio.id_sol_dados_beneficio}
                  onClick={() => {
                    if (!isEditable && !isOpenCorrecion) {
                      setOpenModalFormBeneficios({
                        open: true,
                        beneficio,
                      });
                    }
                  }}
                >
                  <TB.TableCell
                    align="center"
                    style={
                      device === "desktop"
                        ? {
                            minWidth: "20%",
                            width: "20%",
                          }
                        : {
                            minWidth: "30%",
                            width: "30%",
                          }
                    }
                  >
                    {listaBeneficios.itemsTable.length &&
                      listaBeneficios.itemsTable.find(
                        (type) =>
                          type.item_tabela === beneficio.tipo_beneficio_item
                      )?.descr_tabela}
                  </TB.TableCell>

                  {device === "desktop" && (
                    <TB.TableCell align="center">
                      {convertData(beneficio.data_concessao)}
                    </TB.TableCell>
                  )}

                  <TB.TableCell align="center">
                    {beneficio.num_concessao}
                  </TB.TableCell>

                  <TB.TableCell minWidth="30%" width="30%" maxWidth="10rem">
                    {beneficio.razao_social}
                  </TB.TableCell>
                  {device === "desktop" && (
                    <TB.TableCell width="11rem" minWidth="11rem">
                      {cnpjMask(beneficio.cnpj)}
                    </TB.TableCell>
                  )}

                  <TB.TableCell align="center">
                    {isEditable ? (
                      <>
                        <IconButton
                          onClick={() =>
                            setOpenModalFormBeneficios({
                              open: true,
                              beneficio,
                            })
                          }
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          id="button-delete-dependent"
                          onClick={() =>
                            deleteBeneficios(
                              Number(beneficio.id_sol_dados_beneficio)
                            )
                          }
                        >
                          <DeleteIcon id="icon-delete-dependent" />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton
                        onClick={() => {
                          setOpenModalFormBeneficios({
                            open: true,
                            beneficio,
                          });
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    )}
                  </TB.TableCell>
                  {isOpenCorrecion && (
                    <TB.TableCell align="center">
                      <Checkbox
                        color="warning"
                        checked={isOnChangeSetListCorrecion(
                          DEPENDENTES,
                          String(beneficio.id_sol_dados_beneficio)
                        )}
                        onChange={() =>
                          onChangeSetListCorrecion(
                            DEPENDENTES,
                            String(beneficio.id_sol_dados_beneficio),
                            beneficio.razao_social
                          )
                        }
                      />
                    </TB.TableCell>
                  )}
                  {correctionValuesJsonData[
                    `${DEPENDENTES}-${String(beneficio.id_sol_dados_beneficio)}`
                  ] && !isOpenCorrecion ? (
                    <TB.TableCell align="center">
                      {correctionValuesJsonData[
                        `${DEPENDENTES}-${String(
                          beneficio.id_sol_dados_beneficio
                        )}`
                      ]?.message ? (
                        <Tooltip
                          title={
                            correctionValuesJsonData[
                              `${DEPENDENTES}-${String(
                                beneficio.id_sol_dados_beneficio
                              )}`
                            ]?.message
                          }
                          style={{ cursor: "help" }}
                        >
                          <ReportIcon color="warning" />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={false}
                          disableFocusListener
                          disableInteractive
                          disableHoverListener
                          style={{ cursor: "help" }}
                        >
                          <ReportIcon color="warning" />
                        </Tooltip>
                      )}
                    </TB.TableCell>
                  ) : (
                    <TB.TableCell />
                  )}
                </TB.TableRow>
              ))}
            </TB.TableBody>
          </TB.Table>
        </TB.TableContainer>
      ) : (
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          flexDirection="column"
          padding="1rem 0"
        >
          <AttachMoneyIcon fontSize="large" color="action" />
          <T.H6 textAlign="center">Nenhum benefício cadastrado</T.H6>
        </Grid>
      )}

      <Grid item xs={12} display="flex" justifyContent="flex-end" gap={1}>
        {isEditable && (
          <B.ButtonPrimary
            variant="outlined"
            onClick={() => setOpenModalFormBeneficios({ open: true })}
          >
            Adiciona benefício
            <AttachMoneyIcon
              fontSize="small"
              style={{ marginLeft: "0.5rem" }}
            />
          </B.ButtonPrimary>
        )}
        {isEditable && !isCompleted && formBeneficios.length ? (
          <B.ButtonPrimary onClick={nextEtapa}>CONCLUIR ETAPA</B.ButtonPrimary>
        ) : (
          ""
        )}
        {!formBeneficios.length && !isCompleted && isEditable && (
          <B.ButtonPrimary onClick={nextEtapa}>
            Não possuo benefícios
          </B.ButtonPrimary>
        )}
      </Grid>
      <BeneficioSolicitation
        isOpen={openModalFormBeneficos.open}
        onClose={() => setOpenModalFormBeneficios({ open: false })}
        isEditable={isEditable}
        updateListBeneficos={getBeneficios}
        beneficio={openModalFormBeneficos.beneficio}
      />
    </Grid>
  );
}
