import styled, { css } from "styled-components";

import { Span } from "../../components/base/Text";

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    min-height: 100vh;
    background-color: ${theme.background.default};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background-color: ${theme.background.default};
  `}
`;

export const LinkForgotPassword = styled(Span)`
  ${({ theme }) => css`
    width: 100%;
    text-align: right;
    margin-top: -1rem;
    color: ${theme.palette.primary.main};
    cursor: pointer;
    z-index: 999999999999;
  `}
`;
