import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { api } from "../../../services/api";
import { getGraphicPizzaAction } from "../../../store/ducks/Graphics/actions";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import { ISolicitationProps } from "../../../types/Solicitation";
import { convertData, getClientUrl } from "../../../utils/utils";
import { ButtonPrimary, ButtonSecondary } from "../../base/Buttons";
import * as T from "../../base/Text";
import { GraphicPizza } from "../../GraphicPizza";
import * as S from "./styles";

type IUserAnlystProps = {
  id_sol_usuario: number;
  numero_cpf: string;
  status: string;
  descricao: number;
  nome: string;
  dt_atualizacao: string;
};

export function HomeAnalyst() {
  const [idItemGraphic, setIdItemGraphic] = useState(0);
  const [solicitations, setSolicitations] = useState<ISolicitationProps[]>([]);
  const [usersAnalyst, setUsersAnalyst] = useState<IUserAnlystProps[]>([]);
  const navegate = useNavigate();
  const dispatch = useAppDispatch();

  async function getSolicitations() {
    try {
      const { data } = await api.get("/solicitation/list");
      setSolicitations(data);
      setIdItemGraphic(data[0].id_solicitacao);
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  }

  async function getUserAnalyst() {
    try {
      const { data } = await api.get("/solicitation/users/analyst");
      setUsersAnalyst(data);
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  }

  useEffect(() => {
    if (!solicitations.length) {
      getSolicitations();
      getUserAnalyst();
    }
  }, []);

  useEffect(() => {
    if (idItemGraphic) dispatch(getGraphicPizzaAction(idItemGraphic));
  }, [idItemGraphic]);

  return (
    <S.Container>
      <S.HeaderContent>
        <S.BoxGraphics>
          {solicitations.length ? (
            <>
              <GraphicPizza />
              <S.BoxListSolicitations>
                {solicitations.map((solicitation) => (
                  <S.ListSolicitationsItem
                    key={solicitation.id_solicitacao}
                    active={idItemGraphic === solicitation.id_solicitacao}
                    onClick={() =>
                      setIdItemGraphic(Number(solicitation.id_solicitacao))
                    }
                  >
                    <T.Paragraph>{solicitation.descr_solicitacao}</T.Paragraph>
                  </S.ListSolicitationsItem>
                ))}
              </S.BoxListSolicitations>
            </>
          ) : (
            <img
              src="/images/graphics.svg"
              alt="graphics"
              style={{
                width: "100%",
                maxHeight: "400px",
              }}
            />
          )}
        </S.BoxGraphics>
        <S.ContentRight>
          <S.BoxHeaderRigth>
            <img src="/images/homeImage3.svg" alt="Icon" />
            <T.H6 textAlign="center">
              Crie uma nova solicitação para o seus usuarios
            </T.H6>
            <ButtonPrimary
              onClick={() => {
                navegate(`../${getClientUrl()}/admin/cria-solicitacao`);
              }}
              style={{
                width: "100%",
              }}
            >
              Criar solicitações
              <ArrowRightAltIcon
                style={{
                  marginLeft: "10px",
                }}
              />
            </ButtonPrimary>
          </S.BoxHeaderRigth>
        </S.ContentRight>
      </S.HeaderContent>
      <S.BoxInteractions>
        <S.BoxInteractionsHeader>
          <T.H1 size="1.5rem">Em Analise</T.H1>
          <ButtonSecondary
            onClick={() => {
              navegate(`../${getClientUrl()}/em-andamento`);
            }}
          >
            Ver Tudo
          </ButtonSecondary>
        </S.BoxInteractionsHeader>
        <S.BoxInteractionsBody>
          {usersAnalyst.length ? (
            usersAnalyst.map((userSol) => (
              <S.BoxInteractionsItem
                key={userSol.id_sol_usuario}
                onClick={() => {
                  navegate(
                    `../${getClientUrl()}/solicitacao/${userSol.id_sol_usuario}`
                  );
                }}
              >
                <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                  <T.H6 width="min-content">Solicitação: </T.H6>
                  <T.Paragraph>{userSol.descricao}</T.Paragraph>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                    <T.H6 width="min-content">Nome: </T.H6>
                    <T.Paragraph>{userSol.nome}</T.Paragraph>
                  </div>
                  <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                    <T.H6 width="min-content">CPF: </T.H6>
                    <T.Paragraph>{userSol.numero_cpf}</T.Paragraph>
                  </div>
                  <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                    <T.H6 width="min-content">Status: </T.H6>
                    <T.Paragraph>{userSol.status}</T.Paragraph>
                  </div>
                  <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                    <T.H6 width="min-content" style={{ whiteSpace: "nowrap" }}>
                      Data Alteração:{" "}
                    </T.H6>
                    <T.Paragraph>
                      {convertData(userSol.dt_atualizacao)}
                    </T.Paragraph>
                  </div>
                </div>
              </S.BoxInteractionsItem>
            ))
          ) : (
            <div
              style={{
                margin: "auto",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <T.H6 width="min-content" style={{ whiteSpace: "nowrap" }}>
                Não há solicitaões em analise
              </T.H6>
              <img
                src="/images/homeImage1.svg"
                width={250}
                alt="Analise de documentos"
              />
            </div>
          )}
        </S.BoxInteractionsBody>
      </S.BoxInteractions>
    </S.Container>
  );
}
