/* eslint-disable no-param-reassign */
import axios from "axios";
import { toast } from "react-toastify";

import { getClientUrl } from "../utils/utils";
import { logout, TOKEN_KEY } from "./auth";

export const apiUrlBase = process.env.REACT_APP_BASE_URL_API;
export const apiUrlBaseAndSigla = `${apiUrlBase}${getClientUrl()}/`; // SSL

const api = axios.create({
  baseURL: apiUrlBaseAndSigla,
});

// Adiciona o token no header de cada requisição quando ouver um token
api.interceptors.request.use(
  (config) => {
    const token = sessionStorage.getItem(TOKEN_KEY);
    const siglaClient = window.location.pathname.split("/")[1];

    if (siglaClient) {
      config.url = `${apiUrlBaseAndSigla.replace(
        `${getClientUrl()}/`,
        siglaClient
      )}${config.url}`;
    }

    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: token,
      };
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Intercepta todas as respostas da API e verifica se o status é 401
api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      toast(
        error.response.data?.message ||
          "Acesso expirado, faça login novamente.",
        {
          type: "error",
        }
      );
      logout(100);
    }
    return Promise.reject(error);
  }
);

export { api };
