/* eslint-disable quote-props */
/* eslint-disable react/jsx-props-no-spreading */
import {
  Table as MuiTable,
  TableBody as MuiTableBody,
  TableCell as MuiTableCell,
  TableContainer as MuiTableContainer,
  TableHead as MuiTableHead,
  TableRow as MuiTableRow,
} from "@mui/material";
import { CSSProperties } from "react";
import styled from "styled-components";

type TableProps = {
  align?: "inherit" | "left" | "center" | "right" | "justify";
  textOverflow?: "clip" | "ellipsis";
  overflow?: "visible" | "hidden" | "clip";
  whiteSpace?: "normal" | "nowrap" | "pre" | "pre-line" | "pre-wrap";
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  isOverflow?: boolean;
};

export const TableContainer = styled(MuiTableContainer)``;

export const TableBody = styled(MuiTableBody)``;

export const Table = styled(MuiTable)``;

export const TableCell = styled(
  ({
    align,
    textOverflow,
    overflow,
    whiteSpace,
    width,
    minWidth,
    maxWidth,
    isOverflow,
    children,
    ...props
  }) => {
    const style = {
      width: width || "min-content",
      minWidth: minWidth || "initial",
      maxWidth: maxWidth || "initial",
      textAlign: align || "inherit",
      textOverflow: textOverflow || "clip",
      overflow: overflow || "visible",
      whiteSpace: whiteSpace || "normal",
      ...(isOverflow && {
        display: "inline-block",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
      }),
    } as CSSProperties;

    return (
      <MuiTableCell style={style} {...props}>
        {children}
      </MuiTableCell>
    );
  }
)<TableProps>`
  @media (max-width: 768px) {
    padding: 6px !important;
  }
`;

export const TableHead = styled(MuiTableHead)``;

export const TableRow = styled(MuiTableRow)``;
