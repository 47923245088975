/* eslint-disable @typescript-eslint/no-explicit-any */
import CloseIcon from "@mui/icons-material/Close";
import ReportIcon from "@mui/icons-material/Report";
import SendIcon from "@mui/icons-material/Send";
import { Grid, TextField } from "@mui/material";
import { useForm, Controller } from "react-hook-form";

import { ListCorrecionProps } from "../../pages/MySolicitation";
import { api } from "../../services/api";
import { setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../store/hooks";
import { convertSOrN, getIdURLUserSol } from "../../utils/utils";
import { ButtonPrimary, ButtonSecondary } from "../base/Buttons";
import { CardPrimery } from "../base/Cards";
import * as T from "../base/Text";
import { InputCheckBox } from "../Inputs/inputCheckBox";
import * as S from "./styles";

type DrawerCorrecionSolicitationProps = {
  openDrawer: boolean;
  onCloseDrawer: () => void;
  listCorrecion: ListCorrecionProps[];
  getUserSolicitation: () => Promise<void>;
};

export function DrawerCorrecionSolicitation({
  openDrawer,
  onCloseDrawer,
  listCorrecion,
  getUserSolicitation,
}: DrawerCorrecionSolicitationProps) {
  const { handleSubmit, control } = useForm<any>();

  const dispatch = useAppDispatch();

  const trataListCorrecion = (listCorrecion: ListCorrecionProps[]) => {
    const obj: any = {};
    listCorrecion.forEach((item) => {
      if (Array.isArray(obj[item.etapa])) {
        obj[item.etapa].push(item);
      } else {
        obj[item.etapa] = [item];
      }
    });
    const objKeys = Object.keys(obj);
    const newArray = objKeys.map((key) => {
      return {
        etapa: key,
        list: obj[key] as ListCorrecionProps[],
      };
    });
    return newArray;
  };
  const onSubmit = async (body: any) => {
    try {
      const obj: any = {};
      Object.keys(body).forEach((key) => {
        const value = listCorrecion.find((item) => item.id === key);
        if (value) {
          obj[key] = {
            ...value,
            message: body[key],
          };
        }
      });
      const etapasSolicitadas = trataListCorrecion(listCorrecion).map(
        (etapa) => etapa.etapa
      );

      const { data: response } = await api.post(
        `/solicitation/correcion/${getIdURLUserSol()}`,
        {
          status_registrado: 2,
          msg_despacho: body.msg_despacho
            ? String(body.msg_despacho).trim()
            : "",
          json_data: obj,
          etapasSolicitadas,
          ind_enviar_email_correc: convertSOrN(body.ind_enviar_email_correc),
        }
      );
      dispatch(setToastMessage({ type: "success", message: response.message }));
      await getUserSolicitation();
      onCloseDrawer();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  return (
    <S.DrawerBottom
      anchor="bottom"
      open={openDrawer}
      onClose={() => onCloseDrawer()}
    >
      <S.DrawerContainer onSubmit={handleSubmit(onSubmit)} noValidate>
        <S.DrawerHeader>
          <ReportIcon fontSize="large" color="warning" />
          <div>
            <T.H2 size="1.375rem" color="rgb(255, 139, 56)">
              Campos selecionados para correção
            </T.H2>
            <T.Paragraph color="#8c8c8c">
              Voce pode escrever uma observação especifica para cada campo
              selecionado
            </T.Paragraph>
          </div>
        </S.DrawerHeader>
        <S.DrawerContent>
          <CardPrimery container spacing={1}>
            <Grid item xs={12}>
              <InputCheckBox
                control={control}
                id="ind_enviar_email_correc"
                label="Enviar email para o usuário informando correção"
                name="ind_enviar_email_correc"
                defaultValue="S"
                isErrorMessage={false}
              />
            </Grid>
            <Grid item xs={12}>
              <T.Paragraph color="#8c8c8c">
                Envie uma mensagem como observação geral para o usuario corrigir
              </T.Paragraph>
              <Controller
                name="msg_despacho"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      id="msg_despacho"
                      label="Observação"
                      size="small"
                      multiline
                      rows={2}
                      variant="outlined"
                      value={value}
                      onChange={onChange}
                      style={{ width: "100%" }}
                    />
                  );
                }}
              />
            </Grid>
          </CardPrimery>
          {!!listCorrecion.length &&
            trataListCorrecion(listCorrecion).map((etapa) => (
              <CardPrimery key={etapa.etapa}>
                <T.H6>{etapa.etapa}</T.H6>
                <Grid container spacing={2}>
                  {etapa.list.length &&
                    etapa.list.map((item, index) => {
                      const { id, label, message } = item;
                      const listLength = etapa.list.length;
                      const isImpar =
                        listLength % 2 !== 0 &&
                        listLength === index + 1 &&
                        listLength !== 1;

                      return (
                        <Grid
                          item
                          xs={listLength === 1 || isImpar ? 12 : 6}
                          key={id}
                        >
                          <T.Paragraph color="#8c8c8c">{label}</T.Paragraph>
                          <Controller
                            name={id}
                            control={control}
                            render={({ field: { onChange, value } }) => {
                              return (
                                <TextField
                                  id={id}
                                  label="Oque deve ser corrigido?"
                                  size="small"
                                  multiline
                                  rows={2}
                                  variant="outlined"
                                  defaultValue={message}
                                  value={value}
                                  onChange={onChange}
                                  style={{ width: "100%" }}
                                />
                              );
                            }}
                          />
                        </Grid>
                      );
                    })}
                </Grid>
              </CardPrimery>
            ))}
        </S.DrawerContent>
        <S.DrawerFooter>
          <div>
            <ButtonSecondary onClick={() => onCloseDrawer()}>
              Cancelar
              <CloseIcon fontSize="small" style={{ marginLeft: "0.5rem" }} />
            </ButtonSecondary>
            <ButtonPrimary type="submit">
              Solicita correção
              <SendIcon fontSize="small" style={{ marginLeft: "0.5rem" }} />
            </ButtonPrimary>
          </div>
        </S.DrawerFooter>
      </S.DrawerContainer>
    </S.DrawerBottom>
  );
}
