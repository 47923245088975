/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable array-callback-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-unsafe-optional-chaining */
/* eslint-disable func-names */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable @typescript-eslint/no-explicit-any */
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import {
  Checkbox,
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import { ISolicitationProps } from "../../../types/Solicitation";
import { cpfMask } from "../../../utils/mask";
import { debounce, getClientUrl, removeMask } from "../../../utils/utils";
import { ButtonPrimary, ButtonSecondary } from "../../base/Buttons";
import { CardPrimery } from "../../base/Cards";
import * as T from "../../base/Text";
import { InputSelect } from "../../Inputs/InputSelect";
import { InputBase } from "../../Inputs/InputText";
import { ImportUserSolicitation } from "../../ModalsComponents/ImportUserSolicitation";

type ListUsuariosProps = {
  id_sol_usuario: number;
  email: string;
  numero_cpf: string;
  prova_de_vida: string;
  status: string;
  nome?: string;
  secretaria?: string;
  setor?: string;
};

type SearchTableProps = {
  cpf: string;
  email: string;
  nome: string;
  statusPreenchimento: string;
};

export function UsersSolicitation({
  solicitation,
  isEdit,
}: {
  solicitation: ISolicitationProps;
  isEdit: boolean;
}) {
  const TableRef = useRef<HTMLDivElement | null>(null);
  const { handleSubmit, control, getValues } = useForm<SearchTableProps>();
  const dispatch = useAppDispatch();

  const [listUsers, setListUsers] = useState<ListUsuariosProps[]>([]);
  const [isFilter, setIsFilter] = useState(false);

  const navegate = useNavigate();

  // Modals
  const [isOpenModalRenderList, setIsOpenModalRenderList] =
    useState<boolean>(false);

  const onClseModalRenderList = () => {
    setIsOpenModalRenderList(false);
  };

  async function getUsers(body?: SearchTableProps, index?: number) {
    try {
      // if (cpf || email) {
      //   const { data } = await api.get(
      //     `/solicitation/users/${solicitation.id_solicitacao}/filter?cpf=${
      //       cpf ? removeMask(cpf) : ""
      //     }&email=${email || ""}&offset=${index || 0}`
      //   );
      //   setListUsers(data);
      // } else {
      //   const { data } = await api.get(
      //     `/solicitation/users-all/${solicitation.id_solicitacao}?offset=${
      //       index || 0
      //     }`
      //   );
      //   setListUsers((state) => [...state, ...data]);
      // }
      const { data } = await api.get(
        `/solicitation/users/${solicitation.id_solicitacao}/filter?cpf=${
          body?.cpf ? removeMask(body?.cpf) : ""
        }&email=${body?.email || ""}&nome=${body?.nome || ""}&status=${
          body?.statusPreenchimento || ""
        }&offset=${index || 0}`
      );

      setListUsers(data);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  }

  const onSubmit: SubmitHandler<SearchTableProps> = async (body) => {
    try {
      getUsers(body);
    } catch (err: any) {
      setListUsers([]);
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const scrollToTable = debounce(async function () {
    const scrollTotal = Number(TableRef.current?.scrollTop);
    const heightWin =
      Number(TableRef.current?.scrollHeight) -
      Number(TableRef.current?.clientHeight);
    const scroll = (scrollTotal / heightWin) * 100;
    if (scroll >= Number(100) - Number(5)) {
      const index = listUsers.length;
      await getUsers(getValues(), index);
    }
  }, 120);

  useEffect(() => {
    setListUsers([]);
    getUsers();
  }, [isOpenModalRenderList === false]);

  const [selected, setSelected] = useState<readonly number[]>([]);

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelecteds = listUsers.map((n) => n.id_sol_usuario);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    setIsFilter(false);
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  const handleDelete = async (usersIds: number[]) => {
    try {
      await api.delete(
        `/solicitation/users/${solicitation.id_solicitacao}?usersIds=${usersIds}`
      );
      dispatch(
        setToastMessage({
          type: "success",
          message:
            usersIds.length > 1
              ? "usuarios desvinculados com sucesso"
              : "usuario desvinculado com sucesso",
        })
      );
      const newListUsers = listUsers.filter(
        (user) => !usersIds.includes(user.id_sol_usuario)
      );
      setListUsers(newListUsers);
      setSelected([]);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const exportToCSV = async () => {
    // criar um array com os dados que serão exportados
    const csvRow = listUsers.map((row) => [
      row.numero_cpf,
      row.email,
      row.nome,
      row.status,
      row.secretaria,
      row.setor,
    ]);

    // criar um array com os headers
    const headers = ["CPF", "Email", "Nome", "Status", "Secretaria", "Setor"];

    // unir os headers com os dados
    csvRow.unshift(headers);

    // criar um arquivo csv
    const csv = csvRow.join("\r\n");
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "relatorio.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <>
      <CardPrimery marginTop="1rem" height="100%">
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ width: "100%" }}
        >
          <Grid container spacing={1} paddingTop="0.5rem">
            {selected.length > 0 ? (
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                justifyContent="space-between"
              >
                <T.H6>USUARIOS SELECIONADOS {selected.length}</T.H6>
                <Tooltip title="Deleta todos itens">
                  <IconButton
                    onClick={() => {
                      handleDelete(selected as number[]);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Tooltip>
              </Grid>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <T.H6>USUÁRIOS VINCULADOS A SOLICITAÇÃO</T.H6>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      gap: "1rem",
                    }}
                  >
                    {isEdit && (
                      <ButtonPrimary
                        variant="outlined"
                        onClick={() => setIsOpenModalRenderList(true)}
                      >
                        Adicionar usuários
                        <AddIcon style={{ marginLeft: "0.5rem" }} />
                      </ButtonPrimary>
                    )}
                    {!isEdit && (
                      <ButtonSecondary
                        variant="outlined"
                        style={{ gap: "0.5rem" }}
                        onClick={exportToCSV}
                      >
                        Exportar
                        <ExitToAppIcon />
                      </ButtonSecondary>
                    )}
                    <Tooltip title="Filtar usuarios">
                      <IconButton
                        onClick={() => setIsFilter((state) => !state)}
                      >
                        <FilterListIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                </Grid>
                {isFilter && (
                  <>
                    <Grid item xs={12} md={3}>
                      <InputSelect
                        control={control}
                        title="Status de preenchimento"
                        label="Status de preenchimento"
                        id="statusPreenchimento"
                        name="statusPreenchimento"
                        isErrorMessage={false}
                      >
                        <option> </option>
                        <option key={1} value={1}>
                          Pendente
                        </option>
                        <option key={2} value={2}>
                          Em preenchimento
                        </option>
                        <option key={6} value={6}>
                          Em correção
                        </option>
                        <option key={3} value={3}>
                          Análise
                        </option>
                        <option key={4} value={4}>
                          Concluída
                        </option>
                        <option key={5} value={5}>
                          Cancelada
                        </option>
                      </InputSelect>
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <InputBase
                        control={control}
                        id="cpf"
                        label="CPF"
                        name="cpf"
                        type="cpf"
                        isErrorMessage={false}
                      />
                    </Grid>
                    <Grid item xs={12} md={2}>
                      <InputBase
                        control={control}
                        id="nome"
                        label="Nome"
                        name="nome"
                        isErrorMessage={false}
                      />
                    </Grid>
                    <Grid item xs={12} md={3}>
                      <InputBase
                        control={control}
                        id="email"
                        label="Email"
                        name="email"
                        type="email"
                        isErrorMessage={false}
                      />
                    </Grid>

                    <Grid item xs={12} md={2}>
                      <ButtonSecondary
                        variant="outlined"
                        type="submit"
                        style={{ width: "100%" }}
                      >
                        Buscar
                        <SearchIcon style={{ marginLeft: "0.5rem" }} />
                      </ButtonSecondary>
                    </Grid>
                  </>
                )}
              </>
            )}
          </Grid>
          <TableContainer
            ref={TableRef}
            sx={{ maxHeight: "54vh", minHeight: "54vh", marginTop: "1rem" }}
            onScroll={scrollToTable}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {isEdit && (
                    <TableCell padding="checkbox">
                      <Checkbox
                        color="primary"
                        indeterminate={
                          selected.length > 0 &&
                          selected.length < listUsers.length
                        }
                        checked={
                          listUsers.length > 0 &&
                          selected.length === listUsers.length
                        }
                        onChange={handleSelectAllClick}
                        inputProps={{
                          "aria-label": "select all desserts",
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell style={{ minWidth: "10rem", width: "15rem" }}>
                    CPF
                  </TableCell>
                  <TableCell style={{ width: "100%" }}>EMAIL</TableCell>
                  <TableCell style={{ minWidth: "10rem", width: "10rem" }}>
                    NOME
                  </TableCell>
                  <TableCell style={{ minWidth: "10rem", width: "10rem" }}>
                    STATUS
                  </TableCell>
                  <TableCell style={{ minWidth: "20rem", width: "20rem" }}>
                    SECRETARIA
                  </TableCell>
                  <TableCell style={{ minWidth: "15rem", width: "15rem" }}>
                    SETOR
                  </TableCell>
                  {isEdit && <TableCell>EXCLUIR</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {listUsers.length
                  ? listUsers.map((item, index) => {
                      const isItemSelected = isSelected(item.id_sol_usuario);
                      const labelId = `enhanced-table-checkbox-${index}`;

                      return (
                        <TableRow
                          hover
                          style={{ cursor: "pointer" }}
                          role="checkbox"
                          key={item.id_sol_usuario}
                          selected={isItemSelected}
                          onClick={() => {
                            if (!isEdit)
                              navegate(
                                `../${getClientUrl()}/solicitacao/${
                                  item.id_sol_usuario
                                }`
                              );
                          }}
                        >
                          {isEdit && (
                            <TableCell padding="checkbox">
                              <Checkbox
                                color="primary"
                                checked={isItemSelected}
                                onChange={(event: any) =>
                                  handleClick(event, item.id_sol_usuario)
                                }
                                inputProps={{
                                  "aria-labelledby": labelId,
                                }}
                              />
                            </TableCell>
                          )}
                          <TableCell id={labelId}>
                            {cpfMask(item.numero_cpf)}
                          </TableCell>
                          <TableCell>{item.email}</TableCell>
                          <TableCell>{item.nome}</TableCell>
                          <TableCell>{item.status}</TableCell>
                          <TableCell>{item.secretaria}</TableCell>
                          <TableCell>{item.setor}</TableCell>
                          {isEdit && (
                            <TableCell>
                              <IconButton
                                style={{ display: "flex", margin: "auto" }}
                                onClick={() =>
                                  handleDelete([item.id_sol_usuario])
                                }
                              >
                                <DeleteIcon />
                              </IconButton>
                            </TableCell>
                          )}
                        </TableRow>
                      );
                    })
                  : null}
              </TableBody>
            </Table>
          </TableContainer>
        </form>
      </CardPrimery>
      <ImportUserSolicitation
        isOpen={isOpenModalRenderList}
        onClose={onClseModalRenderList}
        idSOlicitation={solicitation.id_solicitacao!}
        listaCPFUserJaNaSolicitacao={listUsers.map((item) => item.numero_cpf)}
      />
    </>
  );
}
