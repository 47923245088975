/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";

import { apiGet } from "../../../services/metods";
import { UtilsTypes } from "../Utils/types";
import { GraphicsTypes, DataGraphicPizza } from "./types";

type ResDataGraphicBar = {
  data: string;
  pendente: number;
  empreenchimento: number;
  emcorrecao: number;
  analise: number;
  concluido: number;
  cancelado: number;
};

const colors = [
  "",
  "#fff07e",
  "#a1d5ff",
  "#00008b",
  "#8257e5",
  "#88ff7d",
  "#fd8077",
];

export function* getDataGraphicPizzaSaga(payload: any) {
  try {
    const idSolicitation = payload.payload;
    const { data }: AxiosResponse<DataGraphicPizza[], any> = yield call<any>(
      apiGet,
      `/solicitation/graphic/pizza/${idSolicitation}`
    );
    const dataGraphic = data.map((item: any) => {
      return {
        id: item.status_descricao,
        label: item.status_descricao,
        value: item.count,
        color:
          colors[
            item.status_descricao === "Pendente" ||
            item.status_descricao === "Em Preenchimento"
              ? item.status_item
              : item.status_item + 1
          ],
      };
    });

    yield put({
      type: GraphicsTypes.SET_GRAPHIC_PIZZA,
      payload: dataGraphic,
    });
  } catch (error: any) {
    yield put({
      type: UtilsTypes.SET_TOAST,
      payload: {
        type: "error",
        message: error?.message || "Plataforma indisponível no momento",
      },
    });
  }
}

export function* getDataGraphicBarSaga(payload: any) {
  try {
    const idSolicitation = payload.payload;
    const { data }: AxiosResponse<ResDataGraphicBar[], any> = yield call<any>(
      apiGet,
      `/solicitation/graphic/bar/${idSolicitation}`
    );

    const dataGraphic: any = data
      .map((item: any) => {
        if (item.data) {
          return {
            country: item.data,
            Pendente: item.pendente,
            PendenteColor: colors[1],
            Preenchimento: item.empreenchimento,
            PreenchimentoColor: colors[2],
            Correcao: item.emcorrecao,
            CorrecaoColor: colors[3],
            Analise: item.analise,
            AnaliseColor: colors[4],
            Concluido: item.concluido,
            ConcluidoColor: colors[5],
            Cancelado: item.cancelado,
            CanceladoColor: colors[6],
          };
        }
        return null;
      })
      .filter((item: any) => item !== null);

    yield put({
      type: GraphicsTypes.SET_GRAPHIC_BAR,
      payload: dataGraphic,
    });
  } catch (error: any) {
    yield put({
      type: UtilsTypes.SET_TOAST,
      payload: {
        type: "error",
        message: error?.message || "Plataforma indisponível no momento",
      },
    });
  }
}
