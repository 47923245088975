/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import * as B from "../../components/base/Buttons";
import * as T from "../../components/base/Text";
import { BoxLogin } from "../../components/boxLogin";
import { InputBase } from "../../components/Inputs/InputText";
import { useLoginRedirect } from "../../hooks/useLoginRedirect";
import { api } from "../../services/api";
import { setLoading, setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../store/hooks";
import { getClientUrl, removeMask } from "../../utils/utils";
import * as S from "./styles";

type FormDataForgotPassword = {
  cpf: string;
};

export function ForgotPassword() {
  const { handleSubmit, control } = useForm<FormDataForgotPassword>();

  const navegate = useNavigate();
  const dispatch = useAppDispatch();
  const isLogged = useLoginRedirect();

  useEffect(() => {
    isLogged();
  }, []);

  const onSubmit: SubmitHandler<FormDataForgotPassword> = async ({ cpf }) => {
    try {
      dispatch(setLoading(true));
      const { data } = await api.post("/user/forgot-password", {
        cpf: removeMask(cpf),
      });
      dispatch(
        setToastMessage({
          type: "success",
          message: data.message,
          autoClose: 5000,
        })
      );
      navegate(`/${getClientUrl()}/login`);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <S.Container>
      <S.Content>
        <BoxLogin>
          <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            style={{ width: "100%" }}
          >
            <T.H6 margin="0 0 1rem 0">
              Digite seu CPF para recuperação de senha
            </T.H6>
            <Grid item xs={12}>
              <InputBase
                control={control}
                id="CPF"
                label="CPF"
                name="cpf"
                type="cpf"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <B.ButtonPrimary type="submit" style={{ width: "100%" }}>
                Enviar codigo
              </B.ButtonPrimary>
            </Grid>
          </form>
          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <B.ButtonLink
              color="secondary"
              to={`/${getClientUrl()}/login`}
              style={{ width: "100%" }}
            >
              Voltar
            </B.ButtonLink>
          </Grid>
        </BoxLogin>
      </S.Content>
    </S.Container>
  );
}
