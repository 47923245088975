/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/naming-convention */
import { ResponsivePie } from "@nivo/pie";
import { useEffect, useState } from "react";

import { DataGraphicPizza } from "../../store/ducks/Graphics/types";
import { useAppSelector } from "../../store/hooks";
import * as T from "../base/Text";

interface ExtendedPieDatum extends DataGraphicPizza {
  hidden: boolean;
}

function CenteredMetric(params: any) {
  const { dataWithArc, centerX, centerY } = params;
  let dataLength = 0;
  dataWithArc.forEach((data: ExtendedPieDatum) => {
    if (!data.hidden) {
      dataLength += Number(data.value);
    }
  });

  return (
    <text
      x={centerX}
      y={centerY}
      textAnchor="middle"
      dominantBaseline="central"
      style={{
        fontSize: "1.5rem",
        fontWeight: "bold",
        fill: "#333333",
      }}
    >
      {dataLength || "Nenhum dado"}
    </text>
  );
}

export function GraphicPizza() {
  const { graphicPizza } = useAppSelector(({ Graphics }) => Graphics);
  const [dataWithVisibility, setDataWithVisibility] = useState<
    ExtendedPieDatum[]
  >([]);

  useEffect(() => {
    setDataWithVisibility(
      graphicPizza.map((item) => ({
        ...item,
        hidden: false, // Inicialmente, todos os itens estão visíveis
      }))
    );
  }, [graphicPizza]);

  let totalValue = 0;
  dataWithVisibility.forEach((data: ExtendedPieDatum) => {
    if (!data.hidden) {
      totalValue += Number(data.value);
    }
  });

  return (
    <div
      style={{
        width: "100%",
        minHeight: "100%",
      }}
    >
      <ResponsivePie
        data={dataWithVisibility}
        margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
        colors={(datum) => datum.data.color}
        innerRadius={0.5}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{
          from: "color",
          modifiers: [["darker", 0.2]],
        }}
        layers={[
          "arcs",
          "arcLabels",
          "arcLinkLabels",
          "legends",
          CenteredMetric,
        ]}
        arcLabel={(datum) =>
          datum.hidden
            ? ""
            : `${Number.parseInt(
                String((datum.value / totalValue) * 100),
                10
              )}%`
        }
        arcLinkLabel={(datum) => `${datum.label}: (${datum.value})`}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{
          from: "color",
          modifiers: [["darker", 2]],
        }}
        enableArcLabels
        tooltip={({ datum }) =>
          !datum.hidden ? (
            <div
              style={{
                color: datum.color,
                backgroundColor: "#fff",
                padding: "0.5rem",
                borderRadius: "0.5rem",
              }}
            >
              <T.Paragraph>
                <T.Span weight="bold">
                  {datum.label}: {datum.value} /{" "}
                  {Number.parseInt(
                    String((datum.value / totalValue) * 100),
                    10
                  )}
                  %
                </T.Span>
              </T.Paragraph>
            </div>
          ) : null
        }
        legends={[
          {
            anchor: "bottom",
            direction: "row",
            justify: false,
            translateX: 0,
            translateY: 56,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: "#999",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            itemsSpacing: 25,
            toggleSerie: true,
            symbolSize: 10,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
            onClick: (datum) => {
              setDataWithVisibility((prevData) =>
                prevData.map((item) =>
                  item.id === datum.id
                    ? { ...item, hidden: !item.hidden }
                    : item
                )
              );
            },
          },
        ]}
      />
    </div>
  );
}
