import { useState } from "react";

import { BodyContainer } from "../../components/BodyContainer";
import { TabNavigation } from "../../components/TabNavigation";
import { GenericParametrs } from "../../components/TabNavigation/System/GenericParametrs";
import { StructuredTable } from "../../components/TabNavigation/System/StructuredTable";

const labesTabs = ["Parametros Gerais", "Tabela Estruturada"];

export function SystemParameters() {
  const [tabValue, setTabValue] = useState(0);

  return (
    <BodyContainer menu="Parametros do Sistema">
      <TabNavigation
        labesTabs={labesTabs}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />
      {tabValue === 0 && <GenericParametrs />}
      {tabValue === 1 && <StructuredTable />}
    </BodyContainer>
  );
}
