/**
 * Action types
 */
export enum SystemParametersTypes {
  SET_PARAMETERS = "@system/SET_PARAMETERS",
  GET_PARAMETERS = "@system/GET_PARAMETERS",
}

/**
 * valores isolados do objeto de parametros
 */
export type ParammetersTypes = {
  descr_remet_mail: string;
  login_remet_mail: string;
  senha_remet_mail: string;
  smtp_mail: string;
  porta_mail: string;
  ind_tls_mail: "S" | "N";
  ind_ssl_mail: "S" | "N";
  url_appweb: string;
  cod_pais_sms: string;
  login_sms: string;
  senha_sms: string;
};

/**
 * valores do state completo desse redux
 */
export type SystemParametersState = {
  readonly systemParameters: ParammetersTypes;
};
