/**
 * Action types
 */
export enum ProofOfLifeTypes {
  SET_FACE_RECOGNITION = "@ProofOfLife/SET_FACE_RECOGNITION",
}

/**
 * valores isolados do objeto de parametros
 */
export type FaceRecognitionTypes = {
  info_faceapi_genero: string;
  info_faceapi_idade: string;
  linkVideo: string;
};

/**
 * valores do state completo desse redux
 */
export type ProofOfLifeState = {
  readonly faceRecognition: FaceRecognitionTypes;
};
