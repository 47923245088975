import { Reducer } from "redux";

import { UserTypes, UserState } from "./types";

const INITIAL_STATE: UserState = {
  user: {
    id_usuario: 0,
    codigo_interno: "",
    nome: "",
    iniciais_usuario: "",
    email: "",
    senha: "",
    ind_ativo: "N",
    celular: "",
    numero_cpf: "",
    dt_nascimento: "",
    perfil_usuario: 0,
    dt_cadastro: "",
    hr_cadastro: "",
    ind_conta_verificada: "N",
    ind_acesso_api: "N",
    token_api: "",
    url_callback: "",
    img_foto: "",
    cargo: "",
    empresa: "",
  },
};

const reducer: Reducer<UserState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UserTypes.SET_USER:
      return { ...state, user: action.payload };

    default:
      return state;
  }
};

export default reducer;
