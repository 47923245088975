/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { Divider, FormControlLabel, Grid, Radio } from "@mui/material";
import { AxiosError } from "axios";
import { Fragment, useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  IBeneficio,
  IINstituidor,
} from "../../../types/FormUserSolicitacao/Beneficios";
import { getIdURLUserSol, removeMask } from "../../../utils/utils";
import { validarCNPJ } from "../../../utils/validators";
import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import { InputDate } from "../../Inputs/InputDate";
import { InputRadios } from "../../Inputs/InputRadios";
import { InputSelect } from "../../Inputs/InputSelect";
import { InputBase } from "../../Inputs/InputText";
import Modal from "../index";
import * as S from "./styles";

interface IModalBeneficioSolicitation {
  isOpen: boolean;
  onClose: () => void;
  beneficio?: IBeneficio;
  updateListBeneficos: () => Promise<void>;
  isEditable: boolean;
}

const instituidorMock = (idBenef: number): IINstituidor => ({
  id_sol_dados_intituidor: crypto.randomUUID(),
  id_sol_dados_beneficio: idBenef,
  nome: "",
  rg: "",
  cpf: "",
  dt_nascimento: "",
  dt_falecimento: "",
  sexo: "M",
  nacionalidade_item: "",
});

export function BeneficioSolicitation({
  isOpen,
  onClose,
  beneficio,
  updateListBeneficos,
  isEditable,
}: IModalBeneficioSolicitation) {
  const [indPersaoPorMorte, setIndPersaoPorMorte] = useState(false);
  const [listaInstituidores, setListaInstituidores] = useState<IINstituidor[]>(
    []
  );
  const { handleSubmit, control, reset, setError } = useForm<IBeneficio>();

  const dispatch = useAppDispatch();

  const { listaBeneficios, nacionalidade } = useAppSelector(
    ({ TableStructure }) => TableStructure
  );

  const onSubmit: SubmitHandler<IBeneficio> = async (body) => {
    try {
      if (!validarCNPJ(body.cnpj)) {
        setError("cnpj", {
          type: "manual",
          message: "CNPJ inválido",
        });
        return;
      }

      const newBeneficio = {
        ...(beneficio?.id_sol_dados_beneficio && { ...beneficio }),
        cnpj: body.cnpj,
        data_concessao: body.data_concessao,
        num_concessao: body.num_concessao,
        razao_social: body.razao_social,
        tipo_beneficio_item: body.tipo_beneficio_item,
      } as IBeneficio;

      const { data } = await api.post(
        `/solicitation/beneficios/save/${getIdURLUserSol()}/${
          beneficio?.id_sol_dados_beneficio || ""
        }`,
        {
          beneficio: newBeneficio,
          listaInstituidores: listaInstituidores.map((instituidor) => ({
            ...instituidor,
            cpf: removeMask(instituidor.cpf),
          })),
        }
      );
      dispatch(setToastMessage({ type: "success", message: data.message }));
      await updateListBeneficos();
      onClose();
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
        if (error?.errors && error?.errors.length) {
          error.errors.forEach((error: any) => {
            setError(error.property, {
              type: "manual",
              message: error.error,
            });
          });
        }
      }
    }
  };

  const setValuesInstuidor = (id: string, property: string, value: unknown) => {
    setListaInstituidores(
      listaInstituidores.map((instituidor) => {
        if (String(instituidor.id_sol_dados_intituidor) === id) {
          return {
            ...instituidor,
            [property]: value,
          };
        }
        return instituidor;
      })
    );
  };

  useEffect(() => {
    if (beneficio?.id_sol_dados_beneficio) {
      reset(beneficio);
      setIndPersaoPorMorte(Number(beneficio.tipo_beneficio_item) === 1);
      const { instituidores } = beneficio as any;
      if (instituidores) setListaInstituidores(instituidores);
    } else {
      reset({
        cnpj: "",
        data_concessao: "",
        num_concessao: "",
        razao_social: "",
        tipo_beneficio_item: "",
      });
      setListaInstituidores([]);
      setIndPersaoPorMorte(false);
    }
  }, [beneficio]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Beneficio">
      <S.ModalBody>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ width: "100%" }}
        >
          <Grid
            container
            spacing={1}
            style={{ width: "100%", marginTop: "1rem" }}
          >
            <Grid item xs={12} md={4}>
              <InputSelect
                title="Tipo de beneficio"
                control={control}
                id="tipo_beneficio_item"
                label="Tipo de beneficio"
                name="tipo_beneficio_item"
                required
                readOnly={!isEditable}
                onClick={(e) => {
                  if (Number(e.target.value) === 1) {
                    setIndPersaoPorMorte(true);
                    if (!listaInstituidores.length) {
                      setListaInstituidores([
                        instituidorMock(
                          Number(beneficio?.id_sol_dados_beneficio)
                        ),
                      ]);
                    }
                  } else {
                    setIndPersaoPorMorte(false);
                    setListaInstituidores([]);
                  }
                }}
              >
                <option> </option>
                {listaBeneficios.itemsTable?.length &&
                  listaBeneficios.itemsTable.map((beneficio) => {
                    if (beneficio.item_tabela === 0) return null;
                    return (
                      <option key={Math.random()} value={beneficio.item_tabela}>
                        {beneficio.descr_tabela}
                      </option>
                    );
                  })}
              </InputSelect>
            </Grid>
            <Grid item xs={12} md={3}>
              <InputDate
                control={control}
                id="data_concessao"
                name="data_concessao"
                label="Data da concessão"
                readOnly={!isEditable}
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <InputBase
                control={control}
                id="num_concessao"
                label="Numero da concessão"
                name="num_concessao"
                readOnly={!isEditable}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <InputBase
                control={control}
                id="razao_social"
                label="Razão Social"
                name="razao_social"
                readOnly={!isEditable}
                required
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputBase
                control={control}
                id="cnpj"
                label="Numero do CNPJ"
                name="cnpj"
                required
                readOnly={!isEditable}
                type="cnpj"
              />
            </Grid>

            {indPersaoPorMorte && (
              <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  display="flex"
                  justifyContent="space-between"
                >
                  <T.H6>INSTITUIDORES</T.H6>
                  {isEditable && (
                    <B.ButtonSecondary
                      style={{ width: "clamp(150px, 300px, 100%)" }}
                      onClick={() => {
                        setListaInstituidores([
                          ...listaInstituidores,
                          instituidorMock(
                            Number(beneficio?.id_sol_dados_beneficio)
                          ),
                        ]);
                      }}
                    >
                      Adicionar instituidor
                    </B.ButtonSecondary>
                  )}
                </Grid>
                {listaInstituidores.map((instituidor) => {
                  const index = crypto.randomUUID();
                  return (
                    <Fragment key={instituidor.id_sol_dados_intituidor}>
                      <Divider style={{ width: "100%", marginTop: "15px" }} />
                      {isEditable && (
                        <Grid item xs={12} md={1} marginTop="10px">
                          <B.ButtonSecondary
                            variant="text"
                            onClick={() => {
                              setListaInstituidores(
                                listaInstituidores.filter(
                                  (item) =>
                                    item.id_sol_dados_intituidor !==
                                    instituidor.id_sol_dados_intituidor
                                )
                              );
                            }}
                          >
                            <DeleteOutlineIcon />
                          </B.ButtonSecondary>
                        </Grid>
                      )}
                      <Grid
                        item
                        xs={12}
                        md={isEditable ? 5 : 6}
                        marginTop="10px"
                      >
                        <InputBase
                          control={control}
                          id={`nome-${index}`}
                          label="Nome do instituidor"
                          name={`nome-${index}`}
                          readOnly={!isEditable}
                          defaultValue={instituidor.nome}
                          onBlur={(e) => {
                            const { value } = e.target;
                            setValuesInstuidor(
                              String(instituidor.id_sol_dados_intituidor),
                              "nome",
                              value
                            );
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={3} marginTop="10px">
                        <InputBase
                          control={control}
                          id={`cpf-${index}`}
                          label="Numero do CPF"
                          name={`cpf-${index}`}
                          readOnly={!isEditable}
                          defaultValue={instituidor.cpf}
                          required={!instituidor.dt_falecimento}
                          onBlur={(e) => {
                            const { value } = e.target;
                            setValuesInstuidor(
                              String(instituidor.id_sol_dados_intituidor),
                              "cpf",
                              value
                            );
                          }}
                          type="cpf"
                        />
                      </Grid>
                      <Grid item xs={12} md={3} marginTop="10px">
                        <InputBase
                          control={control}
                          id={`rg-${index}`}
                          label="Numero do RG"
                          name={`rg-${index}`}
                          readOnly={!isEditable}
                          defaultValue={instituidor.rg}
                          onBlur={(e) => {
                            const { value } = e.target;
                            setValuesInstuidor(
                              String(instituidor.id_sol_dados_intituidor),
                              "rg",
                              value
                            );
                          }}
                          required={!instituidor.dt_falecimento}
                          type="rg"
                        />
                      </Grid>

                      <Grid item xs={12} md={3}>
                        <InputRadios
                          control={control}
                          title="Sexo"
                          id={`sexo-${index}`}
                          name={`sexo-${index}`}
                          readOnly={!isEditable}
                          defaultValue={instituidor.sexo}
                          required
                          row
                        >
                          <FormControlLabel
                            value="M"
                            control={<Radio size="small" />}
                            label="Masculino"
                            onChange={() => {
                              setValuesInstuidor(
                                String(instituidor.id_sol_dados_intituidor),
                                "sexo",
                                "M"
                              );
                            }}
                          />
                          <FormControlLabel
                            value="F"
                            control={<Radio size="small" />}
                            label="Feminino"
                            onChange={() => {
                              setValuesInstuidor(
                                String(instituidor.id_sol_dados_intituidor),
                                "sexo",
                                "F"
                              );
                            }}
                          />
                        </InputRadios>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputSelect
                          title="Nacionalidade"
                          control={control}
                          id={`nacionalidade-${index}`}
                          label="Nacionalidade"
                          name={`nacionalidade-${index}`}
                          defaultValue={instituidor.nacionalidade_item}
                          required
                          readOnly={!isEditable}
                          onClick={(e) => {
                            const { value } = e.target;
                            setValuesInstuidor(
                              String(instituidor.id_sol_dados_intituidor),
                              "nacionalidade_item",
                              value
                            );
                          }}
                        >
                          <option> </option>
                          {nacionalidade.itemsTable?.length &&
                            nacionalidade.itemsTable.map((beneficio) => {
                              if (beneficio.item_tabela === 0) return null;
                              return (
                                <option
                                  key={Math.random()}
                                  value={beneficio.item_tabela}
                                >
                                  {beneficio.descr_tabela}
                                </option>
                              );
                            })}
                        </InputSelect>
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputDate
                          control={control}
                          id={`dt_nascimento-${index}`}
                          name={`dt_nascimento-${index}`}
                          label="Data de nascimento"
                          readOnly={!isEditable}
                          defaultValue={instituidor.dt_nascimento}
                          onChangeCustom={(date) => {
                            setValuesInstuidor(
                              String(instituidor.id_sol_dados_intituidor),
                              "dt_nascimento",
                              date
                            );
                          }}
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputDate
                          control={control}
                          id={`dt_falecimento-${index}`}
                          name={`dt_falecimento-${index}`}
                          label="Data de falecimento"
                          readOnly={!isEditable}
                          defaultValue={instituidor.dt_falecimento}
                          onChangeCustom={(date) => {
                            setValuesInstuidor(
                              String(instituidor.id_sol_dados_intituidor),
                              "dt_falecimento",
                              date
                            );
                          }}
                        />
                      </Grid>
                    </Fragment>
                  );
                })}
              </>
            )}
          </Grid>
          {isEditable && (
            <Grid
              item
              xs={12}
              marginTop="1rem"
              display="flex"
              justifyContent="center"
            >
              <B.ButtonPrimary
                type="submit"
                style={{
                  width: "clamp(150px, 300px, 100%)",
                  marginBottom: "0.5rem",
                }}
              >
                {beneficio?.id_sol_dados_beneficio ? "Salvar" : "Adicionar"}
              </B.ButtonPrimary>
            </Grid>
          )}
        </form>
      </S.ModalBody>
    </Modal>
  );
}
