/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { FasesProvaVidaProps } from "../../../../pages/MySolicitation";
import { api } from "../../../../services/api";
import { UserObjectTypes } from "../../../../store/ducks/User/types";
import {
  setLoading,
  setToastMessage,
} from "../../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { cpfMask } from "../../../../utils/mask";
import {
  convertData,
  copyToClipboard,
  getIdURLUserSol,
} from "../../../../utils/utils";
import { ButtonPrimary } from "../../../base/Buttons";
import * as T from "../../../base/Text";
import { InputBase } from "../../../Inputs/InputText";
import * as S from "./styles";

type ConfirmPIXProps = {
  isEditable: boolean;
  provaVida: FasesProvaVidaProps;
  getUserSolicitation(): Promise<void>;
  userSolicitation: UserObjectTypes;
};

type CobDataProps = {
  devedor: {
    nome: string;
    cpf: string;
  };
  location: {
    criacao: string;
    id: number;
    location: string;
    tipoCob: string;
  };
  qrcode: {
    imagemQrcode: string;
    qrcode: string;
  };
  status: string;
  txid: string;
  valor: string;
};

type DataProvaVidaProps = {
  cpf: string;
  devedor: string;
  endToEndId: string;
  horario: string;
  status: string;
  txid: string;
  valor: string;
};

export function ConfirmPIX({
  isEditable,
  provaVida,
  getUserSolicitation,
  userSolicitation,
}: ConfirmPIXProps) {
  const { handleSubmit, control } = useForm<any>();

  const [cobData, setCobData] = useState<CobDataProps>({} as CobDataProps);
  const [dataPix, setDataPix] = useState<DataProvaVidaProps>(
    {} as DataProvaVidaProps
  );

  const [device] = useAppSelector(({ Utils }) => [Utils.device]);

  const dispatch = useAppDispatch();

  const onSubmit = async () => {
    try {
      dispatch(setLoading(true));
      const { data } = await api.get(
        `/proof-of-life/validate-cob-pix/${getIdURLUserSol()}/${cobData.txid}`
      );
      getUserSolicitation();
      setDataPix(data);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const createCobPix = async () => {
    try {
      dispatch(setLoading(true));
      const { data } = await api.get(
        `/proof-of-life/create-cob-pix/${getIdURLUserSol()}`
      );
      setCobData(data);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getDataProvaVida = async () => {
    try {
      const { data } = await api.get(
        `/proof-of-life/data-pix/${getIdURLUserSol()}`
      );

      setDataPix(data);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  useEffect(() => {
    if (provaVida.isCompleted) {
      getDataProvaVida();
    }
  }, [provaVida]);

  return (
    <S.FormContainer onSubmit={handleSubmit(onSubmit)}>
      {provaVida.isCompleted ? (
        <S.BoxPix>
          {device === "desktop" && (
            <S.BoxPixLeft>
              <img src="/images/payment.gif" width={300} alt="icon" />
            </S.BoxPixLeft>
          )}
          <S.BoxPixRight>
            <div
              style={{
                width: "100%",
                padding: "1rem 0",
              }}
            >
              <T.H6 textAlign="center" size="1.5rem">
                Autenticação via Pix concluida com sucesso!!
              </T.H6>
            </div>
            <S.BoxPixDetails>
              <T.H6 size="1.2rem">Informações de Pagamento</T.H6>
              {dataPix && Object.keys(dataPix).length && (
                <>
                  <S.BoxPixDetailsItem>
                    <T.H6 width="min-content">Nome:</T.H6>
                    <T.Paragraph>{dataPix.devedor}</T.Paragraph>
                  </S.BoxPixDetailsItem>
                  <S.BoxPixDetailsItem>
                    <T.H6 width="min-content">CPF:</T.H6>
                    <T.Paragraph>{cpfMask(dataPix.cpf)}</T.Paragraph>
                  </S.BoxPixDetailsItem>
                  <S.BoxPixDetailsItem>
                    <T.H6 width="auto" style={{ whiteSpace: "nowrap" }}>
                      Data de pagamento:
                    </T.H6>
                    <T.Paragraph>
                      {convertData(dataPix.horario, {
                        dateStyle: "medium",
                      })}
                    </T.Paragraph>
                  </S.BoxPixDetailsItem>
                </>
              )}
            </S.BoxPixDetails>
          </S.BoxPixRight>
        </S.BoxPix>
      ) : cobData.txid ? (
        <S.BoxPix>
          <S.BoxPixLeft>
            <S.PixImageQrCode src={cobData.qrcode.imagemQrcode} alt="Qrcode" />
            <S.BoxQrCodeText
              onClick={() => copyToClipboard(cobData.qrcode.qrcode)}
            >
              <p>{cobData.qrcode.qrcode}</p>
              <ContentCopyIcon fontSize="small" />
            </S.BoxQrCodeText>
          </S.BoxPixLeft>
          <S.BoxPixRight>
            <S.PixWarning>
              Precisamos confirmar seus dados via autenticação bancária! Faça um
              Pix de R$ 0,01 em uma conta que o CPF:{" "}
              {cpfMask(cobData.devedor.cpf)} seja o titular!
            </S.PixWarning>
            <S.BoxPixDetails>
              <S.BoxPixDetailsItem>
                <T.H6 width="min-content">Nome:</T.H6>
                <T.Paragraph>{cobData.devedor.nome}</T.Paragraph>
              </S.BoxPixDetailsItem>
              <S.BoxPixDetailsItem>
                <T.H6 width="min-content">CPF:</T.H6>
                <T.Paragraph>{cpfMask(cobData.devedor.cpf)}</T.Paragraph>
              </S.BoxPixDetailsItem>
              <S.BoxPixDetailsItem>
                <T.H6 width="auto" style={{ whiteSpace: "nowrap" }}>
                  Data de criação:
                </T.H6>
                <T.Paragraph>
                  {convertData(cobData.location.criacao, {
                    dateStyle: "medium",
                  })}
                </T.Paragraph>
              </S.BoxPixDetailsItem>
              <S.BoxPixDetailsItem>
                <T.H6 width="auto" style={{ whiteSpace: "nowrap" }}>
                  Hora de criação:
                </T.H6>
                <T.Paragraph>
                  {convertData(cobData.location.criacao, {
                    timeStyle: "medium",
                  })}
                </T.Paragraph>
              </S.BoxPixDetailsItem>
              <S.BoxPixDetailsItem>
                <T.H6 width="auto" style={{ whiteSpace: "nowrap" }}>
                  Status de pagamento:
                </T.H6>
                <T.Paragraph>
                  {cobData.status === "ATIVA" ? "Pendente" : "Pago"}
                </T.Paragraph>
              </S.BoxPixDetailsItem>
              <S.BoxPixDetailsItem>
                <T.H6 width="auto" style={{ whiteSpace: "nowrap" }}>
                  Tempo de Expiração:
                </T.H6>
                <T.Paragraph>60min</T.Paragraph>
              </S.BoxPixDetailsItem>
            </S.BoxPixDetails>
            <S.FormBoxButtons
              style={{
                justifyContent: "flex-end",
              }}
            >
              <ButtonPrimary type="submit">Autenticar</ButtonPrimary>
            </S.FormBoxButtons>
          </S.BoxPixRight>
        </S.BoxPix>
      ) : (
        <S.FormContent>
          <S.FormItem>
            <T.H6 textAlign="center">
              Sera criada uma cobrança pix para o seu numero de CPF e pelo menos
              deve ser pago
            </T.H6>
          </S.FormItem>
          <S.FormItem>
            <InputBase
              control={control}
              id="numero_cpf"
              label="Numero CPF"
              name="numero_cpf"
              type="cpf"
              defaultValue={userSolicitation?.numero_cpf}
              readOnly
              required
            />
          </S.FormItem>
          {isEditable && (
            <S.FormBoxButtons>
              <ButtonPrimary onClick={createCobPix}>
                Criar cobrança
              </ButtonPrimary>
            </S.FormBoxButtons>
          )}
        </S.FormContent>
      )}
    </S.FormContainer>
  );
}
