/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/require-default-props */
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  convertData,
  convertSOrN,
  perfilStringFormat,
  removeMask,
} from "../../../utils/utils";
import * as B from "../../base/Buttons";
import { InputCheckBox } from "../../Inputs/inputCheckBox";
import { InputDate } from "../../Inputs/InputDate";
import { InputPassword } from "../../Inputs/InputPassword";
import { InputSelect } from "../../Inputs/InputSelect";
import { InputBase } from "../../Inputs/InputText";
import Modal from "../index";
import * as S from "./styles";

type FormData = {
  readonly codigo_interno: string;
  numero_cpf: string;
  nome: string;
  email: string;
  celular: string;
  senha: string;
  dt_nascimento: string;
  perfil_usuario: 1 | 2 | 3 | 4 | 5;
  ind_ativo: "S" | "N";
  ind_conta_verificada: "S" | "N";
  ind_acesso_api: "S" | "N";
  token_api: string;
  url_callback: string;
  cargo: string;
  empresa: string;
};

interface IModalAddNewStructuredTable {
  isOpen: boolean;
  onClose: () => void;
  returnIcon?: () => void;
  item?: FormData | boolean;
  getUpdateTables: () => void;
  schemaCliente: string;
}

export function AddNewUserAndUpdate({
  isOpen,
  onClose,
  returnIcon,
  item,
  getUpdateTables,
  schemaCliente,
}: IModalAddNewStructuredTable) {
  const { handleSubmit, control, reset, setError } = useForm<FormData>();

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<FormData> = async (body) => {
    try {
      const userData = {
        email: body.email,
        nome: body.nome,
        senha: body.senha,
        perfil_usuario: body.perfil_usuario,
        ind_ativo: convertSOrN(body.ind_ativo),
        ind_conta_verificada: convertSOrN(body.ind_conta_verificada),
        ind_acesso_api: convertSOrN(body.ind_acesso_api),
        ...(body.celular && { celular: removeMask(body.celular) }),
        ...(body.dt_nascimento && {
          dt_nascimento: convertData(body.dt_nascimento),
        }),
        ...(body.token_api && { token_api: body.token_api }),
        ...(body.url_callback && { url_callback: body.url_callback }),
        ...(body.cargo && { cargo: body.cargo }),
        ...(body.empresa && { empresa: body.empresa }),
      } as FormData;

      if ((item as FormData).codigo_interno) {
        const { data } = await api.put(
          `/user/update-adm/${(item as FormData).codigo_interno}?sigla=${
            schemaCliente || ""
          }`,
          userData
        );
        dispatch(setToastMessage({ type: "success", message: data.message }));
      } else {
        userData.numero_cpf = removeMask(body.numero_cpf) as string;
        const { data } = await api.post(
          `/user/create-user-adm?sigla=${schemaCliente || ""}`,
          userData
        );
        dispatch(setToastMessage({ type: "success", message: data.message }));
      }
      onClose();
      getUpdateTables();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
      if (error?.errors && error?.errors.length) {
        error.errors.forEach((error: any) => {
          setError(error.property, {
            type: "manual",
            message: error.error,
          });
        });
      }
    }
  };

  const { user } = useAppSelector((state) => state.User);

  useEffect(() => {
    if ((item as FormData).numero_cpf) {
      reset(item as FormData);
    }
    return () => {
      reset({
        numero_cpf: "",
        nome: "",
        email: "",
        celular: "",
        dt_nascimento: "",
        perfil_usuario: 1,
        ind_ativo: "N",
        ind_conta_verificada: "N",
        ind_acesso_api: "N",
        token_api: "",
        url_callback: "",
        cargo: "",
        empresa: "",
      });
    };
  }, [item]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        (item as FormData).numero_cpf ? "Editar Usúario" : "Adicionar Usúario"
      }
      returnIcon={returnIcon}
    >
      <S.ModalBody>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ width: "100%" }}
        >
          <Grid
            container
            spacing={1}
            style={{ width: "100%", marginTop: "1rem" }}
          >
            <Grid item xs={12} md={4}>
              <InputBase
                control={control}
                id="numero_cpf"
                label="Numero CPF"
                name="numero_cpf"
                type="cpf"
                required={(item as FormData).numero_cpf ? false : true}
                disabled={(item as FormData).numero_cpf ? true : false}
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <InputBase
                control={control}
                id="email"
                label="Email"
                name="email"
                type="email"
                required
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputBase
                control={control}
                id="nome"
                label="Nome"
                name="nome"
                required
              />
            </Grid>
            <Grid item xs={6}>
              <InputPassword
                control={control}
                id="senha"
                label="Senha"
                name="senha"
                required={(item as FormData).numero_cpf ? false : true}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputDate
                control={control}
                id="dt_nascimento"
                label="Data Nascimento"
                name="dt_nascimento"
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <InputSelect
                title="Perfil"
                control={control}
                id="perfil_usuario"
                label="Perfil"
                name="perfil_usuario"
                required
              >
                {[1, 5, 2, 3, 4].map((perfil) => {
                  if (user.perfil_usuario !== 4 && perfil > user.perfil_usuario)
                    return null;
                  return (
                    <option key={perfil} value={perfil}>
                      {perfilStringFormat(perfil)}
                    </option>
                  );
                })}
              </InputSelect>
            </Grid>
            <Grid item xs={12} md={4}>
              <InputBase
                control={control}
                id="celular"
                label="Celular"
                type="phone"
                name="celular"
                maxLength={15}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputBase
                control={control}
                id="empresa"
                label="Empresa"
                name="empresa"
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <InputBase
                control={control}
                id="cargo"
                label="Cargo"
                name="cargo"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputCheckBox
                control={control}
                id="ind_ativo"
                label="Ativo"
                name="ind_ativo"
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputCheckBox
                control={control}
                id="ind_conta_verificada"
                label="Conta Verificada"
                name="ind_conta_verificada"
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputCheckBox
                control={control}
                id="ind_acesso_api"
                label="Acesso API"
                name="ind_acesso_api"
              />
            </Grid>
            <Grid item xs={12} md={5}>
              <InputBase
                control={control}
                id="token_api"
                label="Token API"
                name="token_api"
              />
            </Grid>
            <Grid item xs={12}>
              <InputBase
                control={control}
                id="url_callback"
                label="URL Callback"
                name="url_callback"
              />
            </Grid>
          </Grid>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <B.ButtonPrimary
              type="submit"
              style={{ width: "clamp(150px, 300px, 100%)" }}
            >
              {(item as FormData).numero_cpf ? "Salvar" : "Adicionar"}
            </B.ButtonPrimary>
          </div>
        </form>
      </S.ModalBody>
    </Modal>
  );
}
