/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable no-unused-expressions */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */

import {
  Autocomplete,
  FormControl,
  FormHelperText,
  TextField,
  TextFieldProps,
} from "@mui/material";
import { useState } from "react";
import { Control, Controller } from "react-hook-form";

interface IAutoComplete {
  control: Control<any>;
  title: string;
  label: string;
  id: string;
  name: string;
  defaultValue?: any;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  getId?: boolean;
  freeSolo?: boolean;
  optionsArray: any[] | never[];
  getValuesArray?: any;
  isErrorMessage?: boolean;
}

export function AutoComplete({
  control,
  name,
  id,
  label,
  title,
  defaultValue,
  disabled,
  readOnly,
  required,
  freeSolo,
  getId,
  errorMessage,
  optionsArray,
  getValuesArray,
  isErrorMessage,
}: IAutoComplete) {
  const [options, setOptions] = useState(optionsArray);
  return (
    <Controller
      name={name}
      control={control}
      // defaultValue={[defaultValue]}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <FormControl
            required={required}
            disabled={disabled}
            variant="outlined"
            size="small"
            error={!!error?.message || !!errorMessage}
            fullWidth
          >
            <Autocomplete
              id={id}
              freeSolo={freeSolo}
              options={options}
              getOptionLabel={(option: any) => option.text as string}
              onChange={(_, data) => {
                if (getId && data?.id) onChange(data.id);
                else if (data?.text) onChange(data.text);
              }}
              onInputChange={(_, data) => {
                onChange(data);
              }}
              // inputValue={value}
              defaultValue={defaultValue || null}
              size="small"
              disabled={disabled}
              readOnly={readOnly}
              fullWidth
              renderInput={(params: TextFieldProps) => (
                <TextField
                  {...params}
                  label={label}
                  variant="outlined"
                  error={!!error?.message || !!errorMessage}
                  onChange={({ target }) => {
                    if (getValuesArray.name) {
                      getValuesArray(target)
                        .then((data: any) => {
                          if (data?.length) setOptions(data);
                          else setOptions([]);
                        })
                        .catch(() => {});
                    }
                  }}
                />
              )}
            />
            {isErrorMessage === false ? (
              ""
            ) : (
              <FormHelperText
                style={{ margin: "2px 0 0 2px" }}
                error={!!error?.message || !!errorMessage}
              >
                {error?.message || errorMessage || " "}
              </FormHelperText>
            )}
          </FormControl>
        );
      }}
      rules={{
        required: {
          value: !!required,
          message: `${title} é obrigatório`,
        },
      }}
    />
  );
}
