/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";

import { apiGet } from "../../../services/metods";
import { UtilsTypes } from "../Utils/types";
import { TableStructureTypes, ItensTableTypes } from "./types";

export function* getItemTableStructureSaga(params: any) {
  try {
    const codsTabela = params.payload as number[];

    const tablesItens: ItensTableTypes[] = [];
    for (const codTabela of codsTabela) {
      const response: AxiosResponse<ItensTableTypes[]> = yield call(
        apiGet,
        `/system/structured-table?cod=${codTabela}`
      );
      tablesItens.push(...response.data);
    }
    const newObject = codsTabela.reduce(
      (o, key) => Object.assign(o, { [key]: [] }),
      {}
    );

    const newState = Object.keys(newObject).reduce((acc, key) => {
      const newItem = tablesItens.filter((item) => item.cod_tabela === +key);
      const newAcc = { ...acc, [key]: newItem };
      return newAcc;
    }, {});

    yield put({
      type: TableStructureTypes.SET_TABLE_ITEMS,
      payload: newState,
    });
  } catch (error: any) {
    yield put({
      type: UtilsTypes.SET_TOAST,
      payload: {
        type: "error",
        message: error.message,
      },
    });
  }
}
