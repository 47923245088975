/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BadgeIcon from "@mui/icons-material/Badge";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CloseIcon from "@mui/icons-material/Close";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import HistoryIcon from "@mui/icons-material/History";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";
import LockIcon from "@mui/icons-material/Lock";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import PinOutlinedIcon from "@mui/icons-material/PinOutlined";
import ReportIcon from "@mui/icons-material/Report";
import {
  Alert,
  AlertTitle,
  Grid,
  IconButton,
  Modal,
  Tooltip,
} from "@mui/material";
import { AxiosError } from "axios";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { ButtonPrimary, ButtonSecondary } from "../../components/base/Buttons";
import {
  BoxRevisadaSolicitation,
  BoxStatusSolicitation,
} from "../../components/base/stylesGeneric";
import * as T from "../../components/base/Text";
import { BodyContainer } from "../../components/BodyContainer";
import { DrawerCorrecionSolicitation } from "../../components/DrawerCorrecionSolicitation";
import { DrawerHistoricoSolicitation } from "../../components/DrawerHistoricoSolicitation";
import { AnexosSolicitados } from "../../components/FormSolicitation/AnexosSolicitados";
import { Beneficios } from "../../components/FormSolicitation/Beneficios";
import { DadosComplementares } from "../../components/FormSolicitation/DadosComplementares";
import { DeclaracaoBens } from "../../components/FormSolicitation/DeclaracaoBens";
import { Dependents } from "../../components/FormSolicitation/Dependents";
import { DocsSolicitation } from "../../components/FormSolicitation/DocsSolicitation";
import { IdentificacaoGeral } from "../../components/FormSolicitation/IdentificacaoGeral";
import { ProofOfLife } from "../../components/FormSolicitation/ProofOfLife";
import { VinculosTrabalhistas } from "../../components/FormSolicitation/VinculosTrabalhistas";
import { LoadingIntoComponent } from "../../components/Loading";
import { FinalMessageSolicitation } from "../../components/ModalsComponents/FinalMessageSolicitation";
import { SubmitAnalysisSolicitation } from "../../components/ModalsComponents/SubmitAnalysisSolicitation";
import { ModalTermsSignature } from "../../components/ModalsComponents/TermsSignature";
import { WarningAprovedSolicitation } from "../../components/ModalsComponents/WarningAprovedSolicitation";
import { WarningRevisaoSolicitacao } from "../../components/ModalsComponents/WarningRevisaoSolicitacao";
import { ProgressSolicitation } from "../../components/ProgressSolicitation";
import { useTableStructure } from "../../hooks/useTableStructure";
import { api } from "../../services/api";
import { setStateDefaultSolicitationAction } from "../../store/ducks/Solicitation/actions";
import { UserObjectTypes } from "../../store/ducks/User/types";
import { setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IUserSolicitation } from "../../types/UserSolicitation";
import { cpfMask } from "../../utils/mask";
import {
  ANEXOS,
  BENEFCIOS,
  convertData,
  DADOS_COMPLEMENTARES,
  DECLARACAO_BENS,
  DEPENDENTES,
  detectZoom,
  DOCUMENTOS,
  getIdURLUserSol,
  IDENTIFICACAO,
  PROVA_VIDA,
  VINCULOS_TRABALHISTAS,
} from "../../utils/utils";
import * as S from "./styles";

type EtapasProps = {
  index: number;
  order: number;
  etapa: string;
  title: string;
  isCompleted?: boolean;
  desabled?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  isCorrecion?: boolean;
};

export type FasesProvaVidaProps = {
  order: number;
  fase: string;
  title: string;
  icon: React.ReactNode;
  isCompleted?: boolean;
  desabled?: boolean;
  isCorrecion?: boolean;
};

export type ListCorrecionProps = {
  id: string;
  etapa: string;
  message?: string;
  campo?: string;
  label?: string;
};

type valuesCorrecionProps = {
  dt_operacao: string;
  hr_operacao: string;
  id_sol_usuario: number;
  id_solicit_hist: number;
  id_usuario_envio: number;
  json_data: string;
  msg_despacho: string | null;
  status_registrado: number;
};

type TermSignatureProps = {
  idEnvelope: string;
  hashSHA256: string;
  status: "2" | "3";
  listaDadosSignatarios: {
    DadosSignatarioReenvio: {
      nome: string;
      email: string;
      celular: string;
      linkAcesso: string;
    };
  };
};

export function MySolicitation() {
  const [solicitation, setSolicitation] = useState<IUserSolicitation>(
    {} as IUserSolicitation
  );

  useTableStructure([
    "paises",
    "estados",
    "cidades",
    "estadoCivil",
    "etnia",
    "nacionalidade",
    "escolaridade",
    "deficiencia",
    "typeDependents",
    "cid",
    "typeDocVinculo",
    "regimeTrabalho",
    "corCabelo",
    "corOlhos",
    "regimePrevidenciario",
    "listaSecretarias",
    "listaSetores",
    "listaBeneficios",
  ]);

  const [faseProvaVida, setFaseProvaVida] = useState<FasesProvaVidaProps[]>([]);

  const [expanded, setExpanded] = useState<number[]>([]);
  const [userSolicitation, setUserSolicitation] = useState<UserObjectTypes>();
  const [etapas, setEtapas] = useState<EtapasProps[]>([]);
  const [termSignature, setTermSignature] = useState<TermSignatureProps>(
    {} as TermSignatureProps
  );

  const [
    openModalFinalMessageSolicitation,
    setOpenModalFinalMessageSolicitation,
  ] = useState(false);

  const [openModalAprovedSolicitation, setOpenModalAprovedSolicitation] =
    useState(false);

  const [openModalCorfirmRevisao, setOpenModalCorfirmRevisao] = useState(false);

  const [openModalSubmitAnalyst, setOpenModalSubmitAnalyst] = useState(false);
  const onCloseModalSubmitAnalyst = () => setOpenModalSubmitAnalyst(false);

  const [openModalTermSignatue, setOpenModalTermSignatue] = useState(false);
  const onCloseModalTermSignatue = () => setOpenModalTermSignatue(false);

  const [isOpenCorrecion, setIsOpenCorrecion] = useState(false);
  const [listCorrecion, setListCorrecion] = useState<ListCorrecionProps[]>([]);
  const [valuesCorrecion, setValuesCorrecion] = useState<valuesCorrecionProps>(
    {} as valuesCorrecionProps
  );
  const [correctionValuesJsonData, setCorrectionValuesJsonData] = useState({});

  const onChangeSetListCorrecion = (
    etapa: string,
    campo: string,
    label: string
  ) => {
    if (!isOpenCorrecion) return;

    const list = listCorrecion.find(
      (item) => item.etapa === etapa && item.campo === campo
    );
    if (list) {
      setListCorrecion(
        listCorrecion.filter(
          (item) => item.etapa !== etapa || item.campo !== campo
        )
      );
    } else {
      setListCorrecion([
        ...listCorrecion,
        {
          id: `${etapa}-${campo}`,
          message: "",
          etapa,
          campo,
          label,
        },
      ]);
    }
  };

  const isOnChangeSetListCorrecion = (etapa: string, campo: string) => {
    const list = listCorrecion.find(
      (item) => item.etapa === etapa && item.campo === campo
    );
    if (list) {
      return true;
    }
    return false;
  };

  const onCloseListCorrecion = () => {
    setIsOpenCorrecion(false);
  };

  const [openDrawer, setOpenDrawer] = useState(false);
  const onCloseDrawer = () => {
    setOpenDrawer(false);
    setIsOpenCorrecion(false);
  };

  const [openDrawerHistoric, setOpenDrawerHistoric] = useState(false);
  const onCloseDrawerHistoric = () => {
    setOpenDrawerHistoric(false);
  };

  const handleChangeExpanded = (panel: number) => {
    if (solicitation.isUserSolicitation) {
      if (expanded.includes(panel)) {
        setExpanded([]);
      } else {
        setExpanded([panel]);
      }
    } else if (expanded.includes(panel)) {
      setExpanded(expanded.filter((item) => item !== panel));
    } else {
      setExpanded([...expanded, panel]);
    }
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [device, user] = useAppSelector(({ Utils, User }) => [
    Utils.device,
    User.user,
  ]);

  const filterEtapas = (
    etapas: EtapasProps[],
    correcionData?: valuesCorrecionProps
  ) => {
    const newEtapas = etapas.map((etapa, index) => {
      return {
        ...etapa,
        index: index + 1,
        isFirst: index === 0,
        isLast: index === etapas.length - 1,
        desabled:
          correcionData?.status_registrado !== 2 &&
          index !== 0 &&
          !etapa.isCompleted &&
          !etapas[index - 1]?.isCompleted,
      };
    });
    const expended = newEtapas.filter((etapa) => etapa.isCompleted);
    handleChangeExpanded(Number(expended[expended.length - 1]?.index) + 1);
    setEtapas(newEtapas as EtapasProps[]);
  };

  async function getUser(cpf: number) {
    try {
      const { data: user } = await api.get(
        `/user/get-users?sigla=&cpf=${cpf || ""}`
      );

      setUserSolicitation(user[0]);
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  }

  const getUserSolicitation = useCallback(async () => {
    try {
      const { data } = await api.get(`/solicitation/user/${getIdURLUserSol()}`);
      setSolicitation(data.solicitation);
      filterEtapas(data.etapas, data?.correcion);

      if (data.fasesProvaVida?.length) {
        setFaseProvaVida(data.fasesProvaVida);
      }

      if (data.solicitation.isUserAnalyst || data.solicitation.isUserOperador) {
        getUser(data.solicitation.numero_cpf);
      } else {
        setUserSolicitation(user);
      }

      if (data?.correcion?.json_data) {
        setCorrectionValuesJsonData(data.correcion?.json_data);
        setValuesCorrecion(data.correcion);
        setListCorrecion(Object.values(data?.correcion?.json_data));
      } else if (!data?.correcion?.json_data) {
        setListCorrecion([]);
        setCorrectionValuesJsonData([]);
        setValuesCorrecion({} as valuesCorrecionProps);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  }, []);

  const calcProgress = (etapas: EtapasProps[]) => {
    const completed = etapas.filter((etapa) => etapa.isCompleted);
    const progress = Math.round((completed.length / etapas.length) * 100);
    return {
      completed,
      progress,
    };
  };

  const getTermData = async () => {
    try {
      const { data: response } = await api.get(
        `/solicitation/signature-terms/${Number(getIdURLUserSol())}`
      );

      if (Number(response.status) === 3) setTermSignature(response);
      // eslint-disable-next-line no-empty
    } catch (err) {}
  };

  const downloadTerm = async () => {
    try {
      const { data: response } = await api.get(
        `/solicitation/data/signature-terms/${Number(getIdURLUserSol())}`
      );

      if (response?.mimeType) {
        const link = document.createElement("a");
        link.setAttribute(
          "download",
          response.nomeArquivo || "termo-de-preenchimento.pdf"
        );
        link.setAttribute(
          "href",
          `data:${response.mimeType};base64,${response.envelopeContent}`
        );
        link.click();
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  };

  useEffect(() => {
    getUserSolicitation();
    getTermData();
    return () => {
      dispatch(setStateDefaultSolicitationAction());
    };
  }, []);

  useEffect(() => {
    if (solicitation?.msg_fim_preench)
      setOpenModalFinalMessageSolicitation(true);
  }, [solicitation?.msg_fim_preench]);

  return (
    <BodyContainer menu="Solicitações">
      <Grid item xs={12}>
        <Tooltip title="Voltar">
          <IconButton onClick={() => navigate(-1)}>
            <KeyboardReturnIcon />
          </IconButton>
        </Tooltip>
      </Grid>
      <Grid
        container
        spacing={1}
        paddingLeft="1rem"
        borderLeft="4px solid #ff6e07"
        margin="1rem 0 1.5rem 0"
        maxWidth="100%"
      >
        {isOpenCorrecion && (
          <>
            <S.BackDrop />
            <S.BackDropContent>
              <div>
                <T.Paragraph>
                  Selecione os itens que serão corrigidos
                  <IconButton onClick={onCloseListCorrecion} color="inherit">
                    <CloseIcon fontSize="small" color="inherit" />
                  </IconButton>
                </T.Paragraph>
                <div
                  onClick={() => {
                    setIsOpenCorrecion(false);
                    setOpenDrawer(true);
                  }}
                >
                  CONFIRMAR
                </div>
              </div>
            </S.BackDropContent>
          </>
        )}
        <Grid item xs={12} display="flex" justifyContent="space-between">
          <T.H2 margin="0">{solicitation?.descr_solicitacao}</T.H2>
          {solicitation.status_solic_usuario && (
            <BoxStatusSolicitation
              status={solicitation.status_solic_usuario_item}
            >
              {solicitation.status_solic_usuario}
            </BoxStatusSolicitation>
          )}
          {solicitation.isSolicitationRevisada && (
            <BoxRevisadaSolicitation style={{ marginLeft: "1rem" }}>
              REVISADA
            </BoxRevisadaSolicitation>
          )}
        </Grid>
        <Grid item xs={12} md={8}>
          <T.H6>{solicitation.observacoes}</T.H6>
        </Grid>
        {solicitation.isUserAnalyst && solicitation.isSolicitationAnalyst && (
          <Grid
            item
            xs={12}
            md={4}
            display="flex"
            justifyContent="flex-end"
            gap="0.5rem"
          >
            {!solicitation.isSolicitationRevisada && (
              <ButtonSecondary
                onClick={() => setIsOpenCorrecion(!isOpenCorrecion)}
                style={{ height: "max-content" }}
              >
                Solicita correção
              </ButtonSecondary>
            )}
            {solicitation.status_solic_usuario_item === 3 && (
              <ButtonPrimary
                style={{ height: "max-content" }}
                onClick={() => setOpenModalAprovedSolicitation(true)}
              >
                APROVAR SOLICITAÇÃO
              </ButtonPrimary>
            )}
            {solicitation.status_solic_usuario_item === 4 &&
              !solicitation.isSolicitationRevisada && (
                <ButtonPrimary
                  style={{ height: "max-content" }}
                  onClick={() => setOpenModalCorfirmRevisao(true)}
                >
                  CONCLUÍR REVISÃO
                </ButtonPrimary>
              )}
          </Grid>
        )}
        {calcProgress(etapas).progress === 100 &&
        solicitation.isUserSolicitation &&
        Number(solicitation.status_solic_usuario_item) === 2 ? (
          <Grid item xs={12} md={4} display="flex" justifyContent="flex-end">
            <ButtonPrimary
              style={{ height: "max-content" }}
              onClick={() => {
                if (calcProgress(etapas).progress !== 100) {
                  dispatch(
                    setToastMessage({
                      type: "error",
                      message: "Preencha todas as etapas",
                    })
                  );
                  return;
                }
                if (
                  solicitation.isTermIntegrationAstenAssinatura &&
                  Number(termSignature?.status) !== 3
                ) {
                  dispatch(
                    setToastMessage({
                      type: "error",
                      message: "Assine o Termo de Preenchimento",
                    })
                  );
                  return;
                }
                setOpenModalSubmitAnalyst(true);
              }}
            >
              Enviar para analise
            </ButtonPrimary>
          </Grid>
        ) : null}
        {solicitation.isTermIntegrationAstenAssinatura &&
        solicitation.isUserSolicitation &&
        (calcProgress(etapas).progress === 100 ||
          Number(termSignature?.status) === 3) ? (
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <ButtonSecondary
              variant="outlined"
              onClick={() => setOpenModalTermSignatue(true)}
            >
              Termo de Preenchimento
            </ButtonSecondary>
          </Grid>
        ) : null}
        {solicitation.isTermIntegrationAstenAssinatura &&
        solicitation.isUserAnalyst &&
        Number(termSignature?.status) === 3 ? (
          <Grid item xs={12} display="flex" justifyContent="flex-end">
            <ButtonSecondary variant="outlined" onClick={() => downloadTerm()}>
              Donwload Termo de Preenchimento
            </ButtonSecondary>
          </Grid>
        ) : null}
      </Grid>
      <S.BoxDetails
        container
        spacing={1}
        paddingLeft="1rem"
        margin="3rem 0 1.5rem 0"
        maxWidth="100%"
      >
        {solicitation.isUserSolicitation ? (
          <>
            <Grid item xs={12} md={4} display="flex" alignItems="center">
              <PinOutlinedIcon color="action" />
              <T.Paragraph margin="0 0 0 0.5rem">
                Protocolo:
                <T.Span weight="600" margin="0 0 0 0.25rem">
                  {solicitation?.protocolo_preenchimento}
                </T.Span>
              </T.Paragraph>
            </Grid>
            <Grid item xs={12} md={4} display="flex" alignItems="center">
              <ContentPasteSearchIcon color="action" />
              <T.Paragraph margin="0 0 0 0.5rem">
                Tipo de solicitação:
                <T.Span weight="600" margin="0 0 0 0.25rem">
                  {solicitation?.tipo_solicitacao_item}
                </T.Span>
              </T.Paragraph>
            </Grid>
            <Grid item xs={12} md={2} display="flex" alignItems="center">
              <AccessTimeIcon color="action" />
              <T.Paragraph margin="0 0 0 0.5rem">
                Data Inicial:
                <T.Span weight="600" margin="0 0 0 0.25rem">
                  {convertData(solicitation.dt_inicio_preench) || " - "}
                </T.Span>
              </T.Paragraph>
            </Grid>
            <Grid item xs={12} md={2} display="flex" alignItems="center">
              <AccessTimeIcon color="action" />
              <T.Paragraph margin="0 0 0 0.5rem">
                Data Final:
                <T.Span weight="600" margin="0 0 0 0.25rem">
                  {convertData(solicitation.dt_fim_preench) || " - "}
                </T.Span>
              </T.Paragraph>
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={4} display="flex" alignItems="center">
              <ContentPasteSearchIcon color="action" />
              <T.Paragraph margin="0 0 0 0.5rem">
                Protocolo:
                <T.Span weight="600" margin="0 0 0 0.25rem">
                  {solicitation?.protocolo_preenchimento}
                </T.Span>
              </T.Paragraph>
            </Grid>
            <Grid item xs={12} md={3} display="flex" alignItems="center">
              <PermIdentityIcon color="action" />
              <T.Paragraph margin="0 0 0 0.5rem">
                Nome:
                <T.Span weight="600" margin="0 0 0 0.25rem">
                  {userSolicitation?.nome}
                </T.Span>
              </T.Paragraph>
            </Grid>
            <Grid item xs={12} md={3} display="flex" alignItems="center">
              <MailOutlineIcon color="action" />
              <T.Paragraph margin="0 0 0 0.5rem">
                Email:
                <T.Span weight="600" margin="0 0 0 0.25rem">
                  {userSolicitation?.email}
                </T.Span>
              </T.Paragraph>
            </Grid>
            <Grid item xs={12} md={2} display="flex" alignItems="center">
              <BadgeIcon color="action" />
              <T.Paragraph margin="0 0 0 0.5rem">
                CPF:
                <T.Span weight="600" margin="0 0 0 0.25rem">
                  {cpfMask(String(userSolicitation?.numero_cpf))}
                </T.Span>
              </T.Paragraph>
            </Grid>
          </>
        )}
      </S.BoxDetails>
      {valuesCorrecion.msg_despacho &&
        [2, 3].includes(valuesCorrecion.status_registrado) && (
          <S.BoxDetails container padding="0.8rem 1.5rem" maxWidth="100%">
            <Grid item xs={12} display="flex" alignItems="center">
              <ReportIcon color="warning" fontSize="small" />
              <T.Paragraph margin="0 0 0 0.5rem" weight="600">
                Observação da análise:
                <T.Span
                  margin="0 0 0 0.25rem"
                  style={{ whiteSpace: "break-spaces" }}
                >
                  {valuesCorrecion.msg_despacho}
                </T.Span>
              </T.Paragraph>
            </Grid>
          </S.BoxDetails>
        )}
      <Grid container spacing={1} margin="1rem 0 1.5rem 0" maxWidth="100%">
        {solicitation.isUserAnalyst &&
          [2, 3].includes(valuesCorrecion.status_registrado) && (
            <Grid item xs={12}>
              <T.H6 lineHeight="1rem">
                {valuesCorrecion.status_registrado === 2
                  ? "Solicitação foi enviada para correção, aguarde a resposta do usuario!"
                  : "Solicitação corrigida pelo usuario!"}
              </T.H6>
            </Grid>
          )}
        {solicitation.isUserSolicitation && (
          <>
            <Grid item xs={12}>
              <T.H6 lineHeight="1rem">
                Acompanhe abaixo o andamento de cada etapa da sua solicitação.
              </T.H6>
            </Grid>
            <Grid item xs={12}>
              <ProgressSolicitation
                progress={calcProgress(etapas).progress}
                countComplete={calcProgress(etapas).completed.length}
                count={etapas.length}
              />
            </Grid>
          </>
        )}
        <Grid
          item
          xs={12}
          marginTop={2}
          paddingLeft={`${
            solicitation.isUserSolicitation
              ? device === "desktop"
                ? "3.125rem !important"
                : "1.5rem !important"
              : "initial"
          }`}
          maxWidth="100%"
        >
          {etapas.length ? (
            etapas.map((etapa, index) => (
              <Fragment key={etapa.etapa}>
                <S.AccordionConteiner
                  fist={etapa.isFirst}
                  last={etapa.isLast}
                  desabled={etapa.desabled && solicitation.isUserSolicitation}
                  isUserSolicitation={solicitation.isUserSolicitation}
                >
                  <S.AccordionContent
                    expanded={expanded.includes(etapa.index)}
                    onChange={() => handleChangeExpanded(etapa.index)}
                  >
                    <S.AccordionHeaderContaniner
                      expandIcon={<ExpandMoreIcon />}
                      id={`panel1bh-header-${index}`}
                    >
                      <S.AccordionHeaderContent
                        style={{
                          ...(!etapa.isCompleted &&
                            !etapa.isCorrecion &&
                            !etapa.desabled && {
                              paddingLeft: "35px",
                            }),
                          ...(solicitation.isUserAnalyst &&
                            !etapa.isCorrecion && {
                              paddingLeft: "35px",
                            }),
                        }}
                      >
                        {solicitation.isUserSolicitation &&
                          etapa.isCompleted &&
                          !etapa.isCorrecion && (
                            <CheckCircleIcon color="success" fontSize="small" />
                          )}
                        {etapa.isCorrecion && !etapa.isCompleted && (
                          <ReportIcon color="warning" fontSize="small" />
                        )}
                        {solicitation.isUserSolicitation &&
                          !etapa.isCompleted &&
                          etapa.desabled && (
                            <LockIcon color="disabled" fontSize="small" />
                          )}
                        <T.H5
                          size="1.375rem"
                          color={
                            expanded.includes(etapa.index)
                              ? "#006494"
                              : "#8c8c8c"
                          }
                        >
                          {etapa.title}
                        </T.H5>
                      </S.AccordionHeaderContent>
                      {solicitation.isUserSolicitation && (
                        <S.StepperPointer desabled={etapa.desabled}>
                          {etapa.index}
                        </S.StepperPointer>
                      )}
                    </S.AccordionHeaderContaniner>
                    <S.AccordionBody>
                      {etapa.etapa === IDENTIFICACAO && (
                        <IdentificacaoGeral
                          isEditable={solicitation.isEdit}
                          getUserSolicitation={getUserSolicitation}
                          isCompleted={!!etapa.isCompleted}
                          isOpenCorrecion={isOpenCorrecion}
                          onChangeSetListCorrecion={onChangeSetListCorrecion}
                          isOnChangeSetListCorrecion={
                            isOnChangeSetListCorrecion
                          }
                          correctionValuesJsonData={correctionValuesJsonData}
                        />
                      )}
                      {etapa.etapa === DADOS_COMPLEMENTARES && (
                        <DadosComplementares
                          isEditable={solicitation.isEdit}
                          getUserSolicitation={getUserSolicitation}
                          isCompleted={!!etapa.isCompleted}
                          isOpenCorrecion={isOpenCorrecion}
                          onChangeSetListCorrecion={onChangeSetListCorrecion}
                          isOnChangeSetListCorrecion={
                            isOnChangeSetListCorrecion
                          }
                          correctionValuesJsonData={correctionValuesJsonData}
                        />
                      )}
                      {etapa.etapa === DOCUMENTOS && (
                        <DocsSolicitation
                          isEditable={solicitation.isEdit}
                          getUserSolicitation={getUserSolicitation}
                          isCompleted={!!etapa.isCompleted}
                          isOpenCorrecion={isOpenCorrecion}
                          onChangeSetListCorrecion={onChangeSetListCorrecion}
                          isOnChangeSetListCorrecion={
                            isOnChangeSetListCorrecion
                          }
                          correctionValuesJsonData={correctionValuesJsonData}
                        />
                      )}
                      {etapa.etapa === DEPENDENTES && (
                        <Dependents
                          isEditable={solicitation.isEdit}
                          getUserSolicitation={getUserSolicitation}
                          isCompleted={!!etapa.isCompleted}
                          isOpenCorrecion={isOpenCorrecion}
                          onChangeSetListCorrecion={onChangeSetListCorrecion}
                          isOnChangeSetListCorrecion={
                            isOnChangeSetListCorrecion
                          }
                          correctionValuesJsonData={correctionValuesJsonData}
                          statusCorrecion={valuesCorrecion.status_registrado}
                        />
                      )}
                      {etapa.etapa === VINCULOS_TRABALHISTAS && (
                        <VinculosTrabalhistas
                          isEditable={solicitation.isEdit}
                          getUserSolicitation={getUserSolicitation}
                          isCompleted={!!etapa.isCompleted}
                          isOpenCorrecion={isOpenCorrecion}
                          onChangeSetListCorrecion={onChangeSetListCorrecion}
                          isOnChangeSetListCorrecion={
                            isOnChangeSetListCorrecion
                          }
                          correctionValuesJsonData={correctionValuesJsonData}
                          statusCorrecion={valuesCorrecion.status_registrado}
                        />
                      )}
                      {etapa.etapa === BENEFCIOS && (
                        <Beneficios
                          isEditable={solicitation.isEdit}
                          getUserSolicitation={getUserSolicitation}
                          isCompleted={!!etapa.isCompleted}
                          isOpenCorrecion={isOpenCorrecion}
                          onChangeSetListCorrecion={onChangeSetListCorrecion}
                          isOnChangeSetListCorrecion={
                            isOnChangeSetListCorrecion
                          }
                          correctionValuesJsonData={correctionValuesJsonData}
                          statusCorrecion={valuesCorrecion.status_registrado}
                        />
                      )}
                      {etapa.etapa === DECLARACAO_BENS && (
                        <DeclaracaoBens
                          isEditable={solicitation.isEdit}
                          getUserSolicitation={getUserSolicitation}
                          isCompleted={!!etapa.isCompleted}
                          isOpenCorrecion={isOpenCorrecion}
                          onChangeSetListCorrecion={onChangeSetListCorrecion}
                          isOnChangeSetListCorrecion={
                            isOnChangeSetListCorrecion
                          }
                          correctionValuesJsonData={correctionValuesJsonData}
                          statusCorrecion={valuesCorrecion.status_registrado}
                        />
                      )}
                      {etapa.etapa === ANEXOS && (
                        <AnexosSolicitados
                          isEditable={solicitation.isEdit}
                          getUserSolicitation={getUserSolicitation}
                          isCompleted={!!etapa.isCompleted}
                          isOpenCorrecion={isOpenCorrecion}
                          onChangeSetListCorrecion={onChangeSetListCorrecion}
                          isOnChangeSetListCorrecion={
                            isOnChangeSetListCorrecion
                          }
                          correctionValuesJsonData={correctionValuesJsonData}
                        />
                      )}
                      {etapa.etapa === PROVA_VIDA && (
                        <ProofOfLife
                          isEditable={solicitation.isEdit}
                          getUserSolicitation={getUserSolicitation}
                          userSolicitation={userSolicitation!}
                          faseProvaVida={faseProvaVida}
                          isOpenCorrecion={isOpenCorrecion}
                          onChangeSetListCorrecion={onChangeSetListCorrecion}
                          isOnChangeSetListCorrecion={
                            isOnChangeSetListCorrecion
                          }
                          correctionValuesJsonData={correctionValuesJsonData}
                          isUserAnalyst={solicitation.isUserAnalyst}
                        />
                      )}
                    </S.AccordionBody>
                  </S.AccordionContent>
                </S.AccordionConteiner>
                {index !== etapas.length - 1 && (
                  <S.DividerAccordion
                    isUserSolicitation={solicitation.isUserSolicitation}
                  />
                )}
              </Fragment>
            ))
          ) : (
            <LoadingIntoComponent />
          )}
        </Grid>
        {solicitation.isUserAnalyst && solicitation.isSolicitationAnalyst && (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="flex-end"
            gap="0.5rem"
            marginTop={2}
          >
            {!solicitation.isSolicitationRevisada && (
              <ButtonSecondary
                onClick={() => setIsOpenCorrecion(!isOpenCorrecion)}
              >
                Solicita correção
              </ButtonSecondary>
            )}
            {solicitation.status_solic_usuario_item === 3 && (
              <ButtonPrimary
                style={{ height: "max-content" }}
                onClick={() => setOpenModalAprovedSolicitation(true)}
              >
                APROVAR SOLICITAÇÃO
              </ButtonPrimary>
            )}
            {solicitation.status_solic_usuario_item === 4 &&
              !solicitation.isSolicitationRevisada && (
                <ButtonPrimary
                  style={{ height: "max-content" }}
                  onClick={() => setOpenModalCorfirmRevisao(true)}
                >
                  CONCLUÍR REVISÃO
                </ButtonPrimary>
              )}
          </Grid>
        )}
        {calcProgress(etapas).progress === 100 &&
        solicitation.isUserSolicitation &&
        Number(solicitation.status_solic_usuario_item) === 2 ? (
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="flex-end"
            marginTop={2}
          >
            <ButtonPrimary
              onClick={() => {
                if (calcProgress(etapas).progress !== 100) {
                  dispatch(
                    setToastMessage({
                      type: "error",
                      message: "Preencha todas as etapas",
                    })
                  );
                  return;
                }
                if (
                  solicitation.isTermIntegrationAstenAssinatura &&
                  Number(termSignature?.status) !== 3
                ) {
                  dispatch(
                    setToastMessage({
                      type: "error",
                      message: "Assine o Termo de Preenchimento",
                    })
                  );
                  return;
                }
                setOpenModalSubmitAnalyst(true);
              }}
            >
              Enviar para analise
            </ButtonPrimary>
          </Grid>
        ) : null}
      </Grid>
      {solicitation.isUserAnalyst && (
        <>
          <DrawerCorrecionSolicitation
            openDrawer={openDrawer}
            onCloseDrawer={onCloseDrawer}
            listCorrecion={listCorrecion}
            getUserSolicitation={getUserSolicitation}
          />
          <WarningAprovedSolicitation
            isOpen={openModalAprovedSolicitation}
            onClose={() => setOpenModalAprovedSolicitation(false)}
            getUserSolicitation={getUserSolicitation}
            idUserSolicitation={Number(getIdURLUserSol())}
          />
          <WarningRevisaoSolicitacao
            isOpen={openModalCorfirmRevisao}
            onClose={() => setOpenModalCorfirmRevisao(false)}
            getUserSolicitation={getUserSolicitation}
            idUserSolicitation={Number(getIdURLUserSol())}
          />
        </>
      )}
      {solicitation.isUserSolicitation && (
        <>
          <SubmitAnalysisSolicitation
            isOpen={openModalSubmitAnalyst}
            onClose={onCloseModalSubmitAnalyst}
            idSolicitation={Number(getIdURLUserSol())}
            getSolicitation={getUserSolicitation}
            correctionValuesJsonData={correctionValuesJsonData}
          />
          <ModalTermsSignature
            isOpen={openModalTermSignatue}
            onClose={onCloseModalTermSignatue}
            idSolicitation={Number(getIdURLUserSol())}
            setTermSignature={setTermSignature}
            termSignature={termSignature}
            downloadTerm={downloadTerm}
          />
          <FinalMessageSolicitation
            isOpen={openModalFinalMessageSolicitation}
            onClose={() => setOpenModalFinalMessageSolicitation(false)}
            message={solicitation.msg_fim_preench!}
          />
        </>
      )}
      {[2, 3, 4, 5].includes(valuesCorrecion.status_registrado) ||
      calcProgress(etapas).progress === 100 ? (
        <>
          <S.ButtonHistoric onClick={() => setOpenDrawerHistoric(true)}>
            <S.ButtonHistoricContent>
              <HistoryIcon />
              <div>HISTORICO DE INTERAÇÕES</div>
            </S.ButtonHistoricContent>
          </S.ButtonHistoric>
          <DrawerHistoricoSolicitation
            openDrawer={openDrawerHistoric}
            onCloseDrawer={onCloseDrawerHistoric}
            idSolicitation={Number(getIdURLUserSol())}
          />
        </>
      ) : null}
      {detectZoom() > 100 && (
        <Modal open={detectZoom() > 110 && device === "desktop"}>
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            width="70%"
            margin="auto"
            height="100%"
          >
            <Alert severity="warning" sx={{ width: "100%" }}>
              <AlertTitle>Ajuste o zoom do seu navegador</AlertTitle>
              Para uma melhor experiência, ajuste o zoom do seu navegador para
              um nível menor. <br />
              Vá até as configurações do seu navegador e diminua o zoom, ou
              pressione a tecla <strong>Ctrl</strong> e a tecla{" "}
              <strong>-(Sinal de menos)</strong> até encontrar um nível de zoom
              confortável.
            </Alert>
          </Grid>
        </Modal>
      )}
    </BodyContainer>
  );
}
