/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../../services/api";
import { getClientsArrayAction } from "../../../../store/ducks/Clients/actions";
import {
  setLoading,
  setToastMessage,
} from "../../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { convertSOrN, removeMask } from "../../../../utils/utils";
import { ButtonPrimary } from "../../../base/Buttons";
import { CardPrimery } from "../../../base/Cards";
import * as T from "../../../base/Text";
import { InputCheckBox } from "../../../Inputs/inputCheckBox";
import { InputBase } from "../../../Inputs/InputText";

type ClientProps = {
  nome_cliente: string;
  numero_cnpj: string;
  sigla: string;
  ind_ativo: "S" | "N";
  ind_login_cpf: string;
  ind_permitir_criar_conta: "S" | "N";
  schema_base: string;
  token_asten_assinatura: string;
  ind_agendamento: "S" | "N";
  ind_verificar_email: "S" | "N";
};

export function System({ isFormNewClient }: { isFormNewClient: boolean }) {
  const { handleSubmit, control, reset, setError } = useForm<ClientProps>();

  const dispatch = useAppDispatch();

  const { clientToUpdate } = useAppSelector(({ Clients }) => Clients);
  const { user } = useAppSelector((state) => state.User);

  const onSubmit: SubmitHandler<ClientProps> = async (fields) => {
    try {
      dispatch(setLoading(true));
      let response;

      if (isFormNewClient) {
        response = await api.post("/client/create", {
          nome_cliente: fields.nome_cliente,
          numero_cnpj: removeMask(fields.numero_cnpj),
          ind_ativo: convertSOrN(fields.ind_ativo),
          ind_login_cpf: convertSOrN(fields.ind_login_cpf),
          ind_verificar_email: convertSOrN(fields.ind_verificar_email),
          ind_permitir_criar_conta: convertSOrN(
            fields.ind_permitir_criar_conta
          ),
          schema_base: fields.schema_base,
          sigla: fields.sigla,
          ind_agendamento: convertSOrN(fields.ind_agendamento),
          ...(fields?.token_asten_assinatura && {
            token_asten_assinatura: fields.token_asten_assinatura,
          }),
        });
        dispatch(getClientsArrayAction());
      } else {
        response = await api.put(
          `/client/update/${clientToUpdate.id_cliente}`,
          {
            nome_cliente: fields.nome_cliente,
            numero_cnpj: removeMask(fields.numero_cnpj),
            ind_ativo: convertSOrN(fields.ind_ativo),
            ind_login_cpf: convertSOrN(fields.ind_login_cpf),
            ind_verificar_email: convertSOrN(fields.ind_verificar_email),
            ind_permitir_criar_conta: convertSOrN(
              fields.ind_permitir_criar_conta
            ),
            ind_agendamento: convertSOrN(fields.ind_agendamento),
            ...(fields?.token_asten_assinatura && {
              token_asten_assinatura: fields.token_asten_assinatura,
            }),
          }
        );
      }
      dispatch(
        setToastMessage({ type: "success", message: response?.data?.message })
      );
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
      if (error.errors?.length) {
        error.errors.forEach((error: any) => {
          setError(error.property, {
            type: "manual",
            message: error.error,
          });
        });
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(() => {
    reset(clientToUpdate);
  }, [clientToUpdate]);

  useEffect(() => {
    if (isFormNewClient)
      reset({
        nome_cliente: "",
        numero_cnpj: "",
        ind_ativo: "N",
        schema_base: "",
        sigla: "",
        ind_agendamento: "N",
        ind_verificar_email: "S",
        ind_login_cpf: "N",
        ind_permitir_criar_conta: "N",
        token_asten_assinatura: "",
      });
  }, [isFormNewClient]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      style={{ width: "100%" }}
    >
      <CardPrimery container spacing={1}>
        <Grid item xs={12}>
          <T.H6>Dados Gerais</T.H6>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputBase
            control={control}
            id="nome_cliente"
            label="Nome"
            name="nome_cliente"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputBase
            control={control}
            id="numero_cnpj"
            label="CNPJ"
            name="numero_cnpj"
            type="cnpj"
            required
          />
        </Grid>
        {user.perfil_usuario === 4 && (
          <Grid item xs={12} md={2}>
            <InputCheckBox
              control={control}
              id="ind_ativo"
              label="Ativo"
              name="ind_ativo"
            />
          </Grid>
        )}
        <Grid item xs={12} md={2}>
          <InputCheckBox
            control={control}
            id="ind_permitir_criar_conta"
            label="Permitir criar conta"
            name="ind_permitir_criar_conta"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputCheckBox
            control={control}
            id="ind_verificar_email"
            label="Permitir acesso somente com email verificado"
            name="ind_verificar_email"
          />
        </Grid>
        <Grid item xs={12} md={3}>
          <InputCheckBox
            control={control}
            id="ind_login_cpf"
            label="Login com CPF quando houver uma solicitação de para preenchimento"
            name="ind_login_cpf"
          />
        </Grid>
        <Grid item xs={6} md={2}>
          <InputBase
            control={control}
            id="sigla"
            label="Sigla"
            name="sigla"
            readOnly={!isFormNewClient}
          />
        </Grid>
        {user.perfil_usuario === 4 && (
          <>
            <Grid item xs={6} md={2}>
              <InputBase
                control={control}
                id="schema_base"
                label="Schema Base"
                name="schema_base"
                readOnly={!isFormNewClient}
              />
            </Grid>
            <Grid item xs={12} md={3}>
              <InputCheckBox
                control={control}
                id="ind_agendamento"
                label="Ativar agendamento presencial"
                name="ind_agendamento"
              />
            </Grid>
          </>
        )}

        <Grid item xs={12} md={12}>
          <InputBase
            control={control}
            id="token_asten_assinatura"
            label="Token Asten Assinatura"
            name="token_asten_assinatura"
          />
        </Grid>

        <Grid item xs={12} display="flex" justifyContent="flex-end">
          {isFormNewClient ? (
            <ButtonPrimary type="submit">Cria</ButtonPrimary>
          ) : (
            <ButtonPrimary type="submit">Salvar</ButtonPrimary>
          )}
        </Grid>
      </CardPrimery>
    </form>
  );
}
