import styled, { css } from "styled-components";

export const ModalBody = styled.div`
  width: 70vw;
  height: min-content;
  padding: 10px 10% 20px 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    width: 100vw;
    padding: 10px 5% 15px 5%;
  }
`;

export const BoxContentVideo = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px 0;
`;

export const BoxMessageUser = styled.div`
  ${({ theme }) => css`
    position: absolute;
    bottom: 5%;
    left: 0;
    z-index: 2;
    width: 100%;
    height: min-content;

    h6 {
      text-align: center;
      color: ${theme.background.paperDark};
      transition: all 0.3s ease-in-out;
    }
  `}
`;
