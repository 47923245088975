/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-empty */
/* eslint-disable no-nested-ternary */
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import ReportIcon from "@mui/icons-material/Report";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WorkIcon from "@mui/icons-material/Work";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setItemAction } from "../../../store/ducks/TableStructure/actions";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { cnpjMask, cpfMask } from "../../../utils/mask";
import {
  convertData,
  createObjectValNotNull,
  getIdURLUserSol,
  VINCULOS_TRABALHISTAS,
} from "../../../utils/utils";
import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import { InputDate } from "../../Inputs/InputDate";
import { InputRadios } from "../../Inputs/InputRadios";
import { InputSelect } from "../../Inputs/InputSelect";
import { InputBase } from "../../Inputs/InputText";
import { AddVinculo } from "../../ModalsComponents/AddVinculo";
import { WarningNotVinculoCadastrado } from "../../ModalsComponents/WarningNotVinculoCadastrado";
import * as S from "../styles";

type IVinculosTrabalhistasProps = {
  isEditable: boolean;
  getUserSolicitation(): Promise<void>;
  isCompleted: boolean;
  isOpenCorrecion: boolean;
  isOnChangeSetListCorrecion: (etapa: string, campo: string) => boolean;
  onChangeSetListCorrecion: (
    etapa: string,
    campo: string,
    label: string
  ) => void;
  correctionValuesJsonData: any;
  statusCorrecion: number;
};

export function VinculosTrabalhistas({
  isEditable,
  getUserSolicitation,
  isCompleted,
  isOpenCorrecion,
  isOnChangeSetListCorrecion,
  onChangeSetListCorrecion,
  correctionValuesJsonData,
  statusCorrecion,
}: IVinculosTrabalhistasProps) {
  const { handleSubmit, control, getValues, setValue, reset } = useForm<any>();

  const [vinculosTrabValue, setVinculosTrabValue] = useState({} as any);
  const [outrosEmpregos, setOutrosEmpregos] = useState([]);

  const [indTrabalhoEmpOrgaoPub, setIndTrabalhoEmpOrgaoPub] = useState("N");
  const [indVincMunicipal, setIndVincMunicipal] = useState("N");
  const [typeDocument, setTypeDocument] = useState("");
  const [regimePrevi, setRegimePrevi] = useState(0);

  const dispatch = useAppDispatch();

  const [openModalVinculosTrabalhistas, setOpenModalVinculosTrabalhistas] =
    useState<Object | boolean>(false);

  const [
    openModalConfirmaNaoCadastraVinculos,
    setOpenModalConfirmaNaoCadastraVinculos,
  ] = useState({
    confirmNaoCadastraVinculo: false,
    isOpenModal: false,
  });

  const {
    typeDocVinculo,
    regimeTrabalho,
    regimePrevidenciario,
    listaSecretarias,
    listaSetores,
  } = useAppSelector(({ TableStructure }) => TableStructure);

  const setDataValuesOutrosVinculos = (outrosVinculos: any) => {
    setTimeout(() => {
      Object.keys(outrosVinculos).forEach((key) => {
        if (key === "tipo_doc_vinc_item") {
          setTypeDocument(
            Number(outrosVinculos[key]) === 1
              ? "cpf"
              : Number(outrosVinculos[key]) === 2
              ? "cnpj"
              : ""
          );
          setValue(key, outrosVinculos[key]);
        }
        setValue(key, outrosVinculos[key]);
      });
    }, 500);
  };

  const getData = async () => {
    try {
      const { data } = await api.get(
        `/solicitation/vinculos/${getIdURLUserSol()}`
      );

      setVinculosTrabValue(data);
      reset({
        prim_emprego: data.prim_emprego || "N",
        trab_orgao_pub: data.trab_orgao_pub || "N",
        ind_vinc_municipal: data.ind_vinc_municipal || "N",
      });
      if (data.trab_orgao_pub === "S") {
        setIndTrabalhoEmpOrgaoPub("S");
        setRegimePrevi(data?.outros_vinculos?.regime_previdenciario_item);
        setDataValuesOutrosVinculos(data.outros_vinculos);
        if (data.outros_vinculos?.ind_vinc_municipal === "S")
          setIndVincMunicipal("S");
      }
      setOutrosEmpregos(data?.outros_empregos ? data.outros_empregos : []);
    } catch (error) {}
  };

  const onSubmit = async (body: any) => {
    try {
      const newVinculosTrab: any = createObjectValNotNull(body);

      // Abrir modal para confirmar se o usuário não quer cadastrar vinculos trabalhistas
      if (
        !openModalConfirmaNaoCadastraVinculos.confirmNaoCadastraVinculo &&
        !outrosEmpregos.length
      ) {
        setOpenModalConfirmaNaoCadastraVinculos({
          confirmNaoCadastraVinculo: false,
          isOpenModal: true,
        });
        return;
      }

      await api.post(
        `/solicitation/vinculos/${getIdURLUserSol()}/${
          vinculosTrabValue.id_sol_dados_vinc_trab || ""
        }`,
        {
          ...newVinculosTrab,
          ...(newVinculosTrab?.data_inicio && {
            data_inicio: convertData(newVinculosTrab.data_inicio),
          }),
          ...(newVinculosTrab?.data_final && {
            data_final: convertData(newVinculosTrab.data_final),
          }),
          commited: "S",
        }
      );

      getData();
      await getUserSolicitation();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const saveData = async () => {
    try {
      const body: any = createObjectValNotNull(getValues());
      if (!Object.keys(body).length) {
        dispatch(
          setToastMessage({
            type: "error",
            message: "Preencha os campos para salvar",
          })
        );
        return;
      }

      const newVinculosTrab = body;

      const { data } = await api.post(
        `/solicitation/vinculos/${getIdURLUserSol()}/${
          vinculosTrabValue.id_sol_dados_vinc_trab || ""
        }`,
        {
          ...newVinculosTrab,
          ...(newVinculosTrab?.data_inicio && {
            data_inicio: convertData(newVinculosTrab.data_inicio),
          }),
          ...(newVinculosTrab?.data_final && {
            data_final: convertData(newVinculosTrab.data_final),
          }),
          tipo_vinculo: 2,
        }
      );

      getData();
      if (data?.message)
        dispatch(setToastMessage({ type: "success", message: data.message }));
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const deleteVinc = async (idVinc: number) => {
    try {
      const { data } = await api.delete(
        `/solicitation/vinculo/${getIdURLUserSol()}/${idVinc}`
      );

      getData();
      if (data?.message)
        dispatch(setToastMessage({ type: "success", message: data.message }));
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  useEffect(() => {
    if (!vinculosTrabValue.id_sol_usuario) {
      getData();
    }
  }, []);

  const clearValuesForm = (values: any[]) => {
    values.forEach((value) => setValue(value, ""));
  };

  return (
    <Grid container spacing={1} padding="1rem">
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        style={{ width: "100%", height: "100%" }}
      >
        <Grid container spacing={1} style={{ width: "100%" }}>
          <Grid item xs={12} md={12}>
            <T.H6>DETALHAR VÍNCULOS ATUAL E ANTERIORES (SE HOUVER)</T.H6>
          </Grid>
          <S.ContainerInput
            item
            xs={12}
            md={12}
            display="flex"
            justifyContent="space-between"
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              VINCULOS_TRABALHISTAS,
              "prim_emprego"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                VINCULOS_TRABALHISTAS,
                "prim_emprego",
                "O município de Rio Grande foi seu primeiro emprego?"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[
                  `${VINCULOS_TRABALHISTAS}-prim_emprego`
                ]
              }
              message={
                correctionValuesJsonData[
                  `${VINCULOS_TRABALHISTAS}-prim_emprego`
                ]?.message
              }
              style={{ top: "0" }}
            />
            <InputRadios
              control={control}
              id="prim_emprego"
              title="O município de Rio Grande foi seu primeiro emprego?"
              name="prim_emprego"
              readOnly={!isEditable}
              row
              required
            >
              <FormControlLabel
                value="S"
                control={<Radio size="small" />}
                label="Sim"
              />
              <FormControlLabel
                value="N"
                control={<Radio size="small" />}
                label="Não"
              />
            </InputRadios>
            <div style={{ marginTop: "1rem" }}>
              {isEditable && (
                <B.ButtonSecondary
                  variant="outlined"
                  onClick={() => setOpenModalVinculosTrabalhistas(true)}
                >
                  CADASTRAR VÍNCULO
                  <WorkIcon fontSize="small" style={{ marginLeft: "0.5rem" }} />
                </B.ButtonSecondary>
              )}
            </div>
          </S.ContainerInput>
          <TableContainer sx={{ margin: "1rem 0" }}>
            {outrosEmpregos.length ? (
              <Table stickyHeader aria-label="sticky table" size="small">
                <TableHead>
                  <TableRow>
                    <TableCell>RAZÃO SOLCIAL</TableCell>
                    <TableCell>REGIME</TableCell>
                    <TableCell>CARGO</TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: "12rem", width: "12rem" }}
                    >
                      DOCUMENTO
                    </TableCell>
                    <TableCell
                      align="center"
                      style={{ minWidth: "8rem", width: "8rem" }}
                    >
                      AÇÕES
                    </TableCell>
                    {isOpenCorrecion && (
                      <TableCell
                        align="center"
                        style={{ minWidth: "2rem", width: "2rem" }}
                      >
                        CORREÇÃO
                      </TableCell>
                    )}
                    {[2, 3].includes(statusCorrecion) && (
                      <TableCell
                        align="center"
                        style={{ minWidth: "2rem", width: "2rem" }}
                      />
                    )}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {outrosEmpregos.map((vinculo: any) => (
                    <TableRow
                      hover
                      id={`id-${vinculo.id_sol_dados_vinculo}`}
                      style={{ cursor: "pointer" }}
                      key={vinculo.id_sol_dados_vinculo}
                      onClick={() => {
                        if (!isEditable && !isOpenCorrecion) {
                          setOpenModalVinculosTrabalhistas(vinculo);
                        }
                      }}
                    >
                      <TableCell>{vinculo.razao_social}</TableCell>
                      <TableCell>
                        {regimeTrabalho.itemsTable.length &&
                          regimeTrabalho.itemsTable.find(
                            (e) =>
                              Number(e.item_tabela) ===
                              Number(vinculo.tipo_regime_item)
                          )?.descr_tabela}
                      </TableCell>
                      <TableCell>{vinculo.cargo}</TableCell>
                      <TableCell align="center">
                        {vinculo.tipo_doc_vinc_item === 1
                          ? cpfMask(vinculo.num_doc_vinculo)
                          : cnpjMask(vinculo.num_doc_vinculo)}
                      </TableCell>
                      <TableCell align="center">
                        {isEditable ? (
                          <>
                            <IconButton
                              onClick={() => {
                                setOpenModalVinculosTrabalhistas(vinculo);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              onClick={() =>
                                deleteVinc(vinculo.id_sol_dados_vinculo)
                              }
                            >
                              <DeleteIcon />
                            </IconButton>
                          </>
                        ) : (
                          <IconButton
                            onClick={() => {
                              setOpenModalVinculosTrabalhistas(vinculo);
                            }}
                          >
                            <VisibilityIcon />
                          </IconButton>
                        )}
                      </TableCell>
                      {isOpenCorrecion && (
                        <TableCell align="center">
                          <Checkbox
                            color="warning"
                            checked={isOnChangeSetListCorrecion(
                              VINCULOS_TRABALHISTAS,
                              String(`vinculo-${vinculo.id_sol_dados_vinculo}`)
                            )}
                            onChange={() =>
                              onChangeSetListCorrecion(
                                VINCULOS_TRABALHISTAS,
                                String(
                                  `vinculo-${vinculo.id_sol_dados_vinculo}`
                                ),
                                String(vinculo.razao_social)
                              )
                            }
                          />
                        </TableCell>
                      )}
                      {correctionValuesJsonData[
                        `${VINCULOS_TRABALHISTAS}-vinculo-${String(
                          vinculo.id_sol_dados_vinculo
                        )}`
                      ] && !isOpenCorrecion ? (
                        <TableCell align="center">
                          {correctionValuesJsonData[
                            `${VINCULOS_TRABALHISTAS}-vinculo-${String(
                              vinculo.id_sol_dados_vinculo
                            )}`
                          ]?.message ? (
                            <Tooltip
                              title={
                                correctionValuesJsonData[
                                  `${VINCULOS_TRABALHISTAS}-vinculo-${String(
                                    vinculo.id_sol_dados_vinculo
                                  )}`
                                ]?.message
                              }
                              style={{ cursor: "help" }}
                            >
                              <ReportIcon color="warning" />
                            </Tooltip>
                          ) : (
                            <Tooltip
                              title={false}
                              disableFocusListener
                              disableInteractive
                              disableHoverListener
                              style={{ cursor: "help" }}
                            >
                              <ReportIcon color="warning" />
                            </Tooltip>
                          )}
                        </TableCell>
                      ) : (
                        <TableCell />
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            ) : null}
          </TableContainer>
          <Grid item xs={12} md={12} marginTop="1rem">
            <T.H6>OUTRAS FONTES DE RENDA</T.H6>
          </Grid>
          <S.ContainerInput
            item
            xs={12}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              VINCULOS_TRABALHISTAS,
              "trab_orgao_pub"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                VINCULOS_TRABALHISTAS,
                "trab_orgao_pub",
                "Trabalha em outra empresa ou orgão público"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[
                  `${VINCULOS_TRABALHISTAS}-trab_orgao_pub`
                ]
              }
              message={
                correctionValuesJsonData[
                  `${VINCULOS_TRABALHISTAS}-trab_orgao_pub`
                ]?.message
              }
            />
            <InputRadios
              control={control}
              id="trab_orgao_pub"
              title="Trabalha em outra empresa ou orgão público"
              name="trab_orgao_pub"
              readOnly={!isEditable}
              row
              required
            >
              <FormControlLabel
                value="S"
                control={
                  <Radio
                    size="small"
                    onChange={() => {
                      setIndTrabalhoEmpOrgaoPub("S");
                      if (vinculosTrabValue.outros_vinculos) {
                        setDataValuesOutrosVinculos(
                          vinculosTrabValue.outros_vinculos
                        );
                      }
                    }}
                  />
                }
                label="Sim"
              />
              <FormControlLabel
                value="N"
                control={
                  <Radio
                    size="small"
                    onChange={() => {
                      setIndTrabalhoEmpOrgaoPub("N");
                      clearValuesForm([
                        "razao_social",
                        "tipo_regime_item",
                        "data_inicio",
                        "tipo_doc_vinc_item",
                        "ind_vinc_magisterio",
                        "ind_vinc_municipal",
                        "num_doc_vinculo",
                        "municipio_regime_previdenciario",
                        "obs_vinculo",
                      ]);
                    }}
                  />
                }
                label="Não"
              />
            </InputRadios>
          </S.ContainerInput>

          {indTrabalhoEmpOrgaoPub === "S" && (
            <>
              <S.ContainerInput
                item
                xs={12}
                md={regimePrevi === 2 ? 3 : 6}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "razao_social"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "razao_social",
                    "Razão social"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-razao_social`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-razao_social`
                    ]?.message
                  }
                />
                <InputBase
                  control={control}
                  id="razao_social"
                  label="Razão social"
                  name="razao_social"
                  readOnly={!isEditable}
                  required
                />
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={3}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "regime_previdenciario_item"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "regime_previdenciario_item",
                    "Regime de trabalho"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-regime_previdenciario_item`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-regime_previdenciario_item`
                    ]?.message
                  }
                />
                <InputSelect
                  control={control}
                  title="Regime previdenciário"
                  id="regime_previdenciario_item"
                  label="Regime previdenciário"
                  name="regime_previdenciario_item"
                  required
                  readOnly={!isEditable}
                  onClick={({ target }: any) => {
                    const value = Number(target.value);
                    if (value === 1) {
                      setValue("municipio_regime_previdenciario", "");
                    }
                    setRegimePrevi(Number(target.value));
                  }}
                >
                  <option> </option>
                  {regimePrevidenciario.itemsTable?.length &&
                    regimePrevidenciario.itemsTable.map((regime) => {
                      if (regime.item_tabela === 0) return null;
                      return (
                        <option key={Math.random()} value={regime.item_tabela}>
                          {regime.descr_tabela}
                        </option>
                      );
                    })}
                </InputSelect>
              </S.ContainerInput>
              {regimePrevi === 2 && (
                <S.ContainerInput
                  item
                  xs={12}
                  md={3}
                  isOpenCorrecion={isOpenCorrecion}
                  isEditable={isEditable}
                  isSelected={isOnChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "municipio_regime_previdenciario"
                  )}
                  onClick={() =>
                    onChangeSetListCorrecion(
                      VINCULOS_TRABALHISTAS,
                      "municipio_regime_previdenciario",
                      "Municipio/Estado do regime previdenciário"
                    )
                  }
                >
                  <S.IconInputCorrecion
                    isIcon={
                      correctionValuesJsonData[
                        `${VINCULOS_TRABALHISTAS}-municipio_regime_previdenciario`
                      ]
                    }
                    message={
                      correctionValuesJsonData[
                        `${VINCULOS_TRABALHISTAS}-municipio_regime_previdenciario`
                      ]?.message
                    }
                  />
                  <InputBase
                    control={control}
                    id="municipio_regime_previdenciario"
                    label="Municipio/Estado do regime previdenciário"
                    name="municipio_regime_previdenciario"
                    readOnly={!isEditable}
                    required
                  />
                </S.ContainerInput>
              )}
              <S.ContainerInput
                item
                xs={12}
                md={3}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "tipo_regime_item"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "tipo_regime_item",
                    "Regime de trabalho"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-tipo_regime_item`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-tipo_regime_item`
                    ]?.message
                  }
                />
                <InputSelect
                  control={control}
                  title="Regime de trabalho"
                  id="tipo_regime_item"
                  label="Regime de trabalho"
                  name="tipo_regime_item"
                  required
                  readOnly={!isEditable}
                >
                  <option> </option>
                  {regimeTrabalho.itemsTable?.length &&
                    regimeTrabalho.itemsTable.map((regime) => {
                      if (regime.item_tabela === 0) return null;
                      return (
                        <option key={Math.random()} value={regime.item_tabela}>
                          {regime.descr_tabela}
                        </option>
                      );
                    })}
                </InputSelect>
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={3}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "natureza_juridica"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "natureza_juridica",
                    "Natureza jurídica"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-natureza_juridica`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-natureza_juridica`
                    ]?.message
                  }
                />
                <InputRadios
                  control={control}
                  id="natureza_juridica"
                  title="Natureza jurídica"
                  name="natureza_juridica"
                  required
                  readOnly={!isEditable}
                  row
                >
                  <FormControlLabel
                    value="Publica"
                    control={<Radio size="small" />}
                    label="Pública"
                  />
                  <FormControlLabel
                    value="Privada"
                    control={<Radio size="small" />}
                    label="Privada"
                  />
                </InputRadios>
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={3}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "ind_vinc_magisterio"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "ind_vinc_magisterio",
                    "Vínculo magistério"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-ind_vinc_magisterio`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-ind_vinc_magisterio`
                    ]?.message
                  }
                />
                <InputRadios
                  control={control}
                  id="ind_vinc_magisterio"
                  title="Vínculo magistério"
                  name="ind_vinc_magisterio"
                  required
                  readOnly={!isEditable}
                  defaultValue={
                    vinculosTrabValue?.outros_vinculos?.ind_vinc_magisterio ||
                    "N"
                  }
                  row
                >
                  <FormControlLabel
                    value="S"
                    control={<Radio size="small" />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="N"
                    control={<Radio size="small" />}
                    label="Não"
                  />
                </InputRadios>
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={3}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "ind_vinc_municipal"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "ind_vinc_municipal",
                    "Vínculo municipal"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-ind_vinc_municipal`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-ind_vinc_municipal`
                    ]?.message
                  }
                />
                <InputRadios
                  control={control}
                  id="ind_vinc_municipal"
                  title="Vínculo municipal"
                  name="ind_vinc_municipal"
                  required
                  readOnly={!isEditable}
                  row
                >
                  <FormControlLabel
                    value="S"
                    control={<Radio size="small" />}
                    onChange={() => {
                      setValue(
                        "matricula",
                        vinculosTrabValue?.outros_vinculos?.matricula
                      );
                      setValue(
                        "secretaria_item",
                        vinculosTrabValue?.outros_vinculos?.secretaria_item
                      );
                      setValue(
                        "setor",
                        vinculosTrabValue?.outros_vinculos?.setor
                      );
                      setIndVincMunicipal("S");
                    }}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="N"
                    control={<Radio size="small" />}
                    onChange={() => {
                      setValue("matricula", null);
                      setValue("secretaria_item", null);
                      setValue("setor", null);
                      setIndVincMunicipal("N");
                    }}
                    label="Não"
                  />
                </InputRadios>
              </S.ContainerInput>
              {indVincMunicipal === "S" && (
                <>
                  <S.ContainerInput
                    item
                    xs={12}
                    md={3}
                    isOpenCorrecion={isOpenCorrecion}
                    isEditable={isEditable}
                    isSelected={isOnChangeSetListCorrecion(
                      VINCULOS_TRABALHISTAS,
                      "matricula"
                    )}
                    onClick={() =>
                      onChangeSetListCorrecion(
                        VINCULOS_TRABALHISTAS,
                        "matricula",
                        "Matrícula"
                      )
                    }
                  >
                    <S.IconInputCorrecion
                      isIcon={
                        correctionValuesJsonData[
                          `${VINCULOS_TRABALHISTAS}-matricula`
                        ]
                      }
                      message={
                        correctionValuesJsonData[
                          `${VINCULOS_TRABALHISTAS}-matricula`
                        ]?.message
                      }
                    />
                    <InputBase
                      control={control}
                      id="matricula"
                      label="Matrícula"
                      name="matricula"
                      readOnly={!isEditable}
                      required
                    />
                  </S.ContainerInput>
                  <S.ContainerInput
                    item
                    xs={12}
                    md={3}
                    isOpenCorrecion={isOpenCorrecion}
                    isEditable={isEditable}
                    isSelected={isOnChangeSetListCorrecion(
                      VINCULOS_TRABALHISTAS,
                      "secretaria_item"
                    )}
                    onClick={() =>
                      onChangeSetListCorrecion(
                        VINCULOS_TRABALHISTAS,
                        "secretaria_item",
                        "Secretaria"
                      )
                    }
                  >
                    <S.IconInputCorrecion
                      isIcon={
                        correctionValuesJsonData[
                          `${VINCULOS_TRABALHISTAS}-secretaria_item`
                        ]
                      }
                      message={
                        correctionValuesJsonData[
                          `${VINCULOS_TRABALHISTAS}-secretaria_item`
                        ]?.message
                      }
                    />
                    <InputSelect
                      control={control}
                      title="Secretaria"
                      id="secretaria_item"
                      label="Secretaria"
                      name="secretaria_item"
                      required
                      readOnly={!isEditable}
                      onClick={async (e: any) => {
                        const { data } = await api.get(
                          `/system/structured-table?cod=&codSup=26&itemSup=${e.target.value}`
                        );
                        dispatch(setItemAction({ data, key: "listaSetores" }));
                      }}
                    >
                      <option> </option>
                      {listaSecretarias.itemsTable?.length &&
                        listaSecretarias.itemsTable.map((regime) => {
                          if (regime.item_tabela === 0) return null;
                          return (
                            <option
                              key={Math.random()}
                              value={regime.item_tabela}
                            >
                              {regime.descr_tabela}
                            </option>
                          );
                        })}
                    </InputSelect>
                  </S.ContainerInput>
                  <S.ContainerInput
                    item
                    xs={12}
                    md={3}
                    isOpenCorrecion={isOpenCorrecion}
                    isEditable={isEditable}
                    isSelected={isOnChangeSetListCorrecion(
                      VINCULOS_TRABALHISTAS,
                      "setor"
                    )}
                    onClick={() =>
                      onChangeSetListCorrecion(
                        VINCULOS_TRABALHISTAS,
                        "setor",
                        "Setor"
                      )
                    }
                  >
                    <S.IconInputCorrecion
                      isIcon={
                        correctionValuesJsonData[
                          `${VINCULOS_TRABALHISTAS}-setor`
                        ]
                      }
                      message={
                        correctionValuesJsonData[
                          `${VINCULOS_TRABALHISTAS}-setor`
                        ]?.message
                      }
                    />
                    <InputSelect
                      control={control}
                      title="Setor"
                      id="setor"
                      label="Setor"
                      name="setor"
                      readOnly={!isEditable}
                    >
                      <option> </option>
                      {listaSetores.itemsTable?.length &&
                        listaSetores.itemsTable.map((regime) => {
                          if (regime.item_tabela === 0) return null;
                          return (
                            <option
                              key={Math.random()}
                              value={regime.item_tabela}
                            >
                              {regime.descr_tabela}
                            </option>
                          );
                        })}
                    </InputSelect>
                  </S.ContainerInput>
                </>
              )}
              <S.ContainerInput
                item
                xs={12}
                md={indVincMunicipal === "S" ? 6 : 3}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "cargo"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "cargo",
                    "Cargo"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[`${VINCULOS_TRABALHISTAS}-cargo`]
                  }
                  message={
                    correctionValuesJsonData[`${VINCULOS_TRABALHISTAS}-cargo`]
                      ?.message
                  }
                />
                <InputBase
                  control={control}
                  id="cargo"
                  label="Cargo"
                  name="cargo"
                  readOnly={!isEditable}
                  required
                />
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={2}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "data_inicio"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "data_inicio",
                    "Data inicio"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-data_inicio`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-data_inicio`
                    ]?.message
                  }
                />
                <InputDate
                  control={control}
                  id="data_inicio"
                  label="Data inicio"
                  name="data_inicio"
                  readOnly={!isEditable}
                  required
                />
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={2}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "data_final"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "data_final",
                    "Data final"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-data_final`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-data_final`
                    ]?.message
                  }
                />
                <InputDate
                  control={control}
                  id="data_final"
                  label="Data final"
                  name="data_final"
                  readOnly={!isEditable}
                />
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={2}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "salario_bruto"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "salario_bruto",
                    "Salário bruto"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-salario_bruto`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-salario_bruto`
                    ]?.message
                  }
                />
                <InputBase
                  control={control}
                  id="salario_bruto"
                  label="Salário bruto"
                  name="salario_bruto"
                  readOnly={!isEditable}
                  type="currency"
                />
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={2}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "salario_liquido"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "salario_liquido",
                    "Salário liquido"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-salario_liquido`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-salario_liquido`
                    ]?.message
                  }
                />
                <InputBase
                  control={control}
                  id="salario_liquido"
                  label="Salário liquido"
                  name="salario_liquido"
                  readOnly={!isEditable}
                  type="currency"
                />
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={2}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "contribuicao_prev"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "contribuicao_prev",
                    "Contribuição previdenciária"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-contribuicao_prev`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-contribuicao_prev`
                    ]?.message
                  }
                />
                <InputBase
                  control={control}
                  id="contribuicao_prev"
                  label="Contribuição previdenciária"
                  name="contribuicao_prev"
                  readOnly={!isEditable}
                  type="currency"
                />
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={2}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "num_certif_tempo_contribuicao"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "num_certif_tempo_contribuicao",
                    "Nº certificado tempo contribuição (CTC)"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-num_certif_tempo_contribuicao`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-num_certif_tempo_contribuicao`
                    ]?.message
                  }
                />
                <InputBase
                  control={control}
                  id="num_certif_tempo_contribuicao"
                  label="Nº certificado tempo contribuição (CTC)"
                  name="num_certif_tempo_contribuicao"
                  readOnly={!isEditable}
                />
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={2}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "tipo_doc_vinc_item"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "tipo_doc_vinc_item",
                    "Tipo documento"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-tipo_doc_vinc_item`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-tipo_doc_vinc_item`
                    ]?.message
                  }
                />
                <InputSelect
                  title="Tipo documento"
                  control={control}
                  id="tipo_doc_vinc_item"
                  label="Tipo documento"
                  name="tipo_doc_vinc_item"
                  readOnly={!isEditable}
                  onClick={({ target }: any) => {
                    setTypeDocument(
                      Number(target.value) === 1
                        ? "cpf"
                        : Number(target.value) === 2
                        ? "cnpj"
                        : ""
                    );
                  }}
                  required
                >
                  <option> </option>
                  {typeDocVinculo.itemsTable?.length &&
                    typeDocVinculo.itemsTable.map((doc) => {
                      if (doc.item_tabela === 0) return null;
                      return (
                        <option key={Math.random()} value={doc.item_tabela}>
                          {doc.sigla_tabela}
                        </option>
                      );
                    })}
                </InputSelect>
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={3}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "num_doc_vinculo"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "num_doc_vinculo",
                    "Número documento"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-num_doc_vinculo`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-num_doc_vinculo`
                    ]?.message
                  }
                />
                <InputBase
                  control={control}
                  id="num_doc_vinculo"
                  label="Número documento"
                  name="num_doc_vinculo"
                  readOnly={!isEditable}
                  type={typeDocument}
                  required
                />
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={7}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  VINCULOS_TRABALHISTAS,
                  "obs_vinculo"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    VINCULOS_TRABALHISTAS,
                    "obs_vinculo",
                    "Observações"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-obs_vinculo`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${VINCULOS_TRABALHISTAS}-obs_vinculo`
                    ]?.message
                  }
                />
                <InputBase
                  control={control}
                  id="obs_vinculo"
                  label="Observações"
                  name="obs_vinculo"
                  readOnly={!isEditable}
                />
              </S.ContainerInput>
            </>
          )}
        </Grid>
        {isEditable && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "1rem",
            }}
          >
            {!isCompleted && (
              <B.ButtonPrimary variant="outlined" onClick={() => saveData()}>
                Salvar
              </B.ButtonPrimary>
            )}
            <B.ButtonPrimary type="submit">
              {isCompleted ? "Atualizar" : "CONCLUIR ETAPA"}
            </B.ButtonPrimary>
          </div>
        )}
      </form>
      <AddVinculo
        isOpen={Boolean(openModalVinculosTrabalhistas)}
        onClose={() => setOpenModalVinculosTrabalhistas(false)}
        vinculo={openModalVinculosTrabalhistas || {}}
        updateListVinc={getData}
        isEditable={isEditable}
        idVincTrab={vinculosTrabValue.id_sol_dados_vinc_trab}
      />
      <WarningNotVinculoCadastrado
        isOpen={openModalConfirmaNaoCadastraVinculos.isOpenModal}
        onClose={() =>
          setOpenModalConfirmaNaoCadastraVinculos({
            confirmNaoCadastraVinculo: true,
            isOpenModal: false,
          })
        }
        onOpenCadastraVinculo={() => {
          setOpenModalConfirmaNaoCadastraVinculos({
            confirmNaoCadastraVinculo: false,
            isOpenModal: false,
          });
          setOpenModalVinculosTrabalhistas(true);
        }}
      />
    </Grid>
  );
}
