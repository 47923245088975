/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */

import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  FormLabel,
} from "@mui/material";
import { Controller } from "react-hook-form";

interface IInputCheckBox {
  control: any;
  id: string;
  title?: string;
  name: string;
  label: string;
  labelPlacement?: "start" | "end" | "top" | "bottom";
  defaultValue?: string | number | boolean;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  row?: boolean;
  isErrorMessage?: boolean;
}

export function InputCheckBox({
  control,
  id,
  name,
  title,
  label,
  defaultValue,
  labelPlacement,
  disabled,
  readOnly,
  required,
  errorMessage,
  row,
  isErrorMessage,
}: IInputCheckBox) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <FormControl
            component="fieldset"
            required={required}
            disabled={disabled || readOnly}
            variant="outlined"
            fullWidth
            size="small"
            id={id}
            error={!!error?.message || !!errorMessage}
          >
            {title && <FormLabel component="legend">{title}</FormLabel>}
            <FormGroup aria-label={title} onChange={onChange} row={row}>
              <FormControlLabel
                checked={value === "S" ? true : value === "N" ? false : !!value}
                control={<Checkbox />}
                label={label}
                labelPlacement={labelPlacement || "end"}
              />
            </FormGroup>
            <FormHelperText
              style={{ margin: "2px 0 0 2px" }}
              error={!!error?.message || !!errorMessage}
            >
              {isErrorMessage === false
                ? ""
                : error?.message || errorMessage || " "}
            </FormHelperText>
          </FormControl>
        );
      }}
      rules={{
        required: {
          value: !!required,
          message: `${title} é obrigatório`,
        },
      }}
    />
  );
}
