/* eslint-disable @typescript-eslint/no-explicit-any */
import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridColumns, GridSelectionModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

import { removeMask } from "../../../../utils/utils";
import { validarCPF } from "../../../../utils/validators";
import { HeaderDataGridAddUser } from "../../../HeaderDataGridAddUser";

interface IImportarManualUserSol {
  listaNovosUsers: ListUsuariosProps[];
  setListaNovosUsers: React.Dispatch<React.SetStateAction<ListUsuariosProps[]>>;
  isCreateAccount: boolean;
  setIsCreateAccount: React.Dispatch<React.SetStateAction<boolean>>;
}

type ListUsuariosProps = {
  id: number;
  numero_cpf: string;
  nome?: string;
  email?: string;
};

export function ImportarManualUserSol({
  listaNovosUsers,
  setListaNovosUsers,
  isCreateAccount,
  setIsCreateAccount,
}: IImportarManualUserSol) {
  function detectarSeparadorCSV(textoCSV: string) {
    const separadores = [",", ";", "\t"]; // Lista de possíveis separadores
    const linhas = textoCSV.split("\n"); // Dividir o texto em linhas

    let separadorMaisComum = "";
    let maxOcorrencias = 0;

    // Loop através de cada separador para contar as ocorrências
    separadores.forEach((separador) => {
      let ocorrencias = 0;
      linhas.forEach((linha: string) => {
        if (linha.includes(separador)) {
          // eslint-disable-next-line no-plusplus
          ocorrencias++;
        }
      });

      // Verificar se este separador tem mais ocorrências do que os anteriores
      if (ocorrencias > maxOcorrencias) {
        maxOcorrencias = ocorrencias;
        separadorMaisComum = separador;
      }
    });

    return separadorMaisComum;
  }

  const readFile = (e: any) => {
    const fileCsv = e.target.files[0];
    const reader = new FileReader();
    reader.onload = (e: any) => {
      const conteudoCSV = e.target.result;
      const separador = detectarSeparadorCSV(conteudoCSV);

      const response = conteudoCSV
        .split("\n")
        .map((row: string) => {
          const [cpf, nome, email] = row.split(separador);

          if (validarCPF(cpf)) {
            return {
              id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
              numero_cpf: removeMask(cpf) as string,
              nome,
              email,
            };
          }
          return null;
        })
        .filter((item: any) => item);

      setListaNovosUsers((state) => [...state, ...response]);
    };
    reader.readAsText(fileCsv);
  };

  const getFileInput = () => {
    const fileInput = document.createElement("input");
    fileInput.type = "file";
    fileInput.onchange = readFile;
    fileInput.click();
  };

  // Fica guardado quantos itens estao marcados
  const [selectionModel, setSelectionModel] = useState<GridSelectionModel>([]);

  const handleSelectionModelChange = (selectionModel: GridSelectionModel) => {
    setSelectionModel(selectionModel);
  };

  const addUserManual = () => {
    const id = Math.floor(Math.random() * Number.MAX_SAFE_INTEGER);
    const newUser = {
      id,
      numero_cpf: "",
      nome: "",
      email: "",
    };
    setListaNovosUsers((state) => [newUser, ...state]);
  };

  // DELETA OS ITENS MARCADOS
  const handleDeleteLote = () => {
    setListaNovosUsers((state) =>
      state.filter((item) => !selectionModel.includes(item.id))
    );
    setSelectionModel([]);
  };

  const handleDelete = (cellValues: any) => {
    setListaNovosUsers((state) =>
      state.filter((item) => item.id !== cellValues.row.id)
    );
  };

  const hendleUpdate = (e: any) => {
    const { id, value, field } = e;
    const newList = [...listaNovosUsers].map((item) => {
      if (item.id === id) {
        return { ...item, [field]: value };
      }
      return item;
    });
    setListaNovosUsers(newList);
  };

  const dataGridColumns: GridColumns<ListUsuariosProps> = [
    {
      field: "numero_cpf",
      headerName: "CPF",
      width: 170,
      editable: true,
    },
    { field: "nome", headerName: "NOME", width: 220, editable: true },
    {
      field: "email",
      headerName: "EMAIL",
      width: 280,
      editable: true,
    },
    {
      field: "acao",
      headerName: "AÇÃO",
      editable: false,
      width: 80,
      renderCell: (cellValues: any) => {
        return (
          <Tooltip title="Deletar" style={{ margin: "auto" }}>
            <IconButton onClick={() => handleDelete(cellValues)}>
              <DeleteIcon />
            </IconButton>
          </Tooltip>
        );
      },
    },
  ];

  useEffect(
    () => () => {
      setListaNovosUsers([]);
      setIsCreateAccount(false);
    },
    []
  );

  return (
    <DataGrid
      columns={dataGridColumns}
      rows={listaNovosUsers}
      checkboxSelection
      disableSelectionOnClick
      editMode="cell"
      rowsPerPageOptions={[5, 10, 20, 50, 100]}
      onSelectionModelChange={handleSelectionModelChange}
      onCellEditCommit={hendleUpdate}
      selectionModel={selectionModel}
      rowHeight={40}
      disableColumnMenu // desabilita o menu de filtrar
      components={{
        Toolbar: HeaderDataGridAddUser,
      }}
      componentsProps={{
        toolbar: {
          numSelected: selectionModel.length,
          importCSV: getFileInput,
          addUserManual,
          handleDeleteLote,
          setIsCreateAccount,
          isCreateAccount,
        },
      }}
      localeText={{
        MuiTablePagination: {
          labelDisplayedRows: ({ from, to, count }) =>
            `${from}–${to} de ${count !== -1 ? count : `mais do que ${to}`}`,
          labelRowsPerPage: "Itens por página",
        },
        footerRowSelected: () => "",
        footerTotalVisibleRows: () => "Teste",
        noRowsLabel: "Nenhum usuário importado",
      }}
    />
  );
}
