import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    min-height: 100vh;
    background-color: ${theme.background.default};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    max-height: 100vh;
    background-color: ${theme.background.default};
  `}
`;

export const ContentLogin = styled.div`
  margin: 0 auto;
`;

export const BoxImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  cursor: pointer;

  img {
    pointer-events: none;
    height: 100%;
  }

  @media (max-width: 1023px) {
    img {
      display: none;
    }
  }
`;
