/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";

import { apiGet } from "../../../services/metods";
import { UtilsTypes } from "../Utils/types";
import { UserTypes, UserObjectTypes } from "./types";

// Primeira chamada do sistema para busca o cliente baseado na sigla que esta na url
export function* getUserSaga() {
  try {
    const { data }: AxiosResponse<UserObjectTypes, any> = yield call<any>(
      apiGet,
      "/user/get-user"
    );

    yield put({
      type: UserTypes.SET_USER,
      payload: data,
    });
  } catch (error: any) {
    yield put({
      type: UtilsTypes.SET_TOAST,
      payload: {
        type: "error",
        message: error.message,
      },
    });
  }
}
