/* eslint-disable @typescript-eslint/no-explicit-any */
import AnalyticsIcon from "@mui/icons-material/Analytics";
import { useEffect, useRef } from "react";

import { api } from "../../../services/api";
import {
  setLoading,
  setToastMessage,
} from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import Modal from "../index";
import * as S from "./styles";

interface IModalTermsSignature {
  isOpen: boolean;
  onClose: () => void;
  idSolicitation: number;
  setTermSignature: (value: any) => void;
  termSignature: any;
  downloadTerm: () => Promise<void>;
}

export function ModalTermsSignature({
  isOpen,
  onClose,
  idSolicitation,
  setTermSignature,
  termSignature,
  downloadTerm,
}: IModalTermsSignature) {
  const intervalRef = useRef<any>();
  const dispatch = useAppDispatch();

  const generateTerm = async () => {
    try {
      dispatch(setLoading(true));
      const { data: response } = await api.post(
        `/solicitation/signature-terms/${idSolicitation}`
      );

      if (Number(response.status) === 2 && response?.listaDadosSignatarios) {
        window.open(
          response.listaDadosSignatarios.DadosSignatarioReenvio.linkAcesso,
          "_blank"
        );
      } else if (Number(response.status) === 3) {
        dispatch(
          setToastMessage({
            type: "success",
            message: "Envelope com Termo de Preenchimento foi assinado",
          })
        );
        setTermSignature(response);
      } else {
        dispatch(
          setToastMessage({
            type: "error",
            message: "Erro ao criar envelope com Termo de Preenchimento",
          })
        );
      }
      onClose();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const getTermData = async () => {
    try {
      const { data: response } = await api.get(
        `/solicitation/signature-terms/${idSolicitation}`
      );

      if (Number(response.status) === 3) {
        dispatch(
          setToastMessage({
            type: "success",
            message: "Envelope com Termo de Preenchimento foi assinado",
          })
        );
        setTermSignature(response);
        onClose();
      }
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  useEffect(() => {
    if (isOpen && Number(termSignature?.status) !== 3) {
      intervalRef.current = setInterval(() => {
        getTermData();
      }, 3000);
    } else {
      clearInterval(intervalRef.current);
    }
    return () => clearInterval(intervalRef.current);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <S.ModalBody>
        <S.Header>
          <AnalyticsIcon fontSize="large" color="warning" />
          <T.H3 color="#ed6c02" margin="0 0 0 1rem">
            TERMO DE PREENCHIMEMTO
          </T.H3>
        </S.Header>
        {termSignature && Number(termSignature.status) === 3 ? (
          <>
            <T.Paragraph margin="0 0 1rem 0">
              Termo de Preenchimento assinado, para visualizar o termo, faça o
              download
            </T.Paragraph>
            <S.Footer>
              <B.ButtonSecondary onClick={onClose}>Fechar</B.ButtonSecondary>
              <B.ButtonPrimary
                onClick={() => {
                  downloadTerm();
                  onClose();
                }}
              >
                Download Termo
              </B.ButtonPrimary>
            </S.Footer>
          </>
        ) : (
          <>
            <T.Paragraph margin="0 0 1rem 0">
              Para enviar a solicitação para análise, é necessário{" "}
              <b>assinar o termo de preenchimento.</b> Voce sera redirecionado
              para uma nova página para assinar o termo.
            </T.Paragraph>
            <T.Paragraph margin="0 0 1rem 0">
              <b>OBS:</b> Após assinar o termo, você deve voltar para esta
              página para finalizar o processo.
            </T.Paragraph>
            <S.Footer>
              <B.ButtonSecondary onClick={onClose}>Cancelar</B.ButtonSecondary>
              <B.ButtonPrimary onClick={() => generateTerm()}>
                Assinar
              </B.ButtonPrimary>
            </S.Footer>
          </>
        )}
      </S.ModalBody>
    </Modal>
  );
}
