import { Reducer } from "redux";

import { SystemParametersTypes, SystemParametersState } from "./types";

const INITIAL_STATE: SystemParametersState = {
  systemParameters: {
    descr_remet_mail: "",
    login_remet_mail: "",
    senha_remet_mail: "",
    smtp_mail: "",
    porta_mail: "",
    ind_tls_mail: "N",
    ind_ssl_mail: "N",
    url_appweb: "",
    cod_pais_sms: "",
    login_sms: "",
    senha_sms: "",
  },
};

const reducer: Reducer<SystemParametersState> = (
  state = INITIAL_STATE,
  action
) => {
  switch (action.type) {
    case SystemParametersTypes.SET_PARAMETERS:
      return { ...state, systemParameters: action.payload };

    default:
      return state;
  }
};

export default reducer;
