/* eslint-disable consistent-return */
import * as faceApi from "face-api.js";

type FaceRecognitionMobile = {
  width: number;
  height: number;
  video: HTMLVideoElement;
};

const IndLoadModels = {
  emProcesso: false,
  erro: false,
  finalizado: false,
};

const loadModels = async () => {
  try {
    IndLoadModels.emProcesso = true;
    await Promise.all([
      faceApi.loadSsdMobilenetv1Model("/models"),
      faceApi.loadTinyFaceDetectorModel("/models"),
      faceApi.loadFaceLandmarkModel("/models"),
      faceApi.loadFaceLandmarkTinyModel("/models"),
      faceApi.loadFaceRecognitionModel("/models"),
      faceApi.loadFaceExpressionModel("/models"),
      faceApi.loadAgeGenderModel("/models"),
    ]);
  } catch (error) {
    IndLoadModels.erro = true;
  } finally {
    IndLoadModels.emProcesso = false;
    IndLoadModels.erro = false;
    IndLoadModels.finalizado = true;
  }
};

loadModels();

export const onFaceRecognitionMobile = async (data: FaceRecognitionMobile) => {
  try {
    if (IndLoadModels.erro || IndLoadModels.emProcesso) return;
    if (!IndLoadModels.finalizado) await loadModels();

    const { width, height, video } = data;

    const canvas = faceApi.createCanvas({ width, height });
    faceApi.matchDimensions(video, { width, height });
    const ctx = canvas.getContext("2d");
    ctx?.drawImage(video, 0, 0, width, height);
    const imgData = ctx?.getImageData(0, 0, width, height);
    ctx?.putImageData(imgData!, 0, 0);

    const detections = await faceApi
      .detectSingleFace(canvas, new faceApi.SsdMobilenetv1Options())
      // .detectSingleFace(canvas, new faceApi.TinyFaceDetectorOptions())
      .withFaceLandmarks()
      .withFaceExpressions()
      .withFaceDescriptor()
      .withAgeAndGender();

    canvas.remove();

    return { detections };
  } catch (error: any) {
    throw new Error(error);
  }
};
