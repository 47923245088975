/* eslint-disable @typescript-eslint/no-explicit-any */
import PersonIcon from "@mui/icons-material/Person";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { api } from "../../../services/api";
import {
  setLoading,
  setToastMessage,
} from "../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { IUserSolicitationList } from "../../../types/UserSolicitationList";
import { convertData, getClientUrl } from "../../../utils/utils";
import { ButtonPrimary } from "../../base/Buttons";
import * as T from "../../base/Text";
import { ProgressSolicitation } from "../../ProgressSolicitation";
import * as S from "./styles";

export function HomeUser() {
  const dispatch = useAppDispatch();
  const navegate = useNavigate();

  const [solicitations, setSolicitations] = useState<IUserSolicitationList[]>(
    []
  );

  async function getUserSolicitations() {
    dispatch(setLoading(true));
    try {
      const { data } = await api.get("/solicitation/user");
      setSolicitations(data);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  }

  useEffect(() => {
    if (!solicitations.length) {
      getUserSolicitations();
    }
  }, []);

  const {
    User: { user },
    Utils: { device },
  } = useAppSelector((state) => state);

  return (
    <S.Container>
      <S.BoxHeader>
        <div
          style={{
            padding: "2rem 0",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            gap: "2rem",
          }}
        >
          <div>
            <T.H5 size="1.8rem">Ola, {user.nome}</T.H5>
            <T.H6 margin="0.8rem 0 0 0">
              Seja bem vindo ao sistema de solicitações e prova de vida, aqui
              irao aparecer as solicitações que você possui.
            </T.H6>
          </div>
          <ButtonPrimary onClick={() => navegate(`/${getClientUrl()}/perfil`)}>
            Dados da conta
            <PersonIcon style={{ marginLeft: "0.5rem" }} />
          </ButtonPrimary>
        </div>
        <img src="/images/homeImage.svg" alt="Icon" />
      </S.BoxHeader>
      {solicitations.length ? (
        <>
          <S.HeaderListSolicitation>
            <T.H6 size="1.5rem" margin="0.8rem 0 0 0">
              Solicitações
            </T.H6>
          </S.HeaderListSolicitation>
          <S.BoxContent>
            {device === "desktop" && (
              <div
                style={{
                  margin: "auto",
                }}
              >
                <img src="/images/homeImage3.svg" alt="Icon" width={300} />
              </div>
            )}
            <S.BoxSolicitations>
              {solicitations.map((solicitation) => (
                <S.SolicitationItem
                  key={solicitation.id_solicitacao}
                  onClick={() => {
                    navegate(
                      `../${getClientUrl()}/solicitacao/${
                        solicitation.id_sol_usuario
                      }`,
                      { state: { solicitation } }
                    );
                  }}
                  $animation={Boolean(solicitation.status === "Pendente")}
                >
                  <S.SolicitationItemContent>
                    <div>
                      <T.Paragraph>
                        {solicitation.descr_solicitacao}
                      </T.Paragraph>
                    </div>
                    <div>
                      <div>
                        <T.Paragraph>Tipo:</T.Paragraph>
                        <T.Paragraph>
                          {solicitation.tipo_solicitacao_item}
                        </T.Paragraph>
                      </div>
                      <div>
                        <T.Paragraph>Status:</T.Paragraph>
                        <T.Paragraph>{solicitation.status}</T.Paragraph>
                      </div>
                      {solicitation.dt_fim_preench &&
                      solicitation.dt_inicio_preench ? (
                        <div>
                          <T.Paragraph>Periodo:</T.Paragraph>
                          <T.Paragraph>
                            {convertData(solicitation.dt_inicio_preench, {
                              year: "numeric",
                              month: "numeric",
                            })}{" "}
                            -{" "}
                            {convertData(solicitation.dt_inicio_preench, {
                              year: "numeric",
                              month: "numeric",
                            })}{" "}
                          </T.Paragraph>
                        </div>
                      ) : null}
                      {solicitation.dt_atualizacao ? (
                        <div>
                          <T.Paragraph>Data Alteração:</T.Paragraph>
                          <T.Paragraph>
                            {convertData(solicitation.dt_atualizacao)}
                          </T.Paragraph>
                        </div>
                      ) : null}
                    </div>
                  </S.SolicitationItemContent>
                  <S.SolicitationItemProgress>
                    <ProgressSolicitation
                      progress={Number(solicitation.progress)}
                    />
                  </S.SolicitationItemProgress>
                </S.SolicitationItem>
              ))}
            </S.BoxSolicitations>
          </S.BoxContent>
        </>
      ) : (
        <div
          style={{
            margin: "auto",
          }}
        >
          <T.H6 size="1.2rem">
            Voce não possui nenhuma solicitação atualmente!
          </T.H6>
          <img src="/images/homeImage3.svg" alt="Icon" />
        </div>
      )}
    </S.Container>
  );
}
