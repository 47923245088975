/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable func-names */
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
  Checkbox,
  Tooltip,
  IconButton,
} from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../../services/api";
import { setToastMessage } from "../../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { cpfMask } from "../../../../utils/mask";
import { debounce } from "../../../../utils/utils";
import { ButtonSecondary } from "../../../base/Buttons";
import { CardPrimery } from "../../../base/Cards";
import * as T from "../../../base/Text";
import { InputBase } from "../../../Inputs/InputText";

interface IImportSistemUserSol {
  listUsersSelected: ListUsuariosProps[];
  setListUsersSelected: React.Dispatch<
    React.SetStateAction<ListUsuariosProps[]>
  >;
  listaCPFUserJaNaSolicitacao: string[];
}

type ListUsuariosProps = {
  id: number;
  numero_cpf: string;
  nome?: string;
  email?: string;
};

type SearchTableProps = {
  cpf?: string;
  nome?: string;
  email?: string;
};

export function ImportSistemUserSol({
  listUsersSelected,
  setListUsersSelected,
  listaCPFUserJaNaSolicitacao,
}: IImportSistemUserSol) {
  const [listaUsersSitema, setListaUsersSitema] = useState<ListUsuariosProps[]>(
    []
  );
  const TableRef = useRef<HTMLDivElement>(null);
  const [isFilter, setIsFilter] = useState(false);
  const { handleSubmit, control, getValues } = useForm<SearchTableProps>();

  const dispatch = useAppDispatch();
  const { clientLogged } = useAppSelector(({ Clients }) => Clients);

  async function getUsersSystem(
    cpf?: string,
    nome?: string,
    email?: string,
    offset?: number
  ) {
    try {
      const { data } = await api.get(
        `/user/get-users?sigla=${clientLogged.schema_base || ""}&cpf=${
          cpf || ""
        }&nome=${nome || ""}&email=${email || ""}&offset=${offset || ""}`
      );

      const newListaUserSystem = data.map((item: any) => ({
        ...item,
        id: item.id_usuario,
      }));
      setListaUsersSitema(newListaUserSystem);

      const newListaSelecteds = newListaUserSystem.filter((item: any) =>
        listaCPFUserJaNaSolicitacao.includes(item.numero_cpf)
      );
      setListUsersSelected((prevState) => {
        const newList = prevState.filter(
          (item) => !newListaSelecteds.some((user: any) => user.id === item.id)
        );
        return [...newList, ...newListaSelecteds];
      });
      setListaUsersSitema(newListaUserSystem);
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  }

  const onSubmit: SubmitHandler<SearchTableProps> = async (body) => {
    try {
      const { cpf, nome, email } = body;
      getUsersSystem(cpf, nome, email);
    } catch (err) {
      setListaUsersSitema([]);
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  };

  const handleSelected = (item: ListUsuariosProps) => {
    setListUsersSelected((state) => {
      const isExist = state.find((user) => user.id === item.id);
      if (isExist) return state.filter((user) => user.id !== item.id);
      return [...state, item];
    });
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setListUsersSelected(listaUsersSitema);
    } else
      setListUsersSelected(
        listaUsersSitema.filter((user) =>
          listaCPFUserJaNaSolicitacao.includes(user.numero_cpf)
        )
      );
  };

  const scrollToTable = debounce(async function () {
    const scrollTotal = Number(TableRef.current?.scrollTop);
    const heightWin =
      Number(TableRef.current?.scrollHeight) -
      Number(TableRef.current?.clientHeight);
    const scroll = (scrollTotal / heightWin) * 100;
    if (scroll >= Number(100) - Number(2)) {
      const index = listaUsersSitema.length;
      const { cpf, nome, email } = getValues();
      await getUsersSystem(cpf, nome, email, index);
    }
  }, 120);

  useEffect(() => {
    if (!listaUsersSitema.length) getUsersSystem();
    return () => {
      setListUsersSelected([]);
    };
  }, []);

  return (
    <CardPrimery container spacing={1}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        style={{ width: "100%" }}
      >
        <Grid container spacing={1} paddingTop="0.5rem">
          <Grid
            item
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <T.H6>
              {listUsersSelected.length
                ? `Usuário(s) selecionado(s) ${listUsersSelected.length}`
                : "Nenhum usuário selecionado"}
            </T.H6>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "1rem",
              }}
            >
              <Tooltip title="Filtar usuarios">
                <IconButton onClick={() => setIsFilter((state) => !state)}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>
            </div>
          </Grid>
          {isFilter && (
            <>
              <Grid item xs={12} md={3}>
                <InputBase
                  control={control}
                  id="cpf"
                  label="CPF"
                  name="cpf"
                  type="cpf"
                  isErrorMessage={false}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <InputBase
                  control={control}
                  id="nome"
                  label="Nome"
                  name="nome"
                  isErrorMessage={false}
                />
              </Grid>
              <Grid item xs={12} md={4}>
                <InputBase
                  control={control}
                  id="email"
                  label="Email"
                  name="email"
                  type="email"
                  isErrorMessage={false}
                />
              </Grid>
              <Grid item xs={12} md={2}>
                <ButtonSecondary
                  variant="outlined"
                  type="submit"
                  style={{ width: "100%" }}
                >
                  Buscar
                  <SearchIcon style={{ marginLeft: "0.5rem" }} />
                </ButtonSecondary>
              </Grid>
            </>
          )}
        </Grid>
      </form>
      <TableContainer
        ref={TableRef}
        sx={{ maxHeight: "45vh", minHeight: "45vh" }}
        onScroll={scrollToTable}
      >
        <Table stickyHeader aria-label="sticky table" size="small">
          <TableHead>
            <TableRow>
              <TableCell padding="checkbox">
                <Checkbox
                  color="primary"
                  indeterminate={
                    listUsersSelected.length > 0 &&
                    listUsersSelected.length < listaUsersSitema.length
                  }
                  checked={
                    listaUsersSitema.length > 0 &&
                    listUsersSelected.length === listaUsersSitema.length
                  }
                  onChange={handleSelectAllClick}
                  inputProps={{
                    "aria-label": "select all desserts",
                  }}
                />
              </TableCell>

              <TableCell style={{ minWidth: "10rem", width: "15rem" }}>
                CPF
              </TableCell>
              <TableCell style={{ minWidth: "15rem", width: "15rem" }}>
                NOME
              </TableCell>
              <TableCell style={{ width: "100%" }}>EMAIL</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {listaUsersSitema.length
              ? listaUsersSitema.map((item, index) => {
                  const labelId = `enhanced-table-checkbox-${index}`;
                  const isItemSelected = listUsersSelected.some(
                    (userSelected) => userSelected.id === item.id
                  );

                  return (
                    <TableRow
                      hover
                      style={{ cursor: "pointer" }}
                      role="checkbox"
                      key={item.id}
                      selected={isItemSelected}
                      onClick={() => {
                        if (
                          !listaCPFUserJaNaSolicitacao.includes(item.numero_cpf)
                        )
                          handleSelected(item);
                      }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          disabled={listaCPFUserJaNaSolicitacao.includes(
                            item.numero_cpf
                          )}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>

                      <TableCell>{cpfMask(item.numero_cpf)}</TableCell>
                      <TableCell>{item.nome}</TableCell>
                      <TableCell>{item.email}</TableCell>
                    </TableRow>
                  );
                })
              : null}
          </TableBody>
        </Table>
      </TableContainer>
    </CardPrimery>
  );
}
