import styled, { css } from "styled-components";

type Props = {
  isOpenCorrecion?: boolean;
  isSelected?: boolean;
  isCamera?: boolean;
  backgroundUrl?: string;
};

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 2rem;
  padding: 0 1.5rem;

  @media screen {
    @media (max-width: 768px) {
      padding: 0;
    }
  }
`;

export const FormContent = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
`;

export const BoxDocImages = styled.div`
  width: 100%;
  max-width: 100%;
  height: 400px;
  max-height: 400px;
  display: flex;
  justify-content: center;
  gap: 2rem;

  @media screen {
    @media (max-width: 768px) {
      flex-direction: column;
      max-height: initial;
      min-height: 300px;
      height: auto;

      & > div {
        width: 100%;
        max-width: 100%;
      }

      & > div:last-child {
        margin-top: 1rem;
      }
    }
  }
`;

export const BoxIconCenter = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ButtonsTitle = styled.div`
  padding: 0 0.25rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const BoxBoxDocImageItem = styled.div`
  flex: 1;
  max-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  align-items: center;
`;

export const BoxBoxDocImageItemTitle = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const BoxDocImageLabelContainer = styled.div<Props>`
  ${({ theme, isOpenCorrecion, isSelected }) => css`
    flex: 1;
    width: 100%;
    min-height: 90%;
    max-height: 90%;
    border: 3px dashed #ddd;
    background-color: #f0f0f0;
    border-radius: 0.5rem;
    cursor: pointer;

    ${isOpenCorrecion &&
    css`
      &:hover {
        border-color: ${theme.palette.secondary.light};
        border-style: ${isSelected ? "solid" : "dashed"};
      }
    `}

    ${isSelected &&
    isOpenCorrecion &&
    css`
      border-color: ${theme.palette.secondary.light};
      border-style: solid;
    `}

    @media screen {
      @media (max-width: 768px) {
        min-height: 70%;
      }
    }
  `}
`;

export const BoxDocImageLabel = styled.label<Props>`
  ${({ isCamera, backgroundUrl }) => css`
    flex: 1;
    width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
    display: ${isCamera ? "initial" : "flex"};
    justify-content: center;
    align-items: center;
    cursor: pointer;
    position: relative;
    border-radius: 0.5rem;

    background-image: ${backgroundUrl ? `url(${backgroundUrl})` : "none"};
    background-size: contain;
    background-position: center center;
    background-repeat: no-repeat;
  `}
`;

export const FormBoxButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;
