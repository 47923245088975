import { action } from "typesafe-actions";

import { TableStructureTypes } from "./types";

export const setItensTableAction = (data: object) =>
  action(TableStructureTypes.SET_TABLE_ITEMS, data);

export const setItemAction = ({ data, key }: { data: object; key: string }) =>
  action(TableStructureTypes.SET_ITEM, { data, key });

export const getItemTableAction = (codTabela: number[]) =>
  action(TableStructureTypes.GET_TABLE_ITEM, codTabela);
