import styled, { css } from "styled-components";

type ProgressBarProps = {
  percent: number;
};

export const BoxProgressBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ProgressBarHeader = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    p {
      font-weight: bold;
      font-size: 0.775rem;
      color: ${theme.text.primary};
    }
  `}
`;

export const ProgressBody = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 0.5rem;
    background-color: ${theme.border.light};
    border-radius: 0.625rem;
    position: relative;
    cursor: pointer;
  `}
`;

export const ProgressBar = styled.div<ProgressBarProps>`
  ${({ percent }) => css`
    width: ${percent}%;
    height: 100%;
    transition: width 0.5s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.625rem;
    background-image: linear-gradient(
      136deg,
      rgb(97, 195, 240) 0%,
      rgb(55, 137, 175) 50%,
      rgb(0, 70, 103) 100%
    );
  `}
`;
