import { TextField } from "@mui/material";
import styled, { css } from "styled-components";

type weeItemProps = {
  disabled?: boolean;
  checked?: boolean;
};

export const Content = styled.div`
  ${({ theme }) => css`
    padding: 1.3rem 1rem 0.8rem 0.8rem;
    width: 477px;
    height: 318px;
    display: flex;
    flex-direction: column;

    @media (max-width: ${theme.device.xs}) {
      width: 100%;
      height: auto;
      padding: 0.8rem 0.25rem 0.5rem 0.5rem;
    }
  `}
`;

export const ContentHeader = styled.header`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  & > div {
    display: flex;
    align-items: center;
    gap: 0.5rem;
  }
`;

export const ContentBody = styled.div`
  flex: 1;
  width: 100%;
  margin-top: 0.7rem;
  display: flex;
  flex-direction: column;
`;

export const WeekHeaderContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 0.5rem;
    gap: 7px;

    @media (max-width: ${theme.device.xs}) {
      gap: 4px;
    }
  `}
`;

export const WeekHeaderItem = styled.div`
  ${({ theme }) => css`
    flex: 1;
    font-size: 12px;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: ${theme.device.xs}) {
      font-size: 8px;
      padding: 4px 6px;
      border-radius: 12px;
    }
  `}
`;

export const WeeContent = styled.div`
  ${({ theme }) => css`
    flex: 1;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 7px;
    max-height: 170px;
    overflow-y: auto;
    overflow-x: hidden;
    ::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #c5c5c5;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    @media (max-width: ${theme.device.xs}) {
      gap: 4px;
    }
  `}
`;

export const WeekItens = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 7px;

    @media (max-width: ${theme.device.xs}) {
      gap: 4px;
    }
  `}
`;

export const WeekItem = styled.div<weeItemProps>`
  ${({ theme, disabled, checked }) => css`
    flex: 1;
    color: ${theme.text.secondary};
    background-color: ${disabled ? theme.background.blueLight : "#78ACB7"};
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
    cursor: not-allowed;

    ${(!disabled || checked) &&
    css`
      cursor: pointer;
      &:hover {
        background-color: ${theme.palette.primary.main};
        color: ${theme.text.with};
      }
    `}

    ${checked &&
    css`
      background-color:${theme.palette.primary.main}};
      color: ${theme.text.with};
    `}

    ${disabled &&
    css`
      color: ${theme.text.disabled};
      pointer-events: none;
      cursor: not-allowed;
    `}

    @media (max-width: ${theme.device.xs}) {
      font-size: 8px;
      padding: 4px 6px;
      border-radius: 12px;
    }
  `}
`;

export const ContentFooter = styled.footer`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-top: 10px;

    @media (max-width: ${theme.device.xs}) {
      margin-top: 5px;
    }
  `}
`;

export const InputDate = styled(TextField)`
  position: relative;
  border: none !important;

  & > div > fieldset {
    border: none !important;
    display: none;
  }

  & > div > input {
    visibility: hidden;
    width: 0;
    height: 0;
  }

  button {
    position: absolute;
    right: 0;
    top: 0;
  }
`;
