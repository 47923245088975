/* eslint-disable func-names */
/* eslint-disable react/jsx-props-no-spreading */
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ButtonPrimary, ButtonSecondary } from "../../components/base/Buttons";
import { CardPrimery } from "../../components/base/Cards";
import * as TB from "../../components/base/Table";
import * as T from "../../components/base/Text";
import { BodyContainer } from "../../components/BodyContainer";
import { AutoComplete } from "../../components/Inputs/InputAutoComplete";
import { InputDate } from "../../components/Inputs/InputDate";
import { InputSelect } from "../../components/Inputs/InputSelect";
import { InputBase } from "../../components/Inputs/InputText";
import { useTableStructure } from "../../hooks/useTableStructure";
import { api } from "../../services/api";
import { setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { cpfMask } from "../../utils/mask";
import {
  convertData,
  createObjectValNotNull,
  debounce,
  getClientUrl,
} from "../../utils/utils";

type SearchTableProps = {
  // Usuario
  usuario?: string;
  cpf?: string;
  sexo?: string;
  faixa_etaria?: string;
  tipo_dependente_item?: string;
  pais_item?: string;
  estado_item?: string;
  cidade_item?: string;

  // solicitação
  protocoloSol?: string;
  statusPreenchimento?: string;
  tipo_solicitacao_item?: string;
  dataInicioSol?: string;
  dataFinalSol?: string;
  id_solicitacao?: string;

  // ordenação
  ordem?: string;
  limit?: string;
  offset?: string;
};

type UserSolicitationTable = {
  id_sol_usuario: number;
  nome: string;
  numero_cpf: string;
  protocolo_preenchimento: string;
  status: string;
  tipo_solicitacao: string;
  solicitacao: string;
  progress: number;
  id_solicit_revisada?: number;
  ind_conclusao_administrativa?: "S" | "N";
};

export function MySolicitations() {
  const [listaSolicitacoes, setListaSolicitacoes] = useState<
    Array<{ id_solicitacao: string; descr_solicitacao: string }>
  >([]);
  const { handleSubmit, control, watch, reset } = useForm<SearchTableProps>();
  const TableRef = useRef<HTMLDivElement>(null);

  const navegate = useNavigate();

  const dispatch = useAppDispatch();

  const getParamsURl = () => {
    const params = new URLSearchParams(window.location.search);
    const paramsBase64 = params.get("params");

    if (
      paramsBase64 &&
      Object.keys(createObjectValNotNull(JSON.parse(atob(paramsBase64 || ""))))
        .length
    ) {
      return JSON.parse(atob(paramsBase64 || ""));
    }

    return {};
  };

  useTableStructure([
    "statusUserSolicitation",
    "typeSolicitation",
    "typeDependents",
    "paises",
    "estados",
    "cidades",
  ]);

  const {
    Utils: { device },
    TableStructure: {
      statusUserSolicitation,
      typeSolicitation,
      typeDependents,
    },
  } = useAppSelector((state) => state);

  const [userSolicitations, setUserSolicitations] = useState<
    UserSolicitationTable[]
  >([]);
  const [isFilter, setIsFilter] = useState(false);
  const [itemSupTabela, setItemSupTabela] = useState({
    itemSupEstado: 26,
    itemSupCidade: 0,
  });

  async function getUserSolicitations(
    params?: SearchTableProps,
    offset?: number
  ) {
    try {
      const paramsJson = {
        cpf: params?.cpf || "",
        usuario: params?.usuario || "",
        sexo: params?.sexo || "",
        faixa_etaria: params?.faixa_etaria || "",
        tipo_dependente_item: params?.tipo_dependente_item || "",
        pais_item: params?.pais_item || "",
        estado_item: params?.estado_item || "",
        cidade_item: params?.cidade_item || "",
        id_solicitacao: params?.id_solicitacao || "",
        statusPreenchimento: params?.statusPreenchimento || "",
        protocoloSol: params?.protocoloSol || "",
        dataInicioSol: convertData(params?.dataInicioSol) || "",
        dataFinalSol: convertData(params?.dataFinalSol) || "",
        tipo_solicitacao_item: params?.tipo_solicitacao_item || "",
        limit: params?.limit || "",
        offset: offset || "",
        ordem: params?.ordem || "",
      };
      const paramsBase64 = btoa(JSON.stringify(paramsJson));

      if (Object.keys(createObjectValNotNull(paramsJson)).length) {
        window.history.replaceState(null, "", `?params=${paramsBase64}`);
      }

      const { data } = await api.get(
        `/solicitation/users/in-progress/${paramsBase64}`
      );

      if (offset) {
        setUserSolicitations((state) => [...state, ...data]);
      } else {
        setUserSolicitations(data);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  }

  const onSubmit: SubmitHandler<SearchTableProps> = async (body) => {
    try {
      getUserSolicitations(body);
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  };

  const getValoresTabelaEstruturada = async (
    target: any,
    codTabela: number,
    codTabelaSup?: number,
    itemSup?: number
  ) => {
    const descr_tabela = target?.value;

    const { data } = await api.get(
      `/system/structured-table?cod=${codTabela || ""}&descricao=${
        descr_tabela || ""
      }&codSup=${codTabelaSup || ""}&itemSup=${itemSup || ""}`
    );

    const newValue = data
      .filter((item: any) => item.item_tabela !== 0)
      .map((item: any) => ({
        id: item.item_tabela,
        text: `${item.descr_tabela} - ${item.sigla_tabela}`,
      }));

    return newValue;
  };

  const getSolicitacoes = async () => {
    const { data } = await api.get("/solicitation/list");
    if (!data.length) return;
    setListaSolicitacoes(data);
  };

  useEffect(() => {
    setItemSupTabela({
      itemSupEstado: Number(watch().pais_item) || 0,
      itemSupCidade: Number(watch().estado_item) || 0,
    });
  }, [watch().pais_item, watch().estado_item]);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsJson = getParamsURl();

    if (Object.keys(createObjectValNotNull(paramsJson)).length) {
      reset(paramsJson);
      getUserSolicitations(paramsJson);
      getSolicitacoes();
      setIsFilter(true);
    } else {
      params.delete("params");
      getUserSolicitations();
      getSolicitacoes();
    }

    return () => {
      params.delete("params");
    };
  }, []);

  const scrollToTable = debounce(async function () {
    const scrollTotal = Number(TableRef.current?.scrollTop);
    const heightWin =
      Number(TableRef.current?.scrollHeight) -
      Number(TableRef.current?.clientHeight);
    const scroll = (scrollTotal / heightWin) * 100;
    if (scroll >= Number(100) - Number(2)) {
      const index = userSolicitations.length;
      getUserSolicitations(getParamsURl(), index);
    }
  }, 120);

  const exportToCSV = async () => {
    // buscar dados completo
    const paramsJson = getParamsURl();

    const jsonBody = {
      cpf: paramsJson?.cpf || "",
      usuario: paramsJson?.usuario || "",
      sexo: paramsJson?.sexo || "",
      faixa_etaria: paramsJson?.faixa_etaria || "",
      tipo_dependente_item: paramsJson?.tipo_dependente_item || "",
      pais_item: paramsJson?.pais_item || "",
      estado_item: paramsJson?.estado_item || "",
      cidade_item: paramsJson?.cidade_item || "",
      id_solicitacao: paramsJson?.id_solicitacao || "",
      statusPreenchimento: paramsJson?.statusPreenchimento || "",
      protocoloSol: paramsJson?.protocoloSol || "",
      dataInicioSol: convertData(paramsJson?.dataInicioSol) || "",
      dataFinalSol: convertData(paramsJson?.dataFinalSol) || "",
      tipo_solicitacao_item: paramsJson?.tipo_solicitacao_item || "",
      limit: 10000,
      offset: 0,
      ordem: paramsJson?.ordem || "",
    };

    const { data } = await api.get(
      `/solicitation/users/in-progress/${btoa(JSON.stringify(jsonBody))}`
    );

    // criar um array com os dados que serão exportados
    const csvRow = data.map((row: UserSolicitationTable) => [
      row.solicitacao,
      row.nome || "",
      row.numero_cpf,
      row.status,
      row.tipo_solicitacao,
      `${row.progress}%`,
    ]);

    // criar um array com os headers
    const headers = [
      "Solicitação",
      "Nome",
      "CPF",
      "Status",
      "Tipo",
      "Progresso",
    ];

    // unir os headers com os dados
    csvRow.unshift(headers);

    // criar um arquivo csv
    const csv = csvRow.join("\r\n");
    const blob = new Blob([csv], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "relatorio.csv";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  return (
    <BodyContainer menu="Em Andamento">
      <Grid
        container
        spacing={1}
        style={{
          margin: "1rem 0 1.5rem 0",
          borderLeft: "4px solid #ff6e07",
          paddingLeft: "1rem",
        }}
      >
        <Grid item xs={12}>
          <T.H2>SOLICITAÇÕES EM ANDAMENTO</T.H2>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "0.6rem",
          }}
        >
          <T.H6 lineHeight="1.3rem">
            Acompanhe o andamento das solicitações abaixo.
          </T.H6>
        </Grid>
      </Grid>
      <CardPrimery container spacing={1} height="100%">
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ flex: "1" }}
        >
          <Grid container spacing={1}>
            <Grid item xs={12} display="flex" justifyContent="flex-end" gap={1}>
              {!!userSolicitations.length && (
                <div>
                  <ButtonSecondary
                    variant="outlined"
                    style={{ gap: "0.5rem" }}
                    onClick={exportToCSV}
                  >
                    Exportar
                    <ExitToAppIcon />
                  </ButtonSecondary>
                </div>
              )}
              <div style={{ width: "3rem" }}>
                <Tooltip title="Pesquisa avançada">
                  <IconButton onClick={() => setIsFilter((state) => !state)}>
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
            {isFilter && (
              <>
                <Grid item xs={12}>
                  <T.H6>Pesquisar por solicitação</T.H6>
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputSelect
                    title="Soliciatação"
                    control={control}
                    id="id_solicitacao"
                    label="Soliciatação"
                    name="id_solicitacao"
                    isErrorMessage={false}
                  >
                    <option> </option>
                    {listaSolicitacoes.length &&
                      listaSolicitacoes.map((type) => (
                        <option
                          key={type.id_solicitacao}
                          value={type.id_solicitacao}
                        >
                          {type.descr_solicitacao}
                        </option>
                      ))}
                  </InputSelect>
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputBase
                    control={control}
                    id="protocoloSol"
                    label="Protocolo"
                    name="protocoloSol"
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputSelect
                    control={control}
                    title="Tipo de solicitação"
                    label="Tipo de solicitação"
                    id="tipo_solicitacao_item"
                    name="tipo_solicitacao_item"
                    isErrorMessage={false}
                  >
                    <option> </option>
                    {typeSolicitation.itemsTable?.length &&
                      typeSolicitation.itemsTable.map((item) => {
                        if (item.item_tabela === 0) return null;
                        return (
                          <option
                            key={item.item_tabela}
                            value={item.item_tabela}
                          >
                            {item.descr_tabela}
                          </option>
                        );
                      })}
                  </InputSelect>
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputSelect
                    control={control}
                    title="Status de preenchimento"
                    label="Status de preenchimento"
                    id="statusPreenchimento"
                    name="statusPreenchimento"
                    isErrorMessage={false}
                  >
                    <option> </option>
                    {statusUserSolicitation.itemsTable?.length &&
                      statusUserSolicitation.itemsTable.map((item) => {
                        if (item.item_tabela === 0) return null;
                        return (
                          <option
                            key={item.item_tabela}
                            value={item.item_tabela}
                          >
                            {item.descr_tabela}
                          </option>
                        );
                      })}
                  </InputSelect>
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputDate
                    control={control}
                    id="dataInicioSol"
                    name="dataInicioSol"
                    label="Data inicial"
                    defaultValue=""
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputDate
                    control={control}
                    id="dataFinalSol"
                    name="dataFinalSol"
                    label="Data final"
                    defaultValue=""
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12}>
                  <T.H6>Pesquisar por dados de preechimento</T.H6>
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputBase
                    control={control}
                    id="usuario"
                    label="Usuário"
                    name="usuario"
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputBase
                    control={control}
                    id="cpf"
                    label="CPF"
                    name="cpf"
                    type="cpf"
                    rules={{
                      maxLength: {
                        value: 14,
                        message:
                          "O CPF deve ter no máximo 11 dígitos, sem pontos e traços",
                      },
                    }}
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputSelect
                    control={control}
                    title="Sexo"
                    label="Sexo"
                    id="sexo"
                    name="sexo"
                    isErrorMessage={false}
                  >
                    <option> </option>
                    <option value="M">Masculino</option>
                    <option value="F">Feminino</option>
                  </InputSelect>
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputBase
                    control={control}
                    label="Faixa etária"
                    id="faixa_etaria"
                    name="faixa_etaria"
                    type="faixaEtaria"
                    rules={{
                      maxLength: {
                        value: 5,
                        message: "A faixa etária deve ter no máximo 2 dígitos",
                      },
                      minLength: {
                        value: 5,
                        message: "A faixa etária deve ter no mínimo 2 dígitos",
                      },
                    }}
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputSelect
                    control={control}
                    title="Tipo de dependente"
                    label="Tipo de dependente"
                    id="tipo_dependente_item"
                    name="tipo_dependente_item"
                    isErrorMessage={false}
                  >
                    <option> </option>
                    {typeDependents.itemsTable?.length &&
                      typeDependents.itemsTable.map((item) => {
                        if (item.item_tabela === 0) return null;
                        return (
                          <option
                            key={item.item_tabela}
                            value={item.item_tabela}
                          >
                            {item.descr_tabela}
                          </option>
                        );
                      })}
                  </InputSelect>
                </Grid>
                <Grid item xs={12} md={2}>
                  <AutoComplete
                    control={control}
                    title="País"
                    label="País"
                    id="pais_item"
                    name="pais_item"
                    optionsArray={[]}
                    isErrorMessage={false}
                    getId
                    getValuesArray={(target: any) => {
                      return getValoresTabelaEstruturada(target, 1);
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <AutoComplete
                    control={control}
                    title="Estado"
                    label="Estado"
                    id="estado_item"
                    name="estado_item"
                    optionsArray={[]}
                    isErrorMessage={false}
                    getId
                    getValuesArray={(target: any) => {
                      return getValoresTabelaEstruturada(
                        target,
                        2,
                        1,
                        itemSupTabela.itemSupEstado
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <AutoComplete
                    control={control}
                    title="Cidade"
                    label="Cidade"
                    id="cidade_item"
                    name="cidade_item"
                    optionsArray={[]}
                    isErrorMessage={false}
                    getId
                    getValuesArray={(target: any) => {
                      return getValoresTabelaEstruturada(
                        target,
                        3,
                        2,
                        itemSupTabela.itemSupCidade
                      );
                    }}
                  />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <ButtonPrimary type="submit" style={{ gap: "0.5rem" }}>
                    Busca
                    <SearchIcon />
                  </ButtonPrimary>
                </Grid>
              </>
            )}
          </Grid>
        </form>

        <TB.TableContainer
          sx={{
            maxHeight: "calc(100vh - 300px)",
            minHeight: "65vh",
            marginTop: isFilter ? "1rem" : "initial",
          }}
          ref={TableRef}
          onScroll={scrollToTable}
        >
          <TB.Table stickyHeader aria-label="sticky table">
            <TB.TableHead>
              <TB.TableRow>
                <TB.TableCell width="100%">SOLICITAÇÃO</TB.TableCell>
                <TB.TableCell
                  {...(device === "desktop"
                    ? { width: "15rem", maxWidth: "15rem", minWidth: "15rem" }
                    : { width: "8rem", maxWidth: "8rem", minWidth: "8rem" })}
                >
                  NOME
                </TB.TableCell>
                {device === "desktop" && (
                  <TB.TableCell minWidth="10rem" width="10rem">
                    CPF
                  </TB.TableCell>
                )}
                <TB.TableCell
                  align="center"
                  {...(device === "desktop"
                    ? { width: "12rem", maxWidth: "12rem", minWidth: "12rem" }
                    : { width: "7rem", maxWidth: "7rem", minWidth: "7rem" })}
                >
                  STATUS
                </TB.TableCell>
                {device === "desktop" && (
                  <TB.TableCell align="center" minWidth="12rem">
                    TIPO
                  </TB.TableCell>
                )}
                <TB.TableCell
                  align="center"
                  {...(device === "desktop"
                    ? { width: "8rem", maxWidth: "8rem", minWidth: "8rem" }
                    : { width: "3rem", maxWidth: "3rem", minWidth: "3rem" })}
                >
                  {device === "desktop" && "PROGRESSO"}
                </TB.TableCell>
              </TB.TableRow>
            </TB.TableHead>
            <TB.TableBody>
              {userSolicitations.length
                ? userSolicitations.map((item) => (
                    <TB.TableRow
                      hover
                      style={{ cursor: "pointer" }}
                      role="checkbox"
                      key={item.id_sol_usuario}
                      onClick={() => {
                        navegate(
                          `../${getClientUrl()}/solicitacao/${
                            item.id_sol_usuario
                          }`,
                          { state: { solicitation: item } }
                        );
                      }}
                    >
                      <TB.TableCell>
                        {item.solicitacao}
                        {item?.ind_conclusao_administrativa === "S" && (
                          <T.Span margin="0 0.5rem" color="#1c8513e1">
                            | Conclusão administrativa
                          </T.Span>
                        )}
                        {item.id_solicit_revisada && (
                          <T.Span margin="0 0.5rem" color="#006494">
                            | Revisada
                          </T.Span>
                        )}
                      </TB.TableCell>
                      <TB.TableCell>{item.nome}</TB.TableCell>
                      {device === "desktop" && (
                        <TB.TableCell>{cpfMask(item.numero_cpf)}</TB.TableCell>
                      )}
                      <TB.TableCell align="center">{item.status}</TB.TableCell>
                      {device === "desktop" && (
                        <TB.TableCell align="center">
                          {item.tipo_solicitacao}
                        </TB.TableCell>
                      )}
                      <TB.TableCell align="center">
                        {item.progress}%
                      </TB.TableCell>
                    </TB.TableRow>
                  ))
                : null}
            </TB.TableBody>
          </TB.Table>
        </TB.TableContainer>
      </CardPrimery>
    </BodyContainer>
  );
}
