import { all, takeLatest } from "redux-saga/effects";

import {
  getClientLoggedSaga,
  getClientToUpdateSaga,
  getClientsArraySaga,
} from "./Clients/sagas";
import { ClientsTypes } from "./Clients/types";
import {
  getDataGraphicPizzaSaga,
  getDataGraphicBarSaga,
} from "./Graphics/sagas";
import { GraphicsTypes } from "./Graphics/types";
import { getSystemParametersTypesSaga } from "./SystemParameters/sagas";
import { SystemParametersTypes } from "./SystemParameters/types";
import { getItemTableStructureSaga } from "./TableStructure/sagas";
import { TableStructureTypes } from "./TableStructure/types";
import { getUserSaga } from "./User/sagas";
import { UserTypes } from "./User/types";

export default function* rootSaga() {
  yield all([
    // userSaga
    takeLatest(
      SystemParametersTypes.GET_PARAMETERS,
      getSystemParametersTypesSaga
    ),
    takeLatest(ClientsTypes.GET_CLIENTS_ARRAY, getClientsArraySaga),
    takeLatest(ClientsTypes.GET_CLIENT_LOGGED, getClientLoggedSaga),
    takeLatest(ClientsTypes.GET_CLIENT_TO_UPDATE, getClientToUpdateSaga),
    takeLatest(TableStructureTypes.GET_TABLE_ITEM, getItemTableStructureSaga),
    takeLatest(UserTypes.GET_USER, getUserSaga),
    takeLatest(GraphicsTypes.GET_GRAPHIC_PIZZA, getDataGraphicPizzaSaga),
    takeLatest(GraphicsTypes.GET_GRAPHIC_BAR, getDataGraphicBarSaga),
  ]);
}
