/* eslint-disable react/no-danger */
import { Grid } from "@mui/material";
import axios, { AxiosError } from "axios";
import { useEffect, useState } from "react";

import { CardPrimery } from "../../components/base/Cards";
import * as T from "../../components/base/Text";
import { BodyContainer } from "../../components/BodyContainer";
import { TabNavigation } from "../../components/TabNavigation";
import { TOKEN_KEY } from "../../services/auth";
import { setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../store/hooks";

export function ErrorLogs() {
  const [logsApp, setLogsApp] = useState("");
  const [logsJobs, setLogsJobs] = useState("");
  const [tabValue, setTabValue] = useState(0);

  const dispatch = useAppDispatch();

  async function getLogs() {
    try {
      const { data } = await axios.get(
        `${process.env.REACT_APP_BASE_URL_API}error-log`,
        {
          headers: {
            Authorization: String(sessionStorage.getItem(TOKEN_KEY)),
          },
        }
      );
      setLogsApp(data.errosApp);
      setLogsJobs(data.errosJobs);
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  }

  useEffect(() => {
    getLogs();
  }, []);

  return (
    <BodyContainer
      menu="logs"
      bodyStyle={{
        maxHeight: "calc(100vh - 70px)",
      }}
    >
      <Grid
        container
        spacing={1}
        style={{
          margin: "1rem 0 1.5rem 0",
          borderLeft: "4px solid #ff6e07",
          paddingLeft: "1rem",
        }}
      >
        <Grid item xs={12}>
          <T.H2>Logs de erro sistema</T.H2>
        </Grid>
      </Grid>
      <TabNavigation
        labesTabs={["Logs do sistema", "Logs dos Jobs"]}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />
      <CardPrimery
        style={{
          flex: 1,
          maxHeight: "inherit",
          overflowY: "auto",
          padding: "1rem",
        }}
      >
        {tabValue === 0 && (
          <pre dangerouslySetInnerHTML={{ __html: logsApp }} />
        )}
        {tabValue === 1 && (
          <pre dangerouslySetInnerHTML={{ __html: logsJobs }} />
        )}
      </CardPrimery>
    </BodyContainer>
  );
}
