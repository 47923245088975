import styled from "styled-components";

export const ModalBody = styled.div`
  width: 70vw;
  height: min-content;
  padding: 10px 5% 20px 5%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    width: 100vw;
    padding: 10px 5% 15px 5%;
  }
`;
