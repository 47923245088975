/* eslint-disable @typescript-eslint/no-explicit-any */
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import * as B from "../../components/base/Buttons";
import { CardPrimery } from "../../components/base/Cards";
import * as T from "../../components/base/Text";
import { BodyContainer } from "../../components/BodyContainer";
import { InputDate } from "../../components/Inputs/InputDate";
import { InputPassword } from "../../components/Inputs/InputPassword";
import { InputBase } from "../../components/Inputs/InputText";
import { RulesPassword } from "../../components/RulesPassword";
import { api } from "../../services/api";
import { setUserAction } from "../../store/ducks/User/actions";
import { setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { convertData, removeMask } from "../../utils/utils";
import * as S from "./styles";

type FormUserData = {
  numero_cpf: string;
  nome: string;
  email: string;
  celular: string;
  senha: string;
  dt_nascimento: string;
  cargo: string;
  empresa: string;
};

type FormUpdatePassword = {
  currentPassword: string;
  password: string;
  confirmPassword: string;
};

export function Perfil() {
  const [password, setPassword] = useState("");
  const [hasValidatePassword, setHasValidatePassword] = useState(false);
  const { handleSubmit, control, reset, setError } = useForm<FormUserData>();
  const {
    handleSubmit: handleSubmitPassword,
    control: controlPassword,
    setError: setErrorPassword,
    reset: resetPassword,
  } = useForm<FormUpdatePassword>();

  const dispatch = useAppDispatch();
  const { user } = useAppSelector((state) => state.User);

  const onSubmitUserData: SubmitHandler<FormUserData> = async (body) => {
    try {
      const userData = {
        email: body.email,
        nome: body.nome,
        ...(body.celular && { celular: removeMask(body.celular) }),
        ...(body.dt_nascimento && {
          dt_nascimento: convertData(body.dt_nascimento as string),
        }),
        ...(body.cargo && { cargo: body.cargo }),
        ...(body.empresa && { empresa: body.empresa }),
      } as FormUserData;
      const { data } = await api.put("/user/update-user", userData);
      dispatch(setToastMessage({ type: "success", message: data.message }));
      dispatch(
        setUserAction({
          ...user,
          ...userData,
          dt_nascimento: body.dt_nascimento,
        })
      );
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
      if (error?.errors && error?.errors.length) {
        error.errors.forEach((error: any) => {
          setError(error.property, {
            type: "manual",
            message: error.error,
          });
        });
      }
    }
  };

  const onSubmitUpdatePassword: SubmitHandler<FormUpdatePassword> = async (
    body
  ) => {
    try {
      if (body.confirmPassword !== body.password) {
        setErrorPassword("confirmPassword", {
          type: "manual",
          message: "As senhas não conferem",
        });
        return;
      }
      if (!hasValidatePassword) {
        setErrorPassword("password", {
          type: "manual",
          message: "Senha não atende aos requisitos",
        });
        return;
      }
      const { data } = await api.put("/user/update-password", body);
      dispatch(setToastMessage({ type: "success", message: data.message }));
      resetPassword();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
      if (error?.errors && error?.errors.length) {
        error.errors.forEach((error: any) => {
          setErrorPassword(error.property, {
            type: "manual",
            message: error.error,
          });
        });
      }
    }
  };

  const updatePhoto = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      if (e.target.files) {
        const file = e.target.files.item(0) as File;
        const formData = new FormData();
        formData.append("file", file);
        const { data } = await api.put("/user/update-photo", formData);
        dispatch(
          setUserAction({
            ...user,
            img_foto: data.file,
          })
        );
        dispatch(setToastMessage({ type: "success", message: data.message }));
      }
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  useEffect(() => {
    if (user.codigo_interno) {
      reset({
        ...user,
        dt_nascimento: user.dt_nascimento,
      } as FormUserData);
    }
  }, [user]);

  return (
    <BodyContainer menu="Perfil">
      <Grid container spacing={1} style={{ marginBottom: "2rem" }}>
        <Grid item xs={12} display="flex" alignItems="flex-end" gap="1rem">
          <S.BoxImage
            htmlFor="archive"
            image={
              user.img_foto ? `data:image/png;base64,${user.img_foto}` : ""
            }
          >
            <>
              <input
                type="file"
                name=""
                id="archive"
                accept="image/*"
                onChange={updatePhoto}
                style={{ display: "none" }}
              />
              {!user.img_foto && (
                <PhotoCameraIcon fontSize="large" color="action" />
              )}
            </>
          </S.BoxImage>
          <T.H2 color="#555">{user.nome}</T.H2>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12} md={9} height="100%">
          <CardPrimery container spacing={1} height="100%">
            <form
              onSubmit={handleSubmit(onSubmitUserData)}
              noValidate
              style={{ width: "100%", height: "100%" }}
            >
              <Grid
                container
                spacing={1}
                width="100%"
                marginTop="1rem"
                display="flex"
                justifyContent="flex-start"
                alignContent="flex-start"
                alignItems="flex-end"
                minHeight="100%"
              >
                <Grid item xs={12} md={4}>
                  <InputBase
                    control={control}
                    id="numero_cpf"
                    label="Numero CPF"
                    name="numero_cpf"
                    type="cpf"
                    disabled
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <InputBase
                    control={control}
                    id="email"
                    label="Email"
                    name="email"
                    type="email"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={8}>
                  <InputBase
                    control={control}
                    id="nome"
                    label="Nome"
                    name="nome"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputDate
                    control={control}
                    id="dt_nascimento"
                    label="Data Nascimento"
                    name="dt_nascimento"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputBase
                    control={control}
                    id="celular"
                    label="Celular"
                    type="phone"
                    name="celular"
                    maxLength={15}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputBase
                    control={control}
                    id="empresa"
                    label="Empresa"
                    name="empresa"
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputBase
                    control={control}
                    id="cargo"
                    label="Cargo"
                    name="cargo"
                  />
                </Grid>
                {user?.token_api && (
                  <Grid item xs={12} md={12}>
                    <InputBase
                      control={control}
                      id="token_api"
                      label="Token Api"
                      name="token_api"
                      readOnly
                    />
                  </Grid>
                )}
                <Grid item xs={12} md={4} height="100%" alignSelf="self-end">
                  <B.ButtonPrimary type="submit" style={{ width: "100%" }}>
                    Salvar dados
                  </B.ButtonPrimary>
                </Grid>
              </Grid>
            </form>
          </CardPrimery>
        </Grid>
        <Grid item xs={12} md={3}>
          <CardPrimery container spacing={1}>
            <form
              onSubmit={handleSubmitPassword(onSubmitUpdatePassword)}
              noValidate
              style={{ width: "100%", height: "100%" }}
            >
              <Grid
                container
                spacing={1}
                style={{ width: "100%", marginTop: "1rem" }}
              >
                <Grid item xs={12}>
                  <InputPassword
                    control={controlPassword}
                    id="currentPassword"
                    label="Senha atual"
                    name="currentPassword"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputPassword
                    control={controlPassword}
                    id="password"
                    label="Nova senha"
                    name="password"
                    customChange={(e) => {
                      setPassword(e.target.value);
                    }}
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <InputPassword
                    control={controlPassword}
                    id="confirmPassword"
                    label="Confirmar senha"
                    name="confirmPassword"
                    required
                  />
                </Grid>
                <Grid item xs={12}>
                  <RulesPassword
                    password={password}
                    setHasValidatePassword={setHasValidatePassword}
                  />
                </Grid>
                <Grid item xs={12}>
                  <B.ButtonPrimary
                    type="submit"
                    style={{ width: "100%", marginTop: "1rem" }}
                  >
                    Alterar senha
                  </B.ButtonPrimary>
                </Grid>
              </Grid>
            </form>
          </CardPrimery>
        </Grid>
      </Grid>
    </BodyContainer>
  );
}
