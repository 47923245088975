/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/no-array-index-key */
import { Grid } from "@mui/material";
import { useEffect, useLayoutEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../../services/api";
import { getSystemParametersAction } from "../../../../store/ducks/SystemParameters/actions";
import {
  setLoading,
  setToastMessage,
} from "../../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { convertSOrN } from "../../../../utils/utils";
import { ButtonPrimary } from "../../../base/Buttons";
import { CardPrimery } from "../../../base/Cards";
import * as T from "../../../base/Text";
import { InputCheckBox } from "../../../Inputs/inputCheckBox";
import { InputPassword } from "../../../Inputs/InputPassword";
import { InputBase } from "../../../Inputs/InputText";
import * as S from "../../styles";

type ParametrosProps = {
  descr_remet_mail: string;
  login_remet_mail: string;
  senha_remet_mail: string;
  smtp_mail: string;
  porta_mail: string;
  ind_tls_mail: "S" | "N";
  ind_ssl_mail: "S" | "N";
  url_appweb: string;
  cod_pais_sms: string;
  login_sms: string;
  senha_sms: string;
  url_api: string;
};

export function GenericParametrs() {
  const { handleSubmit, control, reset } = useForm<ParametrosProps>();

  const dispatch = useAppDispatch();

  const { systemParameters } = useAppSelector(
    ({ SystemParameters }) => SystemParameters
  );

  const onSubmit: SubmitHandler<ParametrosProps> = async (body) => {
    try {
      dispatch(setLoading(true));
      const newBody = {
        ...body,
        ind_ssl_mail: convertSOrN(body.ind_ssl_mail),
        ind_tls_mail: convertSOrN(body.ind_tls_mail),
        senha_sms: body.senha_sms ? body.senha_sms : null,
        senha_remet_mail: body.senha_remet_mail ? body.senha_remet_mail : null,
      };
      const { data } = await api.post("/system/update-parameters", newBody);
      dispatch(setToastMessage({ type: "success", message: data.message }));
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useLayoutEffect(() => {
    if (!systemParameters.descr_remet_mail)
      dispatch(getSystemParametersAction());
  }, []);

  useEffect(() => {
    reset(systemParameters);
  }, [systemParameters]);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      style={{ width: "100%" }}
    >
      <CardPrimery container spacing={1}>
        <Grid item xs={12}>
          <T.H6>Email</T.H6>
        </Grid>
        <Grid item xs={12} md={4}>
          <InputBase
            control={control}
            id="descr_remet_mail"
            label="Remetente"
            name="descr_remet_mail"
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputBase
            control={control}
            id="login_remet_mail"
            label="Login"
            name="login_remet_mail"
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputPassword
            control={control}
            id="senha_remet_mail"
            label="Senha"
            name="senha_remet_mail"
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputBase
            control={control}
            id="smtp_mail"
            label="Servidor SMTP"
            name="smtp_mail"
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputBase
            control={control}
            id="porta_mail"
            label="Porta SMTP"
            name="porta_mail"
            type="number"
            required
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <InputCheckBox
            control={control}
            id="ind_ssl_mail"
            label="Usar SSL"
            name="ind_ssl_mail"
          />
        </Grid>
        <Grid item xs={12} md={2}>
          <InputCheckBox
            control={control}
            id="ind_tls_mail"
            label="Usar TLS"
            name="ind_tls_mail"
          />
        </Grid>
      </CardPrimery>
      <CardPrimery container spacing={1}>
        <Grid item xs={12}>
          <T.H6>SMS</T.H6>
        </Grid>
        <Grid item xs={12} md={2}>
          <InputBase
            control={control}
            id="cod_pais_sms"
            label="COD. PAIS"
            name="cod_pais_sms"
            required
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <InputBase
            control={control}
            id="login_sms"
            label="login SMS"
            name="login_sms"
            required
          />
        </Grid>
        <Grid item xs={12} md={5}>
          <InputPassword
            control={control}
            id="senha_sms"
            label="Senha SMS"
            name="senha_sms"
          />
        </Grid>
      </CardPrimery>
      <CardPrimery container spacing={1}>
        <Grid item xs={12}>
          <T.H6>Gerais</T.H6>
        </Grid>
        <Grid item xs={12} md={6}>
          <InputBase
            control={control}
            id="url_appweb"
            label="Url App Web"
            name="url_appweb"
            required
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <InputBase
            control={control}
            id="url_api"
            label="Url API"
            name="url_api"
            required
          />
        </Grid>
      </CardPrimery>
      <S.BoxButton container spacing={1}>
        <Grid item xs={12} md={2} sm={5}>
          <ButtonPrimary type="submit" style={{ width: "100%" }}>
            Enviar
          </ButtonPrimary>
        </Grid>
      </S.BoxButton>
    </form>
  );
}
