import { Reducer } from "redux";

import { SolicitationTypes, SolicitationState } from "./types";

const INITIAL_STATE: SolicitationState = {
  identificacaoGeral: {
    nome_completo: "",
    nome_social: "",
    dt_nascimento: "",
    numero_cpf: "",
    nis: null,
    sexo: "M",
    nome_pai: "",
    nome_mae: "",
    estado_civil: null,
    etnia: null,
    nacionalidade: null,
    cidade: null,
    estado: null,
    pais: null,
    escolaridade: null,
    deficiencia: null,
    obs_deficiencia: "",
    cid: null,
    cor_cabelo: null,
    cor_olhos: null,
    ind_preench_terceiro: "",
    nome_compl_preench_terceiro: "",
    cpf_preench_terceiro: "",
    rg_preench_terceiro: "",
    fone_preench_terceiro: "",
  },
  dadosComplementares: {
    id_sol_usuario: null,
    id_sol_dados_compl: null,
    email: "",
    telefone_fixo: "",
    telefone_movel: "",
    endereco: "",
    numero: "",
    bairro: "",
    cidade: null,
    pais: null,
    estado: null,
    complemento: "",
    cep: "",
    dt_chegada_brasil: "",
    dt_naturalizacao: "",
    casado_br: "N",
    filho_br: "N",
    ind_comprada_fgts: "N",
    ind_reside_exterior: "N",
    ind_residencia_propria: "N",
    ind_trabalhador_estrang: "N",
  },
};

const reducer: Reducer<SolicitationState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SolicitationTypes.SET_IDENTIFICATION_GENERIC:
      return { ...state, identificacaoGeral: action.payload };

    case SolicitationTypes.SET_DADOS_COMPLEMENTARES:
      return { ...state, dadosComplementares: action.payload };

    case SolicitationTypes.SET_STATE_DEFAULT:
      return INITIAL_STATE;

    default:
      return state;
  }
};

export default reducer;
