import PersonIcon from "@mui/icons-material/Person";
import { useNavigate } from "react-router-dom";

import { useAppSelector } from "../../store/hooks";
import { getClientUrl } from "../../utils/utils";
import * as T from "../base/Text";
import { ButtonMenu } from "../ButtonMenu";
import * as S from "./styles";

export function Header() {
  const navegation = useNavigate();

  const { device } = useAppSelector(({ Utils }) => Utils);
  const { user } = useAppSelector((state) => state.User);

  return (
    <S.Container>
      <S.Content>
        <S.BoxLeft onClick={() => navegation(`/${getClientUrl()}/home`)}>
          <img src="/images/logo-censo-2.png" alt="Icone de Logo" />
        </S.BoxLeft>
        <S.BoxRight>
          {device === "desktop" ? (
            <>
              <S.BoxImage
                image={
                  user.img_foto ? `data:image/png;base64,${user.img_foto}` : ""
                }
                onClick={() => navegation(`/${getClientUrl()}/perfil`)}
              >
                {!user.img_foto && <PersonIcon />}
              </S.BoxImage>
              <T.H6
                width="min-content"
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  marginLeft: "0.5rem",
                }}
              >
                {user.nome}
              </T.H6>
            </>
          ) : (
            <ButtonMenu />
          )}
        </S.BoxRight>
      </S.Content>
    </S.Container>
  );
}
