/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */

import {
  FormControl,
  FormHelperText,
  FormLabel,
  RadioGroup,
} from "@mui/material";
import { ReactNode } from "react";
import { Controller } from "react-hook-form";

interface IInputRadios {
  control: any;
  id: string;
  title: string;
  name: string;
  defaultValue?: string | number;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  row?: boolean;
  isErrorMessage?: boolean;
  children: ReactNode;
}

export function InputRadios({
  control,
  id,
  name,
  title,
  defaultValue,
  disabled,
  readOnly,
  required,
  errorMessage,
  row,
  isErrorMessage,
  children,
}: IInputRadios) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <FormControl
            component="fieldset"
            required={required}
            disabled={disabled || readOnly}
            variant="outlined"
            fullWidth
            size="small"
            id={id}
            error={!!error?.message || !!errorMessage}
          >
            <FormLabel id={id}>{title}</FormLabel>
            <RadioGroup
              aria-label={title}
              name={name}
              value={value || ""}
              onChange={onChange}
              row={row}
            >
              {children}
            </RadioGroup>
            <FormHelperText
              style={{ margin: "2px 0 0 2px" }}
              error={!!error?.message || !!errorMessage}
            >
              {isErrorMessage === false
                ? ""
                : error?.message || errorMessage || " "}
            </FormHelperText>
          </FormControl>
        );
      }}
      rules={{
        required: {
          value: !!required,
          message: `${title} é obrigatório`,
        },
      }}
    />
  );
}
