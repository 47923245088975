import { Drawer } from "@mui/material";
import styled, { css } from "styled-components";

export const DrawerBottom = styled(Drawer)``;

export const DrawerContainer = styled.div`
  ${() => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    width: 40vw;
    height: 100vh;
    padding: 0 3rem;
    position: relative;

    @media screen {
      @media (max-width: 768px) {
        width: 80vw;
        padding: 0 1rem;
      }
    }
  `}
`;

export const DrawerHeader = styled.header`
  ${({ theme }) => css`
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 1rem;
    padding-top: 1rem;

    width: 100%;
    height: 5rem;

    color: ${theme.palette.primary.main};
    h2 {
      font-size: 1.5rem;
      color: ${theme.palette.primary.main};
    }
  `}
`;

export const DrawerContent = styled.div`
  ${() => css`
    flex: 1;
    width: 100%;
    max-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    gap: 1rem;
    padding: 2rem 0.25rem 0 0;
    overflow-x: hidden;
    overflow-y: auto;

    ::-webkit-scrollbar {
      display: none;
    }

    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow-y: scroll;

    -webkit-overflow-scrolling: touch;
  `}
`;

export const DrawerFooter = styled.footer`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 1rem 0;
    border-top: 1px solid ${theme.border.light};
  `}
`;

export const BoxDetails = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: max-content;
    border-radius: 0.25rem;
    white-space: nowrap;
    text-transform: uppercase;
    border: 1px solid ${theme.palette.primary.light};
    background-color: ${theme.background.blueLight};
    fill-opacity: 0.8;
    padding: 0.8rem;
    position: relative;

    p {
      font-size: ${theme.typography.h6.fontSize};
      font-weight: ${theme.typography.h6.fontWeightBold};
      color: ${theme.text.secondary};
    }
    span {
      padding-left: 0.25rem;
      font-size: 0.875rem;
      color: ${theme.text.secondary};
      white-space: normal;
    }
  `}
`;

export const BoxDetailsTime = styled.div`
  position: absolute;
  top: 1rem;
  right: 1rem;

  display: flex;
  align-items: center;
`;
