/* eslint-disable @typescript-eslint/no-explicit-any */
import AddTaskIcon from "@mui/icons-material/AddTask";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Box, Tooltip } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";

import * as B from "../../base/Buttons";
import Modal from "../index";
import * as S from "./styles";

type FeedBackUserProps = {
  errors: {
    message: string;
    numero_cpf: string;
    create_account: boolean;
  }[];
  success: {
    message: string;
    numero_cpf: string;
    create_account: boolean;
  }[];
};

type ListUsuariosDataGridProps = {
  numero_cpf: string;
  message: string;
  isError: boolean;
};

interface IModalFeedBackUserSolicitation {
  isOpen: boolean;
  onClose: () => void;
  listaUsuariosFeedBack: FeedBackUserProps;
}

export function FeedBackUserSolicitation({
  isOpen,
  onClose,
  listaUsuariosFeedBack,
}: IModalFeedBackUserSolicitation) {
  const [listUsuarios, setListUsuarios] = useState<ListUsuariosDataGridProps[]>(
    []
  );

  const dataGridColumns = [
    {
      field: "numero_cpf",
      headerName: "CPF",
      width: 170,
      editable: false,
    },
    {
      field: "message",
      headerName: "MENSAGEM",
      width: 500,
      editable: false,
    },
    {
      field: "status",
      headerName: "STATUS",
      width: 80,
      editable: false,
      renderCell: (cellValues: any) => {
        return cellValues.row.isError ? (
          <Tooltip
            title="Erro ao Adicionar esse usuario"
            style={{ margin: "auto" }}
          >
            <ErrorOutlineIcon color="error" />
          </Tooltip>
        ) : (
          <Tooltip
            title="Usuario adicionado com sucesso"
            style={{ margin: "auto" }}
          >
            <AddTaskIcon color="success" />
          </Tooltip>
        );
      },
    },
    {
      field: "create_account",
      headerName: "CONTA CRIADA",
      width: 130,
      editable: false,
      renderCell: (cellValues: any) => {
        return cellValues.row.create_account ? (
          <Tooltip title="Conta criada com sucesso" style={{ margin: "auto" }}>
            <AddTaskIcon color="success" />
          </Tooltip>
        ) : (
          <Tooltip
            title="Não foi possivel criar a conta para esse usuario"
            style={{ margin: "auto" }}
          >
            <ErrorOutlineIcon color="error" />
          </Tooltip>
        );
      },
    },
  ];

  useEffect(() => {
    if (listaUsuariosFeedBack.errors?.length > 0) {
      const errors = listaUsuariosFeedBack.errors.map((item) => ({
        id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
        numero_cpf: item.numero_cpf,
        message: item.message,
        create_account: item.create_account,
        isError: true,
      }));
      setListUsuarios((state) => [...state, ...errors]);
    }
    if (listaUsuariosFeedBack.success?.length > 0) {
      const success = listaUsuariosFeedBack.success.map((item) => ({
        id: Math.floor(Math.random() * Number.MAX_SAFE_INTEGER),
        numero_cpf: item.numero_cpf,
        message: item.message,
        create_account: item.create_account,
        isError: false,
      }));
      setListUsuarios((state) => [...state, ...success]);
    }
    return () => {
      setListUsuarios([]);
    };
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Importar usuários">
      <S.ModalBody>
        <Box sx={{ width: "100%", height: 500 }}>
          <DataGrid
            columns={dataGridColumns}
            rows={listUsuarios}
            disableSelectionOnClick
            rowsPerPageOptions={[5, 10, 20, 50, 100]}
            rowHeight={40}
            disableColumnMenu
            localeText={{
              MuiTablePagination: {
                labelDisplayedRows: ({ from, to, count }) =>
                  `${from}–${to} de ${
                    count !== -1 ? count : `mais do que ${to}`
                  }`,
                labelRowsPerPage: "Itens por página",
              },
              footerRowSelected: () => "",
              footerTotalVisibleRows: () => "Teste",
              noRowsLabel: "Nenhum usuário importado",
            }}
          />
        </Box>
        <B.ButtonSecondary
          type="submit"
          style={{ margin: "10px" }}
          onClick={() => onClose()}
        >
          Voltar
        </B.ButtonSecondary>
      </S.ModalBody>
    </Modal>
  );
}
