/* eslint-disable @typescript-eslint/no-explicit-any */
import AddIcon from "@mui/icons-material/Add";
import CachedIcon from "@mui/icons-material/Cached";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Divider,
  Grid,
} from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { api } from "../../../../services/api";
import { setToastMessage } from "../../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../../store/hooks";
import { convertData, convertSOrN } from "../../../../utils/utils";
import {
  ButtonIcon,
  ButtonPrimary,
  ButtonSecondary,
} from "../../../base/Buttons";
import { CardPrimery } from "../../../base/Cards";
import * as T from "../../../base/Text";
import { InputCheckBox } from "../../../Inputs/inputCheckBox";
import { InputDate } from "../../../Inputs/InputDate";
import { InputSelect } from "../../../Inputs/InputSelect";
import { InputBase } from "../../../Inputs/InputText";
import { HoraDiaSemanaConfigAg } from "../../../Popover/HoraDiaSemanaConfigAg";
import { RestricaoDataConfigAg } from "../../../Popover/RestricaoDataConfigAg";
import * as S from "./styles";

type IIntervalos = {
  id_config_ag_intervalo_hora: number;
  id_config_agendamento: number;
  num_dia_semana: number;
  hr_inicio: string;
  hr_final: string;
};

type IRestricoes = {
  id_config_ag_retricao_data: number;
  dt_restrita: string;
};

type IVincSolicitacaoAgendamento = {
  id_vinculo_config_ag_solicitacao: number;
  id_config_agendamento: number;
  id_solicitacao: number;
  descr_solicitacao: string;
};

type IConfigEndereco = {
  id_config_agendamento: number;
  descricao: string;
  limit_agendamento_hora: number;
  dt_final_ag: string;
  dt_inico_ag: string;
  endereco_atend_presencial: string;
  ind_ativo: "S" | "N";
  intervalos: IIntervalos[];
  restricoes: IRestricoes[];
  solicitacoes: IVincSolicitacaoAgendamento[];
};

export function Agendamentos() {
  const { handleSubmit, control, reset, setError } = useForm<IConfigEndereco>();

  const {
    handleSubmit: handleSubmitUpdate,
    control: controlUpdate,
    reset: resetUpdate,
    setError: setErrorUpdate,
  } = useForm<IConfigEndereco>();

  const formCriarConfigEndereco = useRef<HTMLFormElement>(null);
  const formAtualizarConfigEndereco = useRef<HTMLFormElement>(null);
  const [openPopoverHoraDia, setOpenPopoverHoraDia] = useState<{
    el: HTMLElement | null;
    diaSemana: number | null;
  }>({
    el: null,
    diaSemana: null,
  });

  const [openPopoverRestricaoData, setOpenPopoverRestricaoData] =
    useState<HTMLElement | null>(null);

  const [listaConfigEnderecos, setListaConfigEnderecos] = useState<
    IConfigEndereco[]
  >([]);
  const [listaSolicitacoes, setListaSolicitacoes] = useState<
    Array<{ id_solicitacao: number; descr_solicitacao: string }>
  >([]);

  const [configEnderecos, setConfigEnderecos] = useState<IConfigEndereco>(
    {} as IConfigEndereco
  );
  const [indNovaConfiguracao, setIndNovaConfiguracao] = useState(false);
  const [expanded, setExpanded] = useState<number | false>(false);

  const dispatch = useAppDispatch();

  const handleChange =
    (panel: number) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      if (isExpanded) {
        const config = listaConfigEnderecos.find(
          (config) => config.id_config_agendamento === panel
        );
        setIndNovaConfiguracao(false);
        setExpanded(panel);
        setConfigEnderecos(config as IConfigEndereco);
        resetUpdate(config);
      } else {
        setExpanded(false);
        setConfigEnderecos({} as IConfigEndereco);
        resetUpdate({});
      }
    };

  const getConfigEnderecos = async () => {
    try {
      const { data } = await api.get("/agendamento/config-enderecos");
      if (!data.length) return;
      setListaConfigEnderecos(data);
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  };

  const getSolicitacoes = async () => {
    const { data } = await api.get("/solicitation/list");
    if (!data.length) return;
    setListaSolicitacoes(data);
  };

  const criarConfigEndereco = async (body: IConfigEndereco) => {
    try {
      await api.post("/agendamento/config-enderecos", {
        ...body,
        dt_inico_ag: convertData(body.dt_inico_ag),
        dt_final_ag: convertData(body.dt_final_ag),
        ind_ativo: convertSOrN(body.ind_ativo),
      });
      getConfigEnderecos();
      setIndNovaConfiguracao(false);
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
        if (Array.isArray(error?.errors) && error?.errors.length) {
          error.errors.forEach((error: any) => {
            setError(error.property, {
              type: "manual",
              message: error.error,
            });
          });
        }
      }
    }
  };

  const AtualizarConfigEndereco = async (body: IConfigEndereco) => {
    try {
      await api.post(
        `/agendamento/config-enderecos/${configEnderecos.id_config_agendamento}`,
        {
          ...body,
          dt_inico_ag: convertData(body.dt_inico_ag),
          dt_final_ag: convertData(body.dt_final_ag),
          ind_ativo: convertSOrN(body.ind_ativo),
        }
      );
      getConfigEnderecos();
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
        if (Array.isArray(error?.errors) && error?.errors.length) {
          error.errors.forEach((error: any) => {
            setErrorUpdate(error.property, {
              type: "manual",
              message: error.error,
            });
          });
        }
      }
    }
  };

  const deleteConfigEnderecos = async (idConfigAg: number) => {
    try {
      await api.delete(`/agendamento/config-enderecos/${idConfigAg}`);
      getConfigEnderecos();
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  };

  const deleteIntervaloHora = async (idIntervaloHora: number) => {
    try {
      await api.delete(
        `/agendamento/config-enderecos/intervalo-hora/${idIntervaloHora}`
      );
      getConfigEnderecos();
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  };

  const deleteRestricaoData = async (idRestricaoData: number) => {
    try {
      await api.delete(
        `/agendamento/config-enderecos/restricao-data/${idRestricaoData}`
      );
      getConfigEnderecos();
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  };

  const vincularSolicitacao = async (
    idConfigAg: number,
    idSolicitacao: number
  ) => {
    try {
      await api.post(
        `/agendamento/config-enderecos/vincula-solicitacao/${idConfigAg}/${idSolicitacao}`
      );
      getConfigEnderecos();
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  };

  const deleteVincSolAg = async (idVincSolAg: number) => {
    try {
      await api.delete(
        `/agendamento/config-enderecos/vincula-solicitacao/${idVincSolAg}`
      );
      getConfigEnderecos();
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  };

  useEffect(() => {
    getConfigEnderecos();
    getSolicitacoes();
  }, []);

  return (
    <CardPrimery container spacing={1}>
      <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
        <T.H6>Configurações dos agendamentos presenciais</T.H6>
        {!indNovaConfiguracao && (
          <div>
            <ButtonPrimary
              type="submit"
              style={{ width: "100%" }}
              onClick={() => {
                setIndNovaConfiguracao(true);
                setExpanded(false);
                setConfigEnderecos({} as IConfigEndereco);
                reset({});
              }}
            >
              Nova configuração
              <AddIcon style={{ marginLeft: "0.5rem" }} />
            </ButtonPrimary>
          </div>
        )}
      </Grid>

      {indNovaConfiguracao && (
        <form
          ref={formCriarConfigEndereco}
          onSubmit={handleSubmit(criarConfigEndereco)}
          noValidate
        >
          <CardPrimery
            container
            spacing={1}
            padding="0.5rem"
            margin="0.5rem"
            justifyContent="flex-end"
          >
            <Grid item xs={12} md={4}>
              <InputBase
                control={control}
                id="descricao"
                label="Local de atendimento"
                name="descricao"
                required
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputBase
                control={control}
                id="limit_agendamento_hora"
                label="Limite de agendamento por hora"
                name="limit_agendamento_hora"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputDate
                control={control}
                id="dt_inico_ag"
                label="Data inicial"
                name="dt_inico_ag"
                required
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputDate
                control={control}
                id="dt_final_ag"
                label="Data final"
                name="dt_final_ag"
                required
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputCheckBox
                control={control}
                id="ind_ativo"
                label="Ativo"
                name="ind_ativo"
              />
            </Grid>
            <Grid item xs={12} md={12}>
              <InputBase
                control={control}
                id="endereco_atend_presencial"
                label="Endereço"
                name="endereco_atend_presencial"
                required
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <ButtonSecondary
                variant="outlined"
                type="submit"
                style={{ width: "100%" }}
                onClick={() => setIndNovaConfiguracao(false)}
              >
                Fechar
                <ClearIcon style={{ marginLeft: "0.5rem" }} />
              </ButtonSecondary>
            </Grid>
            <Grid item xs={12} md={2}>
              <ButtonPrimary type="submit" style={{ width: "100%" }}>
                Criar
                <AddIcon style={{ marginLeft: "0.5rem" }} />
              </ButtonPrimary>
            </Grid>
          </CardPrimery>
        </form>
      )}

      {!!listaConfigEnderecos.length &&
        listaConfigEnderecos.map((end) => (
          <Accordion
            key={end.id_config_agendamento}
            expanded={expanded === Number(end.id_config_agendamento)}
            onChange={handleChange(end.id_config_agendamento)}
            style={{ width: "100%", marginTop: "0.5rem" }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Grid container spacing={1}>
                <Grid item md={3}>
                  <T.H6>{end.descricao}</T.H6>
                </Grid>
                <Grid item md={5}>
                  <T.H6>{end.endereco_atend_presencial}</T.H6>
                </Grid>
                <Grid item md={4}>
                  <T.Span>{convertData(end.dt_inico_ag)}</T.Span> até{" "}
                  <T.Span>{convertData(end.dt_final_ag)}</T.Span>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <form
                ref={formAtualizarConfigEndereco}
                noValidate
                onSubmit={handleSubmitUpdate(AtualizarConfigEndereco)}
              >
                <Grid container spacing={1} justifyContent="flex-end">
                  <Grid item xs={12} md={4}>
                    <InputBase
                      control={controlUpdate}
                      id="descricao"
                      label="Local de atendimento"
                      name="descricao"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <InputBase
                      control={controlUpdate}
                      id="limit_agendamento_hora"
                      label="Limite de agendamento por hora"
                      name="limit_agendamento_hora"
                      type="number"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <InputDate
                      control={controlUpdate}
                      id="dt_inico_ag"
                      label="Data inicial"
                      name="dt_inico_ag"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <InputDate
                      control={controlUpdate}
                      id="dt_final_ag"
                      label="Data final"
                      name="dt_final_ag"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <InputCheckBox
                      control={controlUpdate}
                      id="ind_ativo"
                      label="Ativo"
                      name="ind_ativo"
                    />
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <InputBase
                      control={controlUpdate}
                      id="endereco_atend_presencial"
                      label="Endereço"
                      name="endereco_atend_presencial"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <ButtonSecondary
                      variant="outlined"
                      type="submit"
                      style={{ width: "100%" }}
                      onClick={() =>
                        deleteConfigEnderecos(end.id_config_agendamento)
                      }
                    >
                      Excluir
                      <DeleteOutlineIcon style={{ marginLeft: "0.5rem" }} />
                    </ButtonSecondary>
                  </Grid>
                  <Grid item xs={12} md={2}>
                    <ButtonPrimary
                      variant="outlined"
                      type="submit"
                      style={{ width: "100%" }}
                    >
                      Atualizar
                      <CachedIcon style={{ marginLeft: "0.5rem" }} />
                    </ButtonPrimary>
                  </Grid>
                </Grid>
              </form>
              <Grid container spacing={1} margin="1rem 0">
                <Divider style={{ width: "100%" }} />
                <Grid item xs={12}>
                  <T.H6>Intervalos de horas por dia da semana</T.H6>
                </Grid>
                <S.ContainerWeek>
                  {["DOM", "SEG", "TER", "QUA", "QUI", "SEX", "SAB"].map(
                    (dia, index) => (
                      <S.ContainerDayWeek key={dia}>
                        <T.Span>{dia}</T.Span>
                        {end.intervalos
                          .filter((int) => int.num_dia_semana === index)
                          .map((int) => (
                            <S.ContentDayWeek
                              key={int.id_config_ag_intervalo_hora}
                            >
                              <T.Span>{int.hr_inicio}</T.Span>
                              <T.Span>até</T.Span>
                              <T.Span>{int.hr_final}</T.Span>
                              <ButtonIcon
                                variant="text"
                                onClick={() =>
                                  deleteIntervaloHora(
                                    int.id_config_ag_intervalo_hora
                                  )
                                }
                              >
                                <DeleteOutlineIcon fontSize="small" />
                              </ButtonIcon>
                            </S.ContentDayWeek>
                          ))}
                        <ButtonIcon
                          variant="text"
                          onClick={(e: any) => {
                            setOpenPopoverHoraDia({
                              el: e.currentTarget,
                              diaSemana: index,
                            });
                          }}
                        >
                          <AddIcon />
                        </ButtonIcon>
                      </S.ContainerDayWeek>
                    )
                  )}
                </S.ContainerWeek>
              </Grid>
              <Grid container spacing={1} margin="1rem 0">
                <Divider style={{ width: "100%" }} />
                <Grid item xs={12} display="flex" justifyContent="center">
                  <T.H6>Restrições de datas</T.H6>
                  <ButtonPrimary
                    variant="outlined"
                    onClick={(e: any) =>
                      setOpenPopoverRestricaoData(e.currentTarget)
                    }
                  >
                    Adicionar restrição
                    <AddIcon style={{ marginLeft: "0.5rem" }} />
                  </ButtonPrimary>
                </Grid>
                <Grid item xs={12} display="flex" gap="1rem">
                  {end.restricoes.map((res) => (
                    <div key={res.id_config_ag_retricao_data}>
                      <T.Span>{convertData(res.dt_restrita)}</T.Span>
                      <ButtonIcon
                        variant="text"
                        onClick={() =>
                          deleteRestricaoData(res.id_config_ag_retricao_data)
                        }
                      >
                        <DeleteOutlineIcon fontSize="small" />
                      </ButtonIcon>
                    </div>
                  ))}
                </Grid>
              </Grid>
              <Grid container spacing={1}>
                <Divider style={{ width: "100%" }} />
                <Grid item xs={12} md={8}>
                  <T.H6>
                    Solicitações de agendamentos vinuladas ao endereço
                  </T.H6>
                </Grid>
                <Grid item xs={4}>
                  <InputSelect
                    title="Vincular solicitação"
                    control={control}
                    id="id_solicitacao"
                    label="Vincular solicitação"
                    name="id_solicitacao"
                    onClick={(e) => {
                      const idSolicitacao = Number(e.target.value);
                      if (!idSolicitacao) return;
                      vincularSolicitacao(
                        end.id_config_agendamento,
                        Number(e.target.value)
                      );
                      reset({});
                    }}
                  >
                    <option> </option>
                    {listaSolicitacoes.length &&
                      listaSolicitacoes
                        .filter(
                          (sol) =>
                            end.solicitacoes
                              .map((s) => s.id_solicitacao)
                              .indexOf(sol.id_solicitacao) === -1
                        )
                        .map((sol) => (
                          <option
                            key={sol.id_solicitacao}
                            value={sol.id_solicitacao}
                          >
                            {sol.descr_solicitacao}
                          </option>
                        ))}
                  </InputSelect>
                </Grid>
                <Grid item xs={12} display="flex" gap="1rem">
                  {end.solicitacoes.map((sol) => (
                    <div key={sol.id_vinculo_config_ag_solicitacao}>
                      <T.Span>{sol.descr_solicitacao}</T.Span>
                      <ButtonIcon
                        variant="text"
                        onClick={() =>
                          deleteVincSolAg(sol.id_vinculo_config_ag_solicitacao)
                        }
                      >
                        <DeleteOutlineIcon fontSize="small" />
                      </ButtonIcon>
                    </div>
                  ))}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        ))}
      <HoraDiaSemanaConfigAg
        open={Boolean(openPopoverHoraDia.el)}
        anchorEl={openPopoverHoraDia.el}
        handleClose={() => setOpenPopoverHoraDia({ el: null, diaSemana: null })}
        diaSemana={Number(openPopoverHoraDia.diaSemana)}
        idConfigAg={configEnderecos.id_config_agendamento}
        getConfigEnderecos={getConfigEnderecos}
      />
      <RestricaoDataConfigAg
        open={Boolean(openPopoverRestricaoData)}
        anchorEl={openPopoverRestricaoData}
        handleClose={() => setOpenPopoverRestricaoData(null)}
        idConfigAg={configEnderecos.id_config_agendamento}
        getConfigEnderecos={getConfigEnderecos}
      />
    </CardPrimery>
  );
}
