import { BodyContainer } from "../../components/BodyContainer";
import { HomeAnalyst } from "../../components/Home/Analyst";
import { HomeOperador } from "../../components/Home/Operador";
import { HomeUser } from "../../components/Home/User";
import { LoadingIntoComponent } from "../../components/Loading";
import { useAppSelector } from "../../store/hooks";
import * as S from "./styles";

export function Home() {
  const { user } = useAppSelector(({ User }) => User);

  return (
    <BodyContainer menu="Home">
      {user.nome ? (
        <S.Conteiner>
          {[2, 3, 4].includes(user.perfil_usuario) && <HomeAnalyst />}
          {[5].includes(user.perfil_usuario) && <HomeOperador />}
          {[1].includes(user.perfil_usuario) && <HomeUser />}
        </S.Conteiner>
      ) : (
        <LoadingIntoComponent />
      )}
    </BodyContainer>
  );
}
