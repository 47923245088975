/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable react/require-default-props */
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import { Tooltip } from "@mui/material";
import { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";

import * as S from "./styles";

type WebcamCaptureProps = {
  typeWebcam?: "frente" | "verse";
  deviceId?: string;
  uploadImageCamera: (image: string, type: string) => void;
  onClose: () => void;
};

export function WebcamCapture({
  deviceId,
  typeWebcam,
  uploadImageCamera,
  onClose,
}: WebcamCaptureProps) {
  const webcamRef = useRef<Webcam>(null);
  const [preview, setPreview] = useState<string | null | undefined>("");
  const capture = useCallback(() => {
    const imageSrc = webcamRef?.current?.getScreenshot();
    setPreview(imageSrc);
  }, [webcamRef]);

  return preview ? (
    <S.Container src={preview}>
      <S.ButtonOptions onClick={() => uploadImageCamera(preview, typeWebcam!)}>
        <Tooltip title="Salvar">
          <SaveIcon color="action" />
        </Tooltip>
      </S.ButtonOptions>
      <S.ButtonOptions onClick={() => setPreview(null)}>
        <Tooltip title="Excluir">
          <DeleteIcon color="action" />
        </Tooltip>
      </S.ButtonOptions>
      <S.ButtonClose onClick={onClose}>
        <Tooltip title="Fechar">
          <CloseIcon color="action" />
        </Tooltip>
      </S.ButtonClose>
    </S.Container>
  ) : (
    <S.Container>
      <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        videoConstraints={{
          deviceId: deviceId ? { exact: deviceId } : undefined,
          width: { ideal: 640 },
          height: { ideal: 480 },
        }}
      />
      <S.ButtonCapture onClick={capture}>
        <Tooltip title="Capturar">
          <CameraAltIcon color="action" />
        </Tooltip>
      </S.ButtonCapture>
      <S.ButtonClose onClick={onClose}>
        <Tooltip title="Fechar">
          <CloseIcon color="action" />
        </Tooltip>
      </S.ButtonClose>
    </S.Container>
  );
}
