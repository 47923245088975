export default {
  background: {
    default: "#303030",
    paper: "#424242",
    paperDark: "rgba(0, 0, 0, 0.38)",
    hover: "rgba(0, 0, 0, 0.38)",
    active: "rgba(0, 0, 0, 0.38)",
    blueLight: "#002b405c",
  },
  border: {
    light: "#555",
  },
  text: {
    primary: "#FFFFFF",
    secondary: "rgba(255, 255, 255, 0.7)",
    disabled: "rgba(255, 255, 255, 0.5)",
    with: "#FFFFFF",
    withDark: "#757575cc",
  },
  palette: {
    mode: "dark",
    primary: {
      main: "#006494",
      light: "rgb(51, 131, 169)",
      dark: "rgb(0, 70, 103)",
    },
    secondary: {
      main: "#ff6e07",
      light: "rgb(255, 139, 56)",
      dark: "rgb(178, 77, 4)",
    },
  },
  backgroundIcons: "#F0f0f0",

  device: {
    xs: "768px",
    sm: "1049px",
    md: "1440px",
  },

  typography: {
    fontFamily: "'Lato', sans-serif",
    fontSize: 14,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 700,
    h1: {
      fontSize: "2rem",
      fontWeight: 300,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    h2: {
      fontSize: "2rem",
      fontWeight: 300,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    h3: {
      fontSize: "1.5rem",
      fontWeight: 300,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    h4: {
      fontSize: "1.5rem",
      fontWeight: 300,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    h5: {
      fontSize: "1.25rem",
      fontWeight: 300,
      fontWeightLight: 300,
      fontWeightRegular: 400,

      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    h6: {
      fontSize: "1rem",
      fontWeight: 300,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    subtitle1: {
      fontSize: "1rem",
      fontWeight: 300,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 300,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 300,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 300,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    button: {
      fontSize: "0.875rem",
      fontWeight: 300,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 300,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
    overline: {
      fontSize: "0.625rem",
      fontWeight: 300,
      fontWeightLight: 300,
      fontWeightRegular: 400,
      fontWeightMedium: 500,
      fontWeightBold: 700,
    },
  },
};
