/* eslint-disable @typescript-eslint/no-explicit-any */
import { Box } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";

import { api } from "../../../services/api";
import {
  setLoading,
  setToastMessage,
} from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import { convertSOrN } from "../../../utils/utils";
import * as B from "../../base/Buttons";
import { TabNavigation } from "../../TabNavigation";
import { FeedBackUserSolicitation } from "../FeedBackUserSolicitation";
import Modal from "../index";
import { ImportarManualUserSol } from "./ImportarManual";
import { ImportSistemUserSol } from "./ImportSistem";
import * as S from "./styles";

interface IModalRenderListUserCSV {
  isOpen: boolean;
  onClose: () => void;
  idSOlicitation: number;
  listaCPFUserJaNaSolicitacao: string[];
}

type ListUsuariosProps = {
  id: number;
  numero_cpf: string;
  nome?: string;
  email?: string;
};

const listSteep = ["Usuários do sitema", "Novos usuários"];

export function ImportUserSolicitation({
  isOpen,
  onClose,
  idSOlicitation,
  listaCPFUserJaNaSolicitacao,
}: IModalRenderListUserCSV) {
  const [tabValue, setTabValue] = useState(0);
  const [listaNovosUsers, setListaNovosUsers] = useState<ListUsuariosProps[]>(
    []
  );

  const [isCreateAccount, setIsCreateAccount] = useState(false);
  const [responseUserFeedBacks, setResponseUserFeedBacks] = useState({
    errors: [],
    success: [],
  });

  const [isOpenModalFeedBack, setIsOpenModalFeedBack] =
    useState<boolean>(false);

  const onClseModalFeedBack = () => {
    onClose();
    setIsOpenModalFeedBack(false);
  };

  const dispatch = useAppDispatch();

  const hendleSubmit = async (users: ListUsuariosProps[]) => {
    try {
      dispatch(setLoading(true));
      const body = {
        id_solicitacao: idSOlicitation,
        ind_criar_conta: convertSOrN(isCreateAccount),
        usuarios: users
          .map((item) => {
            return {
              numero_cpf: item.numero_cpf,
              nome: item.nome,
              email: item.email,
            };
          })
          .filter(
            (user) => !listaCPFUserJaNaSolicitacao.includes(user.numero_cpf)
          ),
      };
      const { data } = await api.post("/solicitation/add-user", body);
      setResponseUserFeedBacks(data);
      setIsOpenModalFeedBack(true);
    } catch (err: any) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  useEffect(
    () => () => {
      setListaNovosUsers([]);
      setResponseUserFeedBacks({ errors: [], success: [] });
      setIsCreateAccount(false);
    },
    [isOpen]
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Vincular usuários">
      <S.ModalBody>
        <Box sx={{ width: "100%" }}>
          <TabNavigation
            labesTabs={listSteep}
            tabValue={tabValue}
            setTabValue={setTabValue}
          />
        </Box>
        <Box sx={{ width: "100%", height: 500 }}>
          {tabValue === 0 && (
            <ImportSistemUserSol
              listUsersSelected={listaNovosUsers}
              setListUsersSelected={setListaNovosUsers}
              listaCPFUserJaNaSolicitacao={listaCPFUserJaNaSolicitacao}
            />
          )}
          {tabValue === 1 && (
            <ImportarManualUserSol
              listaNovosUsers={listaNovosUsers}
              setListaNovosUsers={setListaNovosUsers}
              isCreateAccount={isCreateAccount}
              setIsCreateAccount={setIsCreateAccount}
            />
          )}
        </Box>
        <B.ButtonPrimary
          type="submit"
          style={{ margin: "10px" }}
          onClick={() => hendleSubmit(listaNovosUsers)}
        >
          Adicionar a solicitação
        </B.ButtonPrimary>
      </S.ModalBody>
      <FeedBackUserSolicitation
        isOpen={isOpenModalFeedBack}
        onClose={onClseModalFeedBack}
        listaUsuariosFeedBack={responseUserFeedBacks}
      />
    </Modal>
  );
}
