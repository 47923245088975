/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { UseFormRegister, UseFormWatch } from "react-hook-form";

import { CreateSolicitationProps } from "../../../pages/CreateSolicitation";
import { api } from "../../../services/api";
import { ItensVaueTypes } from "../../../store/ducks/TableStructure/types";
import { useAppSelector } from "../../../store/hooks";
import { CardPrimery } from "../../base/Cards";
import * as T from "../../base/Text";
import { AutoComplete } from "../../Inputs/InputAutoComplete";
import { InputDate } from "../../Inputs/InputDate";
import { InputSelect } from "../../Inputs/InputSelect";
import { InputBase } from "../../Inputs/InputText";
import * as S from "./styles";

type GeneralDefinitionProps = {
  control: any;
  register: UseFormRegister<CreateSolicitationProps>;
  watch: UseFormWatch<CreateSolicitationProps>;
  typeSolicitation: ItensVaueTypes;
};

export function GeneralDefinition({
  control,
  register,
  watch,
  typeSolicitation,
}: GeneralDefinitionProps) {
  const [termoFile, setTermoFile] = useState<File | undefined>();

  const { clientLogged } = useAppSelector(({ Clients }) => Clients);

  const getValuesArray = async (target: any) => {
    if (target.value.length > 2) {
      const { data } = await api.get(
        `/solicitation/list?descr_solicitacao=${target.value || ""}`
      );
      const newValue = data.map((item: any) => ({
        id: item.id_solicitacao,
        text: item.descr_solicitacao,
      }));
      return newValue;
    }
    return null;
  };

  useEffect(() => {
    if (watch().termo_preenchimento?.length)
      setTermoFile(watch().termo_preenchimento[0]);
  }, [watch().termo_preenchimento]);

  return (
    <CardPrimery
      container
      spacing={1}
      style={{
        padding: "1.5rem 1rem",
      }}
    >
      <Grid item xs={12} md={8}>
        <InputBase
          control={control}
          id="descr_solicitacao"
          label="Descrição"
          name="descr_solicitacao"
          required
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputSelect
          title="Tipo de Solicitação"
          control={control}
          id="tipo_solicitacao_item"
          label="Tipo de Solicitação"
          name="tipo_solicitacao_item"
          required
        >
          <option> </option>
          {typeSolicitation.itemsTable?.length &&
            typeSolicitation.itemsTable.map((type: any) => {
              if (type.item_tabela === 0) return null;
              return (
                <option key={type.item_tabela} value={type.item_tabela}>
                  {type.descr_tabela}
                </option>
              );
            })}
        </InputSelect>
      </Grid>
      <Grid item xs={12} md={4}>
        <InputDate
          control={control}
          id="dt_inicio_preench"
          label="Data Início Preenchimento"
          name="dt_inicio_preench"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <InputDate
          control={control}
          id="dt_fim_preench"
          label="Data Fim Preenchimento"
          name="dt_fim_preench"
          defaultValue=""
        />
      </Grid>
      <Grid item xs={12} md={4}>
        <AutoComplete
          control={control}
          id="id_solicitacao_sup"
          label="Vincula a outra solicitação"
          title="Vincula a outra solicitação"
          name="id_solicitacao_sup"
          optionsArray={[]}
          getId
          getValuesArray={getValuesArray}
        />
      </Grid>
      <Grid item xs={12}>
        <InputBase
          control={control}
          id="observacoes"
          label="Observacões"
          name="observacoes"
          maxRows={2}
          multiline
        />
      </Grid>
      <Grid item xs={12}>
        <InputBase
          control={control}
          id="msg_fim_preench"
          label="Messagem de fim de preenchimento"
          name="msg_fim_preench"
          maxRows={2}
          multiline
        />
      </Grid>
      {clientLogged?.token_asten_assinatura && (
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <T.H6>Asten Assinatura</T.H6>
              <T.Paragraph size="0.775rem">
                No campo abaixo voce pode definir o{" "}
                <b>Termo de Preenchimento</b> que o usuario irá assinar
                eletronicamente na plataforma{" "}
                <a
                  href="https://plataforma.astenassinatura.com.br/login"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    color: "inherit",
                    textDecoration: "underline",
                    fontWeight: "bold",
                  }}
                >
                  Asten Assinatura
                </a>
                , caso não seja definido nenhum termo, o sistema irá utilizar o
                termo padrão.
              </T.Paragraph>
            </Grid>
            <Grid item xs={12}>
              <S.ContainerInputFile success={Boolean(termoFile?.size)}>
                <S.InputLabel success={Boolean(termoFile?.size)}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      width: "50%",
                    }}
                  >
                    <T.H6 width="max-content">Termo de Preenchimento</T.H6>
                    {termoFile?.name && <T.Span>{termoFile.name}</T.Span>}
                  </div>
                  {!termoFile?.size && (
                    <div style={{ width: "50%" }}>
                      <Tooltip title="Anexar arquivo">
                        <CloudUploadIcon color="action" />
                      </Tooltip>
                    </div>
                  )}
                  <input
                    type="file"
                    id="termo_preenchimento"
                    style={{ display: "none" }}
                    accept="image/*, application/pdf"
                    {...register("termo_preenchimento")}
                  />
                </S.InputLabel>
                <div
                  style={{
                    display: "inline-flex",
                    alignItems: "center",
                    minWidth: termoFile?.size ? "initial" : "2.5rem",
                  }}
                >
                  {termoFile?.size ? (
                    <Tooltip title="Deletar Arquivo">
                      <IconButton onClick={() => setTermoFile(undefined)}>
                        <DeleteIcon />
                      </IconButton>
                    </Tooltip>
                  ) : null}
                </div>
              </S.ContainerInputFile>
            </Grid>
          </Grid>
        </Grid>
      )}
    </CardPrimery>
  );
}
