import styled, { css } from "styled-components";

type StyleProps = {
  image?: string;
};

export const Container = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: center;
    /* background-color: ${theme.palette.primary.main}; */
    background-image: linear-gradient(
      136deg,
      rgb(97, 195, 240) 0%,
      rgb(55, 137, 175) 50%,
      rgb(0, 70, 103) 100%
    );
  `}
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 90%;
  height: 100%;
`;

export const BoxLeft = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  cursor: pointer;

  img {
    height: 48px;
    margin-right: 20px;
  }
`;

export const BoxRight = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    height: 100%;

    h6 {
      margin-left: 0.5rem;
      white-space: nowrap;
      overflow: hidden;
      color: ${theme.text.with};
    }
  `}
`;

export const BoxImage = styled.div<StyleProps>`
  ${({ theme, image }) => css`
    --webkit-background-size: 40px 40px;
    background-size: 40px 40px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background-image: url(${image});
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.border.light};
    background-color: ${theme.background.active};
    cursor: pointer;

    svg {
      transition: all 0.3s ease-in-out;
      opacity: 0.5;
    }
  `}
`;
