import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Fragment } from "react";

import { useAppSelector } from "../../store/hooks";
import { MenuList } from "./MenuList";
import { ItemMenuButton } from "./styles";

export function MenuMobile({
  open,
  toogleMenu,
  active,
  navegateTo,
}: {
  open: boolean;
  active: string;
  toogleMenu: () => void;
  navegateTo: (path: string) => void;
}) {
  const {
    User: { user },
    Clients: { clientLogged },
  } = useAppSelector(({ User, Clients }) => ({ User, Clients }));

  return (
    <Drawer anchor="left" open={open} onClose={toogleMenu}>
      <Box role="presentation" onClick={toogleMenu} onKeyDown={toogleMenu}>
        <List>
          {MenuList(Number(user.perfil_usuario), clientLogged).map((item) => {
            return (
              <Fragment key={item.id}>
                <ItemMenuButton
                  open={open}
                  selecion={{ active: active === item.name }}
                  device="phone"
                  onClick={() => {
                    if (item.path) navegateTo(item.path);
                    if (item.action) item.action();
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    {item.icon}
                  </ListItemIcon>
                  <ListItemText
                    primary={item.name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                </ItemMenuButton>
                <Divider />
              </Fragment>
            );
          })}
        </List>
      </Box>
    </Drawer>
  );
}
