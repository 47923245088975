import { Card } from "@mui/material";
import styled, { css } from "styled-components";

import { animationPulse } from "../../base/Animations";

export const Container = styled.div`
  flex: 3;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const CardElevation = styled(Card)`
  ${({ theme }) => css`
    border-radius: 0.5rem !important;
    margin-left: 0 !important;
    border: 1px solid ${theme.border.light};
  `}
`;

export const BoxHeader = styled(CardElevation)`
  width: 100%;
  padding: 0 2rem 0 2rem;
  display: flex;
  justify-content: space-between;
  height: min-content;

  img {
    width: 500px;
  }

  @media (max-width: 768px) {
    img {
      display: none;
    }
  }
`;

export const HeaderListSolicitation = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const BoxContent = styled.div`
  width: 100%;
  display: flex;
  gap: 1.5rem;
`;

export const BoxSolicitations = styled.div`
  ${({ theme }) => css`
    width: 70%;
    max-height: 500px;
    padding: 0.5rem;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 3rem;
    overflow-x: hidden;
    overflow-y: auto;

    scrollbar-width: 5px; /* for Firefox */
    scrollbar-color: transparent ${theme.border.light}; /* for Firefox */
    ::-webkit-scrollbar {
      width: 5px;
      background: transparent;
    }
    ::-webkit-scrollbar-thumb {
      background: ${theme.border.light};
      border-radius: 5px;
    }

    @media (max-width: 768px) {
      width: 100%;
    }
  `}
`;

export const SolicitationItem = styled(CardElevation)<{ $animation: boolean }>`
  ${({ theme, $animation }) => css`
    height: 380px;
    max-width: 320px;
    padding: 0.775rem;
    border: 1px solid ${theme.border.light};
    border-radius: 0.5rem;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    flex-direction: column;

    ${$animation
      ? css`
          animation: ${animationPulse("rgb(51, 131, 169, 0.4)")} 2s infinite;
        `
      : ""}

    &:hover {
      background-color: ${theme.background.blueLight};
    }

    @media (max-width: 768px) {
      max-width: initial;
    }
  `}
`;

export const SolicitationItemContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  & > div:nth-child(1) {
    flex: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      font-size: 1.2rem;
      font-weight: 600;
      text-align: center;
      color: ${({ theme }) => theme.palette.primary.main};
    }
  }
  & > div:nth-child(2) {
    flex: 1;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      display: flex;
      justify-content: space-between;
    }

    p {
      width: min-content;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const SolicitationItemProgress = styled.div`
  align-self: flex-end;
  width: 100%;
  height: 15px;
`;
