import styled, { css } from "styled-components";

type ContainerInputFileProps = {
  disabled?: boolean;
  error?: boolean;
  success?: boolean;
  isOpenCorrecion?: boolean;
  isSelected?: boolean;
};

export const ContainerInputFile = styled.div<ContainerInputFileProps>`
  ${({ theme, error, success, isOpenCorrecion, isSelected }) => css`
    width: 100%;
    min-height: 4.125rem;
    background-color: ${theme.background.active};
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    border-style: dashed;
    border-color: ${theme.border.light};
    border-width: 3px;
    border-spacing: 0.5rem;
    border-radius: 0.5rem;

    ${success &&
    css`
      border-color: ${theme.border.light};
      border-style: solid;
    `}
    ${error &&
    css`
      border-color: #df0606;
    `}

    ${isOpenCorrecion &&
    css`
      &:hover {
        border-color: ${theme.palette.secondary.light};
        border-style: ${isSelected ? "solid" : "dashed"};
      }
    `}

    ${isSelected &&
    isOpenCorrecion &&
    css`
      border-color: ${theme.palette.secondary.light};
      border-style: solid;
    `}
  `}
`;

export const InputTitle = styled.div`
  ${() => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    padding: 0.5rem 1rem;
  `}
`;

export const InputLabel = styled.label`
  ${() => css`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;

    padding: 0.5rem 1rem;
  `}
`;

export const BoxIconAnexar = styled.div`
  ${() => css`
    position: absolute;
    left: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  `}
`;

export const ListaArquivosAnexados = styled.ul`
  ${() => css`
    width: 100%;
    padding: 0;
    margin: 0;
    list-style: none;
    z-index: 1;
  `}
`;

export const ItemArquivoAnexado = styled.li`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.25rem 1rem;
    border-bottom: 1px solid ${theme.border.light};
  `}
`;
