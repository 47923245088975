import styled from "styled-components";

export const ModalBody = styled.form`
  width: 680px;
  height: min-content;
  padding: 1rem 10% 2rem 10%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (max-width: 500px) {
    width: 100vw;
    padding: 1rem 5% 2rem 5%;
  }
`;

export const BoxDescription = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Footer = styled(BoxDescription)`
  justify-content: space-between;
  margin-top: 2rem;
`;
