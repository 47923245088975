/* eslint-disable @typescript-eslint/no-explicit-any */
import { Menu, MenuItem } from "@mui/material";

type MenuPappoverprops = {
  options: Array<{
    id: string | number;
    label: string;
    onClick: (e?: any) => void;
  }>;
  open: boolean;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
};

const ITEM_HEIGHT = 98;

export function MenuPappover({
  options,
  open,
  handleClose,
  anchorEl,
}: MenuPappoverprops) {
  return (
    <div>
      <Menu
        // id={anchorEl?.title}
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: "max-content",
          },
        }}
      >
        {options.map((option) => (
          <MenuItem
            id={anchorEl?.title}
            key={option.id}
            onClick={option.onClick}
          >
            {option.label}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
