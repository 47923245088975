import { ReactElement, ReactNode, useLayoutEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useNavigate,
} from "react-router-dom";

import { ActiveAccount } from "./pages/ActiveAccount";
import { AgendamentosPage } from "./pages/Agendamentos";
import { AgendarPreenchimento } from "./pages/AgendarPreenchimento";
import { CreateSolicitation } from "./pages/CreateSolicitation";
import { ErrorLogs } from "./pages/ErrorLogs";
import { FaqPage } from "./pages/Faq";
import { ForgotPassword } from "./pages/ForgotPassword";
import { Home } from "./pages/Home";
import { MySolicitations } from "./pages/InProgressSolicitations";
import { Login } from "./pages/Login";
import { LogsPage } from "./pages/LogsPage";
import { Management } from "./pages/Management";
import { ManagementSolicitation } from "./pages/ManagementSolicitation";
import { MySolicitation } from "./pages/MySolicitation";
import { NotFound } from "./pages/NotFound";
import { Perfil } from "./pages/Perfil";
import { RedefinePassword } from "./pages/RedefinePassword";
import { Register } from "./pages/Register";
import { SolicitacoesRevisadas } from "./pages/SolicitacoesRevisadas";
import { Solicitations } from "./pages/Solicitations";
import { SystemParameters } from "./pages/SystemParameters";
import { UsersAll } from "./pages/UsersAll";
import { api } from "./services/api";
import { isAuthenticated, logout } from "./services/auth";
import { getClientLoggedAction } from "./store/ducks/Clients/actions";
import { setUserAction } from "./store/ducks/User/actions";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import { getClientUrl } from "./utils/utils";

// Função de privatização de rotas
function Private({ children }: { children: ReactNode }): ReactElement {
  const isLogged = isAuthenticated();
  if (!isLogged) {
    logout(300);
    return <Navigate to={`/${getClientUrl()}/login`} replace />;
  }
  const { user } = useAppSelector((state) => state.User);
  const dispatch = useAppDispatch();
  const navegation = useNavigate();

  if (!user.codigo_interno) {
    api
      .get("/user/get-user")
      .then((response) => dispatch(setUserAction(response.data)))
      .catch(() => {
        navegation(`/${getClientUrl()}/login`);
        logout();
      });
  }
  return children as ReactElement;
}

export function MyRoutes() {
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    dispatch(getClientLoggedAction());
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/:cliente/login" element={<Login />} />
        <Route path="/:cliente/cadastro" element={<Register />} />
        <Route path="/:cliente/recuperar-senha" element={<ForgotPassword />} />
        <Route
          path="/:cliente/ativar-conta/:token"
          element={<ActiveAccount />}
        />
        <Route
          path="/:cliente/redefinir-senha/:token"
          element={<RedefinePassword />}
        />
        <Route path="/:cliente/agendar" element={<AgendarPreenchimento />} />
        <Route path="/:cliente/faq" element={<FaqPage />} />
        {/* ROTAS PRIVADAS */}
        <Route
          path="/:cliente/home"
          element={
            <Private>
              <Home />
            </Private>
          }
        />
        <Route
          path="/:cliente/parametros-sistema"
          element={
            <Private>
              <SystemParameters />
            </Private>
          }
        />
        <Route
          path="/:cliente/administracao"
          element={
            <Private>
              <Management />
            </Private>
          }
        />
        <Route
          path="/:cliente/admin/cria-solicitacao"
          element={
            <Private>
              <CreateSolicitation />
            </Private>
          }
        />
        <Route
          path="/:cliente/admin/solicitacoes"
          element={
            <Private>
              <Solicitations />
            </Private>
          }
        />
        <Route
          path="/:cliente/em-andamento"
          element={
            <Private>
              <MySolicitations />
            </Private>
          }
        />
        <Route
          path="/:cliente/revisadas"
          element={
            <Private>
              <SolicitacoesRevisadas />
            </Private>
          }
        />
        <Route
          path="/:cliente/admin/solicitacao/:id"
          element={
            <Private>
              <ManagementSolicitation />
            </Private>
          }
        />
        <Route
          path="/:cliente/solicitacao/:id"
          element={
            <Private>
              <MySolicitation />
            </Private>
          }
        />
        <Route
          path="/:cliente/perfil"
          element={
            <Private>
              <Perfil />
            </Private>
          }
        />
        <Route
          path="/:cliente/agendamentos"
          element={
            <Private>
              <AgendamentosPage />
            </Private>
          }
        />
        <Route
          path="/:cliente/admin/logs"
          element={
            <Private>
              <LogsPage />
            </Private>
          }
        />
        <Route
          path="/:cliente/admin/error-logs"
          element={
            <Private>
              <ErrorLogs />
            </Private>
          }
        />
        <Route
          path="/:cliente/usuarios"
          element={
            <Private>
              <UsersAll />
            </Private>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
