/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Popover } from "@mui/material";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import { ButtonPrimary } from "../../base/Buttons";
import { InputDate } from "../../Inputs/InputDate";
import * as S from "./styles";

type MenuPappoverprops = {
  open: boolean;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
  diaSemana: number;
  idConfigAg: number;
  getConfigEnderecos: () => void;
};

type IHoraDiaSemana = {
  hr_inicio_agendamento: Date;
  hr_final_agendamento: Date;
};

export function HoraDiaSemanaConfigAg({
  open,
  handleClose,
  anchorEl,
  diaSemana,
  idConfigAg,
  getConfigEnderecos,
}: MenuPappoverprops) {
  const { handleSubmit, control, setError } = useForm<IHoraDiaSemana>();

  const dispatch = useAppDispatch();

  const novoHorarioPorDia = async (body: IHoraDiaSemana) => {
    try {
      const horaInicio = new Date(
        body.hr_inicio_agendamento
      ).toLocaleTimeString();
      const horaFim = new Date(body.hr_final_agendamento).toLocaleTimeString();

      await api.post(
        `/agendamento/config-enderecos/intervalo-hora/${idConfigAg}`,
        {
          diaSemana,
          hr_inicio_agendamento: horaInicio,
          hr_final_agendamento: horaFim,
        }
      );
      getConfigEnderecos();
      handleClose();
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
        if (Array.isArray(error?.errors) && error?.errors.length) {
          error.errors.forEach((error: any) => {
            setError(error.property, {
              type: "manual",
              message: error.error,
            });
          });
        }
      }
    }
  };

  return (
    <div>
      <Popover
        id="inserir-hora-dia-semana"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <S.Content onSubmit={handleSubmit(novoHorarioPorDia)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputDate
                control={control}
                id="hr_inicio_agendamento"
                label="Hora início"
                name="hr_inicio_agendamento"
                type="Time"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <InputDate
                control={control}
                id="hr_final_agendamento"
                label="Hora término"
                name="hr_final_agendamento"
                type="Time"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonPrimary type="submit" style={{ width: "100%" }}>
                Salvar
              </ButtonPrimary>
            </Grid>
          </Grid>
        </S.Content>
      </Popover>
    </div>
  );
}
