/* eslint-disable @typescript-eslint/no-explicit-any */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import { InputBase } from "../../Inputs/InputText";
import Modal from "../index";
import * as S from "./styles";

interface IModalWarningAprovedSolicitation {
  isOpen: boolean;
  onClose: () => void;
  getUserSolicitation: () => Promise<void>;
  idUserSolicitation: number;
}

export function WarningAprovedSolicitation({
  isOpen,
  onClose,
  idUserSolicitation,
  getUserSolicitation,
}: IModalWarningAprovedSolicitation) {
  const { handleSubmit, control } = useForm<{ emailMessage: string }>();

  const dispatch = useAppDispatch();

  const onSubmit = async (body: { emailMessage: string }) => {
    try {
      const { emailMessage } = body;
      const { data: response } = await api.post(
        `/solicitation/aproved/${idUserSolicitation}`,
        { emailMessage }
      );

      dispatch(setToastMessage({ type: "success", message: response.message }));
      await getUserSolicitation();
      onClose();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <S.ModalBody onSubmit={handleSubmit(onSubmit)} noValidate>
        <S.BoxDescription>
          <CheckCircleIcon fontSize="large" color="success" />
          <T.H2 color="#2e7d32" margin="0 0 0 1rem">
            APROVA SOLICITAÇÃO
          </T.H2>
        </S.BoxDescription>
        <T.Paragraph margin="1rem 0 0.5rem 0">
          Este campo é opcional, caso deseje enviar uma mensagem de{" "}
          <b>email personalizada</b> para o usuário.
        </T.Paragraph>
        <InputBase
          control={control}
          id="emailMessage"
          label="Messagem de email"
          name="emailMessage"
          maxRows={3}
          multiline
        />
        <S.BoxDescription>
          <WarningAmberIcon fontSize="medium" color="warning" />
          <T.Paragraph margin="0 0 0 0.5rem" color="#ed6c02">
            <b>
              Após aprovar a solicitação voce não poderá desfazer esta ação.
            </b>
          </T.Paragraph>
        </S.BoxDescription>
        <S.Footer>
          <B.ButtonSecondary onClick={onClose}>Cancelar</B.ButtonSecondary>
          <B.ButtonPrimary type="submit">APROVAR</B.ButtonPrimary>
        </S.Footer>
      </S.ModalBody>
    </Modal>
  );
}
