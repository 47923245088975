/* eslint-disable @typescript-eslint/no-explicit-any */
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import Modal from "../index";
import * as S from "./styles";

interface IModalAddNewStructuredTable {
  isOpen: boolean;
  onClose: () => void;
  onOpenCadastraVinculo: () => void;
}

export function WarningNotVinculoCadastrado({
  isOpen,
  onClose,
  onOpenCadastraVinculo,
}: IModalAddNewStructuredTable) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <S.ModalBody>
        <S.Header>
          <WarningAmberIcon fontSize="large" color="warning" />
          <T.H2 color="#ed6c02" margin="0 0 0 1rem">
            AVISO
          </T.H2>
        </S.Header>
        <T.Paragraph>
          Tem certeza que deseja concluir a etapa sem cadastrar seus vinculos de
          trabalho?
        </T.Paragraph>
        <T.Paragraph>
          Essa informação é importante para o cálculo do atorial de
          aposentadoria.
        </T.Paragraph>
        <S.Footer>
          <B.ButtonSecondary onClick={onClose}>Não cadastrar</B.ButtonSecondary>
          <B.ButtonPrimary onClick={onOpenCadastraVinculo}>
            Cadastrar vinculo
          </B.ButtonPrimary>
        </S.Footer>
      </S.ModalBody>
    </Modal>
  );
}
