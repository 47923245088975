/* eslint-disable @typescript-eslint/no-explicit-any */
import ArticleIcon from "@mui/icons-material/Article";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useEffect, useState } from "react";
import { UseFormGetValues, UseFormWatch } from "react-hook-form";

import { CreateSolicitationProps } from "../../../pages/CreateSolicitation";
import { CardPrimery } from "../../base/Cards";
import { ButtonRadioStyles } from "../../base/stylesGeneric";
import * as T from "../../base/Text";
import { InputCheckBox } from "../../Inputs/inputCheckBox";
import * as S from "../styles";

type IdentificationsProps = {
  control: any;
  watch: UseFormWatch<CreateSolicitationProps>;
  getValues: UseFormGetValues<CreateSolicitationProps>;
};

type CheckedsProps = {
  exigir_dados_ident: boolean | "S" | "N";
  exigir_dados_compl: boolean | "S" | "N";
  exigir_dados_depend: boolean | "S" | "N";
  exigir_dados_vinc_trab: boolean | "S" | "N";
  exigir_dados_beneficios: "S" | "N";
  exigir_dados_decl_bens: boolean | "S" | "N";
};

export function Identifications({
  control,
  getValues,
  watch,
}: IdentificationsProps) {
  const [checked, setChecked] = useState<CheckedsProps>({} as CheckedsProps);

  const setCheckeds = () => {
    const value = getValues();
    setChecked({
      exigir_dados_ident: value.exigir_dados_ident,
      exigir_dados_compl: value.exigir_dados_compl,
      exigir_dados_depend: value.exigir_dados_depend,
      exigir_dados_vinc_trab: value.exigir_dados_vinc_trab,
      exigir_dados_beneficios: value.exigir_dados_beneficios,
      exigir_dados_decl_bens: value.exigir_dados_decl_bens,
    });
  };

  useEffect(() => {
    setCheckeds();
  }, [
    watch().exigir_dados_ident,
    watch().exigir_dados_compl,
    watch().exigir_dados_depend,
    watch().exigir_dados_vinc_trab,
    watch().exigir_dados_beneficios,
    watch().exigir_dados_decl_bens,
  ]);

  return (
    <CardPrimery
      container
      style={{
        padding: "1.5rem 2rem",
      }}
    >
      <S.BoxGrid>
        <S.BoxLabel checked={checked.exigir_dados_ident}>
          <T.H5 textAlign="center">Exigir Identificação</T.H5>
          <ManageAccountsIcon fontSize="large" />
          <T.Span textAlign="center">
            Sera solicidado dados de identificação do usuário, como: nome
            completo, CPF, data de nascimento, sexo, estado civil,
            nacionalidade, endereço, etc.
          </T.Span>
          <S.BoxStyleRadios>
            <ButtonRadioStyles checked={checked.exigir_dados_ident} />
          </S.BoxStyleRadios>
          <div style={{ display: "none" }}>
            <InputCheckBox
              control={control}
              id="exigir_dados_ident"
              label="Identificação"
              name="exigir_dados_ident"
            />
          </div>
        </S.BoxLabel>
        <S.BoxLabel checked={checked.exigir_dados_compl}>
          <T.H5 textAlign="center">Exigir Dados Complementares</T.H5>
          <FactCheckIcon fontSize="large" />
          <T.Span textAlign="center">
            Sera solicitado dados complementares do usuário, como: e-mail,
            telefone celular, telefone fixo, endereço atual, etc.
          </T.Span>
          <S.BoxStyleRadios>
            <ButtonRadioStyles checked={checked.exigir_dados_compl} />
          </S.BoxStyleRadios>
          <div style={{ display: "none" }}>
            <InputCheckBox
              control={control}
              id="exigir_dados_compl"
              label="Dados Complementares"
              name="exigir_dados_compl"
            />
          </div>
        </S.BoxLabel>
        <S.BoxLabel checked={checked.exigir_dados_depend}>
          <T.H5 textAlign="center"> Exigir Dados dos Dependentes</T.H5>
          <GroupAddIcon fontSize="large" />
          <T.Span textAlign="center">
            Sera solicitado dados dos dependentes do usuário, como: nome, CPF,
            data de nascimento, sexo, etc.
          </T.Span>
          <S.BoxStyleRadios>
            <ButtonRadioStyles checked={checked.exigir_dados_depend} />
          </S.BoxStyleRadios>
          <div style={{ display: "none" }}>
            <InputCheckBox
              control={control}
              id="exigir_dados_depend"
              label="Dependetes"
              name="exigir_dados_depend"
            />
          </div>
        </S.BoxLabel>
        <S.BoxLabel checked={checked.exigir_dados_vinc_trab}>
          <T.H5 textAlign="center">Exigir Vinculos Trabalhistas</T.H5>
          <AssuredWorkloadIcon fontSize="large" />
          <T.Span textAlign="center">
            Sera solicitado dados das fontes de renda do usuário, vinculos com
            municipios, etc.
          </T.Span>
          <S.BoxStyleRadios>
            <ButtonRadioStyles checked={checked.exigir_dados_vinc_trab} />
          </S.BoxStyleRadios>
          <div style={{ display: "none" }}>
            <InputCheckBox
              control={control}
              id="exigir_dados_vinc_trab"
              label="Vinculos Trabalhistas"
              name="exigir_dados_vinc_trab"
            />
          </div>
        </S.BoxLabel>
        <S.BoxLabel checked={checked.exigir_dados_beneficios}>
          <T.H5 textAlign="center">Exigir Beneficios</T.H5>
          <AttachMoneyIcon fontSize="large" />
          <T.Span textAlign="center">
            Sera solicitado dados dos beneficios do usuário, como: aposentadoria
            e pensão, etc.
          </T.Span>
          <S.BoxStyleRadios>
            <ButtonRadioStyles checked={checked.exigir_dados_beneficios} />
          </S.BoxStyleRadios>
          <div style={{ display: "none" }}>
            <InputCheckBox
              control={control}
              id="exigir_dados_beneficios"
              label="Beneficios"
              name="exigir_dados_beneficios"
            />
          </div>
        </S.BoxLabel>
        <S.BoxLabel checked={checked.exigir_dados_decl_bens}>
          <T.H5 textAlign="center">Exigir Declaração de Bens e Onus</T.H5>
          <ArticleIcon fontSize="large" />
          <T.Span textAlign="center">
            Sera solicitado dados sobre a declaração de bens e onus do usuário,
            como: imóveis, veículos, etc.
          </T.Span>
          <S.BoxStyleRadios>
            <ButtonRadioStyles checked={checked.exigir_dados_decl_bens} />
          </S.BoxStyleRadios>
          <div style={{ display: "none" }}>
            <InputCheckBox
              control={control}
              id="exigir_dados_decl_bens"
              label="Declaração de bens"
              name="exigir_dados_decl_bens"
            />
          </div>
        </S.BoxLabel>
      </S.BoxGrid>
    </CardPrimery>
  );
}
