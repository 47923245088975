import styled, { css } from "styled-components";

type stylesProps = {
  checked: boolean | "S" | "N";
  isEdit: boolean;
};

export const BoxGrid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
  grid-gap: 2.5rem;
`;

export const BoxLabel = styled.label<stylesProps>`
  ${({ theme, checked, isEdit }) => css`
    padding: 0.5rem;
    border-radius: 0.5rem;
    height: 280px;
    background-color: ${theme.background.active};
    border: 2px solid
      ${checked === "S" || checked === true
        ? theme.palette.primary.light
        : theme.border.light};
    max-width: 100%;
    transition: 150ms ease-in-out;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    gap: 2rem;
    padding: 2rem 1rem 1rem 1rem;

    position: relative;
    overflow: hidden;
    cursor: pointer;

    &:hover {
      transform: translateY(-2px);
    }

    ${!isEdit &&
    css`
      border: 2px solid ${theme.border.light};
      background-color: ${theme.background.active};

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${theme.background.active};
        opacity: 0.5;
        z-index: 1;
      }
    `}
  `}
`;

export const BoxStyleRadios = styled.div`
  position: absolute;
  top: 0.8rem;
  right: 0.8rem;
`;

export const BoxDocumentsAnexos = styled.div<stylesProps>`
  ${({ theme, checked, isEdit }) => css`
    padding: 0.5rem;
    border-radius: 0.5rem;
    height: 280px;
    background-color: ${theme.background.active};
    border: 2px solid
      ${checked === "S" || checked === true
        ? theme.palette.primary.light
        : theme.border.light};
    max-width: 100%;
    transition: 150ms ease-in-out;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 2rem;
    padding: 2rem 1rem;

    position: relative;
    overflow: hidden;

    ${!isEdit &&
    css`
      border: 2px solid ${theme.border.light};
      background-color: ${theme.background.active};

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${theme.background.active};
        opacity: 0.5;
        z-index: 1;
      }
    `}
  `}
`;

export const BoxSwitchs = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`;

export const BoxLabelSwitch = styled.label`
  max-width: 100%;
  transition: 150ms ease-in-out;
  border-radius: 4px;
  padding: 0.3rem;
  margin: 0.25rem 0;
  cursor: pointer;

  display: flex;
  align-items: center;
`;

export const BoxGraphic = styled.div`
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
`;

export const BoxAnexos = styled.div<stylesProps>`
  ${({ theme, checked, isEdit }) => css`
    padding: 0.5rem;
    border-radius: 0.5rem;
    height: 280px;
    background-color: ${theme.background.active};
    border: 2px solid
      ${checked === "S" || checked === true
        ? theme.palette.primary.light
        : theme.border.light};
    max-width: 100%;
    transition: 150ms ease-in-out;

    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    gap: 1.5rem;
    padding: 1.5rem 1rem;

    position: relative;
    overflow: hidden;

    ${!isEdit &&
    css`
      border: 2px solid ${theme.border.light};
      background-color: ${theme.background.active};

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: ${theme.background.active};
        opacity: 0.5;
        z-index: 1;
      }
    `}
  `}
`;

export const InputTextArea = styled.textarea`
  height: auto;
  width: 100%;
  border: none;
  background: transparent;
  font-size: 1.2rem;
  font-weight: 600;
  color: #0000008a;
  resize: none;
`;
