/* eslint-disable @typescript-eslint/no-explicit-any */
import CameraFrontIcon from "@mui/icons-material/CameraFront";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import PaidIcon from "@mui/icons-material/Paid";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import { Grid } from "@mui/material";
import { useState } from "react";

import { FasesProvaVidaProps } from "../../../pages/MySolicitation";
import { api } from "../../../services/api";
import { UserObjectTypes } from "../../../store/ducks/User/types";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  getIdURLUserSol,
  PROVA_VIDA_DOC,
  PROVA_VIDA_FACEAPI,
  PROVA_VIDA_PIX,
  PROVA_VIDA_SELFIE,
  PROVA_VIDA_SMS,
  PROVA_VIDA_VIDEO_CHAMADA,
} from "../../../utils/utils";
import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import { CustomizedSteppers } from "../../CustomizedSteppers";
import { ConfirmPIX } from "./ConfirmPIX";
import { ConfirmSMS } from "./ConfirmSMS";
import { DocFrenteVerso } from "./DocFrenteVerso";
import { DocSelfie } from "./DocSelfie";
import { FaceRecognition } from "./FaceRecognition";

type IProofOfLifeProps = {
  isEditable: boolean;
  getUserSolicitation(): Promise<void>;
  userSolicitation: UserObjectTypes;
  faseProvaVida: FasesProvaVidaProps[];
  isUserAnalyst: boolean;
  isOpenCorrecion: boolean;
  isOnChangeSetListCorrecion: (etapa: string, campo: string) => boolean;
  onChangeSetListCorrecion: (
    etapa: string,
    campo: string,
    label: string
  ) => void;
  correctionValuesJsonData: any;
};

export function ProofOfLife({
  isEditable,
  getUserSolicitation,
  userSolicitation,
  faseProvaVida,
  isUserAnalyst,
  isOpenCorrecion,
  isOnChangeSetListCorrecion,
  onChangeSetListCorrecion,
  correctionValuesJsonData,
}: IProofOfLifeProps) {
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const { device } = useAppSelector(({ Utils }) => Utils);

  const dispatch = useAppDispatch();

  const switchFaseProvaVida = (faseProvaVida: FasesProvaVidaProps[]) => {
    const newFasesProvaVida = faseProvaVida.map((provaVida) => {
      switch (provaVida.fase) {
        case PROVA_VIDA_DOC:
          return {
            ...provaVida,
            icon: <DocumentScannerIcon />,
          };
        case PROVA_VIDA_SELFIE:
          return {
            ...provaVida,
            icon: <CameraFrontIcon />,
          };
        case PROVA_VIDA_FACEAPI:
          return {
            ...provaVida,
            icon: <SensorOccupiedIcon />,
          };
        case PROVA_VIDA_PIX:
          return {
            ...provaVida,
            icon: <PaidIcon />,
          };
        case PROVA_VIDA_SMS:
          return {
            ...provaVida,
            icon: <ForwardToInboxIcon />,
          };
        case PROVA_VIDA_VIDEO_CHAMADA:
          return {
            ...provaVida,
            icon: <OndemandVideoIcon />,
          };
        default:
          return provaVida;
      }
    });
    return newFasesProvaVida;
  };

  const nextStep = () => {
    if (activeStep === faseProvaVida.length - 1) return;
    setActiveStep(activeStep + 1);
  };
  const prevStep = () => {
    if (activeStep === 0) return;
    setActiveStep(activeStep - 1);
  };

  const hendleStepByIndex = async (index: number) => {
    setActiveStep(index);
  };

  const submitAllDataProvaDeVida = async () => {
    try {
      if (loading) return;
      setLoading(true);

      const { data } = await api.post(
        `/proof-of-life/submit/${getIdURLUserSol()}`
      );
      getUserSolicitation();
      dispatch(setToastMessage({ type: "success", message: data.message }));
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    } finally {
      setLoading(false);
    }
  };

  return (
    <Grid container spacing={1} padding="1rem" width="100%" margin="0">
      <CustomizedSteppers
        steps={switchFaseProvaVida(faseProvaVida)}
        activeStep={activeStep}
        onClick={hendleStepByIndex}
      />
      {faseProvaVida.length &&
        faseProvaVida.map((provaVida, index) => {
          if (index === activeStep)
            return (
              <Grid
                key={provaVida.order}
                container
                spacing={1}
                style={{ width: "100%", margin: "0" }}
              >
                {provaVida.fase === PROVA_VIDA_DOC && (
                  <DocFrenteVerso
                    isEditable={isEditable}
                    isOpenCorrecion={isOpenCorrecion}
                    isOnChangeSetListCorrecion={isOnChangeSetListCorrecion}
                    onChangeSetListCorrecion={onChangeSetListCorrecion}
                    correctionValuesJsonData={correctionValuesJsonData}
                  />
                )}
                {provaVida.fase === PROVA_VIDA_SELFIE && (
                  <DocSelfie
                    isEditable={isEditable}
                    isOpenCorrecion={isOpenCorrecion}
                    isOnChangeSetListCorrecion={isOnChangeSetListCorrecion}
                    onChangeSetListCorrecion={onChangeSetListCorrecion}
                    correctionValuesJsonData={correctionValuesJsonData}
                  />
                )}
                {provaVida.fase === PROVA_VIDA_FACEAPI && (
                  <FaceRecognition
                    isEditable={isEditable}
                    provaVida={provaVida}
                    isUserAnalyst={isUserAnalyst}
                    getUserSolicitation={getUserSolicitation}
                  />
                )}
                {provaVida.fase === PROVA_VIDA_PIX && (
                  <ConfirmPIX
                    isEditable={isEditable}
                    getUserSolicitation={getUserSolicitation}
                    userSolicitation={userSolicitation}
                    provaVida={provaVida}
                  />
                )}
                {provaVida.fase === PROVA_VIDA_SMS && (
                  <ConfirmSMS
                    isEditable={isEditable}
                    getUserSolicitation={getUserSolicitation}
                    userSolicitation={userSolicitation}
                    provaVida={provaVida}
                  />
                )}
                {provaVida.fase === PROVA_VIDA_VIDEO_CHAMADA && (
                  <Grid item xs={12} md={12}>
                    <T.H6>{provaVida.title}</T.H6>
                    aqui vai fica o component de Video chamada
                  </Grid>
                )}
                {faseProvaVida.length > 1 ? (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent:
                        index === 0 ? "flex-end" : "space-between",
                      padding:
                        device === "desktop"
                          ? "1rem 1.5rem 0 1.5rem"
                          : "1rem 0 0 0",
                    }}
                  >
                    {index !== 0 && (
                      <B.ButtonSecondary variant="outlined" onClick={prevStep}>
                        Voltar
                      </B.ButtonSecondary>
                    )}
                    {index !== faseProvaVida.length - 1 && (
                      <B.ButtonPrimary variant="outlined" onClick={nextStep}>
                        Proximo
                      </B.ButtonPrimary>
                    )}

                    {isEditable && index === faseProvaVida.length - 1 && (
                      <B.ButtonPrimary onClick={submitAllDataProvaDeVida}>
                        Enviar
                      </B.ButtonPrimary>
                    )}
                  </Grid>
                ) : (
                  <Grid
                    item
                    xs={12}
                    md={12}
                    style={{
                      display: "flex",
                      justifyContent:
                        index === 0 ? "flex-end" : "space-between",
                      padding:
                        device === "desktop"
                          ? "1rem 1.5rem 0 1.5rem"
                          : "1rem 0 0 0",
                    }}
                  >
                    {isEditable && index === faseProvaVida.length - 1 && (
                      <B.ButtonPrimary onClick={submitAllDataProvaDeVida}>
                        Enviar
                      </B.ButtonPrimary>
                    )}
                  </Grid>
                )}
              </Grid>
            );
          return null;
        })}
    </Grid>
  );
}
