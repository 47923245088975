/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ReportIcon from "@mui/icons-material/Report";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Alert, Checkbox, Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { cpfMask } from "../../../utils/mask";
import {
  convertData,
  DEPENDENTES,
  getIdURLUserSol,
} from "../../../utils/utils";
import * as B from "../../base/Buttons";
import * as TB from "../../base/Table";
import * as T from "../../base/Text";
import { DependentsSolicitation } from "../../ModalsComponents/DependentsSolicitation";

type DependentsProps = {
  isEditable: boolean;
  getUserSolicitation(): Promise<void>;
  isCompleted: boolean;
  isOpenCorrecion: boolean;
  isOnChangeSetListCorrecion: (etapa: string, campo: string) => boolean;
  onChangeSetListCorrecion: (
    etapa: string,
    campo: string,
    label: string
  ) => void;
  correctionValuesJsonData: any;
  statusCorrecion: number;
};

export type FormDocumentsDependets = {
  id_sol_dados_depend?: number;
  id_sol_usuario?: number;
  nacionalidade_tab?: number; // TAB: 6
  nacionalidade_item: number;
  pais_nasc_tab?: number; // TAB: 1
  pais_nasc_item: number;
  estado_nasc_tab?: number; // TAB: 2
  estado_nasc_item: number;
  cidade_nasc_tab?: number; // TAB: 3
  cidade_nasc_item: number;
  tipo_dependente_tab?: number;
  tipo_dependente_item: number;
  ind_necess_espec: "S" | "N";
  ind_invalidez: "S" | "N";
  nome_carto_registro?: string;
  num_carto_registro?: number;
  num_livro_carto_registro?: number;
  num_folha_carto_registro?: number;
  nome_mae: string;
  nome_pai: string;
  nome_dependente: string;
  dt_nascimento_depend: string;
  cpf_dependente: string;
  rg_dependente: string;
  sexo_dependente: "F" | "M";
  ind_irrf: "S" | "N";
  ind_salario_familia: "S" | "N";
  ind_estudante: "S" | "N";
  ind_pensao_morte: "S" | "N";
  ind_fam: "S" | "N";

  hr_inclusao?: string;
  dt_inclusao?: string;
};

export function Dependents({
  isEditable,
  getUserSolicitation,
  isCompleted,
  isOpenCorrecion,
  isOnChangeSetListCorrecion,
  onChangeSetListCorrecion,
  correctionValuesJsonData,
  statusCorrecion,
}: DependentsProps) {
  const [formDepedents, setFormDepedents] = useState<FormDocumentsDependets[]>(
    []
  );

  const [openModalFormDepedents, setOpenModalFormDepedents] = useState<
    boolean | FormDocumentsDependets
  >(false);

  const dispatch = useAppDispatch();

  const {
    TableStructure,
    Utils: { device },
  } = useAppSelector((state) => state);
  const { typeDependents } = TableStructure;

  const getDependents = async (values?: any) => {
    const idSolUser = getIdURLUserSol();
    const data =
      values || (await api.get(`/solicitation/dependents/${idSolUser}`)).data;

    setFormDepedents(data);
  };

  const deleteDependents = async (idDepend: number) => {
    try {
      const { data } = await api.delete(
        `/solicitation/dependents/${getIdURLUserSol()}/${idDepend}`
      );

      dispatch(setToastMessage({ type: "success", message: data.message }));
      getDependents();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const nextEtapa = async () => {
    try {
      await api.post(`/solicitation/dependents/commited/${getIdURLUserSol()}`);
      getUserSolicitation();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  useEffect(() => {
    if (formDepedents.length === 0) {
      getDependents();
    }
  }, []);

  return (
    <Grid container spacing={1} padding="1rem">
      {isEditable && (
        <Alert severity="info" style={{ width: "100%" }}>
          <T.Paragraph margin="0">INCLUIR COMO DEPENDENTES</T.Paragraph> <br />
          filho(a) solteiro(a) menor de 21 (vinte e um) anos; filho(a) maior,
          solteiro(a), inválido(a) em caráter permanente para o exercício de
          toda e qualquer atividade laboral e que viva sob a dependência
          econômica; cônjuge/companheiro(a);
        </Alert>
      )}
      {formDepedents.length ? (
        <TB.TableContainer sx={{ margin: "1rem" }}>
          <TB.Table stickyHeader aria-label="sticky table" size="small">
            <TB.TableHead>
              <TB.TableRow>
                <TB.TableCell minWidth="40%" width="40%" maxWidth="50%">
                  NOME
                </TB.TableCell>
                {device === "desktop" && (
                  <TB.TableCell width="15rem" minWidth="10rem">
                    CPF
                  </TB.TableCell>
                )}

                <TB.TableCell
                  align="center"
                  style={
                    device === "desktop"
                      ? {
                          minWidth: "20%",
                          width: "20%",
                        }
                      : {
                          minWidth: "30%",
                          width: "30%",
                        }
                  }
                >
                  TIPO DEPENDENTE
                </TB.TableCell>
                {device === "desktop" && (
                  <TB.TableCell align="center">DATA DE NASCIMENTO</TB.TableCell>
                )}

                <TB.TableCell align="center" minWidth="30%" width="30%">
                  AÇÕES
                </TB.TableCell>
                {isOpenCorrecion && (
                  <TB.TableCell
                    align="center"
                    style={{ minWidth: "2rem", width: "2rem" }}
                  >
                    CORREÇÃO
                  </TB.TableCell>
                )}
                {[2, 3].includes(statusCorrecion) && (
                  <TB.TableCell
                    align="center"
                    style={{ minWidth: "2rem", width: "2rem" }}
                  />
                )}
              </TB.TableRow>
            </TB.TableHead>
            <TB.TableBody>
              {formDepedents.map((dependent) => (
                <TB.TableRow
                  hover
                  id={`id-${dependent.id_sol_dados_depend}`}
                  style={{ cursor: "pointer" }}
                  key={dependent.id_sol_dados_depend}
                  onClick={() => {
                    if (!isEditable && !isOpenCorrecion) {
                      setOpenModalFormDepedents(dependent);
                    }
                  }}
                >
                  <TB.TableCell minWidth="40%" width="40%" maxWidth="10rem">
                    {dependent.nome_dependente}
                  </TB.TableCell>
                  {device === "desktop" && (
                    <TB.TableCell width="15rem" minWidth="10rem">
                      {cpfMask(dependent.cpf_dependente)}
                    </TB.TableCell>
                  )}
                  <TB.TableCell
                    align="center"
                    style={
                      device === "desktop"
                        ? {
                            minWidth: "20%",
                            width: "20%",
                          }
                        : {
                            minWidth: "30%",
                            width: "30%",
                          }
                    }
                  >
                    {typeDependents.itemsTable.length &&
                      typeDependents.itemsTable.find(
                        (type) =>
                          type.item_tabela === dependent.tipo_dependente_item
                      )?.descr_tabela}
                  </TB.TableCell>
                  {device === "desktop" && (
                    <TB.TableCell align="center">
                      {convertData(dependent.dt_nascimento_depend)}
                    </TB.TableCell>
                  )}

                  <TB.TableCell align="center">
                    {isEditable ? (
                      <>
                        <IconButton
                          onClick={() => setOpenModalFormDepedents(dependent)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          id="button-delete-dependent"
                          onClick={() =>
                            deleteDependents(
                              Number(dependent.id_sol_dados_depend)
                            )
                          }
                        >
                          <DeleteIcon id="icon-delete-dependent" />
                        </IconButton>
                      </>
                    ) : (
                      <IconButton
                        onClick={() => {
                          setOpenModalFormDepedents(dependent);
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                    )}
                  </TB.TableCell>
                  {isOpenCorrecion && (
                    <TB.TableCell align="center">
                      <Checkbox
                        color="warning"
                        checked={isOnChangeSetListCorrecion(
                          DEPENDENTES,
                          String(dependent.id_sol_dados_depend)
                        )}
                        onChange={() =>
                          onChangeSetListCorrecion(
                            DEPENDENTES,
                            String(dependent.id_sol_dados_depend),
                            dependent.nome_dependente
                          )
                        }
                      />
                    </TB.TableCell>
                  )}
                  {correctionValuesJsonData[
                    `${DEPENDENTES}-${String(dependent.id_sol_dados_depend)}`
                  ] && !isOpenCorrecion ? (
                    <TB.TableCell align="center">
                      {correctionValuesJsonData[
                        `${DEPENDENTES}-${String(
                          dependent.id_sol_dados_depend
                        )}`
                      ]?.message ? (
                        <Tooltip
                          title={
                            correctionValuesJsonData[
                              `${DEPENDENTES}-${String(
                                dependent.id_sol_dados_depend
                              )}`
                            ]?.message
                          }
                          style={{ cursor: "help" }}
                        >
                          <ReportIcon color="warning" />
                        </Tooltip>
                      ) : (
                        <Tooltip
                          title={false}
                          disableFocusListener
                          disableInteractive
                          disableHoverListener
                          style={{ cursor: "help" }}
                        >
                          <ReportIcon color="warning" />
                        </Tooltip>
                      )}
                    </TB.TableCell>
                  ) : (
                    <TB.TableCell />
                  )}
                </TB.TableRow>
              ))}
            </TB.TableBody>
          </TB.Table>
        </TB.TableContainer>
      ) : (
        <Grid
          item
          xs={12}
          display="flex"
          alignItems="center"
          flexDirection="column"
          padding="1rem 0"
        >
          <PeopleAltIcon fontSize="large" color="action" />
          <T.H6 textAlign="center">Nenhum dependente cadastrado</T.H6>
        </Grid>
      )}

      <Grid item xs={12} display="flex" justifyContent="flex-end" gap={1}>
        {isEditable && (
          <B.ButtonPrimary
            variant="outlined"
            onClick={() => setOpenModalFormDepedents(true)}
          >
            Adiciona Dependente
            <PersonAddIcon fontSize="small" style={{ marginLeft: "0.5rem" }} />
          </B.ButtonPrimary>
        )}
        {isEditable && !isCompleted && formDepedents.length ? (
          <B.ButtonPrimary onClick={nextEtapa}>CONCLUIR ETAPA</B.ButtonPrimary>
        ) : (
          ""
        )}
        {!formDepedents.length && !isCompleted && isEditable && (
          <B.ButtonPrimary onClick={nextEtapa}>
            Não Possuo Dependentes
          </B.ButtonPrimary>
        )}
      </Grid>
      <DependentsSolicitation
        isOpen={Boolean(openModalFormDepedents)}
        onClose={() => setOpenModalFormDepedents(false)}
        dependent={
          openModalFormDepedents === true
            ? undefined
            : (openModalFormDepedents as FormDocumentsDependets)
        }
        updateListDepedents={getDependents}
        isEditable={isEditable}
      />
    </Grid>
  );
}
