/* eslint-disable react/no-array-index-key */
import { Tab } from "@mui/material";
import Tabs, { tabsClasses } from "@mui/material/Tabs";

import * as S from "./styles";

type TabNavigationProps = {
  labesTabs: string[];
  tabValue: number;
  setTabValue: React.Dispatch<React.SetStateAction<number>>;
};

export function TabNavigation({
  labesTabs,
  setTabValue,
  tabValue,
}: TabNavigationProps) {
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
  };

  return (
    <S.Container>
      <S.BoxTabs sx={{ flexGrow: 1 }}>
        <Tabs
          value={tabValue}
          onChange={handleChange}
          variant="scrollable"
          aria-label="visible arrows tabs example"
          sx={{
            [`& .${tabsClasses.scrollButtons}`]: {
              "&.Mui-disabled": { opacity: 0.3 },
            },
          }}
        >
          {labesTabs.map((label, index) => (
            <Tab key={index} label={label} />
          ))}
        </Tabs>
      </S.BoxTabs>
    </S.Container>
  );
}
