import styled, { css } from "styled-components";

type Props = {
  src?: string;
};

export const Container = styled.div<Props>`
  ${({ src }) => css`
    width: 100%;
    height: 100%;
    max-width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
    position: relative;

    video {
      width: 100%;
      height: auto !important;
      min-height: auto !important;
    }

    ${src &&
    css`
      background: url(${src}) no-repeat center center;
      background-size: cover;
      background-position: center center;
    `}

    @media screen {
      @media (max-width: 768px) {
        width: 100vw;
        height: 100vh;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 999;

        video {
          width: auto;
        }
      }
    }
  `}
`;

export const ButtonCapture = styled.div`
  position: absolute;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ddd;
  padding: 0.25rem;
  border-radius: 0.5rem;
  cursor: pointer;

  @media screen {
    @media (max-width: 768px) {
      padding: 0.5rem;
      bottom: 10%;
    }
  }
`;

export const ButtonOptions = styled.div`
  position: absolute;
  bottom: 5%;
  left: 60%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ddd;
  padding: 0.25rem;
  border-radius: 0.5rem;
  cursor: pointer;

  & + & {
    left: 40%;
  }

  @media screen {
    @media (max-width: 768px) {
      padding: 0.5rem;
      bottom: 10%;
    }
  }
`;

export const ButtonClose = styled.div`
  position: absolute;
  top: 5%;
  right: 10%;

  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #ddd;
  padding: 0.25rem;
  border-radius: 0.5rem;
  cursor: pointer;

  @media screen {
    @media (max-width: 768px) {
      padding: 0.5rem;
    }
  }
`;
