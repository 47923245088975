/* eslint-disable no-nested-ternary */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ReportIcon from "@mui/icons-material/Report";
import { IconButton, Tooltip } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

import { api } from "../../../../services/api";
import { setToastMessage } from "../../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import {
  base64ToBlob,
  getIdURLUserSol,
  PROVA_VIDA,
  PROVA_VIDA_DOC,
} from "../../../../utils/utils";
import * as T from "../../../base/Text";
import { MenuPappover } from "../../../MenuPappover";
import { WebcamCapture } from "../../../WebcamComponent";
import * as S from "./styles";

type DocFrenteVersoProps = {
  isEditable: boolean;
  isOpenCorrecion: boolean;
  isOnChangeSetListCorrecion: (etapa: string, campo: string) => boolean;
  onChangeSetListCorrecion: (
    etapa: string,
    campo: string,
    label: string
  ) => void;
  correctionValuesJsonData: any;
};

type ProgresProps = {
  item: string;
  progress: number;
};

type DocumentsProps = {
  conteudo_arquivo?: string;
  mime_type?: string;
  nome_arquivo?: string;
};

type DeviceProps = {
  active: boolean;
  id: string;
  label: string;
  deviceId: string;
};

const formtTextCamera = (text: string) => {
  if (!text) return;
  const textFormatted = text.toLowerCase();
  if (textFormatted.includes("front") || textFormatted.includes("back")) {
    if (textFormatted.includes("front")) {
      return "Camera frontal";
    }
    if (textFormatted.includes("back")) {
      return "Camera traseira";
    }
  } else if (textFormatted.includes("webcam")) {
    return "Webcam";
  } else {
    return "Camera";
  }
};

export function DocFrenteVerso({
  isEditable,
  isOpenCorrecion,
  isOnChangeSetListCorrecion,
  correctionValuesJsonData,
  onChangeSetListCorrecion,
}: DocFrenteVersoProps) {
  const [progress, setProgress] = useState<ProgresProps>({} as ProgresProps);
  const [imgPreviewFrente, setImgPreviewFrente] = useState<DocumentsProps>(
    {} as DocumentsProps
  );
  const [imgPreviewVerse, setImgPreviewVerse] = useState<DocumentsProps>(
    {} as DocumentsProps
  );

  const [typeWebcam, seTtypeWebcam] = useState<"frente" | "verse" | null>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const [devices, setDevices] = useState<DeviceProps[]>([]);
  const [deviceId, setDeviceId] = useState("");
  const [tracks, setTracks] = useState<any>({});

  const dispatch = useAppDispatch();

  const { device } = useAppSelector(({ Utils }) => Utils);

  const getDataProvaVida = async () => {
    try {
      const { data } = await api.get(
        `/proof-of-life/doc-front-back/${getIdURLUserSol()}`
      );

      setImgPreviewFrente(
        data.img_doc_oficial_frente ? data.img_doc_oficial_frente : {}
      );
      setImgPreviewVerse(
        data.img_doc_oficial_verso ? data.img_doc_oficial_verso : {}
      );
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const onCloseWebcam = () => {
    tracks.forEach((track: any) => track.stop());
    setDeviceId({} as any);
    seTtypeWebcam(null);
  };

  const uploadFile = async (
    e: React.ChangeEvent<HTMLInputElement>,
    inputItem: string
  ) => {
    try {
      if (e.target.files && progress.item !== inputItem) {
        const file = e.target.files.item(0) as File;
        const formData = new FormData();
        formData.append("file", file);

        const queryUrl = inputItem === "frente" ? "?front=front" : "?back=back";
        await api.post(
          `/proof-of-life/doc-front-back/${getIdURLUserSol()}${queryUrl}`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percent = Math.floor((loaded * 100) / total);
              setProgress({
                item: inputItem,
                progress: percent,
              });
            },
          }
        );
        getDataProvaVida();
      }
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    } finally {
      setProgress({} as ProgresProps);
    }
  };

  const uploadImageCamera = async (imageBase64: string, inputItem: string) => {
    try {
      if (imageBase64 && inputItem) {
        const blob = base64ToBlob(imageBase64);
        const file = new File([blob], "imageWebcam.jpeg", {
          type: "image/jpeg",
        });

        const formData = new FormData();
        formData.append("file", file);

        const queryUrl = inputItem === "frente" ? "?front=front" : "?back=back";
        await api.post(
          `/proof-of-life/doc-front-back/${getIdURLUserSol()}${queryUrl}`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percent = Math.floor((loaded * 100) / total);
              setProgress({
                item: inputItem,
                progress: percent,
              });
            },
          }
        );
        onCloseWebcam();
        seTtypeWebcam(null);
        getDataProvaVida();
      }
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    } finally {
      setProgress({} as ProgresProps);
    }
  };

  const getPermissionCamera = async (
    deviceId: string,
    type: "frente" | "verse"
  ) => {
    try {
      const response = await navigator.mediaDevices.getUserMedia({
        video: {
          width: { min: 500, ideal: 375 },
          height: { min: 500, ideal: 375 },
          deviceId,
        },
      });
      setTracks(response.getTracks());
      setDeviceId(deviceId);
      seTtypeWebcam(type);
    } catch (err: any) {
      dispatch(
        setToastMessage({
          type: "error",
          message:
            "Não foi possivel acessar a camera do dispositivo, acesse as configurações do navegador e permita o acesso a camera!",
        })
      );
    }
  };

  const handleDevices = useCallback(
    (mediaDevices: any) =>
      setDevices(
        mediaDevices
          .filter(({ kind }: any) => kind === "videoinput")
          .map(({ deviceId, label, active }: any) => ({
            active,
            deviceId,
            label: formtTextCamera(label),
            onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
              getPermissionCamera(deviceId, e.currentTarget.id as any);
              setAnchorEl(null);
            },
          }))
      ),
    [setDevices]
  );

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);

  const downloadFile = async (doc: DocumentsProps) => {
    try {
      if (!doc.mime_type) return;
      const a = document.createElement("a");
      a.href = `data:${doc.mime_type};base64,${doc.conteudo_arquivo}`;
      a.download = doc.nome_arquivo as string;
      a.click();

      dispatch(
        setToastMessage({
          type: "success",
          message: "Download concluido com sucesso!",
        })
      );
    } catch (err: any) {
      dispatch(
        setToastMessage({ type: "error", message: "Erro ao fazer download" })
      );
    }
  };

  useEffect(() => {
    if (
      !imgPreviewVerse?.conteudo_arquivo ||
      !imgPreviewFrente?.conteudo_arquivo
    ) {
      getDataProvaVida();
    }
  }, []);

  return (
    <S.Container>
      {device === "mobile" && (
        <div
          style={{
            marginBottom: "1rem",
          }}
        >
          <T.H6 size="1.3rem">Documentos Frente + Verso</T.H6>
        </div>
      )}
      <S.BoxDocImages>
        <S.BoxBoxDocImageItem>
          <S.BoxBoxDocImageItemTitle>
            <T.H6>Frente do documento</T.H6>
            <S.ButtonsTitle>
              {isEditable &&
                !typeWebcam &&
                devices.length &&
                (devices.length === 1 ? (
                  <Tooltip title={formtTextCamera(devices[0].label) as string}>
                    <IconButton
                      onClick={() =>
                        getPermissionCamera(devices[0].deviceId, "frente")
                      }
                    >
                      <CameraAltIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton
                    title="frente"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <CameraAltIcon fontSize="small" />
                  </IconButton>
                ))}
              {!isEditable && (
                <IconButton
                  title="Download"
                  onClick={() => downloadFile(imgPreviewFrente)}
                >
                  <FileDownloadIcon fontSize="small" />
                </IconButton>
              )}
              {correctionValuesJsonData[
                `${PROVA_VIDA}-${PROVA_VIDA_DOC}_frente`
              ] ? (
                correctionValuesJsonData[
                  `${PROVA_VIDA}-${PROVA_VIDA_DOC}_frente`
                ]?.message ? (
                  <Tooltip
                    title={
                      correctionValuesJsonData[
                        `${PROVA_VIDA}-${PROVA_VIDA_DOC}_frente`
                      ]?.message
                    }
                  >
                    <ReportIcon color="warning" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={false}
                    disableFocusListener
                    disableInteractive
                    disableHoverListener
                  >
                    <ReportIcon color="warning" />
                  </Tooltip>
                )
              ) : null}
            </S.ButtonsTitle>
          </S.BoxBoxDocImageItemTitle>
          {typeWebcam === "frente" && (
            <S.BoxDocImageLabelContainer>
              <S.BoxDocImageLabel isCamera={typeWebcam === "frente"}>
                <WebcamCapture
                  deviceId={deviceId}
                  typeWebcam={typeWebcam}
                  uploadImageCamera={uploadImageCamera}
                  onClose={onCloseWebcam}
                />
              </S.BoxDocImageLabel>
            </S.BoxDocImageLabelContainer>
          )}
          {typeWebcam !== "frente" && (
            <S.BoxDocImageLabelContainer
              isOpenCorrecion={isOpenCorrecion}
              isSelected={isOnChangeSetListCorrecion(
                PROVA_VIDA,
                `${PROVA_VIDA_DOC}_frente`
              )}
              onClick={() =>
                onChangeSetListCorrecion(
                  PROVA_VIDA,
                  `${PROVA_VIDA_DOC}_frente`,
                  "Documento Frente"
                )
              }
            >
              <S.BoxDocImageLabel
                isCamera={false}
                backgroundUrl={
                  imgPreviewFrente &&
                  imgPreviewFrente.conteudo_arquivo &&
                  `data:${imgPreviewFrente.mime_type};base64,${imgPreviewFrente.conteudo_arquivo}`
                }
              >
                {!Object.keys(imgPreviewFrente).length && (
                  <S.BoxIconCenter>
                    <CloudUploadIcon fontSize="large" color="action" />
                    <T.Span>Anexa frente do documento</T.Span>
                  </S.BoxIconCenter>
                )}
                {isEditable && (
                  <input
                    type="file"
                    name="frente"
                    id="frente"
                    onChange={(event) => uploadFile(event, "frente")}
                    style={{ display: "none" }}
                    accept="image/*"
                  />
                )}
              </S.BoxDocImageLabel>
            </S.BoxDocImageLabelContainer>
          )}
        </S.BoxBoxDocImageItem>

        <S.BoxBoxDocImageItem>
          <S.BoxBoxDocImageItemTitle>
            <T.H6>Verso do documento</T.H6>
            <S.ButtonsTitle>
              {isEditable &&
                !typeWebcam &&
                devices.length &&
                (devices.length === 1 ? (
                  <Tooltip title={formtTextCamera(devices[0].label) as string}>
                    <IconButton
                      onClick={() =>
                        getPermissionCamera(devices[0].deviceId, "verse")
                      }
                    >
                      <CameraAltIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                ) : (
                  <IconButton
                    title="verse"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                  >
                    <CameraAltIcon fontSize="small" />
                  </IconButton>
                ))}
              {!isEditable && (
                <IconButton
                  title="Download"
                  onClick={() => downloadFile(imgPreviewVerse)}
                >
                  <FileDownloadIcon fontSize="small" />
                </IconButton>
              )}
              {correctionValuesJsonData[
                `${PROVA_VIDA}-${PROVA_VIDA_DOC}_verse`
              ] ? (
                correctionValuesJsonData[
                  `${PROVA_VIDA}-${PROVA_VIDA_DOC}_verse`
                ]?.message ? (
                  <Tooltip
                    title={
                      correctionValuesJsonData[
                        `${PROVA_VIDA}-${PROVA_VIDA_DOC}_verse`
                      ]?.message
                    }
                  >
                    <ReportIcon color="warning" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={false}
                    disableFocusListener
                    disableInteractive
                    disableHoverListener
                  >
                    <ReportIcon color="warning" />
                  </Tooltip>
                )
              ) : null}
            </S.ButtonsTitle>
          </S.BoxBoxDocImageItemTitle>
          {typeWebcam === "verse" && (
            <S.BoxDocImageLabelContainer>
              <S.BoxDocImageLabel isCamera={typeWebcam === "verse"}>
                <WebcamCapture
                  deviceId={deviceId}
                  uploadImageCamera={uploadImageCamera}
                  typeWebcam={typeWebcam}
                  onClose={onCloseWebcam}
                />
              </S.BoxDocImageLabel>
            </S.BoxDocImageLabelContainer>
          )}
          {typeWebcam !== "verse" && (
            <S.BoxDocImageLabelContainer
              isOpenCorrecion={isOpenCorrecion}
              isSelected={isOnChangeSetListCorrecion(
                PROVA_VIDA,
                `${PROVA_VIDA_DOC}_verse`
              )}
              onClick={() =>
                onChangeSetListCorrecion(
                  PROVA_VIDA,
                  `${PROVA_VIDA_DOC}_verse`,
                  "Documento Verso"
                )
              }
            >
              <S.BoxDocImageLabel
                isCamera={false}
                backgroundUrl={
                  imgPreviewVerse &&
                  imgPreviewVerse.conteudo_arquivo &&
                  `data:${imgPreviewVerse.mime_type};base64,${imgPreviewVerse.conteudo_arquivo}`
                }
              >
                {!Object.keys(imgPreviewVerse).length && (
                  <S.BoxIconCenter>
                    <CloudUploadIcon fontSize="large" color="action" />
                    <T.Span>Anexa verso do documento</T.Span>
                  </S.BoxIconCenter>
                )}
                {isEditable && (
                  <input
                    type="file"
                    name="verse"
                    id="verse"
                    onChange={(event) => uploadFile(event, "verse")}
                    style={{ display: "none" }}
                    accept="image/*"
                  />
                )}
              </S.BoxDocImageLabel>
            </S.BoxDocImageLabelContainer>
          )}
        </S.BoxBoxDocImageItem>
      </S.BoxDocImages>

      {devices.length > 1 && (
        <>
          <MenuPappover
            anchorEl={anchorEl}
            open={Boolean(anchorEl?.title === "frente")}
            handleClose={() => setAnchorEl(null)}
            options={devices as any}
          />
          <MenuPappover
            anchorEl={anchorEl}
            open={Boolean(anchorEl?.title === "verse")}
            handleClose={() => setAnchorEl(null)}
            options={devices as any}
          />
        </>
      )}
    </S.Container>
  );
}
