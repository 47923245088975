/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect } from "react";

import { getItemTableAction } from "../store/ducks/TableStructure/actions";
import { ItensVaueTypes } from "../store/ducks/TableStructure/types";
import { useAppDispatch, useAppSelector } from "../store/hooks";

type KeystableStructure =
  | "paises"
  | "estados"
  | "cidades"
  | "estadoCivil"
  | "etnia"
  | "nacionalidade"
  | "escolaridade"
  | "deficiencia"
  | "cid"
  | "status"
  | "typeDependents"
  | "typeDocVinculo"
  | "typeSolicitation"
  | "documentsSolicitantion"
  | "regimeTrabalho"
  | "statusUserSolicitation"
  | "corCabelo"
  | "corOlhos"
  | "regimePrevidenciario"
  | "listaSecretarias"
  | "listaSetores"
  | "listaBeneficios";

export const useTableStructure = (itens: KeystableStructure[]) => {
  const dispatch = useAppDispatch();

  // @ts-ignore
  const itensArraysValues: ItensVaueTypes[] = itens.map((item: any) => {
    const tabela = useAppSelector(({ TableStructure }) => TableStructure);
    // @ts-ignore
    return tabela[item];
  });
  const arrayGetItemTable = itensArraysValues
    .map((item) => (!item.itemsTable.length ? item.cod_tabela : null))
    .filter((item) => item !== null);

  useEffect(() => {
    if (arrayGetItemTable.length) {
      dispatch(getItemTableAction(arrayGetItemTable as number[]));
    }
  }, []);
};
