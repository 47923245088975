import { useLayoutEffect } from "react";

import { BodyContainer } from "../../components/BodyContainer";
import { Users } from "../../components/TabNavigation/Management/Users";
import { getClientToUdateAction } from "../../store/ducks/Clients/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export function UsersAll() {
  const {
    Clients: { clientToUpdate },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (!clientToUpdate.sigla) dispatch(getClientToUdateAction());
  }, []);

  return (
    <BodyContainer menu="Usuários">
      <Users schemaCliente={String(clientToUpdate.schema_base)} />
    </BodyContainer>
  );
}
