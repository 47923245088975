/* eslint-disable @typescript-eslint/no-explicit-any */
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Divider, Grid, IconButton } from "@mui/material";
import { useState } from "react";

import { DataAnexosSolicitations } from "../../../pages/CreateSolicitation";
import { ButtonPrimary } from "../../base/Buttons";
import { CardPrimery } from "../../base/Cards";
import { SwitchStyle } from "../../base/stylesGeneric";
import * as T from "../../base/Text";
import * as S from "../styles";

type SolicitAnexosProps = {
  setAnexosSolicitantion: (anexos: DataAnexosSolicitations[]) => void;
  anexosSolicitantion: DataAnexosSolicitations[];
};

export function SolicitAnexos({
  anexosSolicitantion,
  setAnexosSolicitantion,
}: SolicitAnexosProps) {
  const [inputValue, setInput] = useState({
    id: "",
    descricao: "",
    ind_obrigatorio: "N",
  });

  const newAnexo = () => {
    const anexo: DataAnexosSolicitations = {
      id: String(Math.random().toString(36).substr(2, 9)),
      descricao: "",
      ind_obrigatorio: "N",
    };

    const anexos = [...anexosSolicitantion, anexo];
    setAnexosSolicitantion(anexos);
  };

  const handleSaveDescription = (descricao: string) => {
    const newDataAnexos: DataAnexosSolicitations[] = anexosSolicitantion.map(
      (anexo) => {
        if (anexo.id === inputValue.id) {
          return {
            ...anexo,
            descricao,
          };
        }
        return anexo;
      }
    );
    setAnexosSolicitantion(newDataAnexos);
    setInput({
      id: "",
      descricao: "",
      ind_obrigatorio: "S",
    });
  };

  const handleSwitch = (id: string) => {
    const newDataAnexos: DataAnexosSolicitations[] = anexosSolicitantion.map(
      (anexo) => {
        if (anexo.id === id) {
          return {
            ...anexo,
            ind_obrigatorio: anexo.ind_obrigatorio === "S" ? "N" : "S",
          };
        }
        return anexo;
      }
    );
    setAnexosSolicitantion(newDataAnexos);
  };

  const handleDelete = (id: string) => {
    const newDataAnexos = anexosSolicitantion.filter(
      (anexo) => anexo.id !== id
    );
    setAnexosSolicitantion(newDataAnexos);
  };

  return (
    <CardPrimery
      container
      style={{
        padding: "1.5rem 2rem",
      }}
    >
      <Grid container marginBottom="1rem">
        <Grid item xs={12} md={10} display="flex" justifyContent="flex-end">
          <T.H5>
            Cadastre os anexos que serão solicitados durante o preenchimento da
            solicitação
          </T.H5>
        </Grid>
        <Grid item xs={12} md={2} display="flex" justifyContent="flex-end">
          <ButtonPrimary onClick={() => newAnexo()}>
            <AddIcon style={{ margin: "0 0.5rem 0 0" }} />
            SOLICITAR ANEXO
          </ButtonPrimary>
        </Grid>
      </Grid>
      <S.BoxGrid>
        {anexosSolicitantion.map((anexo) => (
          <S.BoxAnexos key={anexo.id} checked={anexo.ind_obrigatorio}>
            <S.InputTextArea
              id={anexo.id}
              rows={4}
              autoFocus
              value={
                inputValue.id === anexo.id
                  ? inputValue.descricao
                  : anexo.descricao
              }
              onChange={(e) => {
                setInput({
                  id: anexo.id,
                  descricao: e.target.value,
                  ind_obrigatorio: anexo.ind_obrigatorio,
                });
              }}
              onBlur={(e) => handleSaveDescription(e.target.value)}
            />

            <S.BoxSwitchs>
              <Divider />
              <S.BoxLabelSwitch>
                <T.Paragraph>Exluir</T.Paragraph>
                <IconButton
                  style={{
                    padding: " 4px 8px",
                  }}
                  onClick={() => handleDelete(anexo.id)}
                >
                  <DeleteIcon />
                </IconButton>
              </S.BoxLabelSwitch>
              <Divider />
              <S.BoxLabelSwitch>
                <T.Paragraph>Obrigatorio</T.Paragraph>
                <SwitchStyle checked={anexo.ind_obrigatorio} />
                <input
                  type="checkbox"
                  style={{
                    display: "none",
                  }}
                  checked={anexo.ind_obrigatorio === "S"}
                  onChange={() => handleSwitch(anexo.id)}
                />
              </S.BoxLabelSwitch>
              <Divider />
            </S.BoxSwitchs>
          </S.BoxAnexos>
        ))}
      </S.BoxGrid>
    </CardPrimery>
  );
}
