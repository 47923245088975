/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { FasesProvaVidaProps } from "../../../../pages/MySolicitation";
import { api } from "../../../../services/api";
import { UserObjectTypes } from "../../../../store/ducks/User/types";
import { setToastMessage } from "../../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getIdURLUserSol, removeMask } from "../../../../utils/utils";
import { ButtonPrimary, ButtonSecondary } from "../../../base/Buttons";
import * as T from "../../../base/Text";
import { InputBase } from "../../../Inputs/InputText";
import * as S from "./styles";

type ConfirmSMSProps = {
  isEditable: boolean;
  provaVida: FasesProvaVidaProps;
  getUserSolicitation(): Promise<void>;
  userSolicitation: UserObjectTypes;
};

export function ConfirmSMS({
  isEditable,
  provaVida,
  getUserSolicitation,
  userSolicitation,
}: ConfirmSMSProps) {
  const { handleSubmit, control, getValues, setValue } = useForm<any>();

  const [isSMSEnviado, setIsSMSEnviado] = useState(false);

  const {
    Solicitation: { dadosComplementares },
    Utils: { device },
  } = useAppSelector((state) => state);

  const dispatch = useAppDispatch();

  const onSubmit = async (body: any) => {
    try {
      const { codigoSMS } = body;
      if (!codigoSMS) {
        dispatch(
          setToastMessage({
            type: "error",
            message: "Preencha o código de verificação",
          })
        );
        return;
      }
      const { data } = await api.post(
        `/proof-of-life/validate-cod-sms/${getIdURLUserSol()}`,
        { codigoSMS }
      );
      setIsSMSEnviado(true);
      dispatch(setToastMessage({ type: "success", message: data.message }));
      getUserSolicitation();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const onClickEnviarSMS = async () => {
    try {
      const { data } = await api.post(
        `/proof-of-life/send-cod-sms/${getIdURLUserSol()}`,
        {
          telefone_movel: removeMask(getValues().celular),
        }
      );
      setIsSMSEnviado(true);
      dispatch(setToastMessage({ type: "success", message: data.message }));
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  useEffect(() => {
    if (userSolicitation?.celular || dadosComplementares?.telefone_movel) {
      setValue(
        "celular",
        userSolicitation?.celular || dadosComplementares?.telefone_movel
      );
    }
  }, [dadosComplementares]);

  return (
    <S.FormContainer onSubmit={handleSubmit(onSubmit)}>
      {provaVida.isCompleted ? (
        <S.FormContent>
          {device === "mobile" && (
            <div
              style={{
                marginBottom: "1rem",
              }}
            >
              <T.H6 size="1.3rem">Verificação SMS</T.H6>
            </div>
          )}
          <S.FormItem>
            <T.H6 textAlign="center">Codigo verificado com sucesso!</T.H6>
          </S.FormItem>
          <img src="/images/verify-code.gif" width={200} alt="" />
        </S.FormContent>
      ) : (
        <S.FormContent>
          <div
            style={{
              marginBottom: "1rem",
            }}
          >
            <T.H6 size="1.3rem">Verificação SMS</T.H6>
          </div>
          <S.FormItem>
            <T.H6 textAlign="center">
              {isSMSEnviado
                ? "Confirme o codigo que lhe enviamos por SMS"
                : "Sera enviado um codido SMS para o celular"}
            </T.H6>
          </S.FormItem>
          <S.FormItem>
            <InputBase
              control={control}
              id="celular"
              label="Celular"
              name="celular"
              type="phone"
              readOnly={
                !!(
                  userSolicitation?.celular ||
                  dadosComplementares?.telefone_movel
                ) || !isEditable
              }
              required
            />
          </S.FormItem>
          {isEditable ? (
            isSMSEnviado ? (
              <>
                <S.FormItem>
                  <InputBase
                    control={control}
                    id="codigoSMS"
                    label="Codigo SMS"
                    name="codigoSMS"
                    maxLength={6}
                    readOnly={!isEditable}
                  />
                </S.FormItem>
                <S.FormBoxButtons
                  style={{
                    justifyContent: "space-between",
                  }}
                >
                  <ButtonSecondary
                    variant="outlined"
                    onClick={onClickEnviarSMS}
                  >
                    Reenviar SMS
                  </ButtonSecondary>
                  <ButtonPrimary type="submit">Validar</ButtonPrimary>
                </S.FormBoxButtons>
              </>
            ) : (
              <S.FormBoxButtons>
                <ButtonPrimary onClick={onClickEnviarSMS}>
                  Enviar SMS
                </ButtonPrimary>
              </S.FormBoxButtons>
            )
          ) : null}
        </S.FormContent>
      )}
    </S.FormContainer>
  );
}
