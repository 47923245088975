import { combineReducers } from "redux";

import Clients from "./Clients";
import Graphics from "./Graphics";
import ProofOfLife from "./ProofOfLife";
import Solicitation from "./Solicitation";
import SystemParameters from "./SystemParameters";
import TableStructure from "./TableStructure";
import User from "./User";
import Utils from "./Utils";

export default combineReducers({
  Utils,
  User,
  SystemParameters,
  Clients,
  TableStructure,
  Solicitation,
  ProofOfLife,
  Graphics,
});
