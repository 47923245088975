/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { ISolicitationProps } from "../../../types/Solicitation";
import { convertData } from "../../../utils/utils";
import { ButtonPrimary } from "../../base/Buttons";
import { CardPrimery } from "../../base/Cards";
import * as T from "../../base/Text";
import { AutoComplete } from "../../Inputs/InputAutoComplete";
import { InputDate } from "../../Inputs/InputDate";
import { InputSelect } from "../../Inputs/InputSelect";
import { InputBase } from "../../Inputs/InputText";
import * as S from "./styles";

type GenericManagementProps = {
  solicitation: ISolicitationProps;
  isEdit: boolean;
};

type fileProps = {
  id_anexos_termo_preenchi: number;
  id_solicitacao: number;
  mime_type: string;
  nome_arquivo: string;
  conteudo_arquivo: {
    data: number[];
    type: string;
  };
};

export function GenericManagement({
  solicitation,
  isEdit,
}: GenericManagementProps) {
  const { handleSubmit, control, reset, setError } =
    useForm<ISolicitationProps>();
  const [termoFile, setTermoFile] = useState<fileProps | undefined>();

  const dispatch = useAppDispatch();
  const {
    TableStructure: { typeSolicitation },
    Clients: { clientLogged },
  } = useAppSelector(({ TableStructure, Clients }) => ({
    TableStructure,
    Clients,
  }));

  const onSubmit: SubmitHandler<ISolicitationProps> = async (body) => {
    try {
      const {
        descr_solicitacao,
        observacoes,
        msg_fim_preench,
        id_solicitacao_sup,
        tipo_solicitacao_item,
        dt_fim_preench,
        dt_inicio_preench,
      } = body as ISolicitationProps;

      if (!dt_inicio_preench && dt_fim_preench) {
        setError("dt_inicio_preench", {
          type: "required",
          message:
            "Se data final de preenchimento for informada, é necessário informar a data inicial",
        });
        return;
      }
      const solicitationData = {
        descr_solicitacao,
        tipo_solicitacao_item,
        id_solicitacao_sup,
        observacoes,
        msg_fim_preench,
        ...(dt_inicio_preench && {
          dt_inicio_preench: convertData(dt_inicio_preench),
        }),
        ...(dt_fim_preench && {
          dt_fim_preench: convertData(dt_fim_preench),
        }),
      } as ISolicitationProps;

      const { data } = await api.put(
        `/solicitation/update/${solicitation?.id_solicitacao}`,
        solicitationData
      );
      dispatch(setToastMessage({ type: "success", message: data.message }));
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
      if (error?.errors && error?.errors.length) {
        error.errors.forEach((error: any) => {
          setError(error.property, {
            type: "manual",
            message: error.error,
          });
        });
      }
    }
  };

  const getValuesArray = async (target: any) => {
    if (target.value.length > 2) {
      const { data } = await api.get(
        `/solicitation/list?descr_solicitacao=${target.value || ""}`
      );
      const newValue = data.map((item: any) => ({
        id: item.id_solicitacao,
        text: item.descr_solicitacao,
      }));
      return newValue;
    }
    return null;
  };

  const getTermoPreechimento = async () => {
    try {
      const { data } = await api.get<fileProps>(
        `/solicitation/termo-preenchimento/${solicitation?.id_solicitacao}`
      );
      setTermoFile(data.nome_arquivo ? data : undefined);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const updateTermoPreechimento = async (file: File) => {
    try {
      const formData = new FormData();
      formData.append("termo_preenchimento", file);
      const { data } = await api.put(
        `/solicitation/termo-preenchimento/${solicitation?.id_solicitacao}`,
        formData
      );
      dispatch(setToastMessage({ type: "success", message: data.message }));
      getTermoPreechimento();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const downloadTermoPreechimento = async () => {
    if (termoFile?.nome_arquivo) {
      const a = document.createElement("a");
      a.href = `data:${termoFile.mime_type};base64,${termoFile.conteudo_arquivo}`;
      a.download = termoFile?.nome_arquivo;
      a.click();
    }
  };

  const deleteTermoPreechimento = async () => {
    try {
      const { data } = await api.delete(
        `/solicitation/termo-preenchimento/${termoFile?.id_anexos_termo_preenchi}`
      );
      dispatch(setToastMessage({ type: "success", message: data.message }));
      setTermoFile(undefined);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  useEffect(() => {
    if (solicitation?.id_solicitacao) {
      getTermoPreechimento();
      reset({
        ...solicitation,
        id_solicitacao_sup: solicitation?.descr_solicitacao_sup as number,
      });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardPrimery container spacing={1} margin="1rem" padding="1.5rem 2rem">
        <Grid item xs={12} md={8}>
          <InputBase
            control={control}
            id="descr_solicitacao"
            label="Descrição"
            name="descr_solicitacao"
            readOnly={!isEdit}
            disabled={!isEdit}
            required
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputSelect
            title="Tipo de Solicitação"
            control={control}
            id="tipo_solicitacao_item"
            label="Tipo de Solicitação"
            name="tipo_solicitacao_item"
            readOnly={!isEdit}
            disabled={!isEdit}
            required
          >
            <option> </option>
            {typeSolicitation.itemsTable?.length &&
              typeSolicitation.itemsTable.map((type: any) => {
                if (type.item_tabela === 0) return null;
                return (
                  <option key={type.item_tabela} value={type.item_tabela}>
                    {type.descr_tabela}
                  </option>
                );
              })}
          </InputSelect>
        </Grid>
        <Grid item xs={12} md={4}>
          <InputDate
            control={control}
            id="dt_inicio_preench"
            label="Data Início Preenchimento"
            name="dt_inicio_preench"
            readOnly={!isEdit}
            disabled={!isEdit}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <InputDate
            control={control}
            id="dt_fim_preench"
            label="Data Fim Preenchimento"
            name="dt_fim_preench"
            readOnly={!isEdit}
            disabled={!isEdit}
          />
        </Grid>
        <Grid item xs={4}>
          <AutoComplete
            control={control}
            id="id_solicitacao_sup"
            label="Vincula a outra solicitação"
            title="Vincula a outra solicitação"
            name="id_solicitacao_sup"
            readOnly={!isEdit}
            disabled={!isEdit}
            defaultValue={solicitation?.id_solicitacao_sup}
            optionsArray={[]}
            getId
            getValuesArray={getValuesArray}
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            control={control}
            id="observacoes"
            label="Observacões"
            name="observacoes"
            readOnly={!isEdit}
            disabled={!isEdit}
            maxRows={2}
            multiline
          />
        </Grid>
        <Grid item xs={12}>
          <InputBase
            control={control}
            id="msg_fim_preench"
            label="Messagem de fim de preenchimento"
            name="msg_fim_preench"
            readOnly={!isEdit}
            disabled={!isEdit}
            maxRows={2}
            multiline
          />
        </Grid>
        {clientLogged?.token_asten_assinatura && (
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <T.H6>Asten Assinatura</T.H6>
                <T.Paragraph size="0.775rem">
                  No campo abaixo voce pode definir o{" "}
                  <b>Termo de Preenchimento</b> que o usuario irá assinar
                  eletronicamente na plataforma{" "}
                  <a
                    href="https://plataforma.astenassinatura.com.br/login"
                    target="_blank"
                    rel="noreferrer"
                    style={{
                      color: "inherit",
                      textDecoration: "underline",
                      fontWeight: "bold",
                    }}
                  >
                    Asten Assinatura
                  </a>
                  , caso não seja definido nenhum termo, o sistema irá utilizar
                  o termo padrão.
                </T.Paragraph>
              </Grid>
              <Grid item xs={12}>
                <S.ContainerInputFile
                  success={Boolean(termoFile?.nome_arquivo)}
                >
                  <S.InputLabel success={Boolean(termoFile?.nome_arquivo)}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "50%",
                      }}
                    >
                      <T.H6 width="max-content">Termo de Preenchimento</T.H6>
                      {termoFile?.nome_arquivo && (
                        <T.Span>{termoFile.nome_arquivo}</T.Span>
                      )}
                    </div>
                    {!termoFile?.nome_arquivo && (
                      <div style={{ width: "50%" }}>
                        <Tooltip title="Anexar arquivo">
                          <CloudUploadIcon color="action" />
                        </Tooltip>
                      </div>
                    )}
                    {isEdit && (
                      <input
                        type="file"
                        id="termo_preenchimento"
                        style={{ display: "none" }}
                        accept="image/*, application/pdf"
                        onChange={(e) => {
                          if (e.target.files) {
                            updateTermoPreechimento(e.target.files[0]);
                          }
                        }}
                      />
                    )}
                  </S.InputLabel>
                  <div
                    style={{
                      display: "inline-flex",
                      alignItems: "center",
                      minWidth: termoFile?.nome_arquivo ? "initial" : "2.5rem",
                    }}
                  >
                    {termoFile?.nome_arquivo ? (
                      <Tooltip title="Baixar Arquivo">
                        <IconButton onClick={() => downloadTermoPreechimento()}>
                          <FileDownloadIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                    {termoFile?.nome_arquivo && isEdit ? (
                      <Tooltip title="Deletar Arquivo">
                        <IconButton onClick={() => deleteTermoPreechimento()}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    ) : null}
                  </div>
                </S.ContainerInputFile>
              </Grid>
            </Grid>
          </Grid>
        )}
      </CardPrimery>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        {isEdit && <ButtonPrimary type="submit">Salvar</ButtonPrimary>}
      </div>
    </form>
  );
}
