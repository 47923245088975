/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-restricted-globals */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { FormControlLabel, Grid, Radio } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setDadosComplementaresAction } from "../../../store/ducks/Solicitation/actions";
import { IDadosComplementares } from "../../../store/ducks/Solicitation/types";
import { setItemAction } from "../../../store/ducks/TableStructure/actions";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  convertData,
  createObjectValNotNull,
  DADOS_COMPLEMENTARES,
  getCepViaCep,
  getIdURLUserSol,
  removeMask,
} from "../../../utils/utils";
import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import { InputDate } from "../../Inputs/InputDate";
import { InputRadios } from "../../Inputs/InputRadios";
import { InputSelect } from "../../Inputs/InputSelect";
import { InputBase } from "../../Inputs/InputText";
import * as S from "../styles";

type IDadosComplementaresProps = {
  isEditable: boolean;
  getUserSolicitation(): Promise<void>;
  isCompleted: boolean;
  isOpenCorrecion: boolean;
  isOnChangeSetListCorrecion: (etapa: string, campo: string) => boolean;
  onChangeSetListCorrecion: (
    etapa: string,
    campo: string,
    label: string
  ) => void;
  correctionValuesJsonData: any;
};

export function DadosComplementares({
  isEditable,
  getUserSolicitation,
  isCompleted,
  isOpenCorrecion,
  isOnChangeSetListCorrecion,
  onChangeSetListCorrecion,
  correctionValuesJsonData,
}: IDadosComplementaresProps) {
  const { handleSubmit, control, setError, getValues, reset, setValue } =
    useForm<IDadosComplementares>();

  const [indTrabalhadorEstrangeiro, setIndTrabalhadorEstrangeiro] =
    useState("N");
  const [indResidenciaPropria, setIndResidenciaPropria] = useState("N");

  const dispatch = useAppDispatch();

  const { TableStructure, Solicitation } = useAppSelector((state) => state);
  const { paises, estados, cidades } = TableStructure;

  const { dadosComplementares } = Solicitation;

  const getData = async (values?: any) => {
    const idSolUser = getIdURLUserSol();
    const data =
      values ||
      (await api.get(`/solicitation/supplementary-data/${idSolUser}`)).data;

    if (data) {
      const newDadosComplementares: IDadosComplementares = {
        ...dadosComplementares,
        ...data,
        cidade: data?.cidade_item,
        estado: data?.estado_item,
        pais: data?.pais_item,
      };
      if (newDadosComplementares?.ind_trabalhador_estrang === "S")
        setIndTrabalhadorEstrangeiro("S");
      if (newDadosComplementares?.ind_residencia_propria === "S")
        setIndResidenciaPropria("S");

      reset(newDadosComplementares);
      dispatch(setDadosComplementaresAction(newDadosComplementares));
    }
  };

  async function getCep(cep: string) {
    try {
      const endereco = await getCepViaCep(cep);
      if (Object.keys(endereco).length) {
        setValue("endereco", endereco.logradouro);
        setValue("bairro", endereco.bairro);
        setValue("complemento", endereco.complemento);
      }
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  }

  const onSubmit: SubmitHandler<IDadosComplementares> = async (body) => {
    try {
      const idSolUser = getIdURLUserSol();
      const newDadosComplementares = createObjectValNotNull(
        body
      ) as IDadosComplementares;

      const { data } = await api.post(
        `/solicitation/supplementary-data/commited/${idSolUser}/${
          dadosComplementares.id_sol_dados_compl || ""
        }`,
        {
          ...newDadosComplementares,
          cep: removeMask(newDadosComplementares.cep),
          dt_chegada_brasil: convertData(
            newDadosComplementares.dt_chegada_brasil
          ),
          dt_naturalizacao: convertData(
            newDadosComplementares.dt_naturalizacao
          ),
        }
      );
      getData(data);
      await getUserSolicitation();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
      if (error?.errors && error?.errors.length) {
        error.errors.forEach((error: any) => {
          setError(error.property, {
            type: "manual",
            message: error.error,
          });
        });
      }
    }
  };

  const saveData = async () => {
    try {
      const body = createObjectValNotNull(getValues()) as IDadosComplementares;
      if (!Object.keys(body).length) {
        dispatch(
          setToastMessage({
            type: "error",
            message: "Preencha os campos para salvar",
          })
        );
        return;
      }

      const idSolUser = getIdURLUserSol();
      const newDadosComplementares = body;

      const { data } = await api.post(
        `/solicitation/supplementary-data/save/${idSolUser}/${
          dadosComplementares.id_sol_dados_compl || ""
        }`,
        {
          ...newDadosComplementares,
          ...(newDadosComplementares.cep && {
            cep: removeMask(newDadosComplementares.cep),
            dt_chegada_brasil: convertData(
              newDadosComplementares.dt_chegada_brasil
            ),
            dt_naturalizacao: convertData(
              newDadosComplementares.dt_naturalizacao
            ),
          }),
        }
      );
      getData(data);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
      if (error?.errors && error?.errors.length) {
        error.errors.forEach((error: any) => {
          setError(error.property, {
            type: "manual",
            message: error.error,
          });
        });
      }
    }
  };

  const getItemTableStructure = async (
    codTabelaSup: number,
    itemTabelaSup: number,
    inputAtt: string
  ) => {
    const { data } = await api.get(
      `/system/structured-table?cod=&codSup=${codTabelaSup}&itemSup=${itemTabelaSup}`
    );
    dispatch(setItemAction({ data, key: inputAtt }));
  };

  useEffect(() => {
    if (!dadosComplementares.id_sol_usuario) {
      getData();
    }
  }, []);

  const clearValuesForm = (values: string[]) => {
    // @ts-ignore
    values.forEach((value) => setValue(value, ""));
  };

  return (
    <Grid container spacing={1} padding="1rem">
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        style={{ width: "100%", height: "100%" }}
      >
        <Grid container spacing={1} style={{ width: "100%" }}>
          <Grid item xs={12} md={12}>
            <T.H6>CONTATO</T.H6>
          </Grid>
          <S.ContainerInput
            item
            xs={12}
            md={6}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              DADOS_COMPLEMENTARES,
              "email"
            )}
            onClick={() =>
              onChangeSetListCorrecion(DADOS_COMPLEMENTARES, "email", "email")
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-email`]}
              message={
                correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-email`]
                  ?.message
              }
            />
            <InputBase
              control={control}
              id="email"
              label="Email"
              name="email"
              type="email"
              readOnly={!isEditable}
              required
            />
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              DADOS_COMPLEMENTARES,
              "telefone_movel"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                DADOS_COMPLEMENTARES,
                "telefone_movel",
                "Celular"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[
                  `${DADOS_COMPLEMENTARES}-telefone_movel`
                ]
              }
              message={
                correctionValuesJsonData[
                  `${DADOS_COMPLEMENTARES}-telefone_movel`
                ]?.message
              }
            />
            <InputBase
              control={control}
              id="telefone_movel"
              label="Celular"
              name="telefone_movel"
              type="phone"
              readOnly={!isEditable}
              required
            />
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              DADOS_COMPLEMENTARES,
              "telefone_fixo"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                DADOS_COMPLEMENTARES,
                "telefone_fixo",
                "Telefone fixo"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[
                  `${DADOS_COMPLEMENTARES}-telefone_fixo`
                ]
              }
              message={
                correctionValuesJsonData[
                  `${DADOS_COMPLEMENTARES}-telefone_fixo`
                ]?.message
              }
            />
            <InputBase
              control={control}
              id="telefone_fixo"
              label="Telefone fixo"
              name="telefone_fixo"
              type="phone"
              readOnly={!isEditable}
            />
          </S.ContainerInput>
          <Grid item xs={12} md={12}>
            <T.H6>RESIDENCIA</T.H6>
          </Grid>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              DADOS_COMPLEMENTARES,
              "ind_trabalhador_estrang"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                DADOS_COMPLEMENTARES,
                "ind_trabalhador_estrang",
                "Trabalhador estrangeiro"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[
                  `${DADOS_COMPLEMENTARES}-ind_trabalhador_estrang`
                ]
              }
              message={
                correctionValuesJsonData[
                  `${DADOS_COMPLEMENTARES}-ind_trabalhador_estrang`
                ]?.message
              }
            />
            <InputRadios
              control={control}
              id="ind_trabalhador_estrang"
              title="Trabalhador estrangeiro"
              name="ind_trabalhador_estrang"
              readOnly={!isEditable}
              row
              required
            >
              <FormControlLabel
                value="S"
                control={
                  <Radio
                    size="small"
                    onChange={() => {
                      setIndTrabalhadorEstrangeiro("S");
                      clearValuesForm([
                        "ind_residencia_propria",
                        "ind_comprada_fgts",
                      ]);
                    }}
                  />
                }
                label="Sim"
              />
              <FormControlLabel
                value="N"
                control={
                  <Radio
                    size="small"
                    onChange={() => {
                      setIndTrabalhadorEstrangeiro("N");
                      clearValuesForm([
                        "ind_reside_exterior",
                        "casado_br",
                        "dt_chegada_brasil",
                        "dt_naturalizacao",
                        "filho_br",
                      ]);
                    }}
                  />
                }
                label="Não"
              />
            </InputRadios>
          </S.ContainerInput>
          {indTrabalhadorEstrangeiro === "N" && (
            <>
              <S.ContainerInput
                item
                xs={12}
                md={3}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  DADOS_COMPLEMENTARES,
                  "ind_reside_exterior"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    DADOS_COMPLEMENTARES,
                    "ind_reside_exterior",
                    "Reside exterior"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${DADOS_COMPLEMENTARES}-ind_reside_exterior`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${DADOS_COMPLEMENTARES}-ind_reside_exterior`
                    ]?.message
                  }
                />
                <InputRadios
                  control={control}
                  id="ind_reside_exterior"
                  title="Reside exterior"
                  name="ind_reside_exterior"
                  readOnly={!isEditable}
                  row
                  required
                >
                  <FormControlLabel
                    value="S"
                    control={<Radio size="small" />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="N"
                    control={<Radio size="small" />}
                    label="Não"
                  />
                </InputRadios>
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={3}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  DADOS_COMPLEMENTARES,
                  "ind_residencia_propria"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    DADOS_COMPLEMENTARES,
                    "ind_residencia_propria",
                    "Residência própria"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${DADOS_COMPLEMENTARES}-ind_residencia_propria`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${DADOS_COMPLEMENTARES}-ind_residencia_propria`
                    ]?.message
                  }
                />
                <InputRadios
                  control={control}
                  id="ind_residencia_propria"
                  title="Residência própria"
                  name="ind_residencia_propria"
                  readOnly={!isEditable}
                  row
                  required
                >
                  <FormControlLabel
                    value="S"
                    control={
                      <Radio
                        size="small"
                        onChange={() => setIndResidenciaPropria("S")}
                      />
                    }
                    label="Sim"
                  />
                  <FormControlLabel
                    value="N"
                    control={
                      <Radio
                        size="small"
                        onChange={() => {
                          setIndResidenciaPropria("N");
                          clearValuesForm(["ind_comprada_fgts"]);
                        }}
                      />
                    }
                    label="Não"
                  />
                </InputRadios>
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={3}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  DADOS_COMPLEMENTARES,
                  "ind_comprada_fgts"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    DADOS_COMPLEMENTARES,
                    "ind_comprada_fgts",
                    "Comprada com FGTS"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${DADOS_COMPLEMENTARES}-ind_comprada_fgts`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${DADOS_COMPLEMENTARES}-ind_comprada_fgts`
                    ]?.message
                  }
                />
                <InputRadios
                  control={control}
                  id="ind_comprada_fgts"
                  title="Comprada com FGTS"
                  name="ind_comprada_fgts"
                  readOnly={!isEditable || indResidenciaPropria === "N"}
                  row
                >
                  <FormControlLabel
                    value="S"
                    control={<Radio size="small" />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="N"
                    control={<Radio size="small" />}
                    label="Não"
                  />
                </InputRadios>
              </S.ContainerInput>
            </>
          )}
          {indTrabalhadorEstrangeiro === "S" && (
            <>
              <S.ContainerInput
                item
                xs={12}
                md={3}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  DADOS_COMPLEMENTARES,
                  "casado_br"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    DADOS_COMPLEMENTARES,
                    "casado_br",
                    "Casado no Brasil"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${DADOS_COMPLEMENTARES}-casado_br`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${DADOS_COMPLEMENTARES}-casado_br`
                    ]?.message
                  }
                />
                <InputRadios
                  control={control}
                  id="casado_br"
                  title="Casado no Brasil"
                  name="casado_br"
                  readOnly={!isEditable}
                  row
                  required
                >
                  <FormControlLabel
                    value="S"
                    control={<Radio size="small" />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="N"
                    control={<Radio size="small" />}
                    label="Não"
                  />
                </InputRadios>
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={2}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  DADOS_COMPLEMENTARES,
                  "filho_br"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    DADOS_COMPLEMENTARES,
                    "filho_br",
                    "Filho Brasileiro"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-filho_br`]
                  }
                  message={
                    correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-filho_br`]
                      ?.message
                  }
                />
                <InputRadios
                  control={control}
                  id="filho_br"
                  title="Filho Brasileiro"
                  name="filho_br"
                  readOnly={!isEditable}
                  row
                  required
                >
                  <FormControlLabel
                    value="S"
                    control={<Radio size="small" />}
                    label="Sim"
                  />
                  <FormControlLabel
                    value="N"
                    control={<Radio size="small" />}
                    label="Não"
                  />
                </InputRadios>
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={2}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  DADOS_COMPLEMENTARES,
                  "dt_chegada_brasil"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    DADOS_COMPLEMENTARES,
                    "dt_chegada_brasil",
                    "Data de chegada ao Brasil"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${DADOS_COMPLEMENTARES}-dt_chegada_brasil`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${DADOS_COMPLEMENTARES}-dt_chegada_brasil`
                    ]?.message
                  }
                />
                <InputDate
                  control={control}
                  id="dt_chegada_brasil"
                  label="Data de chegada ao Brasil"
                  name="dt_chegada_brasil"
                  readOnly={!isEditable}
                  required
                />
              </S.ContainerInput>
              <S.ContainerInput
                item
                xs={12}
                md={2}
                isOpenCorrecion={isOpenCorrecion}
                isEditable={isEditable}
                isSelected={isOnChangeSetListCorrecion(
                  DADOS_COMPLEMENTARES,
                  "dt_naturalizacao"
                )}
                onClick={() =>
                  onChangeSetListCorrecion(
                    DADOS_COMPLEMENTARES,
                    "dt_naturalizacao",
                    "Data de naturalização"
                  )
                }
              >
                <S.IconInputCorrecion
                  isIcon={
                    correctionValuesJsonData[
                      `${DADOS_COMPLEMENTARES}-dt_naturalizacao`
                    ]
                  }
                  message={
                    correctionValuesJsonData[
                      `${DADOS_COMPLEMENTARES}-dt_naturalizacao`
                    ]?.message
                  }
                />
                <InputDate
                  control={control}
                  id="dt_naturalizacao"
                  label="Data de naturalização"
                  name="dt_naturalizacao"
                  readOnly={!isEditable}
                  required
                />
              </S.ContainerInput>
            </>
          )}
          <Grid item xs={12} md={12}>
            <T.H6>ENDEREÇO</T.H6>
          </Grid>
          <S.ContainerInput
            item
            xs={12}
            md={4}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              DADOS_COMPLEMENTARES,
              "pais"
            )}
            onClick={() =>
              onChangeSetListCorrecion(DADOS_COMPLEMENTARES, "pais", "Pais")
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-pais`]}
              message={
                correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-pais`]
                  ?.message
              }
            />
            <InputSelect
              title="Pais"
              control={control}
              id="pais"
              label="Pais"
              name="pais"
              readOnly={!isEditable}
              required
              onClick={(e: any) =>
                getItemTableStructure(
                  paises.cod_tabela,
                  e.target.value,
                  "estados"
                )
              }
            >
              <option> </option>
              {paises.itemsTable?.length &&
                paises.itemsTable.map((pais) => {
                  if (pais.item_tabela === 0) return null;
                  return (
                    <option key={Math.random()} value={pais.item_tabela}>
                      {pais.descr_tabela}
                    </option>
                  );
                })}
            </InputSelect>
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={4}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              DADOS_COMPLEMENTARES,
              "estado"
            )}
            onClick={() =>
              onChangeSetListCorrecion(DADOS_COMPLEMENTARES, "estado", "Estado")
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-estado`]
              }
              message={
                correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-estado`]
                  ?.message
              }
            />
            <InputSelect
              title="Estado"
              control={control}
              id="estado"
              label="Estado"
              name="estado"
              readOnly={!isEditable}
              required
              onClick={(e: any) =>
                getItemTableStructure(
                  estados.cod_tabela,
                  e.target.value,
                  "cidades"
                )
              }
            >
              <option> </option>
              {estados.itemsTable?.length &&
                estados.itemsTable.map((estado) => {
                  if (estado.item_tabela === 0) return null;
                  return (
                    <option key={Math.random()} value={estado.item_tabela}>
                      {estado.descr_tabela}
                    </option>
                  );
                })}
            </InputSelect>
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={4}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              DADOS_COMPLEMENTARES,
              "cidade"
            )}
            onClick={() =>
              onChangeSetListCorrecion(DADOS_COMPLEMENTARES, "cidade", "Cidade")
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-cidade`]
              }
              message={
                correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-cidade`]
                  ?.message
              }
            />
            <InputSelect
              title="Cidade"
              control={control}
              id="cidade"
              label="Cidade"
              name="cidade"
              readOnly={!isEditable}
              required
            >
              <option> </option>
              {cidades.itemsTable?.length &&
                cidades.itemsTable.map((cidade) => {
                  if (cidade.item_tabela === 0) return null;
                  return (
                    <option key={Math.random()} value={cidade.item_tabela}>
                      {cidade.descr_tabela}
                    </option>
                  );
                })}
            </InputSelect>
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={3}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(DADOS_COMPLEMENTARES, "cep")}
            onClick={() =>
              onChangeSetListCorrecion(DADOS_COMPLEMENTARES, "cep", "CEP")
            }
          >
            <S.IconInputCorrecion
              isIcon={correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-cep`]}
              message={
                correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-cep`]?.message
              }
            />
            <InputBase
              control={control}
              id="cep"
              label="CEP"
              name="cep"
              type="cep"
              required
              readOnly={!isEditable}
              onBlur={({ target }: any) => {
                const cep = target.value ? removeMask(target.value) : "";
                if (cep && cep.length === 8) {
                  getCep(cep);
                }
              }}
            />
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={7}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              DADOS_COMPLEMENTARES,
              "endereco"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                DADOS_COMPLEMENTARES,
                "endereco",
                "Endereço"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-endereco`]
              }
              message={
                correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-endereco`]
                  ?.message
              }
            />
            <InputBase
              control={control}
              id="endereco"
              label="Endereço"
              name="endereco"
              required
              readOnly={!isEditable}
            />
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={2}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              DADOS_COMPLEMENTARES,
              "numero"
            )}
            onClick={() =>
              onChangeSetListCorrecion(DADOS_COMPLEMENTARES, "numero", "Numero")
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-numero`]
              }
              message={
                correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-numero`]
                  ?.message
              }
            />
            <InputBase
              control={control}
              id="numero"
              label="Numero"
              name="numero"
              type="number"
              required
              readOnly={!isEditable}
            />
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={6}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              DADOS_COMPLEMENTARES,
              "bairro"
            )}
            onClick={() =>
              onChangeSetListCorrecion(DADOS_COMPLEMENTARES, "bairro", "Bairro")
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-bairro`]
              }
              message={
                correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-bairro`]
                  ?.message
              }
            />
            <InputBase
              control={control}
              id="bairro"
              label="Bairro"
              name="bairro"
              required
              readOnly={!isEditable}
            />
          </S.ContainerInput>
          <S.ContainerInput
            item
            xs={12}
            md={6}
            isOpenCorrecion={isOpenCorrecion}
            isEditable={isEditable}
            isSelected={isOnChangeSetListCorrecion(
              DADOS_COMPLEMENTARES,
              "complemento"
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                DADOS_COMPLEMENTARES,
                "complemento",
                "Complemento"
              )
            }
          >
            <S.IconInputCorrecion
              isIcon={
                correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-complemento`]
              }
              message={
                correctionValuesJsonData[`${DADOS_COMPLEMENTARES}-complemento`]
                  ?.message
              }
            />
            <InputBase
              control={control}
              id="complemento"
              label="Complemento"
              name="complemento"
              readOnly={!isEditable}
            />
          </S.ContainerInput>
        </Grid>
        {isEditable && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "1rem",
            }}
          >
            {!isCompleted && (
              <B.ButtonPrimary variant="outlined" onClick={() => saveData()}>
                Salvar
              </B.ButtonPrimary>
            )}
            <B.ButtonPrimary type="submit">
              {isCompleted ? "Atualizar" : "CONCLUIR ETAPA"}
            </B.ButtonPrimary>
          </div>
        )}
      </form>
    </Grid>
  );
}
