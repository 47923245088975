import { Grid } from "@mui/material";
import styled, { css } from "styled-components";

export const Container = styled.div`
  ${({ theme }) => css`
    display: flex;
    width: 100%;
    min-height: 100vh;
    background-color: ${theme.background.default};
  `}
`;

export const Content = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh;
    background-color: ${theme.background.default};
  `}
`;

export const BoxEndereco = styled(Grid)<{ selected: boolean }>`
  ${({ theme, selected }) => css`
    width: 100%;
    height: auto;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: 1px solid ${theme.border.light};

    margin-bottom: 0.25rem;
    cursor: pointer;

    ${selected && `background-color: ${theme.background.blueLight};`}
    ${selected && `border-color: ${theme.background.paperDark};`}
  `}
`;

export const BoxSolicitation = styled.div<{ selected: boolean }>`
  ${({ theme, selected }) => css`
    width: 100%;
    height: auto;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: 1px solid ${theme.border.light};

    margin-bottom: 0.25rem;
    cursor: pointer;

    ${selected && `background-color: ${theme.background.blueLight};`}
    ${selected && `border-color: ${theme.background.paperDark};`}
  `}
`;

export const BoxHoraAgendado = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: auto;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
    border: 1px solid ${theme.border.light};

    margin-bottom: 0.25rem;
  `}
`;
