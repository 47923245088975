/* eslint-disable no-unused-expressions */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-unsafe-optional-chaining */
import { Alert, AlertTitle, CircularProgress, Grid } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useMemo, useRef, useState } from "react";
import RecordRTC from "recordrtc";

import { api } from "../../../services/api";
import {
  setLoading,
  setToastMessage,
} from "../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { formtTextCamera, getIdURLUserSol } from "../../../utils/utils";
import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import Modal from "../index";
import { onFaceRecognitionMobile } from "./faceRecognitionMobile";
import * as S from "./styles";

interface IModalFaceRecognitionModal {
  isOpen: boolean;
  onClose: () => void;
  getUserSolicitation(): Promise<void>;
  getFaceRecognition(): Promise<void>;
}

type GenderProps = {
  gender: string;
  probability: number;
};

type IDataRecognition = {
  descriptors: Array<any>;
  age: Array<number>;
  gender: Array<GenderProps>;
};

const getDataGender = (lista: GenderProps[]): "M" | "F" | null => {
  const listFemale = lista.filter((item) => item.gender === "female");
  const listMale = lista.filter((item) => item.gender === "male");

  if (listFemale.length && listMale.length) {
    return listFemale.length > listMale.length ? "F" : "M";
  }
  if (listFemale.length) {
    return "F";
  }
  if (listMale.length) {
    return "M";
  }
  return null;
};

const dataRecognition: IDataRecognition = {
  descriptors: [],
  age: [],
  gender: [],
};

let abortRecogniton = false;

export function FaceRecognitionModal({
  isOpen,
  onClose,
  getFaceRecognition,
  getUserSolicitation,
}: IModalFaceRecognitionModal) {
  const containerStream = useRef<HTMLDivElement | null>(null);
  const recordVideo = useRef<any>(null);
  const stream = useRef<MediaStream | null>(null);
  const video = useRef<HTMLVideoElement | null>(null);
  const messageProcess = useRef<any>(null);
  const timeout = useRef<number | null>(null);

  const [allDevicesVideo, setAllDevicesVideo] = useState<MediaDeviceInfo[]>([]);

  const [step, setStep] = useState(0);
  const [optionsSize, setOptionsSize] = useState({
    width: 640,
    height: 480,
  });
  const dispatch = useAppDispatch();
  const device = useAppSelector((state) => state.Utils.device);

  const worker: Worker = useMemo(
    () => new Worker(new URL("./faceRecognitionWorker.js", import.meta.url)),
    []
  );

  const onCloseRecogniton = () => {
    stream?.current?.getTracks().forEach((track: any) => track.stop());

    if (video?.current) video.current.srcObject = null;
    if (recordVideo.current) recordVideo.current.clearRecordedData();
    stream.current = null;
    dataRecognition.descriptors = [];
    dataRecognition.age = [];
    dataRecognition.gender = [];
    timeout.current = null;
    setStep(0);
  };

  const sendDataFaceRecogniton = async (blob: Blob, object: any) => {
    try {
      dispatch(setLoading(true));
      if (!blob.type) return;

      const video = new File([blob], "video.webm", { type: blob.type });
      const formData = new FormData();
      formData.append("file", video);
      formData.append("descriptors", JSON.stringify(object.descriptors));
      formData.append("age", object.age);
      formData.append("gender", object.gender);
      const { data } = await api.post(
        `/proof-of-life/face-recognition-video/${getIdURLUserSol()}`,
        formData
      );

      getUserSolicitation();
      getFaceRecognition();
      onClose();
      dispatch(setToastMessage({ type: "success", message: data.message }));
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    } finally {
      abortRecogniton = false;
      dispatch(setLoading(false));
    }
  };

  const onProcessDetection = async (detections: any, callback: any) => {
    console.log("TCL: onProcessDetection -> detections", detections);
    if (abortRecogniton) return;

    if (dataRecognition.descriptors.length >= 25 && stream.current) {
      // finaliza a captura de dados e envia para o servidor
      abortRecogniton = true;
      const dataSend = {
        age: Number(
          dataRecognition.age.reduce((a, b) => a + b, 0) /
            dataRecognition.age.length
        ).toFixed(0),
        gender: getDataGender(dataRecognition.gender),
        descriptors: dataRecognition.descriptors,
      };

      await recordVideo.current.stopRecording(() => {
        const blob = recordVideo.current.getBlob();
        sendDataFaceRecogniton(blob, dataSend);
      });
      onCloseRecogniton();
    }

    // após 4 minutos finaliza a captura de dados e disparar um erro
    if (
      timeout.current &&
      new Date().getTime() - timeout.current > 4 * 60 * 1000
    ) {
      dispatch(
        setToastMessage({
          type: "error",
          message: "Tempo de captura de dados excedido, tente novamente!",
        })
      );
      onCloseRecogniton();
      return;
    }
    callback();

    if (
      detections &&
      detections.detection &&
      detections.detection._score > 0.6
    ) {
      if (!timeout.current) timeout.current = new Date().getTime();

      if (dataRecognition.descriptors.length >= 20) {
        messageProcess.current.innerHTML =
          "Para finalizar, mantanha-se sorrindo &#128513;";
        if (detections.expressions.happy < 0.6) return;
      } else {
        messageProcess.current.style.color = "#128513";
        messageProcess.current.innerHTML =
          "Estamos capturando seus dados, aguarde...!";
      }

      dataRecognition.age.push(detections.age);
      dataRecognition.descriptors.push(detections.descriptor);
      dataRecognition.gender.push({
        gender: detections.gender,
        probability: detections.genderProbability,
      });
    }
  };

  // somente para desktop
  const initProcessWorker = () => {
    if (!video.current) return;
    const videoEl = video.current as HTMLVideoElement;
    const offlineCanvas = new OffscreenCanvas(videoEl.width, videoEl.height);
    const context = offlineCanvas.getContext("2d")!;

    context?.drawImage(videoEl, 0, 0, videoEl.width, videoEl.height);
    const { width, height, data } = context?.getImageData(
      0,
      0,
      videoEl.width,
      videoEl.height
    );
    worker.postMessage(
      {
        type: "frame",
        width,
        height,
        buffer: data.buffer,
      },
      [data.buffer]
    );
  };

  // Em mobile vai rodar esta versão pois o worker não funciona em mobile
  const initProcessMobile = async () => {
    if (!video.current) return;
    const videoEl = video.current as HTMLVideoElement;
    const detections = await onFaceRecognitionMobile({
      width: videoEl.width,
      height: videoEl.height,
      video: videoEl,
    });
    await onProcessDetection(detections?.detections, initProcessMobile);
  };

  useEffect(() => {
    worker.onmessage = async (event) => {
      try {
        const { detections } = event.data;
        await onProcessDetection(detections, initProcessWorker);
      } catch (error) {
        onCloseRecogniton();
        dispatch(
          setToastMessage({
            type: "error",
            message: "Infelizmente não foi possível identificar sua face",
          })
        );
      }
    };

    return () => {
      worker.terminate();
    };
  }, []);

  const getMediaDevice = async (devideId: string) => {
    try {
      if (stream.current) return;
      abortRecogniton = false;

      const streamDevice = await navigator.mediaDevices.getUserMedia({
        audio: false,
        video: {
          deviceId: devideId,
          width: { min: 640, ideal: 1280, max: 1920 },
          height: { min: 480, ideal: 720, max: 1080 },
        },
      });

      if (video.current) {
        video.current.srcObject = streamDevice;
        video.current.play();
        stream.current = streamDevice;
        recordVideo.current = new RecordRTC(streamDevice, {
          type: "video",
        });

        await recordVideo?.current.startRecording();
        if (device === "mobile") {
          new Array(5).fill(0).forEach(() => {
            console.log("TCL: getMediaDevice -> initProcessMobile");
            initProcessMobile();
          });
        } else {
          new Array(5).fill(0).forEach(() => {
            console.log("TCL: getMediaDevice -> initProcessWorker");
            initProcessWorker();
          });
        }
      }
    } catch (error: any) {
      if (error.name === "NotAllowedError") {
        dispatch(
          setToastMessage({
            type: "error",
            message:
              "Não foi possivel acessar a camera do dispositivo, acesse as configurações do navegador e permita o acesso a camera!",
          })
        );
        onCloseRecogniton();
      } else {
        dispatch(
          setToastMessage({
            type: "error",
            message: "Não foi possível acessar a câmera do dispositivo!",
          })
        );
      }
    }
  };

  const getAllDevicesVideo = async () => {
    try {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter(
        (device) => device.kind === "videoinput"
      );
      setAllDevicesVideo(videoDevices);
    } catch (error: any) {
      if (error.name === "NotAllowedError") {
        dispatch(
          setToastMessage({
            type: "error",
            message: "Você precisa autorizar o acesso a câmera!",
          })
        );
        onCloseRecogniton();
      } else
        dispatch(
          setToastMessage({
            type: "error",
            message: "Não foi possível acessar os dispositivos de vídeo!",
          })
        );
    }
  };

  useEffect(() => {
    getAllDevicesVideo();
  }, []);

  useEffect(() => {
    return () => {
      onCloseRecogniton();
    };
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Reconhecimento Facial">
      <S.ModalBody>
        {step === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <T.H5>Instruções para reconhecimento facial</T.H5>
            </Grid>
            <Grid item xs={12}>
              <T.Paragraph>
                Para realizar o reconhecimento facial, siga as instruções
                abaixo:
              </T.Paragraph>
              <T.Paragraph>1. Esteja em um ambiente bem iluminado.</T.Paragraph>
              <T.Paragraph>
                2. Não utilize óculos escuros, boné ou chapéu.
              </T.Paragraph>
              <T.Paragraph>
                3. Deixe o rosto centralizado e olhe diretamente para a câmera.
              </T.Paragraph>
              <T.Paragraph>
                4. Caso a câmera não esteja funcionando, verifique se a mesma
                está bloqueada. Em alguns casos, o navegador pode bloquear o
                acesso à câmera.
              </T.Paragraph>
              <T.Paragraph>
                5. Clique em{" "}
                <T.Span weight="bold">Iniciar Reconhecimento</T.Span> para
                iniciar o processo.
              </T.Paragraph>
              {allDevicesVideo.length > 1 && (
                <T.Paragraph>
                  6. Caso a câmera não esteja funcionando, selecione outra
                  câmera disponível.
                </T.Paragraph>
              )}
            </Grid>
            <Grid
              item
              xs={12}
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
            >
              {allDevicesVideo.length ? (
                allDevicesVideo.map((device) => (
                  <B.ButtonPrimary
                    key={device.deviceId}
                    onClick={() => {
                      setOptionsSize({
                        width: 640,
                        height: 480,
                      });
                      getMediaDevice(device.deviceId);
                      setStep(1);
                    }}
                    style={{ margin: "5px" }}
                  >
                    {formtTextCamera(device.label)
                      ? formtTextCamera(device.label)
                      : "Câmera"}
                  </B.ButtonPrimary>
                ))
              ) : (
                <Alert severity="error">
                  <AlertTitle>Erro</AlertTitle>
                  Não foi possível acessar os dispositivos de vídeo!
                </Alert>
              )}
            </Grid>
          </Grid>
        )}
        {step === 1 && (
          <Grid container spacing={2}>
            <S.BoxContentVideo ref={containerStream}>
              <video
                ref={video}
                width={optionsSize.width}
                height={optionsSize.height}
                autoPlay
                muted
              />
              <S.BoxMessageUser>
                <T.H5 ref={messageProcess} size="1.3rem">
                  <CircularProgress size={20} /> <br />
                  Aproxime o rosto da câmera e aguarde...
                </T.H5>
              </S.BoxMessageUser>
            </S.BoxContentVideo>
            <Grid item xs={12} display="flex" justifyContent="center">
              <B.ButtonSecondary onClick={() => onCloseRecogniton()}>
                Cancelar Reconhecimento
              </B.ButtonSecondary>
            </Grid>
          </Grid>
        )}
      </S.ModalBody>
    </Modal>
  );
}
