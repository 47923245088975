/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable guard-for-in */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
/* eslint-disable radix */
/* eslint-disable prefer-regex-literals */

import validation from "validation-br";

const validationBR: any = validation;

export const validarCPF = (cpf: string): boolean => validationBR.isCPF(cpf); // ok
export const validarCNPJ = (cnpj: string): boolean => validationBR.isCNPJ(cnpj); // ok
export const validarCNH = (cnpj: string): boolean => validationBR.isCNH(cnpj); // ok
export const validarPis = (cnpj: string): boolean => validationBR.isPIS(cnpj); // ok
export const validarTituloEleitor = (cnpj: string): boolean =>
  validationBR.isTituloEleitor(cnpj);

export const validarDados = (obj: any) => {
  let tamData = 0;
  let verifica = 0;
  /* Percorre o objeto data e verifica todos os seus campos, obtendo o tamanho do objeto
   * e incrementando o contador de verificações se o campo for preenchido
   */
  for (const props in obj) {
    if (obj.hasOwnProperty(props)) {
      tamData++;
    }
    if (obj[props] !== "") {
      verifica++;
    }
  }
  if (verifica !== tamData) {
    return false; // Faltam itens
  }
  return true; // Todos os itens preenchidos
};
// Validar um telefone
export const validarTelefone = (telefone: string) => {
  const tel = telefone.replace(/[^\d]+/g, "");
  if (tel.length < 10) return false; // Se o telefone tiver menos de 9 digitos
  if (/^(\d)\1+$/.test(tel)) return false;

  const ddd = tel.substring(0, 2); // Pega o DDD
  const regexDDD = new RegExp("^((1[1-9])|([2-9][0-9]))$"); // Regex para verificar se o DDD está entre 11 e 99
  if (!regexDDD.test(ddd)) {
    return false;
  }
  return true;
};
