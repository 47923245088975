import { AxiosError } from "axios";
import { useEffect, useState } from "react";

import { FasesProvaVidaProps } from "../../../../pages/MySolicitation";
import { api } from "../../../../services/api";
import { setFaceRecognitionAction } from "../../../../store/ducks/ProofOfLife/actions";
import { setToastMessage } from "../../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { getIdURLUserSol } from "../../../../utils/utils";
import { ButtonPrimary } from "../../../base/Buttons";
import * as T from "../../../base/Text";
import { FaceRecognitionModal } from "../../../ModalsComponents/FaceRecognition";
import * as S from "./styles";

type FaceRecognitionProps = {
  isEditable: boolean;
  provaVida: FasesProvaVidaProps;
  getUserSolicitation(): Promise<void>;
  isUserAnalyst: boolean;
};

export function FaceRecognition({
  isEditable,
  provaVida,
  isUserAnalyst,
  getUserSolicitation,
}: FaceRecognitionProps) {
  const [openModalRecognition, setOpenModalRecognition] = useState(false);

  const dispatch = useAppDispatch();
  const {
    ProofOfLife: { faceRecognition },
  } = useAppSelector((state) => state);

  const getDataFaceRecognition = async () => {
    try {
      const { data } = await api.get(
        `/proof-of-life/face-recognition/${getIdURLUserSol()}`
      );

      dispatch(setFaceRecognitionAction(data));
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  };

  useEffect(() => {
    if (!faceRecognition.info_faceapi_idade) getDataFaceRecognition();
  }, []);

  return (
    <S.Container>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginBottom: "1rem",
        }}
      >
        <T.H6 size="1.3rem">Reconhecimento Facial</T.H6>
        {!provaVida.isCompleted && !isUserAnalyst && isEditable && (
          <ButtonPrimary onClick={() => setOpenModalRecognition(true)}>
            Começar
          </ButtonPrimary>
        )}
      </div>
      {faceRecognition.info_faceapi_genero && (
        <S.DetailsFaceRecognition>
          <T.H6 size="1rem">Dados recolhidos do reconhecimento de facial</T.H6>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <T.Paragraph>
              Idade: <T.Span>{faceRecognition.info_faceapi_idade}</T.Span>
            </T.Paragraph>
            <T.Paragraph>
              Genero: <T.Span>{faceRecognition.info_faceapi_genero}</T.Span>
            </T.Paragraph>
          </div>
        </S.DetailsFaceRecognition>
      )}
      {isUserAnalyst && faceRecognition.info_faceapi_idade ? (
        <S.BoxImageIcon>
          <video
            src={faceRecognition.linkVideo}
            style={{
              height: "100%",
              maxWidth: "100%",
              maxHeight: "100%",
            }}
            muted
            controls
          />
        </S.BoxImageIcon>
      ) : (
        <S.BoxImageIcon>
          <img
            src="/images/faceRecognition.gif"
            style={{ height: "100%", maxWidth: "100%", maxHeight: "100%" }}
            alt="icon"
          />
        </S.BoxImageIcon>
      )}
      <FaceRecognitionModal
        isOpen={openModalRecognition}
        onClose={() => setOpenModalRecognition(false)}
        getFaceRecognition={getDataFaceRecognition}
        getUserSolicitation={getUserSolicitation}
      />
    </S.Container>
  );
}
