/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid, Popover } from "@mui/material";
import { AxiosError } from "axios";
import { useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import { convertData } from "../../../utils/utils";
import { ButtonPrimary } from "../../base/Buttons";
import { InputDate } from "../../Inputs/InputDate";
import * as S from "./styles";

type MenuPappoverprops = {
  open: boolean;
  handleClose: () => void;
  anchorEl: HTMLElement | null;
  idConfigAg: number;
  getConfigEnderecos: () => void;
};

type IRestricaoDataConfigAg = {
  dt_restrita: string;
};

export function RestricaoDataConfigAg({
  open,
  handleClose,
  anchorEl,
  idConfigAg,
  getConfigEnderecos,
}: MenuPappoverprops) {
  const { handleSubmit, control, setError } = useForm<IRestricaoDataConfigAg>();

  const dispatch = useAppDispatch();

  const addRestricaoData = async (body: IRestricaoDataConfigAg) => {
    try {
      await api.post(
        `/agendamento/config-enderecos/restricao-data/${idConfigAg}`,
        { dt_restrita: convertData(body.dt_restrita) }
      );
      getConfigEnderecos();
      handleClose();
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
        if (Array.isArray(error?.errors) && error?.errors.length) {
          error.errors.forEach((error: any) => {
            setError(error.property, {
              type: "manual",
              message: error.error,
            });
          });
        }
      }
    }
  };

  return (
    <div>
      <Popover
        id="restrição-data-config-ag"
        open={open}
        onClose={handleClose}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <S.Content onSubmit={handleSubmit(addRestricaoData)}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <InputDate
                control={control}
                id="dt_restrita"
                label="Data Restrita"
                name="dt_restrita"
                type="Date"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <ButtonPrimary type="submit" style={{ width: "100%" }}>
                Salvar
              </ButtonPrimary>
            </Grid>
          </Grid>
        </S.Content>
      </Popover>
    </div>
  );
}
