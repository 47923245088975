/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/jsx-props-no-spreading */
import ReportIcon from "@mui/icons-material/Report";
import { Grid, Tooltip } from "@mui/material";
import styled, { css } from "styled-components";

type ContainerInputProps = {
  isOpenCorrecion: boolean;
  isSelected?: boolean;
};

type IconInputCorrecionProps = {
  message: string;
};

export const ContainerInput = styled(
  ({ isOpenCorrecion, isSelected, isEditable, ...props }) => <Grid {...props} />
)<ContainerInputProps>`
  ${({ theme, isOpenCorrecion, isSelected, isEditable }) => css`
    pointer-events: ${isEditable || isOpenCorrecion ? "auto " : "none"};
    border: 2px dashed transparent;
    position: relative;
    z-index: 2;

    ${isOpenCorrecion &&
    css`
      &:before {
        content: "";
        position: absolute;
        top: -5px;
        left: 3px;
        width: 100%;
        height: 100%;
        z-index: 3;
        cursor: pointer;
      }
      &:hover {
        border-color: ${theme.palette.secondary.light};
        background-color: ${theme.background.default};
      }
    `}

    ${isSelected &&
    isOpenCorrecion &&
    css`
      border-color: ${theme.palette.secondary.light};
      border-style: solid;
      background-color: ${theme.background.default};
    `}
  `}
`;

export const IconInputCorrecion = styled(({ isIcon, message, ...props }) => {
  return isIcon ? (
    message ? (
      <Tooltip title={message} {...props}>
        <ReportIcon color="warning" />
      </Tooltip>
    ) : (
      <Tooltip
        title={false}
        disableFocusListener
        disableInteractive
        disableHoverListener
        {...props}
      >
        <ReportIcon color="warning" />
      </Tooltip>
    )
  ) : null;
})<IconInputCorrecionProps>`
  ${() => css`
    position: absolute;
    top: 15px;
    right: 5px;
    z-index: 3;
    cursor: help;
  `}
`;

export const ContainerListItensCorrecion = styled(
  ({ isOpenCorrecion, isSelected, isEditable, ...props }) => <Grid {...props} />
)<ContainerInputProps>`
  ${({ theme, isOpenCorrecion, isSelected, isEditable }) => css`
    width: 100%;
    position: relative;
    pointer-events: ${isEditable || isOpenCorrecion ? "auto " : "none"};
    z-index: 2;
    border: 2px dashed transparent;

    ${isOpenCorrecion &&
    css`
      &:before {
        content: "";
        position: absolute;
        top: -5px;
        left: 3px;
        width: 100%;
        height: 100%;
        z-index: 3;
        cursor: pointer;
      }
      &:hover {
        border-color: ${theme.palette.secondary.light};
        background-color: ${theme.background.default};
      }
    `}

    ${isSelected &&
    isOpenCorrecion &&
    css`
      border-color: ${theme.palette.secondary.light};
      border-style: solid;
      background-color: ${theme.background.default};
    `}
  `}
`;
