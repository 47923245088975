import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import ShareIcon from "@mui/icons-material/Share";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
  IconButton,
  Paper,
  InputBase,
} from "@mui/material";
import { useEffect, useState } from "react";

import { ButtonLink } from "../../components/base/Buttons";
import { CardPrimery } from "../../components/base/Cards";
import * as T from "../../components/base/Text";
import { BodyContainer } from "../../components/BodyContainer";
import { useAppSelector } from "../../store/hooks";
import { copyToClipboard, getClientUrl } from "../../utils/utils";
import faqs from "./faqs.json";

type IFaq = typeof faqs[0];

export function FaqPage() {
  const [listaFaqs, setListaFaqs] = useState<IFaq[]>(faqs);
  const [expanded, setExpanded] = useState<string | false>(false);
  const [indLinkVisible, setIndLinkVisible] = useState("");

  const { user } = useAppSelector(({ User }) => User);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const onSearch = (palavra: string) => {
    if (palavra.length <= 2) {
      setListaFaqs(faqs);
      return;
    }

    const filtered = faqs.filter((faq) => {
      const indTitle = faq.titulo
        .normalize()
        .toLowerCase()
        .includes(palavra.normalize().toLowerCase());
      const indTags = faq.tags.some((tag) =>
        tag
          .normalize()
          .toLowerCase()
          .includes(palavra.normalize().toLowerCase())
      );
      return indTitle || indTags;
    });
    setListaFaqs(filtered);
  };

  useEffect(() => {
    // pegar o id do item se tiver na url e abrir o item
    const id = window.location.hash.replace("#", "");
    if (id) {
      setExpanded(id);
      setTimeout(() => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 1000);
    }
  }, []);

  return (
    <BodyContainer
      menu={user.id_usuario ? "FAQ" : undefined}
      bodyStyle={{
        maxHeight: "calc(100vh - 70px)",
      }}
    >
      <Grid
        container
        spacing={1}
        style={{
          margin: "1rem 0 1.5rem 0",
          borderLeft: "4px solid #ff6e07",
          paddingLeft: "1rem",
        }}
      >
        <Grid item xs={12}>
          <T.H2>FAQ</T.H2>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "0.6rem",
          }}
        >
          <T.Paragraph lineHeight="1.3rem">
            Veja as duvidas mais frequentes e suas respostas.
          </T.Paragraph>
        </Grid>
      </Grid>

      <Grid container spacing={1} marginBottom="1rem">
        <Grid
          item
          xs={12}
          display="flex"
          justifyContent="center"
          alignItems="center"
          gap="1rem"
          flexWrap={{ xs: "wrap", sm: "nowrap" }}
        >
          <Paper
            sx={{
              p: "2px 5px",
              display: "flex",
              alignItems: "center",
              width: 400,
            }}
          >
            <InputBase
              sx={{ ml: 1, flex: 1 }}
              placeholder="Qual sua duvida?"
              inputProps={{ "aria-label": "Qual sua duvida?" }}
              size="medium"
              onChange={(e) => onSearch(e.target.value)}
            />
            <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
          {!user.id_usuario && (
            <ButtonLink to={`/${getClientUrl()}/login`}>
              Ir para o Login
            </ButtonLink>
          )}
        </Grid>
      </Grid>

      <CardPrimery
        style={{
          flex: 1,
          maxHeight: "inherit",
          overflowY: "auto",
          padding: "0",
          marginTop: "1rem",
        }}
      >
        {listaFaqs.map((faq) => (
          <Accordion
            key={faq.id}
            expanded={expanded === faq.id}
            onChange={handleChange(faq.id)}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
              style={{ padding: "0.5rem 1rem" }}
            >
              <Grid container spacing={1}>
                <Grid
                  item
                  xs={12}
                  display="flex"
                  alignItems="center"
                  onMouseEnter={() => {
                    setIndLinkVisible(faq.id);
                  }}
                  onMouseLeave={() => {
                    setIndLinkVisible("");
                  }}
                >
                  <T.H5 style={{ width: "fit-content" }}>{faq.titulo}</T.H5>
                  {indLinkVisible === faq.id && (
                    <IconButton
                      aria-label="search"
                      size="small"
                      onClick={(e) => {
                        e.stopPropagation();
                        copyToClipboard(
                          `${window.location.origin}${window.location.pathname}#${faq.id}`
                        );
                      }}
                    >
                      <ShareIcon fontSize="small" color="primary" />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              {faq.descricao.map((desc) => (
                <T.Paragraph>{desc}</T.Paragraph>
              ))}

              {faq.links.length > 0 && (
                <T.Paragraph margin="1rem 0 0 0">
                  <strong>Links: </strong>
                  <br />
                  {faq.links.map((link) => (
                    <>
                      <a href={link.url} target="_blank" rel="noreferrer">
                        {link.descricao}
                      </a>
                      <br />
                    </>
                  ))}
                </T.Paragraph>
              )}
              {faq.tags.length > 0 && (
                <T.Paragraph margin="1rem 0 0 0">
                  <strong>Tags: </strong>
                  {faq.tags.join(", ")}
                </T.Paragraph>
              )}
            </AccordionDetails>
          </Accordion>
        ))}
      </CardPrimery>
    </BodyContainer>
  );
}
