import { action } from "typesafe-actions";

import { ClientsTypes, ClientObjectTypes, ClientsArrayTypes } from "./types";

export const getClientsArrayAction = () =>
  action(ClientsTypes.GET_CLIENTS_ARRAY);

export const getClientLoggedAction = () =>
  action(ClientsTypes.GET_CLIENT_LOGGED);

export const getClientToUdateAction = (sigla?: string) =>
  action(ClientsTypes.GET_CLIENT_TO_UPDATE, sigla);

export const setClientLoggedAction = (data: ClientObjectTypes) =>
  action(ClientsTypes.SET_CLIENT_LOGGED, data);

export const setClientsArrayAction = (data: ClientsArrayTypes) =>
  action(ClientsTypes.SET_CLIENTS_ARRAY, data);
