/* eslint-disable @typescript-eslint/naming-convention */
import { FormControlLabel } from "@mui/material";
import Divider from "@mui/material/Divider";
import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { styled, Theme, CSSObject } from "@mui/material/styles";
import { ChangeEvent, Fragment } from "react";

import { setThemeAction } from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ButtonMenu } from "../ButtonMenu";
import { DrawerFooter } from "../DrawerCorrecionSolicitation/styles";
import { MenuList } from "./MenuList";
import { ItemMenuButton, MaterialUISwitch } from "./styles";

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  cursor: "pointer",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export function MenuDesktop({
  open,
  toogleMenu,
  active,
  navegateTo,
}: {
  open: boolean;
  active: string;
  toogleMenu: () => void;
  navegateTo: (path: string) => void;
}) {
  const dispatch = useAppDispatch();
  const {
    User: { user },
    Utils: {
      theme: { mode },
    },
    Clients: { clientLogged },
  } = useAppSelector(({ User, Utils, Clients }) => ({ User, Utils, Clients }));

  const alterTheme = ({ currentTarget }: ChangeEvent<HTMLInputElement>) =>
    dispatch(setThemeAction(currentTarget.checked ? "dark" : "light"));

  return (
    <Drawer
      variant="permanent"
      open={open}
      PaperProps={{
        style: {
          zIndex: 1,
        },
      }}
    >
      <DrawerHeader onClick={toogleMenu} style={{ alignItems: "center" }}>
        <ButtonMenu active={active} />
      </DrawerHeader>
      <Divider />
      <List>
        {MenuList(Number(user.perfil_usuario), clientLogged).map((item) => {
          return (
            <Fragment key={item.id}>
              <ItemMenuButton
                open={open}
                selecion={{ active: active === item.name }}
                device="desktop"
                onClick={() => {
                  if (item.path) navegateTo(item.path);
                  if (item.action) item.action();
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  {item.icon}
                </ListItemIcon>
                <ListItemText
                  primary={item.name}
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ItemMenuButton>
              <Divider />
            </Fragment>
          );
        })}
      </List>
      <DrawerFooter
        style={{ alignItems: "center", margin: "auto auto 1rem auto" }}
      >
        <FormControlLabel
          control={
            <MaterialUISwitch checked={mode === "dark"} onChange={alterTheme} />
          }
          label=""
          style={{ margin: 0 }}
        />
      </DrawerFooter>
    </Drawer>
  );
}
