/**
 * Action types
 */
export enum GraphicsTypes {
  SET_GRAPHIC_PIZZA = "@graphic/SET_GRAPHIC_PIZZA",
  GET_GRAPHIC_PIZZA = "@graphic/GET_GRAPHIC_PIZZA",
  SET_GRAPHIC_BAR = "@graphic/SET_GRAPHIC_BAR",
  GET_GRAPHIC_BAR = "@graphic/GET_GRAPHIC_BAR",
}

/**
 * valores isolados do objeto de parametros
 */
export type DataGraphicPizza = {
  id: number;
  label: string;
  value: number;
  color: string;
};

export type DataGraphicBar = {
  country: string;
  Pendente: number;
  PendenteColor: string;
  Preenchimento: number;
  PreenchimentoColor: string;
  Correcao: number;
  CorrecaoColor: string;
  Analise: number;
  AnaliseColor: string;
  Concluido: number;
  ConcluidoColor: string;
  Cancelado: number;
  CanceladoColor: string;
};

/**
 * valores do state completo desse redux
 */
export type GraphicsState = {
  readonly graphicPizza: DataGraphicPizza[];
  readonly graphicBar: DataGraphicBar[];
};
