import styled, { css } from "styled-components";

export const FormContainer = styled.form`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2rem;

  @media screen {
    @media (max-width: 768px) {
      margin: 1rem 0;
    }
  }
`;

export const FormContent = styled.div`
  width: 100%;
  max-width: 400px;
  padding: 1.5rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 0.8rem;
`;

export const FormItem = styled.div`
  width: 100%;
`;

export const BoxPix = styled.div`
  width: 100%;
  max-width: 100%;
  height: 440px;
  padding: 0 1.5rem;
  display: flex;
  gap: 2rem;

  @media screen {
    @media (max-width: 768px) {
      flex-direction: column;
      height: auto;
      padding: 0;
    }
  }
`;

export const BoxPixLeft = styled.div`
  flex: 1;
  max-width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen {
    @media (max-width: 768px) {
      width: 100%;
      max-width: initial;
      height: auto;
    }
  }
`;

export const BoxPixRight = styled.div`
  flex: 1;
  max-width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  @media screen {
    @media (max-width: 768px) {
      width: 100%;
      max-width: initial;
      height: auto;
    }
  }
`;

export const PixImageQrCode = styled.img`
  height: 100%;
`;

export const BoxQrCodeText = styled.div`
  ${({ theme }) => css`
    width: 100%;
    max-width: 100%;
    padding: 0.8rem;
    border-radius: 0.4rem;
    border: 1px solid ${theme.border.light};
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 0.8rem;

    cursor: copy;

    p {
      font-size: 0.775rem;
      text-align: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  `}
`;

export const PixWarning = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: max-content;
    border-radius: 0.25rem;
    border: 1px solid ${theme.palette.primary.light};
    background-color: ${theme.background.blueLight};
    fill-opacity: 0.8;
    padding: 0.8rem;
    margin: 1.2rem 0;
  `}
`;

export const BoxPixDetails = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;

  div {
    display: flex;
    width: 100%;
  }
`;

export const BoxPixDetailsItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.25rem 0;

  h6,
  p {
    white-space: nowrap;
    overflow: hidden;
    width: min-content;
  }

  & + & {
    border-top: 1px solid ${({ theme }) => theme.border.light};
  }
`;

export const FormBoxButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
