import { useLayoutEffect, useState } from "react";

import { BodyContainer } from "../../components/BodyContainer";
import { BoxListClients } from "../../components/BoxListClients";
import { TabNavigation } from "../../components/TabNavigation";
import { Agendamentos } from "../../components/TabNavigation/Management/Agendamentos";
import { ApiInfo } from "../../components/TabNavigation/Management/ApiInfo";
import { System } from "../../components/TabNavigation/Management/System";
import { Users } from "../../components/TabNavigation/Management/Users";
import { getClientToUdateAction } from "../../store/ducks/Clients/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";

export function Management() {
  const [tabValue, setTabValue] = useState(0);
  const [listSteep, setListSteep] = useState([
    "Administração Geral",
    "Usuarios",
    "Agendamentos",
  ]);
  const [isFormNewClient, setIsFormNewClient] = useState(false);

  const {
    User: { user },
    Clients: { clientToUpdate },
  } = useAppSelector((state) => state);
  const dispatch = useAppDispatch();

  useLayoutEffect(() => {
    if (user.ind_acesso_api === "S" && !listSteep.includes("API")) {
      setListSteep([...listSteep, "API"]);
    }
  }, [user]);

  useLayoutEffect(() => {
    if (!clientToUpdate.schema_base) dispatch(getClientToUdateAction());
  }, []);

  return (
    <BodyContainer menu="Administração">
      {user.perfil_usuario === 4 && (
        <BoxListClients setIsFormNewClient={setIsFormNewClient} />
      )}
      <TabNavigation
        labesTabs={listSteep}
        tabValue={tabValue}
        setTabValue={setTabValue}
      />
      {tabValue === 0 && <System isFormNewClient={isFormNewClient} />}
      {tabValue === 1 && (
        <Users schemaCliente={String(clientToUpdate.schema_base)} />
      )}
      {tabValue === 2 && <Agendamentos />}
      {tabValue === 3 && <ApiInfo />}
    </BodyContainer>
  );
}
