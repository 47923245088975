/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";

import { apiGet } from "../../../services/metods";
import { getClientUrl } from "../../../utils/utils";
import { UtilsTypes } from "../Utils/types";
import { ClientsTypes, ClientObjectTypes, ClientsArrayTypes } from "./types";

// Primeira chamada do sistema para busca o cliente baseado na sigla que esta na url
export function* getClientLoggedSaga() {
  try {
    const { data }: AxiosResponse<ClientObjectTypes, any> = yield call<any>(
      apiGet,
      "/client/data"
    );
    yield put({
      type: ClientsTypes.SET_CLIENT_LOGGED,
      payload: data,
    });
  } catch (error: any) {
    yield put({
      type: UtilsTypes.SET_TOAST,
      payload: {
        type: "error",
        message: error?.message || "Plataforma indisponível no momento",
      },
    });
  }
}

export function* getClientToUpdateSaga({ payload }: any) {
  try {
    const siglaCliente = payload || getClientUrl();
    const { data }: AxiosResponse<ClientObjectTypes, any> = yield call<any>(
      apiGet,
      `/client/clients/${siglaCliente}`
    );

    yield put({
      type: ClientsTypes.SET_CLIENT_TO_UPDATE,
      payload: data,
    });
  } catch (error: any) {
    yield put({
      type: UtilsTypes.SET_TOAST,
      payload: {
        type: "error",
        message: error.message,
      },
    });
  }
}

export function* getClientsArraySaga() {
  try {
    const { data }: AxiosResponse<ClientsArrayTypes, any> = yield call<any>(
      apiGet,
      "/client/clients"
    );

    yield put({
      type: ClientsTypes.SET_CLIENTS_ARRAY,
      payload: data,
    });
  } catch (error: any) {
    yield put({
      type: UtilsTypes.SET_TOAST,
      payload: {
        type: "error",
        message: error.message,
      },
    });
  }
}
