/* eslint-disable quote-props */

import styled, { css } from "styled-components";

type ButtonMenuProps = {
  active: boolean;
};
export const ButtonMenu = styled.button<ButtonMenuProps>`
  ${({ theme, active }) => css`
    display: block;
    border: none;
    background: transparent;
    padding: 1rem 1rem 2rem 1rem;

    span {
      display: block;
      width: 25px;
      height: 3px;
      border-top: 3px solid ${theme.backgroundIcons};

      ${!!active &&
      css`
        border-top-color: transparent;
      `}

      :before,
      :after {
        content: "";
        display: block;
        width: 25px;
        height: 3px;
        background: ${theme.backgroundIcons};
        margin-top: 3.5px;
        transition: all 0.5s ease 0s;
        position: relative;
      }

      ${!!active &&
      css`
        ::before {
          transform: rotate(-135deg);
        }

        ::after {
          transform: rotate(135deg);
          top: -6.5px;
        }
      `}

      @media (max-width: 768px) {
        border-top: 3px solid ${theme.background.default};

        ${!!active &&
        css`
          border-top-color: transparent;
        `}

        :before,
        :after {
          background: ${theme.background.default};
        }
      }
    }
  `}
`;
