/* eslint-disable react/jsx-props-no-spreading */
import ReactJson, { ReactJsonViewProps } from "react-json-view";

export function JsonView({ ...rest }: ReactJsonViewProps) {
  return (
    <div>
      <ReactJson
        theme="codeschool" // Você pode escolher um tema de cores
        name={null} // Define o nome do objeto raiz (null para não mostrar)
        collapsed={false} // Define se o JSON deve ser inicialmente colapsado
        displayDataTypes={false} // Define se os tipos de dados devem ser exibidos
        indentWidth={2} // Define o tamanho da indentação
        enableClipboard={false} // Define se o botão de copiar para a área de transferência deve ser exibido
        {...rest}
      />
    </div>
  );
}
