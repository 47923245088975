/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import * as B from "../../components/base/Buttons";
import { BoxLogin } from "../../components/boxLogin";
import { InputPassword } from "../../components/Inputs/InputPassword";
import { RulesPassword } from "../../components/RulesPassword";
import { useLoginRedirect } from "../../hooks/useLoginRedirect";
import { api } from "../../services/api";
import { setLoading, setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../store/hooks";
import { getClientUrl } from "../../utils/utils";
import * as S from "./styles";

type FormDataRedefinePassword = {
  password: string;
  confirmPassword: string;
};

export function RedefinePassword() {
  const [password, setPassword] = useState("");
  const [hasValidatePassword, setHasValidatePassword] = useState(false);
  const { handleSubmit, control } = useForm<FormDataRedefinePassword>();

  const navegate = useNavigate();
  const dispatch = useAppDispatch();
  const isLogged = useLoginRedirect();

  useEffect(() => {
    isLogged();
  }, []);

  const onSubmit: SubmitHandler<FormDataRedefinePassword> = async ({
    password,
    confirmPassword,
  }) => {
    try {
      if (password !== confirmPassword) {
        dispatch(
          setToastMessage({ type: "error", message: "Senhas diferentes" })
        );
        return;
      }
      if (!hasValidatePassword) {
        dispatch(
          setToastMessage({ type: "error", message: "Senha invalida!" })
        );
        return;
      }
      dispatch(setLoading(true));

      const token = window.location.pathname.split("/").pop();
      const { data } = await api.post(`/user/update-password/${token}`, {
        password,
        confirmPassword,
      });
      dispatch(
        setToastMessage({
          type: "success",
          message: data.message,
          autoClose: 5000,
        })
      );
      navegate(`/${getClientUrl()}/login`);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  return (
    <S.Container>
      <S.Content>
        <BoxLogin>
          <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            style={{ width: "100%" }}
          >
            <Grid item xs={12}>
              <InputPassword
                control={control}
                id="NovaSenha"
                label="Nova senha"
                name="password"
                required
                customChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <InputPassword
                control={control}
                id="RepetirSenha"
                label="Repetir senha"
                name="confirmPassword"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <RulesPassword
                password={password}
                setHasValidatePassword={setHasValidatePassword}
              />
            </Grid>
            <Grid item xs={12}>
              <B.ButtonPrimary
                type="submit"
                style={{ width: "100%", marginTop: "1rem" }}
              >
                Alterar senha
              </B.ButtonPrimary>
            </Grid>
          </form>
          <Grid item xs={12} style={{ marginTop: "1rem" }}>
            <B.ButtonLink
              color="secondary"
              to={`/${getClientUrl()}/login`}
              style={{ width: "100%" }}
            >
              Login
            </B.ButtonLink>
          </Grid>
        </BoxLogin>
      </S.Content>
    </S.Container>
  );
}
