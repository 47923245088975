/* eslint-disable @typescript-eslint/no-explicit-any */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { api } from "../../../services/api";
import { getGraphicPizzaAction } from "../../../store/ducks/Graphics/actions";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import { ISolicitationProps } from "../../../types/Solicitation";
import {
  convertData,
  getClientUrl,
  statusAgendamentoString,
} from "../../../utils/utils";
import { ButtonSecondary } from "../../base/Buttons";
import * as T from "../../base/Text";
import { GraphicPizza } from "../../GraphicPizza";
import * as S from "./styles";

type IAgendamentoProps = {
  id_solicit_agendamento: number;
  id_sol_usuario: number;
  status: string;
  dt_agendamento: string;
  hr_agendamento: string;
  protocolo_agendamento: string;
  descr_solicitacao: number;
  numero_cpf: string;
  nome: string;
};

export function HomeOperador() {
  const [idItemGraphic, setIdItemGraphic] = useState(0);
  const [solicitations, setSolicitations] = useState<ISolicitationProps[]>([]);
  const [agendamentos, setAgendamentos] = useState<IAgendamentoProps[]>([]);
  const navegate = useNavigate();
  const dispatch = useAppDispatch();

  async function getSolicitations() {
    try {
      const { data } = await api.get("/solicitation/list");
      setSolicitations(data);
      setIdItemGraphic(data[0].id_solicitacao);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  }

  async function getAgendamentos() {
    try {
      const queryParam = JSON.stringify({
        statusAg: "1",
        dataInicial: convertData(new Date().toDateString()),
        ordem: "ASC",
        limit: 3,
      });

      const { data } = await api.get(`/agendamento/list/${btoa(queryParam)}`);

      setAgendamentos(data);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  }

  useEffect(() => {
    if (!solicitations.length) {
      getSolicitations();
      getAgendamentos();
    }
  }, []);

  useEffect(() => {
    if (idItemGraphic) dispatch(getGraphicPizzaAction(idItemGraphic));
  }, [idItemGraphic]);

  return (
    <S.Container>
      <S.HeaderContent>
        <S.BoxGraphics>
          {solicitations.length ? (
            <>
              <GraphicPizza />
              <S.BoxListSolicitations>
                {solicitations.map((solicitation) => (
                  <S.ListSolicitationsItem
                    key={solicitation.id_solicitacao}
                    active={idItemGraphic === solicitation.id_solicitacao}
                    onClick={() =>
                      setIdItemGraphic(Number(solicitation.id_solicitacao))
                    }
                  >
                    <T.Paragraph>{solicitation.descr_solicitacao}</T.Paragraph>
                  </S.ListSolicitationsItem>
                ))}
              </S.BoxListSolicitations>
            </>
          ) : (
            <img
              src="/images/graphics.svg"
              alt="graphics"
              style={{
                width: "100%",
                maxHeight: "400px",
              }}
            />
          )}
        </S.BoxGraphics>
      </S.HeaderContent>
      <S.BoxInteractions>
        <S.BoxInteractionsHeader>
          <T.H1 size="1.5rem">Próximos agendamentos</T.H1>
          <ButtonSecondary
            onClick={() => {
              navegate(`../${getClientUrl()}/agendamentos`);
            }}
          >
            Ver Tudo
          </ButtonSecondary>
        </S.BoxInteractionsHeader>
        <S.BoxInteractionsBody>
          {agendamentos.length ? (
            agendamentos.map((agend) => (
              <S.BoxInteractionsItem
                key={agend.id_solicit_agendamento}
                onClick={() => {
                  navegate(
                    `../${getClientUrl()}/solicitacao/${agend.id_sol_usuario}`
                  );
                }}
              >
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                    <T.H6 width="min-content">Solicitação: </T.H6>
                    <T.Paragraph>{agend.descr_solicitacao}</T.Paragraph>
                  </div>
                  <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                    <T.H6 width="min-content">Protocolo: </T.H6>
                    <T.Paragraph>{agend.protocolo_agendamento}</T.Paragraph>
                  </div>
                </div>
                <div
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                    <T.H6 width="min-content">Usuário: </T.H6>
                    <T.Paragraph>{agend.nome}</T.Paragraph>
                  </div>

                  <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                    <T.H6 width="min-content">CPF: </T.H6>
                    <T.Paragraph>{agend.numero_cpf}</T.Paragraph>
                  </div>
                  <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                    <T.H6 width="min-content">Status: </T.H6>
                    <T.Paragraph>
                      {statusAgendamentoString(Number(agend.status)).label}
                    </T.Paragraph>
                  </div>
                  <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                    <T.H6 width="min-content" style={{ whiteSpace: "nowrap" }}>
                      Data:
                    </T.H6>
                    <T.Paragraph>
                      {convertData(agend.dt_agendamento)}
                    </T.Paragraph>
                  </div>
                  <div style={{ display: "inline-flex", gap: "0.5rem" }}>
                    <T.H6 width="min-content" style={{ whiteSpace: "nowrap" }}>
                      Hora:
                    </T.H6>
                    <T.Paragraph>{agend.hr_agendamento}</T.Paragraph>
                  </div>
                </div>
              </S.BoxInteractionsItem>
            ))
          ) : (
            <div
              style={{
                margin: "auto",
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <T.H6 width="min-content" style={{ whiteSpace: "nowrap" }}>
                Não há solicitaões agendadas
              </T.H6>
              <img
                src="/images/homeImage1.svg"
                width={250}
                alt="Analise de documentos"
              />
            </div>
          )}
        </S.BoxInteractionsBody>
      </S.BoxInteractions>
    </S.Container>
  );
}
