/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from "axios";
import { call, put } from "redux-saga/effects";

import { apiGet } from "../../../services/metods";
import { UtilsTypes } from "../Utils/types";
import { SystemParametersTypes, ParammetersTypes } from "./types";

export function* getSystemParametersTypesSaga() {
  try {
    yield put({
      type: UtilsTypes.SET_LOADING,
      payload: true,
    });
    const { data }: AxiosResponse<ParammetersTypes, any> = yield call<any>(
      apiGet,
      "/system/get-parameters"
    );

    yield put({
      type: SystemParametersTypes.SET_PARAMETERS,
      payload: data,
    });
  } catch (error: any) {
    yield put({
      type: UtilsTypes.SET_TOAST,
      payload: {
        type: "error",
        message: error.message,
      },
    });
  } finally {
    yield put({
      type: UtilsTypes.SET_LOADING,
      payload: false,
    });
  }
}
