/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */
import { Control, Controller } from "react-hook-form";

import {
  ageRangeMask,
  cepMask,
  cnhMask,
  cnpjMask,
  cpfMask,
  dateMask,
  moneyMask,
  pisPasepMask,
  telMask,
  tituloEleitorMask,
} from "../../utils/mask";
import * as S from "./styles";

interface IInputBase {
  control: Control<any>;
  id: string;
  label: string;
  name: string;
  defaultValue?: string | number;
  maxLength?: number;
  errorMessage?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  type?: string;
  multiline?: boolean;
  maxRows?: number;
  rules?: any;
  helperText?: string;
  isErrorMessage?: boolean;
  onBlur?: (e: any) => void;
  customChange?: (e: any) => void;
}

const setMask = (type: string | undefined, value: string) => {
  switch (type) {
    case "data":
      return dateMask(value);
    case "currency":
      return moneyMask(value);
    case "phone":
      return telMask(value);
    case "cpf":
      return cpfMask(value);
    case "cnpj":
      return cnpjMask(value);
    case "cep":
      return cepMask(value);
    case "titulo":
      return tituloEleitorMask(value);
    case "cnh":
      return cnhMask(value);
    case "pisPasep":
      return pisPasepMask(value);
    case "faixaEtaria":
      return ageRangeMask(value);
    default:
      return value;
  }
};

export function InputBase({
  control,
  id,
  label,
  name,
  type,
  disabled,
  maxLength,
  readOnly,
  defaultValue,
  required,
  errorMessage,
  rules,
  maxRows,
  multiline,
  isErrorMessage,
  onBlur,
  helperText,
  customChange,
}: IInputBase) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({ field: { onChange, value }, fieldState: { error } }) => {
        return (
          <S.InputBase
            id={id}
            label={label}
            variant="outlined"
            value={value ? setMask(type, value) : ""}
            name={name}
            disabled={disabled}
            onChange={(e) => {
              if (customChange?.name) customChange(e);
              onChange(e);
            }}
            inputProps={{
              readOnly: !!readOnly,
              ...(maxLength && { maxLength: Number(maxLength) }),
            }}
            type={type || "text"}
            multiline={!!multiline}
            rows={maxRows}
            fullWidth
            size="small"
            error={!!error?.message || !!errorMessage}
            helperText={
              isErrorMessage === false
                ? ""
                : error?.message || errorMessage || helperText || " "
            }
            {...(onBlur?.name && { onBlur })}
          />
        );
      }}
      rules={{
        required: {
          value: !!required,
          message: `${label} é obrigatório`,
        },
        ...rules,
      }}
    />
  );
}
