/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import AddIcon from "@mui/icons-material/Add";
import SearchIcon from "@mui/icons-material/Search";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../../services/api";
import { setToastMessage } from "../../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../../store/hooks";
import { cpfMask } from "../../../../utils/mask";
import { debounce, perfilStringFormat } from "../../../../utils/utils";
import { ButtonSecondary } from "../../../base/Buttons";
import { CardPrimery } from "../../../base/Cards";
import * as T from "../../../base/Text";
import { InputBase } from "../../../Inputs/InputText";
import { AddNewUserAndUpdate } from "../../../ModalsComponents/AddNewUserAndUpdate";

type ItensRowProps = {
  codigo_interno: string;
  nome: string;
  email: string;
  numero_cpf: string;
  dt_nascimento: string;
  ind_ativo: string;
  perfil_usuario: number;
  ind_conta_verificada: string;
  ind_acesso_api: string;
  token_api: string;
  url_callback: string;
  cargo: string;
  empresa: string;
};

type SearchTableProps = {
  cpf?: string;
  nome?: string;
  email?: string;
};

export function Users({ schemaCliente }: { schemaCliente: string }) {
  const TableRef = useRef<HTMLDivElement>(null);
  const { handleSubmit, control, getValues } = useForm<SearchTableProps>();

  const dispatch = useAppDispatch();

  const [itemAllTable, setItemAllTable] = useState<ItensRowProps[]>([]);

  const [isOpenMOdalNewTable, setIsOpenMOdalNewTable] = useState<
    boolean | ItensRowProps
  >(false);

  const onClseModalNewTable = () => setIsOpenMOdalNewTable(false);

  async function getUsers(
    cpf?: string,
    nome?: string,
    email?: string,
    offset?: number
  ) {
    try {
      const { data } = await api.get(
        `/user/get-users?sigla=${schemaCliente}&cpf=${cpf || ""}&nome=${
          nome || ""
        }&email=${email || ""}&offset=${offset || ""}`
      );
      setItemAllTable(data);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  }

  const onSubmit: SubmitHandler<SearchTableProps> = async (body) => {
    try {
      const { cpf, nome, email } = body;
      await getUsers(cpf, nome, email);
    } catch (err: any) {
      setItemAllTable([]);
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };
  const getUpdateTables = () => onSubmit(getValues());

  useEffect(() => {
    getUpdateTables();
  }, []);

  const scrollToTable = debounce(async function () {
    const scrollTotal = Number(TableRef.current?.scrollTop);
    const heightWin =
      Number(TableRef.current?.scrollHeight) -
      Number(TableRef.current?.clientHeight);
    const scroll = (scrollTotal / heightWin) * 100;
    if (scroll >= Number(100) - Number(5)) {
      const index = itemAllTable.length;
      const { cpf, nome, email } = getValues();
      await getUsers(cpf, nome, email, index);
    }
  }, 120);

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} noValidate>
        <CardPrimery container spacing={1}>
          <Grid item xs={12} style={{ display: "flex", alignItems: "center" }}>
            <T.H6>Filtrar busca de usuários</T.H6>
            <div
              onClick={() => setIsOpenMOdalNewTable(true)}
              style={{ padding: "0 1rem", cursor: "pointer" }}
            >
              <AddIcon />
            </div>
          </Grid>
          <Grid item xs={12} md={3}>
            <InputBase
              control={control}
              id="cpf"
              label="CPF"
              name="cpf"
              type="cpf"
              isErrorMessage={false}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <InputBase
              control={control}
              id="nome"
              label="Nome"
              name="nome"
              isErrorMessage={false}
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <InputBase
              control={control}
              id="email"
              label="Email"
              name="email"
              type="email"
              isErrorMessage={false}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <ButtonSecondary
              variant="outlined"
              type="submit"
              style={{ width: "100%" }}
            >
              Buscar
              <SearchIcon style={{ marginLeft: "0.5rem" }} />
            </ButtonSecondary>
          </Grid>

          <TableContainer
            ref={TableRef}
            sx={{ maxHeight: "55vh", minHeight: "55vh" }}
            onScroll={scrollToTable}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell style={{ minWidth: "10rem", width: "10rem" }}>
                    CPF
                  </TableCell>
                  <TableCell style={{ minWidth: "15rem", width: "15rem" }}>
                    NOME
                  </TableCell>
                  <TableCell style={{ width: "100%" }}>EMAIL</TableCell>
                  <TableCell style={{ textAlign: "center", minWidth: "8rem" }}>
                    PERFIL
                  </TableCell>
                  <TableCell style={{ textAlign: "center", minWidth: "8rem" }}>
                    ATIVO
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {itemAllTable.map((item) => (
                  <TableRow
                    hover
                    style={{ cursor: "pointer" }}
                    role="checkbox"
                    key={item.numero_cpf}
                    onClick={() => setIsOpenMOdalNewTable(item)}
                  >
                    <TableCell>{cpfMask(item.numero_cpf)}</TableCell>
                    <TableCell>{item.nome}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {perfilStringFormat(item.perfil_usuario)}
                    </TableCell>
                    <TableCell style={{ textAlign: "center" }}>
                      {item.ind_ativo}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardPrimery>
      </form>
      <AddNewUserAndUpdate
        isOpen={!!isOpenMOdalNewTable}
        onClose={onClseModalNewTable}
        item={isOpenMOdalNewTable as any}
        getUpdateTables={getUpdateTables}
        schemaCliente={schemaCliente}
      />
    </>
  );
}
