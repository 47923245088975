/* eslint-disable no-param-reassign */
/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import ReportIcon from "@mui/icons-material/Report";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import { ANEXOS, getIdURLUserSol } from "../../../utils/utils";
import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import * as S from "./styles";

type AnexosSolicitadosProps = {
  isEditable: boolean;
  getUserSolicitation(): Promise<void>;
  isCompleted: boolean;
  isOpenCorrecion: boolean;
  isOnChangeSetListCorrecion: (etapa: string, campo: string) => boolean;
  onChangeSetListCorrecion: (
    etapa: string,
    campo: string,
    label: string
  ) => void;
  correctionValuesJsonData: any;
};

type ListFilesProps = {
  descricao: string;
  id_anexo_solicitado: number;
  id_sol_usuario: number | null;
  id_solicitacao: number;
  ind_obrigatorio: boolean;
  tam_maximo_mb: string | null;
  listaArquivosAnexados: Array<{
    id_sol_dados_anexo: number | null;
    nome_arquivo: string | null;
    success: boolean;
    error: boolean;
    dt_inclusao: string | null;
    hr_inclusao: string | null;
  }>;
};

type ProgresProps = {
  id_anexo_solicitado: number;
  progress: number;
};

export function AnexosSolicitados({
  isEditable,
  getUserSolicitation,
  isCompleted,
  isOpenCorrecion,
  isOnChangeSetListCorrecion,
  onChangeSetListCorrecion,
  correctionValuesJsonData,
}: AnexosSolicitadosProps) {
  const [anexosList, setAnexosList] = useState<ListFilesProps[]>([]);
  const [progress, setProgress] = useState<ProgresProps>({} as ProgresProps);

  const dispatch = useAppDispatch();

  const formatlistFiles = (listFiles: any[]) => {
    return listFiles.map((file: any) => {
      return {
        ...file,
        ind_obrigatorio: file.ind_obrigatorio === "S",
        listaArquivosAnexados: !file.listaArquivosAnexados?.length
          ? []
          : file.listaArquivosAnexados.map((a: any) => {
              return {
                ...a,
                success: Boolean(a.id_sol_dados_anexo),
                error: false,
              };
            }),
      };
    });
  };

  const getAnexosAll = async () => {
    const { data } = await api.get(
      `/solicitation/anexos-solicitados/${getIdURLUserSol()}`
    );

    setAnexosList(formatlistFiles(data));
  };

  useEffect(() => {
    getAnexosAll();
  }, []);

  const uploadFile = async (
    e: React.ChangeEvent<HTMLInputElement>,
    anexo: ListFilesProps
  ) => {
    try {
      if (
        e.target.files &&
        progress.id_anexo_solicitado !== anexo.id_anexo_solicitado
      ) {
        const file = e.target.files.item(0) as File;
        const formData = new FormData();
        formData.append("file", file);
        formData.append(
          "id_anexo_solicitado",
          String(anexo.id_anexo_solicitado)
        );

        const { data } = await api.post(
          `/solicitation/anexos-solicitados/upload/${getIdURLUserSol()}`,
          formData,
          {
            onUploadProgress: (progressEvent) => {
              const { loaded, total } = progressEvent;
              const percent = Math.floor((loaded * 100) / total);
              setProgress({
                id_anexo_solicitado: anexo.id_anexo_solicitado,
                progress: percent,
              });
            },
          }
        );

        dispatch(setToastMessage({ type: "success", message: data.message }));
        getAnexosAll();
      }
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    } finally {
      setProgress({} as ProgresProps);
    }
  };

  const deleteFile = async (id_sol_dados_anexo: number) => {
    try {
      if (id_sol_dados_anexo) {
        const { data } = await api.delete(
          `/solicitation/anexos-solicitados/${getIdURLUserSol()}/${id_sol_dados_anexo}`
        );

        dispatch(setToastMessage({ type: "success", message: data.message }));
        getAnexosAll();
      } else {
        const anexosFiltered = anexosList.map((anexo) => {
          anexo.listaArquivosAnexados = anexo.listaArquivosAnexados.filter(
            (a) => a.id_sol_dados_anexo !== id_sol_dados_anexo
          );
          return anexo;
        });

        setAnexosList(anexosFiltered);
      }
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const downloadFile = async (id_sol_dados_anexo: number) => {
    try {
      if (!id_sol_dados_anexo) return;

      const { data } = await api.get(
        `/solicitation/anexos-solicitados/download/${getIdURLUserSol()}/${id_sol_dados_anexo}`
      );
      if (data.filaname) {
        const a = document.createElement("a");
        a.href = `data:${data.mimeType};base64,${data.file}`;
        a.download = data.filaname;
        a.click();
      }
      dispatch(setToastMessage({ type: "success", message: data.message }));
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const downloadZipZAnexos = async () => {
    try {
      const { data } = await api.get(
        `/solicitation/anexos-solicitados/download-zip/${getIdURLUserSol()}`
      );

      if (data.filaname) {
        const a = document.createElement("a");
        a.href = `data:${data.mimeType};base64,${data.file}`;
        a.download = data.filaname;
        a.click();
      }
      dispatch(setToastMessage({ type: "success", message: data.message }));
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const nextEtapa = async () => {
    try {
      const isObrigatorios = anexosList.filter(
        (anexo) => anexo.ind_obrigatorio
      );

      const isObrigatoriosCompleted = isObrigatorios.filter(
        (anexo) => anexo.listaArquivosAnexados.length
      );
      if (isObrigatoriosCompleted.length !== isObrigatorios.length) {
        dispatch(
          setToastMessage({
            type: "error",
            autoClose: 10000, // 10s
            message:
              "Faça o upload de todos os arquivos obrigatórios para avançar",
          })
        );
        return;
      }
      await api.post(
        `/solicitation/anexos-solicitados/commited/${getIdURLUserSol()}`
      );
      await getUserSolicitation();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  return (
    <Grid container spacing={1} padding="1rem">
      {anexosList.length &&
        anexosList.map((anexo) => (
          <S.ContainerInputFile
            key={anexo.id_anexo_solicitado}
            isOpenCorrecion={isOpenCorrecion}
            isSelected={isOnChangeSetListCorrecion(
              ANEXOS,
              String(anexo.id_anexo_solicitado)
            )}
            onClick={() =>
              onChangeSetListCorrecion(
                ANEXOS,
                String(anexo.id_anexo_solicitado),
                anexo.descricao
              )
            }
          >
            <S.InputTitle>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <T.H6 style={{ zIndex: "2" }}>
                  {anexo.descricao}
                  {anexo.ind_obrigatorio && (
                    <T.Span color="#f00" margin="0 0 0 0.25rem">
                      *(Obrigatório)
                    </T.Span>
                  )}
                </T.H6>
              </div>
              {correctionValuesJsonData[
                `${ANEXOS}-${String(anexo.id_anexo_solicitado)}`
              ] ? (
                correctionValuesJsonData[
                  `${ANEXOS}-${String(anexo.id_anexo_solicitado)}`
                ]?.message ? (
                  <Tooltip
                    title={
                      correctionValuesJsonData[
                        `${ANEXOS}-${String(anexo.id_anexo_solicitado)}`
                      ]?.message
                    }
                  >
                    <ReportIcon color="warning" />
                  </Tooltip>
                ) : (
                  <Tooltip
                    title={false}
                    disableFocusListener
                    disableInteractive
                    disableHoverListener
                  >
                    <ReportIcon color="warning" />
                  </Tooltip>
                )
              ) : null}
            </S.InputTitle>
            {isEditable && (
              <S.InputLabel
                style={{
                  pointerEvents:
                    progress.id_anexo_solicitado === anexo.id_anexo_solicitado
                      ? "none"
                      : "initial",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "1rem",
                    borderBottom: "1px solid #ccc",
                  }}
                >
                  {progress.id_anexo_solicitado ===
                  anexo.id_anexo_solicitado ? (
                    <CircularProgress
                      size={30}
                      variant="determinate"
                      value={progress.progress}
                    />
                  ) : (
                    <CloudUploadIcon color="disabled" />
                  )}
                  <T.Span>Clique aqui para anexar seu arquivo</T.Span>
                </div>
                <input
                  type="file"
                  name="file"
                  id={`anexo-${anexo.id_anexo_solicitado}`}
                  onChange={(event) => uploadFile(event, anexo)}
                  style={{ display: "none" }}
                  accept="image/*, application/pdf"
                />
              </S.InputLabel>
            )}
            {!!anexo?.listaArquivosAnexados?.length && (
              <S.ListaArquivosAnexados>
                {anexo?.listaArquivosAnexados?.map((anexo) => {
                  return (
                    <S.ItemArquivoAnexado key={anexo.id_sol_dados_anexo}>
                      <T.Span>{anexo.nome_arquivo}</T.Span>
                      <div
                        style={{
                          display: "flex",
                          flexWrap: "wrap",
                          justifyContent: "flex-end",
                          alignItems: "flex-end",
                        }}
                      >
                        <Tooltip title="Baixar Arquivo">
                          <IconButton
                            onClick={() =>
                              downloadFile(Number(anexo.id_sol_dados_anexo))
                            }
                          >
                            <FileDownloadIcon fontSize="small" />
                          </IconButton>
                        </Tooltip>
                        {isEditable && (
                          <Tooltip title="Deletar Arquivo">
                            <IconButton
                              onClick={() =>
                                deleteFile(Number(anexo.id_sol_dados_anexo))
                              }
                            >
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </div>
                    </S.ItemArquivoAnexado>
                  );
                })}
              </S.ListaArquivosAnexados>
            )}
          </S.ContainerInputFile>
        ))}

      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="flex-end"
        gap={1}
        flexWrap="wrap"
      >
        {anexosList.filter((anexo) => anexo.listaArquivosAnexados).length >=
          2 && (
          <B.ButtonSecondary onClick={() => downloadZipZAnexos()}>
            Download de todos anexos
            <FileDownloadIcon
              fontSize="small"
              style={{ marginLeft: "0.5rem" }}
            />
          </B.ButtonSecondary>
        )}
        {isEditable && !isCompleted ? (
          <B.ButtonPrimary onClick={() => nextEtapa()}>
            CONCLUIR ETAPA
          </B.ButtonPrimary>
        ) : (
          ""
        )}
      </Grid>
    </Grid>
  );
}
