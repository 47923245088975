/* eslint-disable consistent-return */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable @typescript-eslint/no-this-alias */
/* eslint-disable func-names */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prefer-rest-params */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-expressions */
export const getClientUrl = () => {
  return window.location.pathname.split("/")[1];
};

export const removeMask = (value: string) => {
  if (value) {
    return value.replace(/[^\d]/g, "");
  }
  return null;
};

export const removeMoneyMask = (value: string) => {
  if (value) {
    return value
      .replace(/\D/g, "")
      .replace(/(\d{1})(\d{14})$/, "$1$2")
      .replace(/(\d{1})(\d{11})$/, "$1$2")
      .replace(/(\d{1})(\d{8})$/, "$1$2")
      .replace(/(\d{1})(\d{5})$/, "$1$2")
      .replace(/(\d{1})(\d{1,2})$/, "$1.$2");
  }
  return null;
};

export const perfilStringFormat = (value: number) => {
  const perfils = {
    1: "Usuario", // preechedores de solicitações
    2: "Analista", // analista de solicitações (Contratante)
    3: "Administrador", // administrador de solicitações (Contratante)
    4: "Administrador Sistema", // administrador de software (AVMB)
    5: "Operador", // operador de solicitações (AVMB)
  };
  return perfils[value as keyof typeof perfils];
};

export const statusAgendamentoString = (value: number) => {
  type StatusAgendamento = {
    label: string;
    color: "primary" | "success" | "error";
  };
  const status = {
    1: {
      label: "Agendado",
      color: "primary",
    },
    2: {
      label: "Realizado",
      color: "success",
    },
    3: {
      label: "Cancelado",
      color: "error",
    },
  };
  return status[value as keyof typeof status] as StatusAgendamento;
};

export const convertSOrN = (value: string | boolean) => {
  const res = value ? (value === "S" || value === true ? "S" : "N") : "N";
  return res;
};

export const validadeSOrNArrayAll = (array: Array<"S" | "N">) => {
  return array.some((item) => item === "S");
};

export const convertData = (
  value: string | undefined,
  options?: Intl.DateTimeFormatOptions
) => {
  if (value) {
    const newData = new Date(value);
    if (String(newData) !== "Invalid Date") {
      return new Intl.DateTimeFormat("pt-BR", options).format(new Date(value));
    }
  }
  return undefined;
};

export const convertHora = (hora: string | undefined) => {
  if (hora) {
    const newData = new Date(`${new Date().toDateString()} ${hora}`);
    if (String(newData) !== "Invalid Date") {
      return newData.toLocaleTimeString("pt-BR", {
        hour: "2-digit",
        minute: "2-digit",
      });
    }
  }
  return undefined;
};

export const debounce = <F extends (...args: any[]) => any>(
  func: F,
  waitFor: number
) => {
  let timeout: NodeJS.Timeout;

  return (...args: Parameters<F>): Promise<ReturnType<F>> =>
    new Promise((resolve) => {
      if (timeout) {
        clearTimeout(timeout);
      }

      timeout = setTimeout(() => resolve(func(...args)), waitFor);
    });
};

export const TIPODOCUMENTOITEM = "tipoDocumentItem_";
export const INDOBRIGATORIODOCUMENT = "indObrigatorioDocument_";
export const ANEXOITEM = "anexoItem_";
export const INDOBRIGATORIOANEXO = "indObrigatorioAnexo_";

export const convertValuesDocumentsAndAnexos = (valuesForm: any) => {
  const keys = Object.keys(valuesForm);
  const documentsAndAnexos = {
    documentos: [] as any,
    anexos: [] as any,
  };
  keys.forEach((propertyes) => {
    if (propertyes.includes(TIPODOCUMENTOITEM) && valuesForm[propertyes]) {
      const numberItem = propertyes.split("_")[1];
      const indObrigatorioDocumentValue =
        valuesForm[`${INDOBRIGATORIODOCUMENT}${numberItem}`];
      documentsAndAnexos.documentos.push({
        cod_tabela: 14,
        item_tabela: numberItem,
        ind_obrigatorio: convertSOrN(indObrigatorioDocumentValue),
      });
    } else if (propertyes.includes(ANEXOITEM) && valuesForm[propertyes]) {
      const numberItem = propertyes.split("_")[1];
      const indObrigatorioAnexoValue =
        valuesForm[`${INDOBRIGATORIOANEXO}${numberItem}`];
      documentsAndAnexos.anexos.push({
        cod_tabela: 13,
        item_tabela: numberItem,
        ind_obrigatorio: convertSOrN(indObrigatorioAnexoValue),
      });
    }
  });
  return documentsAndAnexos;
};

export const createObjectValNotNull = (obj: any) => {
  const newObj = {};
  Object.keys(obj).forEach((key) => {
    if (obj[key] || obj[key] === 0) {
      // @ts-ignore
      newObj[key] = obj[key];
    }
  });
  return newObj;
};

export const copyToClipboard = (text: string) => {
  const textArea = document.createElement("textarea");
  textArea.value = text;
  document.body.appendChild(textArea);
  textArea.select();
  document.execCommand("copy");
  textArea.remove();
};

export const formtTextCamera = (text: string) => {
  if (!text) return;
  const textFormatted = text.toLowerCase();
  if (textFormatted.includes("front") || textFormatted.includes("back")) {
    if (textFormatted.includes("front")) {
      return "Camera frontal";
    }
    if (textFormatted.includes("back")) {
      return "Camera traseira";
    }
  } else if (textFormatted.includes("webcam")) {
    return "Webcam";
  } else {
    return "Camera";
  }
};

export const base64ToBlob = (base64: string) => {
  const [type, data] = base64.split(",");
  const byteString = atob(data);
  const ab = new ArrayBuffer(byteString.length);
  const ia = new Uint8Array(ab);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }
  return new Blob([ab], { type });
};

export async function getCepViaCep(cep: string) {
  const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);
  const data = await response.json();
  return data;
}

export const getIdURLUserSol = () => location.pathname.split("/")[3];

export const detectZoom = () => {
  return Math.round(window.devicePixelRatio * 100);
};

// ETAPAS DAS SOLICITAÇÃO
export const IDENTIFICACAO = "IDENTIFICACAO";
export const DADOS_COMPLEMENTARES = "DADOS_COMPLEMENTARES";
export const DOCUMENTOS = "DOCUMENTOS";
export const DECLARACAO_BENS = "DECLARACAO_BENS";
export const VINCULOS_TRABALHISTAS = "VINCULOS_TRABALHISTAS";
export const BENEFCIOS = "BENEFCIOS";
export const DEPENDENTES = "DEPENDENTES";
export const ANEXOS = "ANEXOS";
export const PROVA_VIDA = "PROVA_VIDA";

// FASES PROVA DE VIDA
export const PROVA_VIDA_DOC = "PROVA_VIDA_DOC";
export const PROVA_VIDA_SELFIE = "PROVA_VIDA_SELFIE";
export const PROVA_VIDA_FACEAPI = "PROVA_VIDA_FACEAPI";
export const PROVA_VIDA_PIX = "PROVA_VIDA_PIX";
export const PROVA_VIDA_SMS = "PROVA_VIDA_SMS";
export const PROVA_VIDA_VIDEO_CHAMADA = "PROVA_VIDA_VIDEO_CHAMADA";
