import { Box, Grid } from "@mui/material";
import styled, { css } from "styled-components";

export const Container = styled.div`
  width: 100%;
  margin-bottom: 1rem !important;
  display: flex;
  justify-content: center;
`;

export const BoxTabs = styled(Box)`
  ${({ theme }) => css`
    max-width: 100% !important;
    border: 1px solid ${theme.border.light};
    border-radius: 0.5rem;
  `}
`;

export const BoxButton = styled(Grid)`
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-top: 1rem !important;
`;
