/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unused-expressions */
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import * as B from "../../components/base/Buttons";
import * as T from "../../components/base/Text";
import { BoxLogin } from "../../components/boxLogin";
import { useLoginRedirect } from "../../hooks/useLoginRedirect";
import { api } from "../../services/api";
import { getClientUrl } from "../../utils/utils";
import * as S from "./styles";

export function ActiveAccount() {
  const [message, setMessage] = useState("");
  const navegate = useNavigate();
  const isLogged = useLoginRedirect();

  useEffect(() => {
    isLogged();
  }, []);

  useEffect(() => {
    const token = window.location.pathname.split("/").pop();
    api
      .put(`/user/active-account/${token}`)
      .then((res) => {
        setMessage(res.data.message);
      })
      .catch((err: any) => {
        setMessage(err.response.data.message);
      })
      .finally(() => {
        setTimeout(() => {
          navegate(`/${getClientUrl()}/login`);
        }, 10000);
      });
  }, []);

  return (
    <S.Container>
      <S.Content>
        <BoxLogin>
          <Grid container spacing={1} style={{ marginBottom: "2rem" }}>
            <Grid item xs={12}>
              <T.H2 textAlign="center">ATIVAÇÃO DE CONTA</T.H2>
            </Grid>
            <Grid item xs={12}>
              <T.Paragraph textAlign="center">{message}</T.Paragraph>
            </Grid>
          </Grid>
          <B.ButtonPrimary
            type="submit"
            style={{ width: "100%" }}
            onClick={() => navegate(`/${getClientUrl()}/login`)}
          >
            Login
          </B.ButtonPrimary>
        </BoxLogin>
      </S.Content>
    </S.Container>
  );
}
