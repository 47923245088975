/* eslint-disable func-names */
/* eslint-disable react/jsx-props-no-spreading */
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ButtonSecondary } from "../../components/base/Buttons";
import { CardPrimery } from "../../components/base/Cards";
import * as TB from "../../components/base/Table";
import * as T from "../../components/base/Text";
import { BodyContainer } from "../../components/BodyContainer";
import { InputDate } from "../../components/Inputs/InputDate";
import { InputSelect } from "../../components/Inputs/InputSelect";
import { InputBase } from "../../components/Inputs/InputText";
import { api } from "../../services/api";
import { setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { cpfMask } from "../../utils/mask";
import {
  convertData,
  createObjectValNotNull,
  debounce,
  getClientUrl,
} from "../../utils/utils";

type SearchTableProps = {
  id_solicitacao?: string;
  usuario?: string;
  cpf?: string;
  dataInicio?: string;
  dataFinal?: string;

  // ordenação
  ordem?: string;
  limit?: string;
  offset?: string;
};

type UserSolicitationTable = {
  id_sol_usuario: string;
  nome: string;
  numero_cpf: string;
  descr_solicitacao: string;
  dt_inclusao: string;
  hr_inclusao: string;
};

export function SolicitacoesRevisadas() {
  const [listaSolicitacoes, setListaSolicitacoes] = useState<
    Array<{ id_solicitacao: string; descr_solicitacao: string }>
  >([]);
  const { handleSubmit, control, reset } = useForm<SearchTableProps>();
  const TableRef = useRef<HTMLDivElement>(null);

  const navegate = useNavigate();

  const dispatch = useAppDispatch();

  const {
    Utils: { device },
  } = useAppSelector((state) => state);

  const [userSolicitations, setUserSolicitations] = useState<
    UserSolicitationTable[]
  >([]);
  const [isFilter, setIsFilter] = useState(false);

  async function getUserSolicitations(
    params?: SearchTableProps,
    offset?: number
  ) {
    try {
      const paramsJson = {
        id_solicitacao: params?.id_solicitacao || "",
        cpf: params?.cpf || "",
        usuario: params?.usuario || "",
        dataInicio: convertData(params?.dataInicio) || "",
        dataFinal: convertData(params?.dataFinal) || "",
        limit: params?.limit || "",
        offset: offset || "",
        ordem: params?.ordem || "",
      };
      const paramsBase64 = btoa(JSON.stringify(paramsJson));

      if (Object.keys(createObjectValNotNull(paramsJson)).length) {
        window.history.replaceState(null, "", `?params=${paramsBase64}`);
      }

      const { data } = await api.get(
        `/solicitation/users/revisadas/${paramsBase64}`
      );

      if (offset) {
        setUserSolicitations((state) => [...state, ...data]);
      } else {
        setUserSolicitations(data);
      }
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  }

  const onSubmit: SubmitHandler<SearchTableProps> = async (body) => {
    try {
      getUserSolicitations(body);
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    }
  };

  const getSolicitacoes = async () => {
    const { data } = await api.get("/solicitation/list");
    if (!data.length) return;
    setListaSolicitacoes(data);
  };

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const paramsBase64 = params.get("params");

    if (
      paramsBase64 &&
      Object.keys(createObjectValNotNull(JSON.parse(atob(paramsBase64 || ""))))
        .length
    ) {
      const paramsJson = JSON.parse(atob(paramsBase64 || ""));
      reset(paramsJson);
      getUserSolicitations(paramsJson);
      getSolicitacoes();
      setIsFilter(true);
    } else {
      params.delete("params");
      getUserSolicitations();
      getSolicitacoes();
    }

    return () => {
      params.delete("params");
    };
  }, []);

  const scrollToTable = debounce(async function () {
    const scrollTotal = Number(TableRef.current?.scrollTop);
    const heightWin =
      Number(TableRef.current?.scrollHeight) -
      Number(TableRef.current?.clientHeight);
    const scroll = (scrollTotal / heightWin) * 100;
    if (scroll >= Number(100) - Number(2)) {
      const index = userSolicitations.length;
      const params = new URLSearchParams(window.location.search);
      const paramsBase64 = params.get("params");

      if (
        paramsBase64 &&
        Object.keys(
          createObjectValNotNull(JSON.parse(atob(paramsBase64 || "")))
        ).length
      ) {
        const paramsJson = JSON.parse(atob(paramsBase64 || ""));
        getUserSolicitations(paramsJson, index);
      } else {
        getUserSolicitations({}, index);
      }
    }
  }, 120);

  return (
    <BodyContainer menu="Revisadas">
      <Grid
        container
        spacing={1}
        style={{
          margin: "1rem 0 1.5rem 0",
          borderLeft: "4px solid #ff6e07",
          paddingLeft: "1rem",
        }}
      >
        <Grid item xs={12}>
          <T.H2>SOLICITAÇÕES REVISADAS</T.H2>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "0.6rem",
          }}
        >
          <T.H6 lineHeight="1.3rem">
            Nesta tela você pode visualizar as solicitações que já foram
            revisadas.
          </T.H6>
        </Grid>
      </Grid>
      <CardPrimery container spacing={1} height="100%">
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ flex: "1" }}
        >
          <Grid container spacing={1} display="flex" justifyContent="flex-end">
            <Grid item xs={12} display="flex" justifyContent="flex-end">
              <div style={{ width: "3rem" }}>
                <Tooltip title="Pesquisa avançada">
                  <IconButton onClick={() => setIsFilter((state) => !state)}>
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
            {isFilter && (
              <>
                <Grid item xs={12} md={3}>
                  <InputSelect
                    title="Soliciatação"
                    control={control}
                    id="id_solicitacao"
                    label="Soliciatação"
                    name="id_solicitacao"
                    isErrorMessage={false}
                  >
                    <option> </option>
                    {listaSolicitacoes.length &&
                      listaSolicitacoes.map((type) => (
                        <option
                          key={type.id_solicitacao}
                          value={type.id_solicitacao}
                        >
                          {type.descr_solicitacao}
                        </option>
                      ))}
                  </InputSelect>
                </Grid>
                <Grid item xs={12} md={3}>
                  <InputBase
                    control={control}
                    id="usuario"
                    label="Nome do usuário"
                    name="usuario"
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputBase
                    control={control}
                    id="cpf"
                    label="CPF do usuário"
                    name="cpf"
                    type="cpf"
                    rules={{
                      maxLength: {
                        value: 14,
                        message:
                          "O CPF deve ter no máximo 11 dígitos, sem pontos e traços",
                      },
                    }}
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputDate
                    control={control}
                    id="dataInicio"
                    name="dataInicio"
                    label="Periodo inicial"
                    defaultValue=""
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <InputDate
                    control={control}
                    id="dataFinal"
                    name="dataFinal"
                    label="Periodo final"
                    defaultValue=""
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12} display="flex" justifyContent="flex-end">
                  <ButtonSecondary
                    variant="outlined"
                    type="submit"
                    style={{ gap: "0.5rem" }}
                  >
                    Busca
                    <SearchIcon />
                  </ButtonSecondary>
                </Grid>
              </>
            )}
          </Grid>
        </form>

        <TB.TableContainer
          sx={{
            maxHeight: "calc(100vh - 300px)",
            minHeight: "65vh",
            marginTop: isFilter ? "1rem" : "initial",
          }}
          ref={TableRef}
          onScroll={scrollToTable}
        >
          <TB.Table stickyHeader aria-label="sticky table">
            <TB.TableHead>
              <TB.TableRow>
                <TB.TableCell width="100%">SOLICITAÇÃO</TB.TableCell>
                <TB.TableCell
                  {...(device === "desktop"
                    ? { width: "15rem", maxWidth: "15rem", minWidth: "15rem" }
                    : { width: "8rem", maxWidth: "8rem", minWidth: "8rem" })}
                >
                  NOME
                </TB.TableCell>
                {device === "desktop" && (
                  <TB.TableCell minWidth="10rem" width="10rem">
                    CPF
                  </TB.TableCell>
                )}
                <TB.TableCell
                  align="center"
                  {...(device === "desktop"
                    ? { width: "12rem", maxWidth: "12rem", minWidth: "12rem" }
                    : { width: "7rem", maxWidth: "7rem", minWidth: "7rem" })}
                >
                  Data revisão
                </TB.TableCell>
              </TB.TableRow>
            </TB.TableHead>
            <TB.TableBody>
              {userSolicitations.length
                ? userSolicitations.map((item) => (
                    <TB.TableRow
                      hover
                      style={{ cursor: "pointer" }}
                      role="checkbox"
                      key={item.id_sol_usuario}
                      onClick={() => {
                        navegate(
                          `../${getClientUrl()}/solicitacao/${
                            item.id_sol_usuario
                          }`,
                          { state: { solicitation: item } }
                        );
                      }}
                    >
                      <TB.TableCell>{item.descr_solicitacao}</TB.TableCell>
                      <TB.TableCell>{item.nome}</TB.TableCell>
                      <TB.TableCell>{cpfMask(item.numero_cpf)}</TB.TableCell>
                      <TB.TableCell align="center">
                        {convertData(item.dt_inclusao)}
                      </TB.TableCell>
                    </TB.TableRow>
                  ))
                : null}
            </TB.TableBody>
          </TB.Table>
        </TB.TableContainer>
      </CardPrimery>
    </BodyContainer>
  );
}
