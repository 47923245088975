import { getClientUrl } from "../utils/utils";

export const TOKEN_KEY = "TOKEN";

// Define que a função isAuthenticated recebe o token se não for nulo
export const isAuthenticated = () => sessionStorage.getItem(TOKEN_KEY) !== null;

// Busca o token no localStorage
export const getToken = () => sessionStorage.getItem(TOKEN_KEY);

// Salva o token no localStorage
export const login = (token: string) => {
  sessionStorage.setItem(TOKEN_KEY, token);
};

// Remove o token do localStorage
export const logout = (delay?: number) => {
  sessionStorage.clear();
  if (delay) {
    setTimeout(() => {
      window.location.href = `/${getClientUrl()}/login`;
    }, delay);
  } else window.location.href = `/${getClientUrl()}/login`;
};
