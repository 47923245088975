/* eslint-disable @typescript-eslint/no-explicit-any */
import CameraFrontIcon from "@mui/icons-material/CameraFront";
import DocumentScannerIcon from "@mui/icons-material/DocumentScanner";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import OndemandVideoIcon from "@mui/icons-material/OndemandVideo";
import PaidIcon from "@mui/icons-material/Paid";
import SensorOccupiedIcon from "@mui/icons-material/SensorOccupied";
import { useEffect, useState } from "react";
import { UseFormGetValues, UseFormWatch } from "react-hook-form";

import { CreateSolicitationProps } from "../../../pages/CreateSolicitation";
import { CardPrimery } from "../../base/Cards";
import { ButtonRadioStyles } from "../../base/stylesGeneric";
import * as T from "../../base/Text";
import { InputCheckBox } from "../../Inputs/inputCheckBox";
import * as S from "../styles";

type ProofOfLifeProps = {
  control: any;
  watch: UseFormWatch<CreateSolicitationProps>;
  getValues: UseFormGetValues<CreateSolicitationProps>;
};

type CheckedsProps = {
  ind_prova_vida_doc: boolean | "S" | "N";
  ind_prova_vida_faceapi: boolean | "S" | "N";
  ind_prova_vida_pix: boolean | "S" | "N";
  ind_prova_vida_selfie: boolean | "S" | "N";
  ind_prova_vida_sms: boolean | "S" | "N";
  ind_prova_vida_video_chamada: boolean | "S" | "N";
};

export function ProofOfLife({ control, getValues, watch }: ProofOfLifeProps) {
  const [checked, setChecked] = useState<CheckedsProps>({} as CheckedsProps);

  const setCheckeds = () => {
    const value = getValues();
    setChecked({
      ind_prova_vida_doc: value.ind_prova_vida_doc,
      ind_prova_vida_faceapi: value.ind_prova_vida_faceapi,
      ind_prova_vida_pix: value.ind_prova_vida_pix,
      ind_prova_vida_selfie: value.ind_prova_vida_selfie,
      ind_prova_vida_sms: value.ind_prova_vida_sms,
      ind_prova_vida_video_chamada: value.ind_prova_vida_video_chamada,
    });
  };

  useEffect(() => {
    setCheckeds();
  }, [
    watch().ind_prova_vida_doc,
    watch().ind_prova_vida_faceapi,
    watch().ind_prova_vida_pix,
    watch().ind_prova_vida_selfie,
    watch().ind_prova_vida_sms,
    watch().ind_prova_vida_video_chamada,
  ]);

  return (
    <CardPrimery
      container
      style={{
        padding: "1.5rem 2rem",
      }}
    >
      <S.BoxGrid>
        <S.BoxLabel checked={checked.ind_prova_vida_doc}>
          <T.H5 textAlign="center">Prova de vida com Anexos de Documentos</T.H5>
          <DocumentScannerIcon fontSize="large" />
          <T.Span textAlign="center">
            Sera solicitado o usuário anexe um documento de identificação frente
            e verso
          </T.Span>
          <S.BoxStyleRadios>
            <ButtonRadioStyles checked={checked.ind_prova_vida_doc} />
          </S.BoxStyleRadios>
          <div style={{ display: "none" }}>
            <InputCheckBox
              control={control}
              id="ind_prova_vida_doc"
              label="Prova de vida com documentos"
              name="ind_prova_vida_doc"
            />
          </div>
        </S.BoxLabel>
        <S.BoxLabel checked={checked.ind_prova_vida_faceapi}>
          <T.H5 textAlign="center">
            Prova de Vida com Reconhecimento Facial
          </T.H5>
          <SensorOccupiedIcon fontSize="large" />
          <T.Span textAlign="center">
            O usuário deverá realizar um reconhecimento facial para comprovar a
            sua identidade.
          </T.Span>
          <S.BoxStyleRadios>
            <ButtonRadioStyles checked={checked.ind_prova_vida_faceapi} />
          </S.BoxStyleRadios>
          <div style={{ display: "none" }}>
            <InputCheckBox
              control={control}
              id="ind_prova_vida_faceapi"
              label="Prova de Vida com Reconhecimento Facial"
              name="ind_prova_vida_faceapi"
            />
          </div>
        </S.BoxLabel>
        <S.BoxLabel checked={checked.ind_prova_vida_pix}>
          <T.H5 textAlign="center">Prova de Vida com Validação por Pix</T.H5>
          <PaidIcon fontSize="large" />
          <T.Span textAlign="center">
            O usuário deverá realizar uma transferência pix de R$ 0,01 para
            comprovar a sua identidade apartir de dados bancários.
          </T.Span>
          <S.BoxStyleRadios>
            <ButtonRadioStyles checked={checked.ind_prova_vida_pix} />
          </S.BoxStyleRadios>
          <div style={{ display: "none" }}>
            <InputCheckBox
              control={control}
              id="ind_prova_vida_pix"
              label="Prova de Vida com Validação por Pix"
              name="ind_prova_vida_pix"
            />
          </div>
        </S.BoxLabel>
        <S.BoxLabel checked={checked.ind_prova_vida_selfie}>
          <T.H5 textAlign="center">Prova de vida com Foto + Selfie</T.H5>
          <CameraFrontIcon fontSize="large" />
          <T.Span textAlign="center">
            O usuário deverá fazer uma selfie com o documento de identificação
            em mãos.
          </T.Span>
          <S.BoxStyleRadios>
            <ButtonRadioStyles checked={checked.ind_prova_vida_selfie} />
          </S.BoxStyleRadios>
          <div style={{ display: "none" }}>
            <InputCheckBox
              control={control}
              id="ind_prova_vida_selfie"
              label="Prova de vida com Foto + Selfie"
              name="ind_prova_vida_selfie"
            />
          </div>
        </S.BoxLabel>
        <S.BoxLabel checked={checked.ind_prova_vida_sms}>
          <T.H5 textAlign="center">Prova de vida com Codigo SMS</T.H5>
          <ForwardToInboxIcon fontSize="large" />
          <T.Span textAlign="center">
            O usuário irá receber um codigo de validação por SMS.
          </T.Span>
          <S.BoxStyleRadios>
            <ButtonRadioStyles checked={checked.ind_prova_vida_sms} />
          </S.BoxStyleRadios>
          <div style={{ display: "none" }}>
            <InputCheckBox
              control={control}
              id="ind_prova_vida_sms"
              label="Prova de vida com Codigo SMS"
              name="ind_prova_vida_sms"
            />
          </div>
        </S.BoxLabel>

        {/* <S.BoxLabel checked={checked.ind_prova_vida_video_chamada}>
          <T.H5 textAlign="center">Video chamada</T.H5>
          <OndemandVideoIcon fontSize="large" />
          <T.Span textAlign="center">
            Sera solicitado os tipos de dados mais comuns a um usuario, nome,
            sobrenome, email...
          </T.Span>
          <S.BoxStyleRadios>
            <ButtonRadioStyles checked={checked.ind_prova_vida_video_chamada} />
          </S.BoxStyleRadios>
          <div style={{ display: "none" }}>
            <InputCheckBox
              control={control}
              id="ind_prova_vida_video_chamada"
              label="Prova de vida com Codigo SMS"
              name="ind_prova_vida_video_chamada"
            />
          </div>
        </S.BoxLabel> */}
      </S.BoxGrid>
    </CardPrimery>
  );
}
