/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import AddIcon from "@mui/icons-material/Add";
import FilterListIcon from "@mui/icons-material/FilterList";
import SearchIcon from "@mui/icons-material/Search";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import { ButtonPrimary, ButtonSecondary } from "../../components/base/Buttons";
import { CardPrimery } from "../../components/base/Cards";
import * as TB from "../../components/base/Table";
import * as T from "../../components/base/Text";
import { BodyContainer } from "../../components/BodyContainer";
import { InputSelect } from "../../components/Inputs/InputSelect";
import { InputBase } from "../../components/Inputs/InputText";
import { useTableStructure } from "../../hooks/useTableStructure";
import { api } from "../../services/api";
import { setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ISolicitationProps } from "../../types/Solicitation";
import { convertSOrN, getClientUrl } from "../../utils/utils";

type SearchTableProps = {
  descr_solicitacao?: string;
  ind_ativo?: "S" | "N";
  status_item?: number;
  id_autor?: number;
  tipo_solicitacao_item?: number;
};

export function Solicitations() {
  const { handleSubmit, control, getValues } = useForm<SearchTableProps>();

  const navegate = useNavigate();

  const dispatch = useAppDispatch();

  useTableStructure(["status", "typeSolicitation"]);

  const {
    Clients: { clientToUpdate },
    Utils: { device },
    TableStructure: { status, typeSolicitation },
  } = useAppSelector((state) => state);

  const [itemAllTable, setItemAllTable] = useState<ISolicitationProps[]>([]);
  const [isFilter, setIsFilter] = useState(false);

  async function getSolicitations({
    descr_solicitacao,
    ind_ativo,
    status_item,
    id_autor,
    tipo_solicitacao_item,
  }: SearchTableProps) {
    try {
      const { data } = await api.get(
        `/solicitation/list?descr_solicitacao=${
          descr_solicitacao || ""
        }&id_autor=${id_autor || ""}&ind_ativo=${
          convertSOrN(ind_ativo!) || ""
        }&status_item=${status_item || ""}&tipo_solicitacao_item=${
          tipo_solicitacao_item || ""
        }`
      );
      setItemAllTable(data);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  }

  const onSubmit: SubmitHandler<SearchTableProps> = async (body) => {
    try {
      await getSolicitations(body);
    } catch (err: any) {
      setItemAllTable([]);
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };
  const getUpdateTables = () => onSubmit(getValues());

  useEffect(() => {
    getUpdateTables();
  }, [clientToUpdate.sigla]);

  return (
    <BodyContainer menu="Solicitações">
      <Grid
        container
        spacing={1}
        style={{
          margin: "1rem 0 1.5rem 0",
          borderLeft: "4px solid #ff6e07",
          paddingLeft: "1rem",
        }}
      >
        <Grid item xs={12}>
          <T.H2>LISTA DE SOLICITAÇÕES</T.H2>
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "0.6rem",
          }}
        >
          <T.Paragraph lineHeight="1.3rem">
            Aqui você pode visualizar todas as solicitações cadastradas no
            sistema. Para visualizar os detalhes de uma solicitação, selecione a
            linha correspondente.
          </T.Paragraph>
        </Grid>
      </Grid>
      <CardPrimery container spacing={1} height="100%">
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ flex: "1" }}
        >
          <Grid container spacing={1}>
            <Grid
              item
              xs={12}
              display="flex"
              alignItems="center"
              justifyContent="flex-end"
              style={{ height: "100%" }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  gap: "1rem",
                  paddingRight: "1rem",
                }}
              >
                <ButtonPrimary
                  variant="outlined"
                  onClick={() =>
                    navegate(`../${getClientUrl()}/admin/cria-solicitacao`)
                  }
                >
                  Criar Solicitação
                  <AddIcon style={{ marginLeft: "0.5rem" }} />
                </ButtonPrimary>
                <Tooltip title="Filtar solicitações">
                  <IconButton onClick={() => setIsFilter((state) => !state)}>
                    <FilterListIcon />
                  </IconButton>
                </Tooltip>
              </div>
            </Grid>
            {isFilter && (
              <>
                <Grid item xs={12} md={4}>
                  <InputBase
                    control={control}
                    id="id_autor"
                    label="Autor"
                    name="id_autor"
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputSelect
                    title="Status"
                    control={control}
                    id="status_item"
                    label="Status"
                    name="status_item"
                    isErrorMessage={false}
                  >
                    <option> </option>
                    {status.itemsTable?.length &&
                      status.itemsTable.map((status) => {
                        if (status.item_tabela === 0) return null;
                        return (
                          <option
                            key={status.item_tabela}
                            value={status.item_tabela}
                          >
                            {status.descr_tabela}
                          </option>
                        );
                      })}
                  </InputSelect>
                </Grid>
                <Grid item xs={12} md={4}>
                  <InputSelect
                    title="Tipo Solicitação"
                    control={control}
                    id="tipo_solicitacao_item"
                    label="Tipo Solicitação"
                    name="tipo_solicitacao_item"
                    isErrorMessage={false}
                  >
                    <option> </option>
                    {typeSolicitation.itemsTable?.length &&
                      typeSolicitation.itemsTable.map((type) => {
                        if (type.item_tabela === 0) return null;
                        return (
                          <option
                            key={type.item_tabela}
                            value={type.item_tabela}
                          >
                            {type.descr_tabela}
                          </option>
                        );
                      })}
                  </InputSelect>
                </Grid>
                <Grid item xs={12} md={10}>
                  <InputBase
                    control={control}
                    id="descr_solicitacao"
                    label="Descriçao"
                    name="descr_solicitacao"
                    isErrorMessage={false}
                  />
                </Grid>
                <Grid item xs={12} md={2}>
                  <ButtonSecondary
                    variant="outlined"
                    type="submit"
                    style={{ width: "100%", gap: "0.5rem" }}
                  >
                    Busca
                    <SearchIcon />
                  </ButtonSecondary>
                </Grid>
              </>
            )}
          </Grid>
        </form>

        <TB.TableContainer sx={{ maxHeight: "65vh", minHeight: "65vh" }}>
          <TB.Table stickyHeader aria-label="sticky table">
            <TB.TableHead>
              <TB.TableRow>
                {device === "desktop" && (
                  <TB.TableCell width="10rem" minWidth="10rem">
                    AUTOR
                  </TB.TableCell>
                )}
                <TB.TableCell width="100%">DESCRIÇÃO</TB.TableCell>
                <TB.TableCell align="center">TIPO SOLICITAÇÃO</TB.TableCell>
                <TB.TableCell align="center">STATUS</TB.TableCell>
                {device === "desktop" && (
                  <TB.TableCell align="center" minWidth="10rem">
                    TOTAL PREENCHIDO
                  </TB.TableCell>
                )}
              </TB.TableRow>
            </TB.TableHead>
            <TB.TableBody>
              {itemAllTable.map((item) => (
                <TB.TableRow
                  hover
                  style={{ cursor: "pointer" }}
                  role="checkbox"
                  key={item.id_solicitacao}
                  onClick={() => {
                    navegate(
                      `../${getClientUrl()}/admin/solicitacao/${
                        item.id_solicitacao
                      }`,
                      { state: { solicitation: item } }
                    );
                  }}
                >
                  {device === "desktop" && (
                    <TB.TableCell>{item.nome}</TB.TableCell>
                  )}
                  <TB.TableCell>{item.descr_solicitacao}</TB.TableCell>
                  <TB.TableCell align="center">
                    {typeSolicitation.itemsTable?.length &&
                      typeSolicitation.itemsTable.map((type) => {
                        if (item?.tipo_solicitacao_item === type.item_tabela) {
                          return type.descr_tabela;
                        }
                      })}
                  </TB.TableCell>
                  <TB.TableCell align="center">
                    {item.descr_tabela}
                  </TB.TableCell>
                  {device === "desktop" && (
                    <TB.TableCell align="center">100%</TB.TableCell>
                  )}
                </TB.TableRow>
              ))}
            </TB.TableBody>
          </TB.Table>
        </TB.TableContainer>
      </CardPrimery>
    </BodyContainer>
  );
}
