import { ReactNode } from "react";

import * as S from "./styles";

export function BoxLogin({ children }: { children: ReactNode }) {
  return (
    <S.CardLogin>
      <S.CardHeader>
        <S.Logo src="/images/logo-censo-3.png" />
      </S.CardHeader>
      <S.CardBody container>{children}</S.CardBody>
    </S.CardLogin>
  );
}
