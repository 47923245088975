/**
 * Action types
 */
export enum ClientsTypes {
  SET_CLIENTS_ARRAY = "@client/SET_CLIENTS_ARRAY",
  SET_CLIENT_LOGGED = "@client/SET_CLIENT_LOGGED",
  SET_CLIENT_TO_UPDATE = "@client/SET_CLIENT_TO_UPDATE",
  GET_CLIENTS_ARRAY = "@client/GET_CLIENTS_ARRAY",
  GET_CLIENT_LOGGED = "@client/GET_CLIENT_LOGGED",
  GET_CLIENT_TO_UPDATE = "@client/GET_CLIENT_TO_UPDATE",
}

/**
 * valores isolados do objeto de parametros
 */
export type ClientObjectTypes = {
  id_cliente?: string;
  nome_cliente: string;
  numero_cnpj: string;
  sigla?: string;
  logomarca?: string;
  ind_ativo: "S" | "N";
  ind_login_cpf: "S" | "N";
  ind_permitir_criar_conta: "S" | "N";
  ind_default?: "S" | "N";
  ind_agendamento: "S" | "N";
  schema_base?: string;
  token_asten_assinatura?: string;
  dt_cadastro?: string;
  hr_cadastro?: string;
};

export type ClientsArrayTypes = Array<ClientObjectTypes>;

/**
 * valores do state completo desse redux
 */
export type ClientsState = {
  readonly clientLogged: ClientObjectTypes;
  readonly clientToUpdate: ClientObjectTypes;
  readonly clients: ClientsArrayTypes;
};
