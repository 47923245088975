import { Reducer } from "redux";

import { GraphicsTypes, GraphicsState } from "./types";

const INITIAL_STATE: GraphicsState = {
  graphicPizza: [],
  graphicBar: [],
};

const reducer: Reducer<GraphicsState> = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GraphicsTypes.SET_GRAPHIC_PIZZA:
      return { ...state, graphicPizza: action.payload };

    case GraphicsTypes.SET_GRAPHIC_BAR:
      return { ...state, graphicBar: action.payload };

    default:
      return state;
  }
};

export default reducer;
