/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import AddModeratorIcon from "@mui/icons-material/AddModerator";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import ArticleIcon from "@mui/icons-material/Article";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import FolderIcon from "@mui/icons-material/Folder";
import GroupIcon from "@mui/icons-material/Group";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import SendIcon from "@mui/icons-material/Send";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useLayoutEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { ButtonPrimary } from "../../components/base/Buttons";
import { BoxStatusSolicitation } from "../../components/base/stylesGeneric";
import * as T from "../../components/base/Text";
import { BodyContainer } from "../../components/BodyContainer";
import { BottomNavigationComponent } from "../../components/BottomNavigationComponent";
import { WarningSendSolicitation } from "../../components/ModalsComponents/WarningSendSolicitation";
import { AnexosSolicitados } from "../../components/SettingsSolicitation/AnexosSolicitados";
import { DashbordSolicitation } from "../../components/SettingsSolicitation/DashbordSolicitation";
import { DocsSolicitados } from "../../components/SettingsSolicitation/DocsSolicitados";
import { GenericManagement } from "../../components/SettingsSolicitation/GenericManagement";
import { Identifications } from "../../components/SettingsSolicitation/Identifications";
import { ProofOfLife } from "../../components/SettingsSolicitation/ProofOfLife";
import { UsersSolicitation } from "../../components/SettingsSolicitation/UsersSolicitation";
import { useTableStructure } from "../../hooks/useTableStructure";
import { api } from "../../services/api";
import { setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { ISolicitationProps } from "../../types/Solicitation";
import { convertData, copyToClipboard, getClientUrl } from "../../utils/utils";

interface IDocumentsSolicitadosProps {
  cod_tabela: number;
  item_tabela: number;
  desc_tabela: string;
  sigla_tabela: string;
  id?: number;
}

export interface IAnexosSolicitadosProps {
  id_solicitacao: number | string;
  id_anexo_solicitado: number | string;
  descricao: string;
  ind_obrigatorio?: "S" | "N";
}

export function ManagementSolicitation() {
  const [solicitation, setSolicitation] = useState<ISolicitationProps>(
    {} as ISolicitationProps
  );

  const [documentsSolicitantion, setDocumentsSolicitantion] = useState<
    IDocumentsSolicitadosProps[]
  >([] as IDocumentsSolicitadosProps[]);

  const [anexosSolicitantion, setAnexosSolicitantion] = useState<
    IAnexosSolicitadosProps[]
  >([] as IAnexosSolicitadosProps[]);

  useTableStructure(["status", "typeSolicitation"]);

  const [buttomNavigationState, setButtomNavigationSte] = useState(0);

  const [isEdit, setIsEdit] = useState(false);
  const [openModalWarningSend, setOpenModalWarningSend] = useState(false);

  const onCloseModalWarningSend = () => {
    setOpenModalWarningSend(false);
  };

  const location = useLocation();
  const navegate = useNavigate();

  const dispatch = useAppDispatch();
  const {
    TableStructure: { status },
    User: { user },
  } = useAppSelector((state) => state);

  const ButtomNavigation = [
    {
      icon: <AnalyticsIcon />,
      name: "Dashebord",
    },
    {
      icon: <GroupIcon />,
      name: "Usuarios Vinculados",
    },
    {
      icon: <ArticleIcon />,
      name: "Documentos Solicitados",
    },
    {
      icon: <FolderIcon />,
      name: "Anexos Solicitados",
    },
    {
      icon: <AddModeratorIcon />,
      name: "Prova de Vida",
    },
    {
      icon: <ManageAccountsIcon />,
      name: "Identificações Gerais",
    },
    {
      icon: <BorderColorIcon />,
      name: "Gerencimento Solicitação",
    },
  ];

  const getDocumentsAndAnexosSolicitation = async () => {
    try {
      const id = location.pathname.split("/")[4];
      const [documentos, anexos] = await Promise.all([
        await api.get(`/solicitation/documnets/${id}`),
        await api.get(`/solicitation/anexos/${id}`),
      ]);
      setDocumentsSolicitantion(
        documentos.data.documents as IDocumentsSolicitadosProps[]
      );
      setAnexosSolicitantion(anexos.data.anexos as IAnexosSolicitadosProps[]);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const getSolicitation = async () => {
    try {
      const id = location.pathname.split("/")[4];
      const { data } = await api.get(`/solicitation/get/${id}`);
      setSolicitation(data[0]);
    } catch (err: any) {
      navegate(`../${getClientUrl()}/admin/solicitacoes`);
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  useLayoutEffect(() => {
    getSolicitation();
    getDocumentsAndAnexosSolicitation();
  }, []);

  useEffect(() => {
    if (solicitation.id_solicitacao) {
      setIsEdit(
        solicitation.status_item === 1 &&
          solicitation.id_autor === user.id_usuario
      );
    }
  }, [solicitation.status_item, user.id_usuario]);

  return (
    <BodyContainer menu="Solicitacoes">
      <Grid
        container
        spacing={1}
        style={{
          margin: "1rem 0 1.5rem 0",
          borderLeft: "4px solid #ff6e07",
          paddingLeft: "1rem",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <T.H2>{solicitation.descr_solicitacao}</T.H2>
          {solicitation.id_solicitacao && status.itemsTable?.length ? (
            <BoxStatusSolicitation status={solicitation.status_item as number}>
              {status.itemsTable.map((status: any) => {
                if (solicitation.status_item === status.item_tabela) {
                  return status.descr_tabela;
                }
              })}
            </BoxStatusSolicitation>
          ) : null}
        </Grid>
        <Grid
          item
          xs={12}
          style={{
            marginBottom: "0.6rem",
          }}
        >
          {solicitation.observacoes && (
            <T.Paragraph lineHeight="1.3rem">
              OBSERVAÇÕES: {solicitation.observacoes}
            </T.Paragraph>
          )}

          {solicitation.nome && (
            <T.Paragraph lineHeight="1.3rem">
              AUTOR: {solicitation.nome}
            </T.Paragraph>
          )}
          {solicitation?.id_externo && (
            <T.Paragraph lineHeight="1.3rem">
              IDENTIFICADOR: {solicitation.id_externo}
              <Tooltip title="Copiar identificador">
                <IconButton
                  onClick={() => copyToClipboard(solicitation.id_externo!)}
                >
                  <ContentCopyIcon fontSize="small" />
                </IconButton>
              </Tooltip>
            </T.Paragraph>
          )}

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "3rem",
              }}
            >
              {solicitation.dt_inicio_preench && (
                <T.Span style={{ whiteSpace: "nowrap" }}>
                  DATA INICIAL: {convertData(solicitation.dt_inicio_preench)}
                </T.Span>
              )}
              {solicitation.dt_fim_preench && (
                <T.Span style={{ whiteSpace: "nowrap" }}>
                  DATA FINAL: {convertData(solicitation.dt_fim_preench)}
                </T.Span>
              )}
            </div>
            {isEdit && (
              <ButtonPrimary onClick={() => setOpenModalWarningSend(true)}>
                Enviar para Preenchimento
                <SendIcon style={{ marginLeft: "0.5rem" }} />
              </ButtonPrimary>
            )}
          </div>
        </Grid>
      </Grid>
      <BottomNavigationComponent
        value={buttomNavigationState}
        setValue={setButtomNavigationSte}
        actions={ButtomNavigation}
        style={{ justifyContent: "flex-start", padding: "0.5rem 0" }}
      />
      {buttomNavigationState === 0 && (
        <DashbordSolicitation idItemGraphic={solicitation.id_solicitacao!} />
      )}
      {buttomNavigationState === 1 && (
        <UsersSolicitation solicitation={solicitation} isEdit={isEdit} />
      )}
      {buttomNavigationState === 2 && (
        <DocsSolicitados
          documentsSolicitantion={documentsSolicitantion}
          isEdit={isEdit}
        />
      )}
      {buttomNavigationState === 3 && (
        <AnexosSolicitados
          anexosSolicitantion={anexosSolicitantion}
          setAnexosSolicitantion={setAnexosSolicitantion}
          isEdit={isEdit}
        />
      )}
      {buttomNavigationState === 4 && (
        <ProofOfLife
          solicitation={solicitation}
          getSolicitation={getSolicitation}
          isEdit={isEdit}
        />
      )}
      {buttomNavigationState === 5 && (
        <Identifications
          solicitation={solicitation}
          getSolicitation={getSolicitation}
          isEdit={isEdit}
        />
      )}
      {buttomNavigationState === 6 && (
        <GenericManagement solicitation={solicitation} isEdit={isEdit} />
      )}

      <WarningSendSolicitation
        isOpen={openModalWarningSend}
        onClose={onCloseModalWarningSend}
        idSolicitation={solicitation.id_solicitacao!}
        getSolicitation={getSolicitation}
      />
    </BodyContainer>
  );
}
