import { Card } from "@mui/material";
import styled, { css } from "styled-components";

type StyleProps = {
  image?: string;
};

export const CardActions = styled(Card)`
  padding: 1rem;
  height: 15rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const BoxImage = styled.label<StyleProps>`
  ${({ theme, image }) => css`
    min-width: 150px;
    min-height: 150px;
    max-width: 150px;
    max-height: 150px;
    border-radius: 50%;
    background-image: url(${image});
    background-size: cover;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid ${theme.border.light};
    background-color: ${theme.background.active};

    svg {
      transition: all 0.3s ease-in-out;
      opacity: 0.5;
    }

    :hover {
      background-color: ${theme.background.hover};
      cursor: pointer;

      svg {
        opacity: 1;
        transform: scale(1.1);
      }
    }
  `}
`;
