import { keyframes } from "styled-components";

export const animationPulse = (colorPulse: string) => keyframes`
  0% {
    -webkit-box-shadow: 0 0 0 0 ${colorPulse};
  }
  70% {
      -webkit-box-shadow: 0 0 0 10px rgba(204,169,44, 0);
  }
  100% {
      -webkit-box-shadow: 0 0 0 0 rgba(204,169,44, 0);
  }
`;
