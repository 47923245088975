/* eslint-disable no-empty-pattern */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-unneeded-ternary */
/* eslint-disable react/require-default-props */
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import * as B from "../../base/Buttons";
import { InputBase } from "../../Inputs/InputText";
import Modal from "../index";
import * as S from "./styles";

type FormData = {
  cod_tabela: number | null;
  item_tabela: number | null;
  descr_tabela: string;
  sigla_tabela: string;
  cod_tabela_sup: number | null;
  item_tabela_sup: number | null;
};

interface IModalAddNewStructuredTable {
  isOpen: boolean;
  onClose: () => void;
  returnIcon?: () => void;
  item?: FormData | boolean;
  getUpdateTables: () => void;
}

export function AddNewStructuredTable({
  isOpen,
  onClose,
  returnIcon,
  item,
  getUpdateTables,
}: IModalAddNewStructuredTable) {
  const { handleSubmit, control, reset } = useForm<FormData>();

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<FormData> = async (body) => {
    try {
      if ((item as FormData).cod_tabela) {
        const { data } = await api.put(
          `/system/structured-table/${(item as FormData).cod_tabela}/${
            body.item_tabela
          }`,
          {
            item_tabela: body.item_tabela,
            descr_tabela: body.descr_tabela,
            ...(body.sigla_tabela && { sigla_tabela: body.sigla_tabela }),
            ...(body.cod_tabela_sup && { cod_tabela_sup: body.cod_tabela_sup }),
            ...(body.item_tabela_sup && {
              item_tabela_sup: body.item_tabela_sup,
            }),
          }
        );
        dispatch(setToastMessage({ type: "success", message: data.message }));
      } else {
        const { data } = await api.post("/system/structured-table", body);
        dispatch(setToastMessage({ type: "success", message: data.message }));
      }
      getUpdateTables();
      onClose();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  useEffect(() => {
    if ((item as FormData).cod_tabela) {
      reset(item as FormData);
    }
    return () => {
      reset({
        cod_tabela: null,
        item_tabela: null,
        descr_tabela: "",
        sigla_tabela: "",
        cod_tabela_sup: null,
        item_tabela_sup: null,
      });
    };
  }, [item]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        (item as FormData).cod_tabela
          ? "Editar Item Tabela"
          : "Adicionar Item Tabela"
      }
      returnIcon={returnIcon}
    >
      <S.ModalBody>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ width: "100%" }}
        >
          <Grid
            container
            spacing={1}
            style={{ width: "100%", marginTop: "1rem" }}
          >
            <Grid item xs={6} md={2}>
              <InputBase
                control={control}
                id="cod_tabela"
                label="Codigo"
                name="cod_tabela"
                type="number"
                required
                readOnly={(item as FormData).cod_tabela ? true : false}
              />
            </Grid>
            <Grid item xs={6} md={2}>
              <InputBase
                control={control}
                id="item_tabela"
                label="Item Tabela"
                name="item_tabela"
                type="number"
                required
              />
            </Grid>
            <Grid item xs={12} md={8}>
              <InputBase
                control={control}
                id="descr_tabela"
                label="Descrição"
                name="descr_tabela"
                required
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <InputBase
                control={control}
                id="sigla_tabela"
                label="Sigla"
                name="sigla_tabela"
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <InputBase
                control={control}
                id="cod_tabela_sup"
                label="Codigo Tabela Superior"
                type="number"
                name="cod_tabela_sup"
              />
            </Grid>
            <Grid item xs={4} md={4}>
              <InputBase
                control={control}
                id="item_tabela_sup"
                label="Codigo Item Superior"
                type="number"
                name="item_tabela_sup"
              />
            </Grid>
          </Grid>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <B.ButtonPrimary
              type="submit"
              style={{ width: "clamp(150px, 300px, 100%)" }}
            >
              {(item as FormData).cod_tabela ? "Salvar" : "Adicionar"}
            </B.ButtonPrimary>
          </div>
        </form>
      </S.ModalBody>
    </Modal>
  );
}
