/* eslint-disable @typescript-eslint/no-explicit-any */
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Divider, Grid, IconButton } from "@mui/material";
import { useState } from "react";
import { useParams } from "react-router-dom";

import { IAnexosSolicitadosProps } from "../../../pages/ManagementSolicitation";
import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import { ButtonPrimary } from "../../base/Buttons";
import { CardPrimery } from "../../base/Cards";
import { SwitchStyle } from "../../base/stylesGeneric";
import * as T from "../../base/Text";
import * as S from "../styles";

type AnexosSolicitadosProps = {
  isEdit: boolean;
  setAnexosSolicitantion: (anexos: IAnexosSolicitadosProps[]) => void;
  anexosSolicitantion: IAnexosSolicitadosProps[];
};

export function AnexosSolicitados({
  isEdit,
  anexosSolicitantion,
  setAnexosSolicitantion,
}: AnexosSolicitadosProps) {
  const dispatch = useAppDispatch();
  const idSolicitacao = Number(useParams().id);

  const [inputValue, setInput] = useState<IAnexosSolicitadosProps>({
    id_anexo_solicitado: "",
    id_solicitacao: 0,
    descricao: "",
    ind_obrigatorio: "N",
  });

  // adicona anexo com a uma descrição padrão direto em banco
  const addNewAnexo = async () => {
    const anexo = {
      descricao: "Novo anexo",
      ind_obrigatorio: "N",
    };

    const { data } = await api.post(
      `/solicitation/anexos/${idSolicitacao}`,
      anexo
    );

    const anexos = [...anexosSolicitantion, data.anexo];
    setAnexosSolicitantion(anexos);
  };

  const updateAnexo = async (data: IAnexosSolicitadosProps) => {
    try {
      await api.put(
        `/solicitation/anexos/${idSolicitacao}/${data.id_anexo_solicitado}`,
        data
      );
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  // Atualiza a descrição do anexo
  const handleSaveDescription = async (
    descricao: string,
    id: string | number
  ) => {
    const anexo = anexosSolicitantion.find(
      (anexo) => anexo.id_anexo_solicitado === id
    );
    if (!anexo) return;

    const data: IAnexosSolicitadosProps = {
      ...anexo,
      descricao,
    };
    await updateAnexo(data);
    const newDataAnexos = anexosSolicitantion.map((anexo) => {
      if (anexo.id_anexo_solicitado === id) {
        return data;
      }
      return anexo;
    });
    setAnexosSolicitantion(newDataAnexos);
    setInput({
      id_anexo_solicitado: "",
      id_solicitacao: idSolicitacao,
      descricao: "",
      ind_obrigatorio: "S",
    });
  };

  // Atualiza a obrigatoriedade do anexo
  const handleSwitch = async (id: string | number) => {
    const anexo = anexosSolicitantion.find(
      (anexo) => anexo.id_anexo_solicitado === id
    );
    if (!anexo) return;

    const data: IAnexosSolicitadosProps = {
      descricao: anexo.descricao,
      ind_obrigatorio: anexo.ind_obrigatorio === "S" ? "N" : "S",
      id_anexo_solicitado: anexo.id_anexo_solicitado,
      id_solicitacao: anexo.id_solicitacao,
    };
    await updateAnexo(data);
    const newDataAnexos = anexosSolicitantion.map((anexo) => {
      if (anexo.id_anexo_solicitado === id) {
        return data;
      }
      return anexo;
    });
    setAnexosSolicitantion(newDataAnexos);
  };

  // deleta anexo
  const handleDelete = async (id: string | number) => {
    await api.delete(`/solicitation/anexos/${idSolicitacao}/${id}`);

    const newDataAnexos = anexosSolicitantion.filter(
      (anexo) => anexo.id_anexo_solicitado !== id
    );
    setAnexosSolicitantion(newDataAnexos);
  };

  return (
    <CardPrimery container marginTop="1rem" padding="1.5rem 2rem">
      <Grid container marginBottom="1rem">
        <Grid item xs={12} md={10} display="flex" justifyContent="flex-end">
          <T.H5>
            Aqui você pode atualizar os anexos que serão solicitados para o
            usuario
          </T.H5>
        </Grid>
        {isEdit && (
          <Grid item xs={12} md={2} display="flex" justifyContent="flex-end">
            <ButtonPrimary onClick={() => addNewAnexo()}>
              <AddIcon style={{ margin: "0 0.5rem 0 0" }} />
              SOLICITAR ANEXO
            </ButtonPrimary>
          </Grid>
        )}
      </Grid>
      <S.BoxGrid>
        {Boolean(anexosSolicitantion.length) &&
          anexosSolicitantion.map((anexo) => (
            <S.BoxAnexos
              isEdit={isEdit}
              key={anexo.id_anexo_solicitado}
              checked={anexo.ind_obrigatorio === "S"}
            >
              <S.InputTextArea
                id={String(anexo.id_anexo_solicitado)}
                rows={4}
                readOnly={!isEdit}
                value={
                  inputValue.id_anexo_solicitado === anexo.id_anexo_solicitado
                    ? inputValue.descricao
                    : anexo.descricao
                }
                onChange={(e) => {
                  setInput({
                    id_anexo_solicitado: anexo.id_anexo_solicitado,
                    descricao: e.target.value,
                    id_solicitacao: idSolicitacao,
                    ind_obrigatorio: anexo.ind_obrigatorio,
                  });
                }}
                onBlur={(e) =>
                  handleSaveDescription(
                    e.target.value,
                    anexo.id_anexo_solicitado
                  )
                }
              />

              <S.BoxSwitchs>
                <Divider />
                <S.BoxLabelSwitch>
                  <T.Paragraph>Exluir</T.Paragraph>
                  <IconButton
                    style={{
                      padding: " 4px 8px",
                    }}
                    onClick={() => handleDelete(anexo.id_anexo_solicitado)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </S.BoxLabelSwitch>
                <Divider />
                <S.BoxLabelSwitch>
                  <T.Paragraph>Obrigatorio</T.Paragraph>
                  <SwitchStyle checked={anexo.ind_obrigatorio === "S"} />
                  <input
                    type="checkbox"
                    style={{
                      display: "none",
                    }}
                    checked={anexo.ind_obrigatorio === "S"}
                    onChange={() => handleSwitch(anexo.id_anexo_solicitado)}
                  />
                </S.BoxLabelSwitch>
                <Divider />
              </S.BoxSwitchs>
            </S.BoxAnexos>
          ))}
      </S.BoxGrid>
    </CardPrimery>
  );
}
