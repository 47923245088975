/* eslint-disable @typescript-eslint/ban-ts-comment */
/* eslint-disable no-restricted-syntax */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import {
  convertData,
  DOCUMENTOS,
  getIdURLUserSol,
  removeMask,
} from "../../../utils/utils";
import {
  validarCNH,
  validarCPF,
  validarPis,
  validarTituloEleitor,
} from "../../../utils/validators";
import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import { InputDate } from "../../Inputs/InputDate";
import { InputSelect } from "../../Inputs/InputSelect";
import { InputBase } from "../../Inputs/InputText";
import { LoadingIntoComponent } from "../../Loading";
import * as S from "../styles";

type DocsSolicitationProps = {
  isEditable: boolean;
  getUserSolicitation(): Promise<void>;
  isCompleted: boolean;
  isOpenCorrecion: boolean;
  isOnChangeSetListCorrecion: (etapa: string, campo: string) => boolean;
  onChangeSetListCorrecion: (
    etapa: string,
    campo: string,
    label: string
  ) => void;
  correctionValuesJsonData: any;
};

type FormDocuments = {
  id_solicitacao: number;
  ind_obrigatorio: "S" | "N";
  tipo_doc_ident_tab: number;
  tipo_doc_ident_item: number;
  descr_tabela: string;
  sigla_tabela?: string;
  id_sol_dados_doc_ident?: number;
  id_sol_usuario?: number;
  numero_doc?: string;
  orgao_expedidor?: string;
  estado_item?: string;
  secao?: string;
  zona?: string;
  serie?: string;
  dt_emissao?: string;
  dt_inclusao?: string;
  hr_inclusao?: string;
};

export function DocsSolicitation({
  isEditable,
  getUserSolicitation,
  isCompleted,
  isOpenCorrecion,
  isOnChangeSetListCorrecion,
  onChangeSetListCorrecion,
  correctionValuesJsonData,
}: DocsSolicitationProps) {
  const { handleSubmit, control, getValues, reset, setError, clearErrors } =
    useForm<any>();

  const [formDocuments, setFormDocuments] = useState<FormDocuments[]>([]);

  const dispatch = useAppDispatch();

  const { TableStructure } = useAppSelector((state) => state);
  const { estados } = TableStructure;

  const formatDataNameForm = (data: FormDocuments[]) => {
    const newData = {} as any;
    for (const item of data) {
      newData[`${item.tipo_doc_ident_item}-numero_doc`] = item.numero_doc;
      newData[`${item.tipo_doc_ident_item}-orgao_expedidor`] =
        item.orgao_expedidor;
      newData[`${item.tipo_doc_ident_item}-dt_emissao`] = item.dt_emissao;
      newData[`${item.tipo_doc_ident_item}-estado_item`] = item.estado_item;
      newData[`${item.tipo_doc_ident_item}-secao`] = item.secao;
      newData[`${item.tipo_doc_ident_item}-zona`] = item.zona;
      newData[`${item.tipo_doc_ident_item}-serie`] = item.serie;
    }
    setTimeout(() => {
      reset(newData);
    }, 100);
  };

  const getDocumnets = async (values?: any) => {
    const idSolUser = getIdURLUserSol();
    const data =
      values ||
      (await api.get(`/solicitation/form/documents/${idSolUser}`)).data;

    formatDataNameForm(data);
    setFormDocuments(data);
  };

  const clearData = (body: any) => {
    const newObject = formDocuments.map(
      ({
        tipo_doc_ident_item,
        id_solicitacao,
        tipo_doc_ident_tab,
        id_sol_dados_doc_ident,
        id_sol_usuario,
      }) => {
        return {
          tipo_doc_ident_item,
          id_solicitacao,
          tipo_doc_ident_tab,
          id_sol_dados_doc_ident,
          id_sol_usuario,
          numero_doc: removeMask(body[`${tipo_doc_ident_item}-numero_doc`]),
          orgao_expedidor: body[`${tipo_doc_ident_item}-orgao_expedidor`],
          estado_item: body[`${tipo_doc_ident_item}-estado_item`],
          dt_emissao: convertData(body[`${tipo_doc_ident_item}-dt_emissao`]),
          secao: body[`${tipo_doc_ident_item}-secao`],
          zona: body[`${tipo_doc_ident_item}-zona`],
          serie: body[`${tipo_doc_ident_item}-serie`],
        };
      }
    );
    return newObject.filter((item) => item.numero_doc);
  };

  const onSubmit = async (body: any) => {
    try {
      const idSolUser = getIdURLUserSol();
      const newObject = clearData(body);

      await api.post(`/solicitation/form/documents/${idSolUser}`, {
        commited: "S",
        documents: newObject,
      });
      getUserSolicitation();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const saveData = async () => {
    try {
      const idSolUser = getIdURLUserSol();
      const newObject = clearData(getValues());

      const { data } = await api.post(
        `/solicitation/form/documents/${idSolUser}`,
        {
          commited: "N",
          documents: newObject,
        }
      );
      dispatch(setToastMessage({ type: "success", message: data.message }));
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  const objectValidation = {
    cpf: (calor: string) => validarCPF(calor),
    titulo: (calor: string) => validarTituloEleitor(calor),
    cnh: (calor: string) => validarCNH(calor),
    pis: (calor: string) => validarPis(calor),
  };

  const isValidateCampos = (
    campoName: string,
    campoObejto: any,
    valor: string,
    message: string
  ) => {
    // @ts-ignore
    const isValid = objectValidation[campoObejto](valor);
    if (!isValid) {
      setError(campoName, { type: "manual", message });
    } else {
      clearErrors(campoName);
    }
  };
  useEffect(() => {
    if (formDocuments.length === 0) {
      getDocumnets();
    }
  }, []);

  // fazer fazer o validor de RG e Carteira de Trabalho
  // Tambem tem que adiciona uma regra que não deixa enviar se os inputs estiverem com erro, pois hoje ele envia mesmo com erro

  return (
    <Grid container spacing={1} padding="1rem">
      <form
        onSubmit={handleSubmit(onSubmit)}
        noValidate
        style={{ width: "100%", height: "100%" }}
      >
        {formDocuments.length ? (
          formDocuments.map(
            ({
              tipo_doc_ident_item,
              descr_tabela,
              ind_obrigatorio,
              sigla_tabela,
            }) =>
              tipo_doc_ident_item !== 0 && (
                <S.ContainerListItensCorrecion
                  key={tipo_doc_ident_item}
                  container
                  spacing={1}
                  style={{ width: "100%" }}
                  isEditable={isEditable}
                  isOpenCorrecion={isOpenCorrecion}
                  isSelected={isOnChangeSetListCorrecion(
                    DOCUMENTOS,
                    String(tipo_doc_ident_item)
                  )}
                  onClick={() =>
                    onChangeSetListCorrecion(
                      DOCUMENTOS,
                      String(tipo_doc_ident_item),
                      descr_tabela
                    )
                  }
                >
                  <S.IconInputCorrecion
                    isIcon={
                      correctionValuesJsonData[
                        `${DOCUMENTOS}-${String(tipo_doc_ident_item)}`
                      ]
                    }
                    message={
                      correctionValuesJsonData[
                        `${DOCUMENTOS}-${String(tipo_doc_ident_item)}`
                      ]?.message
                    }
                  />
                  <Grid item xs={12} md={12}>
                    <T.H6>
                      {descr_tabela}
                      {ind_obrigatorio === "S" && (
                        <T.Span color="#f00" size="1rem" margin="0 0.2rem">
                          *
                        </T.Span>
                      )}
                    </T.H6>
                  </Grid>
                  {tipo_doc_ident_item === 1 && ( // RG
                    <>
                      <Grid item xs={12} md={3}>
                        <InputBase
                          control={control}
                          label={`Numero ${sigla_tabela || "documento"}`}
                          id={`${tipo_doc_ident_item}-numero_doc`}
                          name={`${tipo_doc_ident_item}-numero_doc`}
                          readOnly={!isEditable}
                          required={ind_obrigatorio === "S"}
                          type="number"
                          maxLength={12}
                          rules={{
                            maxLength: {
                              value: 12,
                              message: "RG deve ter no máximo 12 dígitos",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputDate
                          control={control}
                          label="Expedição"
                          id={`${tipo_doc_ident_item}-dt_emissao`}
                          name={`${tipo_doc_ident_item}-dt_emissao`}
                          readOnly={!isEditable}
                          required={ind_obrigatorio === "S"}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputBase
                          control={control}
                          label="Orgao expedidor"
                          id={`${tipo_doc_ident_item}-orgao_expedidor`}
                          name={`${tipo_doc_ident_item}-orgao_expedidor`}
                          readOnly={!isEditable}
                          required={ind_obrigatorio === "S"}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputSelect
                          title="Estado"
                          control={control}
                          id={`${tipo_doc_ident_item}-estado_item`}
                          label="Estado"
                          name={`${tipo_doc_ident_item}-estado_item`}
                          readOnly={!isEditable}
                          required={ind_obrigatorio === "S"}
                        >
                          <option> </option>
                          {estados.itemsTable?.length &&
                            estados.itemsTable.map((estado) => {
                              if (estado.item_tabela === 0) return null;
                              return (
                                <option
                                  key={Math.random()}
                                  value={estado.item_tabela}
                                >
                                  {estado.descr_tabela}
                                </option>
                              );
                            })}
                        </InputSelect>
                      </Grid>
                    </>
                  )}
                  {tipo_doc_ident_item === 2 && ( // CPF
                    <Grid item xs={12} md={3}>
                      <InputBase
                        control={control}
                        label={`Numero ${sigla_tabela || "documento"}`}
                        id={`${tipo_doc_ident_item}-numero_doc`}
                        name={`${tipo_doc_ident_item}-numero_doc`}
                        type="cpf"
                        readOnly={!isEditable}
                        required={ind_obrigatorio === "S"}
                        rules={{
                          maxLength: {
                            value: 14,
                            message: "CPF deve ter no máximo 11 dígitos",
                          },
                        }}
                        onBlur={({ target }) => {
                          const cpf = target.value
                            ? removeMask(target.value)
                            : "";
                          if (cpf && cpf.length === 11) {
                            isValidateCampos(
                              `${tipo_doc_ident_item}-numero_doc`,
                              "cpf",
                              cpf,
                              "CPF inválido"
                            );
                          }
                        }}
                      />
                    </Grid>
                  )}
                  {tipo_doc_ident_item === 3 && ( // TITULO DE ELEITOR
                    <>
                      <Grid item xs={12} md={3}>
                        <InputBase
                          control={control}
                          label={`Numero ${sigla_tabela || "documento"}`}
                          id={`${tipo_doc_ident_item}-numero_doc`}
                          name={`${tipo_doc_ident_item}-numero_doc`}
                          readOnly={!isEditable}
                          required={ind_obrigatorio === "S"}
                          type="titulo"
                          rules={{
                            maxLength: {
                              value: 14,
                              message: "Titulo deve ter no máximo 12 dígitos",
                            },
                          }}
                          onBlur={({ target }) => {
                            const titulo = target.value
                              ? removeMask(target.value)
                              : "";
                            if (titulo && titulo.length === 12) {
                              isValidateCampos(
                                `${tipo_doc_ident_item}-numero_doc`,
                                "titulo",
                                titulo,
                                "Titulo inválido"
                              );
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputBase
                          control={control}
                          label="Zona"
                          id={`${tipo_doc_ident_item}-zona`}
                          name={`${tipo_doc_ident_item}-zona`}
                          readOnly={!isEditable}
                          required={ind_obrigatorio === "S"}
                          type="number"
                          maxLength={3}
                          rules={{
                            maxLength: {
                              value: 3,
                              message: "Zona deve ter no máximo 3 dígitos",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputBase
                          control={control}
                          label="Seção"
                          id={`${tipo_doc_ident_item}-secao`}
                          name={`${tipo_doc_ident_item}-secao`}
                          readOnly={!isEditable}
                          required={ind_obrigatorio === "S"}
                          type="number"
                          maxLength={4}
                          rules={{
                            maxLength: {
                              value: 4,
                              message: "Seção deve ter no máximo 4 dígitos",
                            },
                          }}
                        />
                      </Grid>
                    </>
                  )}
                  {tipo_doc_ident_item === 4 && ( // Carteira Nacional de Habilitação
                    <>
                      <Grid item xs={12} md={3}>
                        <InputBase
                          control={control}
                          label={`Numero ${sigla_tabela || "documento"}`}
                          id={`${tipo_doc_ident_item}-numero_doc`}
                          name={`${tipo_doc_ident_item}-numero_doc`}
                          type="cnh"
                          readOnly={!isEditable}
                          required={ind_obrigatorio === "S"}
                          rules={{
                            maxLength: {
                              value: 12,
                              message: "CNH deve ter no máximo 11 dígitos",
                            },
                          }}
                          onBlur={({ target }) => {
                            const cnh = target.value
                              ? removeMask(target.value)
                              : "";
                            if (cnh && cnh.length === 11) {
                              isValidateCampos(
                                `${tipo_doc_ident_item}-numero_doc`,
                                "cnh",
                                cnh,
                                "CNH inválida"
                              );
                            }
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputBase
                          control={control}
                          label="Orgao expedidor"
                          id={`${tipo_doc_ident_item}-orgao_expedidor`}
                          name={`${tipo_doc_ident_item}-orgao_expedidor`}
                          readOnly={!isEditable}
                          required={ind_obrigatorio === "S"}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputDate
                          control={control}
                          label="Data de emissao"
                          id={`${tipo_doc_ident_item}-dt_emissao`}
                          name={`${tipo_doc_ident_item}-dt_emissao`}
                          readOnly={!isEditable}
                          required={ind_obrigatorio === "S"}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputSelect
                          title="Estado"
                          control={control}
                          id={`${tipo_doc_ident_item}-estado_item`}
                          label="Estado"
                          name={`${tipo_doc_ident_item}-estado_item`}
                          readOnly={!isEditable}
                          required={ind_obrigatorio === "S"}
                        >
                          <option> </option>
                          {estados.itemsTable?.length &&
                            estados.itemsTable.map((estado) => {
                              if (estado.item_tabela === 0) return null;
                              return (
                                <option
                                  key={Math.random()}
                                  value={estado.item_tabela}
                                >
                                  {estado.descr_tabela}
                                </option>
                              );
                            })}
                        </InputSelect>
                      </Grid>
                    </>
                  )}
                  {tipo_doc_ident_item === 5 && ( // CARTEIRA DE TRABALHO
                    <>
                      <Grid item xs={12} md={3}>
                        <InputBase
                          control={control}
                          label={`Numero ${sigla_tabela || "documento"}`}
                          id={`${tipo_doc_ident_item}-numero_doc`}
                          name={`${tipo_doc_ident_item}-numero_doc`}
                          type="number"
                          readOnly={!isEditable}
                          required={ind_obrigatorio === "S"}
                          rules={{
                            maxLength: {
                              value: 8,
                              message: "Numero deve ter no máximo 8 dígitos",
                            },
                          }}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputBase
                          control={control}
                          label="Serie"
                          id={`${tipo_doc_ident_item}-serie`}
                          name={`${tipo_doc_ident_item}-serie`}
                          readOnly={!isEditable}
                          required={ind_obrigatorio === "S"}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputDate
                          control={control}
                          label="Data de emissao"
                          id={`${tipo_doc_ident_item}-dt_emissao`}
                          name={`${tipo_doc_ident_item}-dt_emissao`}
                          readOnly={!isEditable}
                          required={ind_obrigatorio === "S"}
                        />
                      </Grid>
                      <Grid item xs={12} md={3}>
                        <InputSelect
                          title="Estado"
                          control={control}
                          id={`${tipo_doc_ident_item}-estado_item`}
                          label="Estado"
                          name={`${tipo_doc_ident_item}-estado_item`}
                          readOnly={!isEditable}
                          required={ind_obrigatorio === "S"}
                        >
                          <option> </option>
                          {estados.itemsTable?.length &&
                            estados.itemsTable.map((estado) => {
                              if (estado.item_tabela === 0) return null;
                              return (
                                <option
                                  key={Math.random()}
                                  value={estado.item_tabela}
                                >
                                  {estado.descr_tabela}
                                </option>
                              );
                            })}
                        </InputSelect>
                      </Grid>
                    </>
                  )}
                  {tipo_doc_ident_item === 6 && ( // PIS/PASEP
                    <Grid item xs={12} md={3}>
                      <InputBase
                        control={control}
                        label={`Numero ${sigla_tabela || "documento"}`}
                        id={`${tipo_doc_ident_item}-numero_doc`}
                        name={`${tipo_doc_ident_item}-numero_doc`}
                        readOnly={!isEditable}
                        required={ind_obrigatorio === "S"}
                        type="pisPasep"
                        rules={{
                          maxLength: {
                            value: 13,
                            message: "Pis/Pasep deve ter no máximo 11 dígitos",
                          },
                        }}
                        onBlur={({ target }) => {
                          const pis = target.value
                            ? removeMask(target.value)
                            : "";
                          if (pis && pis.length === 11) {
                            isValidateCampos(
                              `${tipo_doc_ident_item}-numero_doc`,
                              "pis",
                              pis,
                              "Pis/Pasep inválido"
                            );
                          }
                        }}
                      />
                    </Grid>
                  )}
                  {tipo_doc_ident_item === 7 && ( // NUMERO DO CONSELHO PROFISSIONAL
                    <Grid item xs={12} md={3}>
                      <InputBase
                        control={control}
                        label={descr_tabela}
                        id={`${tipo_doc_ident_item}-numero_doc`}
                        name={`${tipo_doc_ident_item}-numero_doc`}
                        readOnly={!isEditable}
                        required={ind_obrigatorio === "S"}
                        type="number"
                      />
                    </Grid>
                  )}
                </S.ContainerListItensCorrecion>
              )
          )
        ) : (
          <LoadingIntoComponent />
        )}
        {isEditable && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              gap: "1rem",
            }}
          >
            {!isCompleted && (
              <B.ButtonSecondary variant="outlined" onClick={() => saveData()}>
                Salvar
              </B.ButtonSecondary>
            )}
            <B.ButtonPrimary type="submit">
              {isCompleted ? "Atualizar" : "CONCLUIR ETAPA"}
            </B.ButtonPrimary>
          </div>
        )}
      </form>
    </Grid>
  );
}
