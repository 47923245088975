import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Grid,
} from "@mui/material";
import styled, { css } from "styled-components";

type ProgressBarProps = {
  percent: number;
};

type AccordionConteinerProps = {
  fist?: boolean;
  last?: boolean;
  active?: boolean;
  desabled?: boolean;
  isUserSolicitation?: boolean;
};

export const BoxDetails = styled(Grid)`
  ${({ theme }) => css`
    display: flex;
    justify-content: center;
    align-items: center;
    width: min-content;
    height: max-content;
    border-radius: 0.25rem;
    white-space: nowrap;
    text-transform: uppercase;
    border: 1px solid ${theme.palette.primary.light};
    background-color: ${theme.background.blueLight};
    fill-opacity: 0.8;
    padding: 0.2rem 0 0.8rem 0;

    p,
    span {
      font-size: 0.875rem;
      color: ${theme.text.primary};
    }
  `}
`;

export const BoxProgressBar = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const ProgressBarHeader = styled.div`
  ${({ theme }) => css`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.5rem;

    p {
      font-weight: bold;
      font-size: 0.775rem;
      color: ${theme.text.primary};
    }
  `}
`;

export const ProgressBody = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 0.5rem;
    background-color: ${theme.border.light};
    border-radius: 0.625rem;
    position: relative;
    cursor: pointer;
  `}
`;

export const ProgressBar = styled.div<ProgressBarProps>`
  ${({ percent }) => css`
    width: ${percent}%;
    height: 100%;
    transition: width 0.5s ease-in-out;
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 0.625rem;
    background-image: linear-gradient(
      136deg,
      rgb(97, 195, 240) 0%,
      rgb(55, 137, 175) 50%,
      rgb(0, 70, 103) 100%
    );
  `}
`;

export const AccordionConteiner = styled.div<AccordionConteinerProps>`
  ${({ theme, fist, last, desabled, isUserSolicitation }) => css`
    width: 100%;
    position: relative;

    &::before {
      content: "";
      display: block;
      position: absolute;
      width: 1px;
      height: 100%;
      background-color: ${isUserSolicitation
        ? theme.border.light
        : "transparent"};
      left: -2.5rem;
      z-index: 0;
      top: ${fist && "40px"};
      ${last &&
      css`
        top: 0;
        height: 2.5rem;
      `}
      @media screen {
        @media (max-width: 768px) {
          left: -1rem;
        }
      }
    }
    ${desabled &&
    css`
      &::after {
        content: "";
        display: block;
        position: absolute;
        z-index: 999;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: transparent;
        cursor: not-allowed;
      }
    `}
  `}
`;

export const DividerAccordion = styled.div<AccordionConteinerProps>`
  ${({ theme, isUserSolicitation }) => css`
    width: 1px;
    height: 1.5rem;
    margin-left: -2.5rem;
    z-index: 0;
    background-color: ${isUserSolicitation
      ? theme.border.light
      : "transparent"};

    @media screen {
      @media (max-width: 768px) {
        margin-left: -1rem;
      }
    }
  `}
`;

export const AccordionContent = styled(Accordion)`
  ${() => css`
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 3px 0px rgb(0 0 0 / 20%),
      0px 1px 1px 0px rgb(0 0 0 / 14%), 0px 2px 1px -1px rgb(0 0 0 / 12%);
    position: relative;
    z-index: 1;
  `}
`;

export const AccordionHeaderContaniner = styled(AccordionSummary)`
  ${() => css`
    padding-left: 2rem;
    height: 5.4375rem;
  `}
`;

export const StepperPointer = styled.div<AccordionConteinerProps>`
  ${({ theme, desabled }) => css`
    position: absolute;
    left: -3.05rem;
    font-size: 0.8rem;
    color: ${theme.text.with};
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: ${desabled
      ? theme.border.light
      : theme.palette.primary.light};
    fill-opacity: 0.8;
    pointer-events: ${desabled ? "none" : "auto"};

    @media screen {
      @media (max-width: 768px) {
        left: -1.8rem;
      }
    }
  `}
`;

export const AccordionHeaderContent = styled.div`
  ${() => css`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 0.775rem;
  `}
`;

export const AccordionBody = styled(AccordionDetails)`
  ${() => css`
    padding: 16px 8px !important;
  `}
`;

export const BackDrop = styled.div`
  ${() => css`
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
  `}
`;

export const BackDropContent = styled.div`
  ${() => css`
    top: 0;
    right: 0;
    width: 100%;
    border-top: 5px solid rgb(255, 139, 56);
    position: fixed;
    z-index: 5;

    & > div {
      width: min-content;
      background-color: rgb(255, 139, 56);
      border-bottom-left-radius: 100px;
      border-bottom-right-radius: 100px;
      margin: 0 auto;
      padding: 0 3rem 0.8rem 3rem;

      & > p {
        color: #fff;
        white-space: nowrap;
        margin: 0;
      }

      & > div {
        display: flex;
        justify-content: center;
        align-items: center;
        color: #006494;
        font-size: 0.875rem;
        font-weight: bold;
        cursor: pointer;

        :hover {
          color: #fff;
        }
      }
    }
  `}
`;

export const ButtonHistoric = styled.div`
  ${() => css`
    position: fixed;
    top: 300px;
    right: 0;
    width: 42px;
    height: 42px;
    z-index: 0;

    @media screen {
      @media (max-width: 768px) {
        z-index: 2;
        /* display: none; */
      }
    }
  `}
`;

export const ButtonHistoricContent = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;

    background-color: ${theme.palette.primary.light};
    border-radius: 8px 0 0 8px;
    cursor: pointer;
    position: relative;

    & > div {
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      width: 230px;
      height: 42px;
      text-align: center;
      font-size: 1rem;
      font-weight: bold;
      white-space: nowrap;

      border-radius: 8px 0px 0px 0px;

      background-color: ${theme.palette.primary.light};
      top: 321%;
      transform: rotate(-90deg);
      visibility: hidden;
      opacity: 0;
      transition: visibility 0s, opacity 0.5s;
      border-right: 1px solid #fff;

      :hover {
        visibility: visible;
        opacity: 1;
      }
    }

    &:hover {
      & > div {
        visibility: visible;
        opacity: 1;
      }
    }
  `}
`;
