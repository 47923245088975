/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

import { api } from "../../../../services/api";
import { setUserAction } from "../../../../store/ducks/User/actions";
import { setToastMessage } from "../../../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { copyToClipboard } from "../../../../utils/utils";
import { CardPrimery } from "../../../base/Cards";
import * as T from "../../../base/Text";

export function ApiInfo() {
  const [tokenApi, setTokenApi] = useState("");
  const dispatch = useAppDispatch();

  const { user } = useAppSelector((state) => state.User);

  const generateToken = async () => {
    try {
      const { data } = await api.get("/user/generate/token-api");
      dispatch(
        setUserAction({
          ...user,
          token_api: data.token,
        })
      );
      setTokenApi(data.token);
      dispatch(setToastMessage({ type: "success", message: data?.message }));
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  useEffect(() => {
    setTokenApi(user.token_api!);
  }, [user]);

  return (
    <CardPrimery container spacing={1}>
      <Grid item xs={12}>
        <T.H6>Informações da API</T.H6>
      </Grid>
      <Grid item xs={12} display="inline-flex" justifyContent="space-between">
        <T.Paragraph>
          URL API:{" "}
          <T.Span color="primary">{process.env.REACT_APP_BASE_URL_API}</T.Span>
        </T.Paragraph>
        <div>
          <Tooltip title="Copiar URL">
            <IconButton
              onClick={() =>
                copyToClipboard(process.env.REACT_APP_BASE_URL_API!)
              }
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
      <Grid item xs={12} display="inline-flex" justifyContent="space-between">
        <T.Paragraph>
          DOCUMENTAÇÃO API:{" "}
          <T.Span color="primary">
            {process.env.REACT_APP_BASE_URL_API}api-docs
          </T.Span>
        </T.Paragraph>
        <div>
          <Tooltip title="Copiar URL">
            <IconButton
              onClick={() =>
                copyToClipboard(`${process.env.REACT_APP_BASE_URL_API}api-docs`)
              }
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
      <Grid
        item
        xs={12}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <div
          style={{
            width: "250px",
            flex: "1",
          }}
        >
          <T.Paragraph
            style={{
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
              gap: "5px",
            }}
          >
            TOKEN API:
            <T.Span
              color="primary"
              style={{
                display: "inline-block",
                width: "inherit",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {tokenApi}
            </T.Span>
          </T.Paragraph>
        </div>
        <div>
          <Tooltip title="Gerar novo Token">
            <IconButton onClick={() => generateToken()}>
              <AutorenewIcon />
            </IconButton>
          </Tooltip>
          <Tooltip title="Copiar">
            <IconButton onClick={() => copyToClipboard(tokenApi)}>
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>
        </div>
      </Grid>
    </CardPrimery>
  );
}
