/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import AddIcon from "@mui/icons-material/Add";
import { Grid } from "@mui/material";
import { useLayoutEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import {
  getClientsArrayAction,
  getClientToUdateAction,
} from "../../store/ducks/Clients/actions";
import { setLoading, setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getClientUrl } from "../../utils/utils";
import { CardPrimery } from "../base/Cards";
import * as T from "../base/Text";
import { InputSelect } from "../Inputs/InputSelect";

type ClientProps = {
  clientSigla: string;
};

export function BoxListClients({ setIsFormNewClient }: any) {
  const { handleSubmit, control, reset, getValues } = useForm<ClientProps>();

  const dispatch = useAppDispatch();

  const { clients } = useAppSelector(({ Clients }) => Clients);

  const onSubmit: SubmitHandler<ClientProps> = async (body) => {
    try {
      dispatch(setLoading(true));
      dispatch(getClientToUdateAction(body.clientSigla));
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  useLayoutEffect(() => {
    dispatch(getClientsArrayAction());
    reset({
      clientSigla: clients.find((client) => client.sigla === getClientUrl())
        ?.sigla,
    });
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      style={{ width: "100%", marginBottom: "1.5rem" }}
    >
      <CardPrimery container spacing={1}>
        <Grid
          item
          xs={12}
          onClick={() => {
            setIsFormNewClient(true);
          }}
          style={{ display: "flex", cursor: "pointer" }}
        >
          <T.H6>Clientes</T.H6>
          <div style={{ padding: "0 1rem" }}>
            <AddIcon />
          </div>
        </Grid>
        <Grid item xs={12}>
          <InputSelect
            title="Clientes"
            control={control}
            id="clientSigla"
            label="Clientes"
            name="clientSigla"
            defaultValue={getClientUrl()}
            onClick={() => {
              setIsFormNewClient(false);
              onSubmit(getValues());
            }}
          >
            <option> </option>
            {clients.map((client) => (
              <option key={client.id_cliente} value={client.sigla}>
                {client.nome_cliente}
              </option>
            ))}
          </InputSelect>
        </Grid>
      </CardPrimery>
    </form>
  );
}
