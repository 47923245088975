// perfils
// 1 - usuario
// 2 - Analistas
// 3 - Administradores
// 4 - SuperAdimistradores( Desenvolvedores )
// 5 - Operadores ( Atendentes - AVMB )

import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ConstructionIcon from "@mui/icons-material/Construction";
import ContactMailIcon from "@mui/icons-material/ContactMail";
import FeedIcon from "@mui/icons-material/Feed";
import GradingIcon from "@mui/icons-material/Grading";
import GroupIcon from "@mui/icons-material/Group";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import QuizIcon from "@mui/icons-material/Quiz";
import SettingsSystemDaydreamIcon from "@mui/icons-material/SettingsSystemDaydream";

import { logout } from "../../services/auth";
import { getClientUrl } from "../../utils/utils";

const client = getClientUrl();

type ICondiconaisMenu = {
  ind_agendamento?: "S" | "N";
};

export const MenuList = (
  perfilUsuario: number,
  condicionais?: ICondiconaisMenu
) => {
  const menu = [
    {
      id: "1",
      name: "Home",
      icon: <HomeIcon />,
      path: `/${client}/home`,
      perfil: [1, 2, 3, 4, 5],
    },
    {
      id: "2",
      name: "Administração",
      icon: <AdminPanelSettingsIcon />,
      path: `/${client}/administracao`,
      perfil: [3, 4],
    },
    {
      id: "3",
      name: "Perfil",
      icon: <AccountCircleIcon />,
      path: `/${client}/perfil`,
      perfil: [1, 2, 3, 4, 5],
    },
    {
      id: crypto.randomUUID(),
      name: "FAQ",
      icon: <QuizIcon />,
      path: `/${client}/faq`,
      perfil: [1, 2, 3, 4, 5],
    },
    {
      id: crypto.randomUUID(),
      name: "Agendamentos",
      icon: <CalendarMonthIcon />,
      path: `/${client}/agendamentos`,
      perfil: [1, 4, 5],
      condicoes: {
        ind_agendamento: "S",
      },
    },
    {
      id: crypto.randomUUID(),
      name: "Usuários",
      icon: <GroupIcon />,
      path: `/${client}/usuarios`,
      perfil: [2, 4],
    },
    {
      id: "4",
      name: "Cria Solicitação",
      icon: <ConstructionIcon />,
      path: `/${client}/admin/cria-solicitacao`,
      perfil: [2, 3, 4],
    },
    {
      id: "5",
      name: "Solicitações",
      icon: <ContactMailIcon />,
      path: `/${client}/admin/solicitacoes`,
      perfil: [2, 3, 4, 5],
    },
    {
      id: "6",
      name: "Em Andamento",
      icon: <FeedIcon />,
      path: `/${client}/em-andamento`,
      perfil: [2, 3, 4, 5],
    },
    {
      id: "7",
      name: "Revisadas",
      icon: <GradingIcon />,
      path: `/${client}/revisadas`,
      perfil: [2, 3, 4],
    },
    {
      id: "8",
      name: "Parametros do Sistema",
      icon: <SettingsSystemDaydreamIcon />,
      path: `/${client}/parametros-sistema`,
      perfil: [4],
    },
    {
      id: "9",
      name: "Sair",
      icon: <LogoutIcon />,
      path: "",
      action: () => {
        logout();
      },
      perfil: [1, 2, 3, 4, 5],
    },
  ];

  const menuFiltrado = menu.filter((item) => {
    if (!item.perfil.includes(perfilUsuario)) return null;

    if (item.condicoes) {
      const condicoes = Object.entries(item.condicoes);

      const condicoesFiltradas = condicoes.filter((condicao) => {
        if (condicionais) {
          return (
            condicionais[condicao[0] as keyof ICondiconaisMenu] === condicao[1]
          );
        }
        return false;
      });

      return condicoesFiltradas.length === condicoes.length;
    }
    return item;
  });

  return menuFiltrado;
};
