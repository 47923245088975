import { action } from "typesafe-actions";

import { GraphicsTypes, DataGraphicPizza, DataGraphicBar } from "./types";

export const getGraphicPizzaAction = (idSolicitation: number) =>
  action(GraphicsTypes.GET_GRAPHIC_PIZZA, idSolicitation);

export const setGraphicPizzaAction = (data: DataGraphicPizza[]) =>
  action(GraphicsTypes.SET_GRAPHIC_PIZZA, data);

export const getGraphicBarAction = (idSolicitation: number) =>
  action(GraphicsTypes.GET_GRAPHIC_BAR, idSolicitation);

export const setGraphicBarAction = (data: DataGraphicBar[]) =>
  action(GraphicsTypes.SET_GRAPHIC_BAR, data);
