/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/require-default-props */

import { FormControl, FormHelperText, InputLabel, Select } from "@mui/material";
import { ReactNode } from "react";
import { Controller } from "react-hook-form";

interface IInputSelect {
  control: any;
  title: string;
  label: string;
  id: string;
  name: string;
  defaultValue?: string | number;
  errorMessage?: string;
  helperText?: string;
  required?: boolean;
  disabled?: boolean;
  readOnly?: boolean;
  onClick?: (e?: any) => void;
  children: ReactNode;
  isErrorMessage?: boolean;
}

export function InputSelect({
  control,
  name,
  id,
  label,
  title,
  defaultValue,
  disabled,
  readOnly,
  required,
  errorMessage,
  onClick,
  children,
  helperText,
  isErrorMessage,
}: IInputSelect) {
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={({
        field: { onChange, value, onBlur },
        fieldState: { error },
      }) => {
        return (
          <FormControl
            required={required}
            disabled={disabled || readOnly}
            variant="outlined"
            size="small"
            error={!!error?.message || !!errorMessage}
            fullWidth
          >
            <InputLabel htmlFor={id}>{title}</InputLabel>
            <Select
              native
              value={value || ""}
              label={label}
              onChange={onChange}
              onBlur={onBlur}
              labelId={id}
              id={id}
              required={required}
              readOnly={readOnly}
              onClick={onClick}
              inputProps={{
                name,
                "aria-label": label,
              }}
            >
              {children}
            </Select>
            <FormHelperText
              style={{ margin: "2px 0 0 2px" }}
              error={!!error?.message || !!errorMessage}
            >
              {isErrorMessage === false
                ? ""
                : error?.message || errorMessage || helperText || " "}
            </FormHelperText>
          </FormControl>
        );
      }}
      rules={{
        required: {
          value: !!required,
          message: `${title} é obrigatório`,
        },
      }}
    />
  );
}
