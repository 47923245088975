/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import AnalyticsIcon from "@mui/icons-material/Analytics";
import { TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import Modal from "../index";
import * as S from "./styles";

interface IModalSubmitAnalysisSolicitation {
  isOpen: boolean;
  onClose: () => void;
  getSolicitation: () => Promise<void>;
  idSolicitation: number;
  correctionValuesJsonData: any;
}

export function SubmitAnalysisSolicitation({
  isOpen,
  onClose,
  idSolicitation,
  getSolicitation,
  correctionValuesJsonData,
}: IModalSubmitAnalysisSolicitation) {
  const { handleSubmit, control, getValues } = useForm();
  const dispatch = useAppDispatch();

  const onSubmit = async (body: any) => {
    try {
      const { data: response } = await api.post(
        `/solicitation/analysis/${idSolicitation}`,
        {
          correcion: correctionValuesJsonData,
          msg_despacho: body.msg_despacho,
        }
      );

      dispatch(setToastMessage({ type: "success", message: response.message }));
      await getSolicitation();
      onClose();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <S.ModalBody onSubmit={handleSubmit(onSubmit)} noValidate>
        <S.Header>
          <AnalyticsIcon fontSize="large" color="warning" />
          <T.H3 color="#ed6c02" margin="0 0 0 1rem">
            ENVIA PARA ANÁLISE
          </T.H3>
        </S.Header>
        <T.Paragraph margin="0 0 1rem 0">
          Voce pode manda uma observação para o analista para que ele possa
          analisar a sua solicitação.
        </T.Paragraph>
        <Controller
          name="msg_despacho"
          control={control}
          render={({ field: { onChange, value } }) => {
            return (
              <TextField
                id="msg_despacho"
                label="Observação"
                size="small"
                multiline
                rows={2}
                variant="outlined"
                value={value}
                onChange={onChange}
                style={{ width: "100%" }}
              />
            );
          }}
        />
        <S.Footer>
          <B.ButtonSecondary onClick={onClose}>Cancelar</B.ButtonSecondary>
          <B.ButtonPrimary
            onClick={async (e: any) => {
              e.target.disabled = true;
              await onSubmit(getValues());
              e.target.disabled = false;
            }}
          >
            Enviar
          </B.ButtonPrimary>
        </S.Footer>
      </S.ModalBody>
    </Modal>
  );
}
