import { useLayoutEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  setdDeviceAction,
  toogleMenuAction,
} from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { MenuDesktop } from "./MenuDesktop";
import { MenuMobile } from "./MenuMobile";

export function Menu({ active }: { active: string }) {
  const {
    menu: { isOpen },
    device,
  } = useAppSelector(({ Utils }) => Utils);

  const dispatch = useAppDispatch();
  const navegate = useNavigate();

  const toogleMenu = () =>
    dispatch(toogleMenuAction({ isOpen: !isOpen, active }));

  useLayoutEffect(() => {
    const onResize = () => {
      if (window.innerWidth < 768) {
        dispatch(setdDeviceAction("mobile"));
      } else {
        dispatch(setdDeviceAction("desktop"));
      }
    };
    window.addEventListener("resize", onResize);
    onResize();
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const navegateTo = (path: string) => navegate(path);

  return device === "mobile" ? (
    <MenuMobile
      open={isOpen}
      active={active}
      toogleMenu={toogleMenu}
      navegateTo={navegateTo}
    />
  ) : (
    <MenuDesktop
      open={isOpen}
      active={active}
      toogleMenu={toogleMenu}
      navegateTo={navegateTo}
    />
  );
}
