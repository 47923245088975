/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import * as B from "../../components/base/Buttons";
import { BoxLogin } from "../../components/boxLogin";
import { InputPassword } from "../../components/Inputs/InputPassword";
import { InputBase } from "../../components/Inputs/InputText";
import { useLoginRedirect } from "../../hooks/useLoginRedirect";
import { api } from "../../services/api";
import { login } from "../../services/auth";
import { setLoading, setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getClientUrl, removeMask } from "../../utils/utils";
import * as S from "./styles";

type FormDataLogin = {
  password: string;
  cpf: string;
};

export function Login() {
  const { handleSubmit, control, getValues } = useForm<FormDataLogin>();
  const [verifyCpf, setVerifyCpf] = useState({
    ind_account: false,
    ind_solicitation_active: false,
  });

  const navegate = useNavigate();
  const dispatch = useAppDispatch();
  const [{ clientLogged }, { theme }] = useAppSelector(({ Clients, Utils }) => [
    Clients,
    Utils,
  ]);

  const onSubmit: SubmitHandler<FormDataLogin> = async (body) => {
    try {
      dispatch(setLoading(true));
      const { cpf, password } = body;
      const newCpf = removeMask(cpf);

      if (!password) {
        // verifica se o usuário tem conta ativa
        const { data } = await api.get(`/user/verify-account/${newCpf}`);
        setVerifyCpf(data);
        if (!data.ind_account && data.ind_solicitation_active)
          navegate(`/${getClientUrl()}/cadastro`, {
            state: newCpf,
          });
        return;
      }

      const { data } = await api.post("/user/login", { cpf: newCpf, password });
      login(data.token);
      navegate(`/${getClientUrl()}/home`);
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    } finally {
      dispatch(setLoading(false));
    }
  };

  const toAgendamento = (cpf: string) => {
    navegate(`/${getClientUrl()}/agendar`, {
      state: cpf,
    });
  };

  const isLogged = useLoginRedirect();

  useEffect(() => {
    isLogged();
  }, []);

  return (
    <S.Container>
      <S.Content>
        <S.ContentLogin>
          <BoxLogin>
            <form
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              style={{ width: "100%" }}
            >
              <Grid item xs={12}>
                <InputBase
                  control={control}
                  id="CPF"
                  label="CPF"
                  name="cpf"
                  type="cpf"
                  required
                  disabled={verifyCpf.ind_account}
                />
              </Grid>
              {verifyCpf.ind_account && (
                <>
                  <Grid item xs={12}>
                    <InputPassword
                      control={control}
                      id="password"
                      label="Senha"
                      name="password"
                      required
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    display="flex"
                    justifyContent="flex-end"
                    marginBottom="1rem"
                  >
                    <S.LinkForgotPassword
                      onClick={() =>
                        navegate(`/${getClientUrl()}/recuperar-senha`)
                      }
                    >
                      Esqueceu a senha?
                    </S.LinkForgotPassword>
                  </Grid>
                </>
              )}
              <Grid item xs={12}>
                <B.ButtonPrimary type="submit" style={{ width: "100%" }}>
                  Acessar conta
                </B.ButtonPrimary>
              </Grid>
            </form>
            {clientLogged.ind_agendamento === "S" &&
              verifyCpf.ind_solicitation_active && (
                <Grid item xs={12} marginTop="1rem">
                  <B.ButtonPrimary
                    color="info"
                    variant="outlined"
                    style={{ width: "100%" }}
                    onClick={() => toAgendamento(getValues("cpf"))}
                  >
                    Agendar Preenchimento
                  </B.ButtonPrimary>
                </Grid>
              )}

            {clientLogged.ind_permitir_criar_conta === "S" && (
              <Grid item xs={12} marginTop="1rem">
                <B.ButtonLink
                  color="secondary"
                  to={`/${getClientUrl()}/cadastro`}
                  style={{ width: "100%" }}
                >
                  Criar conta
                </B.ButtonLink>
              </Grid>
            )}
          </BoxLogin>
        </S.ContentLogin>
        <S.BoxImage
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_BASE_URL_API}decreto-previrg`,
              "_blank"
            );
          }}
        >
          <img src={`/images/bunner_${theme.mode}.png`} alt="" />
        </S.BoxImage>
      </S.Content>
    </S.Container>
  );
}
