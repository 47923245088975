/* eslint-disable @typescript-eslint/no-explicit-any */
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import Modal from "../index";
import * as S from "./styles";

interface IModalAddNewStructuredTable {
  isOpen: boolean;
  onClose: () => void;
  getSolicitation: () => Promise<void>;
  idSolicitation: number;
}

export function WarningSendSolicitation({
  isOpen,
  onClose,
  idSolicitation,
  getSolicitation,
}: IModalAddNewStructuredTable) {
  const dispatch = useAppDispatch();

  const submitSolicitation = async () => {
    try {
      const { data } = await api.post(`/solicitation/submit/${idSolicitation}`);
      dispatch(setToastMessage({ type: "success", message: data.message }));
      await getSolicitation();
      onClose();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <S.ModalBody>
        <S.Header>
          <WarningAmberIcon fontSize="large" color="warning" />
          <T.H2 color="#ed6c02" margin="0 0 0 1rem">
            AVISO
          </T.H2>
        </S.Header>
        <T.Paragraph>
          Esta ação fara com que a solictação seja enviada para peenchimento,
          apartir dessa fase a mesma não podera ser editada ou excluida,
          usuarios não poderão ser inseridos ou removidos e usuarios vinculado
          serão notificados por email.
        </T.Paragraph>
        <S.Footer>
          <B.ButtonSecondary onClick={onClose}>Cancelar</B.ButtonSecondary>
          <B.ButtonPrimary onClick={submitSolicitation}>Enviar</B.ButtonPrimary>
        </S.Footer>
      </S.ModalBody>
    </Modal>
  );
}
