/* eslint-disable no-nested-ternary */
/* eslint-disable react/require-default-props */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Grid } from "@mui/material";
import { useEffect } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import { getIdURLUserSol, removeMoneyMask } from "../../../utils/utils";
import * as B from "../../base/Buttons";
import { InputBase } from "../../Inputs/InputText";
import Modal from "../index";
import * as S from "./styles";

interface IModalAddBensAndOuns {
  isOpen: boolean;
  onClose: () => void;
  returnIcon?: () => void;
  bensOnus?: FormData;
  updateListBensOnus: () => Promise<void>;
  isEditable: boolean;
  typeModal: "bens" | "onus";
  idDeclBens: number;
}

type FormData = {
  id_sol_dados_bens_onus?: number | null;
  id_sol_dados_decl_bens?: number | null;
  tipo_bem_onus_tab?: number | null;
  tipo_bem_onus_item?: number | null;
  quantidade?: string | null;
  descricao?: string | null;
  valor?: string | null;
};

export function AddBensAndOuns({
  isOpen,
  onClose,
  returnIcon,
  bensOnus,
  idDeclBens,
  updateListBensOnus,
  isEditable,
  typeModal,
}: IModalAddBensAndOuns) {
  const { handleSubmit, control, reset, setError } = useForm<FormData>();

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<FormData> = async (body) => {
    try {
      const { data } = await api.post(
        `/solicitation/bens-onus/save/${getIdURLUserSol()}/${idDeclBens || ""}`,
        {
          ...body,
          valor: body.valor ? removeMoneyMask(body.valor) : null,
          tipo_bem_onus_item: bensOnus?.tipo_bem_onus_item
            ? bensOnus.tipo_bem_onus_item
            : typeModal === "bens"
            ? 1
            : 2,
        }
      );
      dispatch(setToastMessage({ type: "success", message: data.message }));
      await updateListBensOnus();
      onClose();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
      if (error?.errors && error?.errors.length) {
        error.errors.forEach((error: any) => {
          setError(error.property, {
            type: "manual",
            message: error.error,
          });
        });
      }
    }
  };

  useEffect(() => {
    if (bensOnus?.id_sol_dados_bens_onus) {
      reset({
        ...bensOnus,
      });
    } else {
      reset({
        id_sol_dados_bens_onus: null,
        id_sol_dados_decl_bens: null,
        tipo_bem_onus_item: null,
        valor: null,
        quantidade: null,
        descricao: null,
      });
    }
  }, [bensOnus]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={
        bensOnus?.id_sol_dados_bens_onus
          ? "Editar Bens e Ónus"
          : "Adicionar Bens e Ónus"
      }
      returnIcon={returnIcon}
    >
      <S.ModalBody>
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          style={{ width: "100%" }}
        >
          <Grid
            container
            spacing={1}
            style={{ width: "100%", marginTop: "1rem" }}
          >
            {typeModal === "bens" && (
              <Grid item xs={12} md={2}>
                <InputBase
                  control={control}
                  id="quantidade"
                  label="Quantidade"
                  name="quantidade"
                  readOnly={!isEditable}
                  type="number"
                  required
                />
              </Grid>
            )}
            <Grid item xs={12} md={typeModal === "bens" ? 8 : 10}>
              <InputBase
                control={control}
                id="descricao"
                label="Descrição"
                name="descricao"
                readOnly={!isEditable}
                required
              />
            </Grid>
            <Grid item xs={12} md={2}>
              <InputBase
                control={control}
                id="valor"
                label="Valor"
                name="valor"
                readOnly={!isEditable}
                type="currency"
                required
              />
            </Grid>
          </Grid>
          {isEditable && (
            <Grid
              item
              xs={12}
              marginTop="1rem"
              display="flex"
              justifyContent="center"
            >
              <B.ButtonPrimary
                type="submit"
                style={{
                  width: "clamp(150px, 300px, 100%)",
                  marginBottom: "0.5rem",
                }}
              >
                {bensOnus?.id_sol_dados_bens_onus ? "Salvar" : "Adicionar"}
              </B.ButtonPrimary>
            </Grid>
          )}
        </form>
      </S.ModalBody>
    </Modal>
  );
}
