/* eslint-disable @typescript-eslint/no-explicit-any */
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import * as B from "../../base/Buttons";
import * as T from "../../base/Text";
import Modal from "../index";
import * as S from "./styles";

interface IModalFinalMessageSolicitation {
  isOpen: boolean;
  onClose: () => void;
  message: string;
}

export function FinalMessageSolicitation({
  isOpen,
  onClose,
  message,
}: IModalFinalMessageSolicitation) {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <S.ModalBody>
        <S.Header>
          <CheckCircleIcon fontSize="large" color="success" />
          <T.H3 margin="0 0 0 1rem" color="#2e7d32">
            SOLICITAÇÃO CONCLUIDA
          </T.H3>
        </S.Header>
        <T.Paragraph>{message}</T.Paragraph>
        <S.Footer>
          <B.ButtonPrimary variant="outlined" onClick={onClose}>
            Fechar
          </B.ButtonPrimary>
        </S.Footer>
      </S.ModalBody>
    </Modal>
  );
}
