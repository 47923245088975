/* eslint-disable @typescript-eslint/no-explicit-any */
import ArticleIcon from "@mui/icons-material/Article";
import AssuredWorkloadIcon from "@mui/icons-material/AssuredWorkload";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import { useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { api } from "../../../services/api";
import { setToastMessage } from "../../../store/ducks/Utils/actions";
import { useAppDispatch } from "../../../store/hooks";
import { ISolicitationProps } from "../../../types/Solicitation";
import { convertSOrN } from "../../../utils/utils";
import { CardPrimery } from "../../base/Cards";
import { ButtonRadioStyles } from "../../base/stylesGeneric";
import * as T from "../../base/Text";
import * as S from "../styles";

type IdentificationsProps = {
  solicitation: ISolicitationProps;
  isEdit: boolean;
  getSolicitation: () => Promise<void>;
};

export function Identifications({
  solicitation,
  getSolicitation,
  isEdit,
}: IdentificationsProps) {
  const { handleSubmit, control, reset } = useForm<any>();

  const dispatch = useAppDispatch();

  const onSubmit = () => null;

  const update = async (name: string, value: boolean) => {
    try {
      const body = {
        [name]: convertSOrN(value),
      };
      await api.put(
        `/solicitation/update/${solicitation?.id_solicitacao}`,
        body
      );
      getSolicitation();
    } catch (err: any) {
      const error = err.response.data;
      dispatch(setToastMessage({ type: "error", message: error.message }));
    }
  };

  useEffect(() => {
    if (solicitation?.id_solicitacao) {
      reset({ ...solicitation });
    }
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <CardPrimery container margin="1rem" padding="1.5rem 2rem">
        <S.BoxGrid>
          <Controller
            name="exigir_dados_ident"
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <S.BoxLabel checked={value} isEdit={isEdit}>
                  <T.H5 textAlign="center">Exigir Identificação</T.H5>
                  <ManageAccountsIcon fontSize="large" />
                  <T.Span textAlign="center">
                    Sera solicidado dados de identificação do usuário, como:
                    nome completo, CPF, data de nascimento, sexo, estado civil,
                    nacionalidade, endereço, etc.
                  </T.Span>
                  <S.BoxStyleRadios>
                    <ButtonRadioStyles checked={value} />
                  </S.BoxStyleRadios>
                  <div style={{ display: "none" }}>
                    <input
                      type="checkbox"
                      name="exigir_dados_ident"
                      checked={value || false}
                      disabled={!isEdit}
                      onChange={(e) => {
                        update(name, e.target.checked);
                        onChange(e);
                      }}
                    />
                  </div>
                </S.BoxLabel>
              );
            }}
          />

          <Controller
            name="exigir_dados_compl"
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <S.BoxLabel checked={value} isEdit={isEdit}>
                  <T.H5 textAlign="center">Exigir Dados Complementares</T.H5>
                  <FactCheckIcon fontSize="large" />
                  <T.Span textAlign="center">
                    Sera solicitado dados complementares do usuário, como:
                    e-mail, telefone celular, telefone fixo, endereço atual,
                    etc.
                  </T.Span>
                  <S.BoxStyleRadios>
                    <ButtonRadioStyles checked={value} />
                  </S.BoxStyleRadios>
                  <div style={{ display: "none" }}>
                    <input
                      type="checkbox"
                      name="exigir_dados_compl"
                      checked={value || false}
                      disabled={!isEdit}
                      onChange={(e) => {
                        update(name, e.target.checked);
                        onChange(e);
                      }}
                    />
                  </div>
                </S.BoxLabel>
              );
            }}
          />

          <Controller
            name="exigir_dados_depend"
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <S.BoxLabel checked={value} isEdit={isEdit}>
                  <T.H5 textAlign="center">Exigir Dados dos Dependentes</T.H5>
                  <GroupAddIcon fontSize="large" />
                  <T.Span textAlign="center">
                    Sera solicitado dados dos dependentes do usuário, como:
                    nome, CPF, data de nascimento, sexo, etc.
                  </T.Span>
                  <S.BoxStyleRadios>
                    <ButtonRadioStyles checked={value} />
                  </S.BoxStyleRadios>
                  <div style={{ display: "none" }}>
                    <input
                      type="checkbox"
                      checked={value || false}
                      disabled={!isEdit}
                      name="exigir_dados_depend"
                      onChange={(e) => {
                        update(name, e.target.checked);
                        onChange(e);
                      }}
                    />
                  </div>
                </S.BoxLabel>
              );
            }}
          />

          <Controller
            name="exigir_dados_vinc_trab"
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <S.BoxLabel checked={value} isEdit={isEdit}>
                  <T.H5 textAlign="center">Exigir Vinculos Trabalhistas</T.H5>
                  <AssuredWorkloadIcon fontSize="large" />
                  <T.Span textAlign="center">
                    Sera solicitado dados das fontes de renda do usuário,
                    vinculos com municipios, etc.
                  </T.Span>
                  <S.BoxStyleRadios>
                    <ButtonRadioStyles checked={value} />
                  </S.BoxStyleRadios>
                  <div style={{ display: "none" }}>
                    <input
                      type="checkbox"
                      checked={value || false}
                      disabled={!isEdit}
                      name="exigir_dados_vinc_trab"
                      onChange={(e) => {
                        update(name, e.target.checked);
                        onChange(e);
                      }}
                    />
                  </div>
                </S.BoxLabel>
              );
            }}
          />

          <Controller
            name="exigir_dados_beneficios"
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <S.BoxLabel checked={value} isEdit={isEdit}>
                  <T.H5 textAlign="center">Exigir Beneficios</T.H5>
                  <AttachMoneyIcon fontSize="large" />
                  <T.Span textAlign="center">
                    Sera solicitado dados dos beneficios do usuário, como:
                    aposentadoria e pensão, etc.
                  </T.Span>
                  <S.BoxStyleRadios>
                    <ButtonRadioStyles checked={value} />
                  </S.BoxStyleRadios>
                  <div style={{ display: "none" }}>
                    <input
                      type="checkbox"
                      checked={value || false}
                      disabled={!isEdit}
                      name="exigir_dados_beneficios"
                      onChange={(e) => {
                        update(name, e.target.checked);
                        onChange(e);
                      }}
                    />
                  </div>
                </S.BoxLabel>
              );
            }}
          />

          <Controller
            name="exigir_dados_decl_bens"
            control={control}
            render={({ field: { onChange, value, name } }) => {
              return (
                <S.BoxLabel checked={value} isEdit={isEdit}>
                  <T.H5 textAlign="center">
                    Exigir Declaração de Bens e Onus
                  </T.H5>
                  <ArticleIcon fontSize="large" />
                  <T.Span textAlign="center">
                    Sera solicitado dados sobre a declaração de bens e onus do
                    usuário, como: imóveis, veículos, etc.
                  </T.Span>
                  <S.BoxStyleRadios>
                    <ButtonRadioStyles checked={value} />
                  </S.BoxStyleRadios>
                  <div style={{ display: "none" }}>
                    <input
                      type="checkbox"
                      checked={value || false}
                      disabled={!isEdit}
                      name="exigir_dados_decl_bens"
                      onChange={(e) => {
                        update(name, e.target.checked);
                        onChange(e);
                      }}
                    />
                  </div>
                </S.BoxLabel>
              );
            }}
          />
        </S.BoxGrid>
      </CardPrimery>
    </form>
  );
}
