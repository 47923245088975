/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable @typescript-eslint/no-explicit-any */
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ScheduleIcon from "@mui/icons-material/Schedule";
import { Grid } from "@mui/material";
import { AxiosError } from "axios";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";

import * as B from "../../components/base/Buttons";
import * as T from "../../components/base/Text";
import { BoxLogin } from "../../components/boxLogin";
import { InputBase } from "../../components/Inputs/InputText";
import { AgendarPreench } from "../../components/Popover/AgendarPreench";
import { api } from "../../services/api";
import { setLoading, setToastMessage } from "../../store/ducks/Utils/actions";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IAgendamento } from "../../types/Agendamento";
import {
  convertData,
  copyToClipboard,
  getClientUrl,
  removeMask,
} from "../../utils/utils";
import * as S from "./styles";

type FormDataLogin = {
  cpf: string;
  dataHora: string | Date;
};

type Endereo = {
  id_config_agendamento: number;
  dt_inico_ag: string;
  dt_final_ag: string;
  descricao: string;
  ind_ativo: "S" | "N";
  endereco_atend_presencial: string;
};

type DataSolicitProps = {
  id_sol_usuario: number;
  id_solicitacao: number;
  numero_cpf: string;
  email: string;
  descr_solicitacao: string;
  dt_inicio_preench?: string;
  dt_fim_preench?: string;
  anexos?: Array<{
    id_anexo_solicitado: number;
    id_solicitacao: number;
    descricao: string;
  }>;
  enderecos?: Array<Endereo>;
};

type DataUserProps = {
  ind_solicitation_active: boolean;
  solicitacoesPreenchimento: DataSolicitProps[];
  listaAgendamentos: Array<
    IAgendamento & {
      solicitacao: DataSolicitProps;
    }
  >;
  listaEnderecos: Array<Endereo>;
};

export function AgendarPreenchimento() {
  const { handleSubmit, control, setValue } = useForm<FormDataLogin>();
  const [dataUser, setDataUser] = useState<DataUserProps>({} as DataUserProps); // busca dados do usuário
  const [solicitationSelected, setSolicitationSelected] = useState(0); // busca dados do usuário
  const [enderecoSelected, setEnderecoSelected] = useState(0); // busca dados do usuário

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const navegate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { clientLogged } = useAppSelector(({ Clients }) => Clients);

  const onSubmit: SubmitHandler<FormDataLogin> = async (body) => {
    try {
      dispatch(setLoading(true));
      const { cpf } = body;
      const newCpf = removeMask(cpf);

      // verifica se o usuário tem conta ativa ou solicitação pendentes
      const { data } = await api.get(
        `/agendamento/verify-agendamento/${newCpf}`
      );
      if (!data.ind_account && data.ind_solicitation_active) {
        dispatch(
          setToastMessage({
            type: "error",
            message:
              "Voce não possui cadastro e nem solicitaçõe para preencimento!",
          })
        );
        navegate(`/${getClientUrl()}/cadastro`, { state: newCpf });
        return;
      }

      if (data.listaEnderecos.length === 1)
        setEnderecoSelected(data.listaEnderecos[0].id_config_agendamento);
      if (data.solicitacoesPreenchimento.length === 1)
        setSolicitationSelected(
          data.solicitacoesPreenchimento[0].id_sol_usuario
        );

      setDataUser({
        ind_solicitation_active: !!data.solicitacoesPreenchimento.length,
        ...data,
      });
      return;
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    } finally {
      dispatch(setLoading(false));
    }
  };

  const onAgendar = async (dateHour: any) => {
    try {
      const dataHora = new Date(`${dateHour?.data}T${dateHour?.hora}`);
      if (dataHora.toString() === "Invalid Date") {
        dispatch(
          setToastMessage({
            type: "error",
            message: "Selecione uma data e hora para agendar!",
          })
        );
        return;
      }

      if (!solicitationSelected) {
        dispatch(
          setToastMessage({
            type: "error",
            message: "Selecione uma solicitação para agendar o preenchimento!",
          })
        );
        return;
      }

      const solicitacao = dataUser.solicitacoesPreenchimento.find(
        (sol) => sol.id_sol_usuario === solicitationSelected
      )!;

      const { data } = await api.post("/agendamento/agendar", {
        numero_cpf: solicitacao.numero_cpf,
        id_sol_usuario: solicitacao.id_sol_usuario,
        id_solicitacao: solicitacao.id_solicitacao,
        dataHora,
        idConfigAg: enderecoSelected,
      });

      const newlistaAgendamentos = [
        ...dataUser.listaAgendamentos,
        {
          ...data.data,
          solicitacao,
        },
      ];
      setDataUser({
        ...dataUser,
        listaAgendamentos: newlistaAgendamentos,
      });
      dispatch(setToastMessage({ type: "success", message: data.message }));
    } catch (err) {
      if (err instanceof AxiosError) {
        const error = err.response?.data;
        dispatch(setToastMessage({ type: "error", message: error.message }));
      }
    } finally {
      setAnchorEl(null);
    }
  };

  useEffect(() => {
    if (clientLogged?.nome_cliente && clientLogged.ind_agendamento !== "S") {
      navegate(`/${getClientUrl()}/login`);
      dispatch(setToastMessage({ type: "error", message: "Acesso negado!" }));
    }
  }, [clientLogged]);

  useEffect(() => {
    if (location.state) {
      const cpf = location.state as string;
      setDataUser({} as DataUserProps);
      setSolicitationSelected(0);
      setValue("cpf", cpf);
      handleSubmit(onSubmit)();
    }
  }, []);

  return (
    <S.Container>
      <S.Content>
        <BoxLogin>
          <form
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            style={{ width: "100%" }}
          >
            <Grid item xs={12} marginBottom="15px">
              <T.H5>Agendar preenchimento</T.H5>
            </Grid>
            <Grid item xs={12}>
              <InputBase
                control={control}
                id="CPF"
                label="CPF"
                name="cpf"
                type="cpf"
                required
                disabled={dataUser.ind_solicitation_active}
              />
            </Grid>

            {dataUser.ind_solicitation_active && (
              <>
                {dataUser.listaEnderecos
                  .filter((e) => e.ind_ativo === "S")
                  .map((end) => (
                    <S.BoxEndereco
                      item
                      xs={12}
                      marginBottom="20px"
                      key={end.id_config_agendamento}
                      selected={end.id_config_agendamento === enderecoSelected}
                      onClick={() => {
                        setEnderecoSelected(end.id_config_agendamento);
                      }}
                    >
                      <T.Paragraph>
                        <b>Local:</b> {end.descricao}
                      </T.Paragraph>
                      <T.Paragraph>
                        <b>Periodo de atendimento:</b> <br />{" "}
                        {convertData(end.dt_inico_ag)} até{" "}
                        {convertData(end.dt_final_ag)}
                      </T.Paragraph>
                      <T.Paragraph>
                        <b>Endereço:</b> {end.endereco_atend_presencial}
                      </T.Paragraph>
                    </S.BoxEndereco>
                  ))}

                <Grid item xs={12}>
                  <T.H6>Solicitações em fase de preenchimento</T.H6>
                  {dataUser.solicitacoesPreenchimento.length &&
                    dataUser.solicitacoesPreenchimento
                      .filter((s) =>
                        s.enderecos?.find(
                          (e) => e.id_config_agendamento === enderecoSelected
                        )
                      )
                      .map((solicitacao) => (
                        <S.BoxSolicitation
                          key={solicitacao.id_sol_usuario}
                          selected={
                            solicitacao.id_sol_usuario === solicitationSelected
                          }
                          onClick={() => {
                            setSolicitationSelected(solicitacao.id_sol_usuario);
                          }}
                        >
                          <T.Paragraph textOverflow overflow weight="bold">
                            {solicitacao.descr_solicitacao}
                          </T.Paragraph>
                          {solicitacao.dt_inicio_preench && (
                            <T.Span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <CalendarMonthIcon />
                              {convertData(
                                solicitacao.dt_inicio_preench
                              )} até {convertData(solicitacao.dt_fim_preench)}
                            </T.Span>
                          )}
                          {!!solicitacao?.anexos?.length && (
                            <>
                              <T.Paragraph
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <T.Span
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <ContactPageIcon />
                                  Documentos solicitados:
                                </T.Span>
                              </T.Paragraph>
                              <ul style={{ padding: "revert" }}>
                                {solicitacao.anexos.map((anexo) => (
                                  <li key={anexo.id_anexo_solicitado}>
                                    <T.Span>{anexo.descricao}</T.Span>
                                  </li>
                                ))}
                              </ul>
                            </>
                          )}
                        </S.BoxSolicitation>
                      ))}
                </Grid>

                {!!dataUser.listaAgendamentos.length && (
                  <Grid item xs={12} marginTop="20px">
                    <T.H6>Agentamentos</T.H6>
                    {dataUser.listaAgendamentos.length &&
                      dataUser.listaAgendamentos.map((ag) => (
                        <S.BoxHoraAgendado key={ag.id_solicit_agendamento}>
                          <T.Paragraph textOverflow overflow>
                            {ag.solicitacao.descr_solicitacao}
                          </T.Paragraph>

                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <T.Paragraph width="auto" size="14px">
                              Protocolo:{" "}
                            </T.Paragraph>
                            <T.Paragraph
                              textOverflow
                              overflow
                              size="14px"
                              textAlign="end"
                            >
                              {ag.protocolo_agendamento}
                            </T.Paragraph>
                            <ContentCopyIcon
                              style={{ cursor: "pointer", fontSize: "16px" }}
                              onClick={() => {
                                copyToClipboard(ag.protocolo_agendamento);
                                dispatch(
                                  setToastMessage({
                                    type: "success",
                                    message: "Protocolo copiado!",
                                  })
                                );
                              }}
                            />
                          </div>

                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            <T.Span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <CalendarMonthIcon />
                              {convertData(ag.dt_agendamento)}
                            </T.Span>
                            <T.Span
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "5px",
                              }}
                            >
                              <ScheduleIcon />
                              {ag.hr_agendamento}
                            </T.Span>
                          </div>
                        </S.BoxHoraAgendado>
                      ))}
                  </Grid>
                )}

                <Grid item xs={12} marginTop="20px">
                  <B.ButtonPrimary
                    style={{ width: "100%" }}
                    onClick={(e: any) => {
                      if (!enderecoSelected) {
                        dispatch(
                          setToastMessage({
                            type: "error",
                            message: "Selecione um endereço para agendar!",
                          })
                        );
                        return;
                      }
                      setAnchorEl(e.currentTarget);
                    }}
                  >
                    Ver horarios disponiveis
                  </B.ButtonPrimary>
                </Grid>
              </>
            )}
            {!dataUser.ind_solicitation_active && (
              <Grid item xs={12}>
                <B.ButtonPrimary type="submit" style={{ width: "100%" }}>
                  Acessar agenda
                </B.ButtonPrimary>
              </Grid>
            )}
          </form>
          <Grid item xs={12} marginTop="1rem">
            <B.ButtonLink
              color="secondary"
              to={`/${getClientUrl()}/login`}
              style={{ width: "100%" }}
            >
              Login
            </B.ButtonLink>
          </Grid>
        </BoxLogin>
      </S.Content>
      <AgendarPreench
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        handleClose={() => setAnchorEl(null)}
        idAgendamento={enderecoSelected}
        onClick={onAgendar}
      />
    </S.Container>
  );
}
